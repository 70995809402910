import React, { forwardRef } from 'react';
import {
  AppBar,
  Chip,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import PostSalesModalContent from './PostSalesModalContent';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  getLeadStatusColor,
  getStatusLabel,
  splitByUnderscoresAndCapitalize,
} from '../../../../utils/common';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

export default function PostSalesLeadModal({
  openDetailedPostSalesLead,
  handleDetailedModalClose,
  shouldShowPostSalesInSmallScreen,
}) {
  return (
    <Dialog
      fullScreen={!shouldShowPostSalesInSmallScreen}
      maxWidth={shouldShowPostSalesInSmallScreen ? 'lg' : 'lg'}
      PaperProps={{
        sx: shouldShowPostSalesInSmallScreen
          ? {
              marginLeft: '10px',
              marginRight: '10px',
              width: `calc(100% - ${'20px'})`,
              height: '80%',
            }
          : false,
      }}
      open={openDetailedPostSalesLead}
      onClose={handleDetailedModalClose}
      TransitionComponent={Transition}
    >
      <PostSalesLeadModalNavbar
        handleDetailedModalClose={handleDetailedModalClose}
      />

      <PostSalesModalContent />
    </Dialog>
  );
}

function PostSalesLeadModalNavbar({ handleDetailedModalClose }) {
  const { leadData } = useCurrentPostSalesLead();
  const backgroundColor = getLeadStatusColor(leadData.lead_status ?? '');

  return (
    <AppBar
      sx={{
        position: 'relative',
        backgroundColor: backgroundColor,
        color: '#000',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
        >
          <Typography
            variant='h6'
            component='p'
          >
            {`${leadData.lead_name} #${leadData.lead_id}`}
          </Typography>

          <Tooltip title='Open lead details in new tab'>
            <IconButton
              edge='start'
              onClick={(e) => {
                window.open(`/leads/${leadData.lead_id}`, '_blank', 'noopener');
              }}
            >
              <OpenInNewIcon fontSize='small' />
            </IconButton>
          </Tooltip>

          {getStatusLabel(leadData.lead_status, leadData.is_hot)}

          <Chip
            label={splitByUnderscoresAndCapitalize(leadData.status)}
            size='small'
            sx={{
              fontWeight: 500,
              '& .MuiChip-icon': {
                color: 'red',
              },
            }}
          />
        </Stack>

        <IconButton
          edge='start'
          color='inherit'
          onClick={handleDetailedModalClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
