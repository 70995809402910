import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { Typography } from '@mui/material';

import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../components/Mode';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '40%',
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(-1),
  },
  fileName: {
    marginTop: theme.spacing(0),
    textAlign: 'center',
    cursor: 'pointer',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px',
    borderRadius: '10px',
    padding: theme.spacing(2, 4, 3),
  },
  buttonYes: {
    width: '200px',
    marginTop: '20px',
    backgroundColor: 'blue',
    color: 'white',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'darkblue',
    },
  },
  buttonNo: {
    width: '200px',
    marginTop: '20px',
    marginLeft: '20px',
    color: 'black',
    border: '1px solid black',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
}));

const CandidateResumes = ({ resumeDetails }) => {
  const classes = useStyles();
  const [isModelOpenForDeletingResume, setModelOpenForDeletingResume] =
    useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const deleteResume = () => {
    setModelOpenForDeletingResume(true);
  };

  const handleCloseModal = () => {
    setModelOpenForDeletingResume(false);
  };

  const handleDeleteConfirmed = async (resume_id) => {
    const resumeUrl =
      base_url() +
      '/api/hrms/hrms_candidates/' +
      resumeDetails.candidate_id +
      '/hrms_candidate_resumes/' +
      resume_id;

    await axiosInstance
      .delete(resumeUrl)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Resume deleted Sucessfully');
      })
      .catch((err) => {
        console.log('err', err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message);
      });

    setModelOpenForDeletingResume(false);
  };

  const openResumeInNewTab = (pdfUrl) => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    } else {
      setHasError(true);
      setErrorMessage('Resume URL is missing.');
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Paper
        className={classes.root}
        elevation={3}
        square
      >
        <div className={classes.iconContainer}>
          <IconButton
            onClick={() => {
              deleteResume();
            }}
            color='secondary'
          >
            <CloseIcon />
          </IconButton>
        </div>
        <PictureAsPdfIcon
          color='primary'
          fontSize='large'
        />
        <div
          onClick={() => openResumeInNewTab(resumeDetails.resume_path)}
          className={classes.fileName}
        >
          {resumeDetails.resume_path.split('amazonaws.com/')[1]}
        </div>

        <Modal
          className={classes.modal}
          open={isModelOpenForDeletingResume}
          onClose={handleCloseModal}
          aria-labelledby='modal-title'
          aria-describedby='modal-description'
        >
          <Paper className={classes.modalPaper}>
            <div style={{ height: '150px' }}>
              <Typography
                fontWeight={'bold'}
                fontSize={'26px'}
                display={'inline-table'}
                paddingTop={'20px'}
              >
                Are you sure you want to delete this?
              </Typography>
              <br />
              <Button
                onClick={() => handleDeleteConfirmed(resumeDetails.id)}
                color='secondary'
                className={classes.buttonYes}
              >
                Yes
              </Button>
              <Button
                onClick={handleCloseModal}
                className={classes.buttonNo}
                color='primary'
              >
                No
              </Button>
            </div>
          </Paper>
        </Modal>
      </Paper>
    </>
  );
};

export default CandidateResumes;
