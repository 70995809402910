import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { base_url } from '../../../components/Mode';
import { Chip, Stack } from '@mui/material';

export default function AssigneeOverview({ setHasError, setErrorMessage }) {
  const [overview, setOverview] = useState([]);
  const [perPage, setPerPage] = useState(50);

  useEffect(() => {
    fetchAssigneeOverview();
    return () => {};
  }, []);

  function fetchAssigneeOverview() {
    axiosInstance
      .get(`/api/admins/summary?type=assignee_overview`)
      .then((res) => {
        const tmpOverview = [];
        Object.keys(res.data).forEach((id) => {
          if (!('inbound' in res.data[id])) {
            res.data[id]['inbound'] = 0;
          }
          if (!('outbound' in res.data[id])) {
            res.data[id]['outbound'] = 0;
          }
          tmpOverview.push(res.data[id]);
        });
        setOverview(tmpOverview);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch assignee overview"
        );
      });
  }

  const columns = [
    {
      field: 'id',
      headerName: 'S.No',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ id, row }) => {
        const components = [<Typography>{id}</Typography>];

        if (row.newlyAssignedLeads > 0) {
          components.push(
            <Chip
              label={`${row.newlyAssignedLeads} new`}
              color='error'
              variant='outlined'
              size='small'
              sx={{ ml: 1 }}
            />
          );
        }
        return components;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => params.row.name,
    },
    {
      field: 'inbound',
      headerName: 'Inbound',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      valueGetter: (params) => params.row?.inbound,
    },
    {
      field: 'outbound',
      headerName: 'Outbound',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      valueGetter: (params) => params.row?.outbound,
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      valueGetter: (params) => params.row.inbound + params.row.outbound,
    },
  ];

  return (
    <>
      <Box width={850}>
        {overview.length > 0 && (
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            rows={overview}
            columns={columns}
            pageSize={perPage}
            rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
            onPageSizeChange={(page) => setPerPage(page)}
            pagination
            autoHeight
            disableSelectionOnClick
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#e3f2fd',
              },
              '& .MuiDataGrid-cell': {
                paddingLeft: '25px',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                paddingLeft: '20px',
              },
            }}
          />
        )}
      </Box>
      {overview.length === 0 && (
        <Typography
          variant='body1'
          alignSelf='center'
        >
          No data found
        </Typography>
      )}
    </>
  );
}
