import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  Typography,
  Stack,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { DataGrid } from '@mui/x-data-grid';
import { base_url } from '../../components/Mode';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import AddTechStackModal from './AddTechStackModal';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const initialState = {
  id: '',
  name: '',
  type: 'unknown',
};

export default function TechStacks() {
  const [techStacks, setTechStacks] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [openNewTechStackModal, setOpenNewTechStackModal] = useState(false);
  const [filters, setFilters] = useState(initialState);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    fetchTechStacks();
    return () => {};
  }, [filters]);

  const handleOpenAddModal = () => {
    setOpenNewTechStackModal(true);
  };

  const handleNewTechStackModal = () => {
    setOpenNewTechStackModal(!openNewTechStackModal);
  };

  const updateTechStackApiCall = (body, techStackId) => {
    axiosInstance
      .patch(`/api/tech-stacks/${techStackId}`, body)
      .then((res) => {
        const updatedTechStacks = techStacks.filter(
          (techStack) => techStack.id !== techStackId
        );

        setTechStacks(updatedTechStacks);
        setIsSuccess(true);
        setSuccessMessage('Tech Stack successfully updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the tech stack.'
        );
      });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      align: 'left',
      headerAlign: 'left',
      paddingLeft: '25px',
      valueGetter: (params) => `${params.row.name}`,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 400,
      align: 'left',
      headerAlign: 'left',
      paddingLeft: '25px',
      valueGetter: (params) => `${params.row.type}`,
      renderCell: (params) => {
        const states = [
          {
            name: 'tech_background',
            displayName: 'Tech background',
            color: 'success',
            size: 'small',
          },
          {
            name: 'non_tech_background',
            displayName: 'Non tech background',
            color: 'error',
            size: 'small',
          },
        ];

        return (
          <ButtonGroup size='small'>
            {states.map((state) => {
              return (
                <Button
                  size={state.size}
                  color={state.color}
                  variant={
                    params.row.type === state.name ? 'contained' : 'outlined'
                  }
                  onClick={() => {
                    const body = {
                      identified: state.name,
                    };
                    updateTechStackApiCall(body, params.row.id);
                  }}
                >
                  {state.displayName}
                </Button>
              );
            })}
          </ButtonGroup>
        );
      },
    },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const fetchTechStacks = () => {
    setLoading(true);
    const params = [];
    Object.entries(filters).forEach(([key, val], index) => {
      if (val && val !== '') {
        params.push(`${key}=${encodeURIComponent(val)}`);
      }
    });

    let url = `/api/tech-stacks`;
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    axiosInstance
      .get(url)
      .then((res) => {
        setTechStacks(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'Could not fetch the unknown tech stacks'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack
      width='100%'
      height='100%'
      spacing={2}
      sx={{
        mt: 5,
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant='h6'
            component='p'
          >
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={0.8}
            direction={{
              xs: 'column',
              sm: 'row',
            }}
          >
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='id'
                fullWidth
              >
                <TextField
                  size='small'
                  type='text'
                  name='id'
                  placeholder='Id'
                  onChange={handleChange}
                  value={filters?.id}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='name'
                fullWidth
              >
                <TextField
                  size='small'
                  type='text'
                  name='name'
                  placeholder='Name'
                  onChange={handleChange}
                  value={filters?.name}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='tech_stack_type'
                size='small'
                fullWidth
              >
                <InputLabel id='tech_stacks_type'>Type</InputLabel>
                <Select
                  name='type'
                  label='tech_stack_type'
                  labelId='tech_stack_type'
                  onChange={handleChange}
                  value={filters?.type}
                >
                  <MenuItem value='all'>All </MenuItem>
                  <MenuItem value='unknown'>Unknown background</MenuItem>
                  <MenuItem value='tech_background'>Tech background</MenuItem>
                  <MenuItem value='non_tech_background'>
                    Non Tech background
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Button
        variant='contained'
        onClick={handleOpenAddModal}
        sx={{
          alignSelf: 'flex-end',
        }}
      >
        Add Tech Stack
      </Button>

      {openNewTechStackModal && (
        <AddTechStackModal
          isOpen={openNewTechStackModal}
          onClose={handleNewTechStackModal}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
        />
      )}
      <DataGrid
        rows={techStacks}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[50, 100]}
        onPageSizeChange={(page) => setPerPage(page)}
        pagination
        autoHeight
        disableSelectionOnClick
        loading={loading}
      />
    </Stack>
  );
}
