import { Divider, Typography, Box, Modal } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { base_url } from '../components/Mode';
import { Search } from '../components/search/Search';
import LeadsDataTable from '../components/data-table/LeadsDataTable';
import {
  getOffsetFromPage,
  currentAdminIsIC,
  currentAdminIsTrainee,
  getCurrentAdminId,
  currentAdminIsTraineeOrIC,
  checkTraineeICAccess,
} from '../utils/common';

import { buildQueryStringFiltersToFetchLeads } from '../components/users/GetLeads';
import { Dialog, DialogContent, DialogContentText, Stack } from '@mui/material';
import LeadRequestModal from '../components/ToastNotifications/LeadRequestModal';
import Spinner from '../components/ToastNotifications/LeadRequestModal/Spinner';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesomeOutlined';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import constants from '../utils/constants';
import { useSocket } from '../socket/socketContext';

const initialState = {
  id: '',
  name: '',
  email: '',
  snoozed: '',
  snoozed_until: '',
  assignee: '',
  experience: '',
  college: '',
  company: '',
  role: '',
  label: '',
  lead_source_type: '',
  utm_campaign: '',
  status: '',
  product: '',
  note: '',
  tech_stack: '',
  lead_created_from: '',
  lead_created_to: '',
  tech_stack_type: '',
  pooled_state: '',
};

export function GlobalSearch() {
  // 'queryParams' contains all the query params from the browser's search bar as well as all the filters
  // manually added through global search options.
  const [queryParams, setQueryParams] = useState({});
  const [hotLeadsCheck, setHotLeadsCheck] = useState(false);
  const [leads, setLeads] = useState([]);
  const [limit, setLimit] = useState(50);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState('');
  const [searchParams, setSearchParams] = useState({}); // this only contains all the query params from the browser's search bar
  const [globalFilters, setGlobalFilters] = useState(initialState);
  const loc = useLocation();
  const navigate = useNavigate();
  const [fetachingLeadData, setFetachingLeadData] = useState(false);

  const location = useLocation();
  const [socket] = useSocket();
  useEffect(() => {
    if (location.state) {
      setGlobalFilters({ ...globalFilters, id: location.state });
    }
  }, [location.state]);

  useEffect(() => {
    if (socket && currentAdminIsIC()) {
      socket.emit('userOnline', { userId: getCurrentAdminId() });
    }

    // bucket-search?access=allowed&uid=171&k=lwejlffqpWKLFlwekjl32lIHKJHDlwerhe
    const currentLink = window.location.href.trim().split('?')[1];
    if (currentAdminIsTraineeOrIC()) {
      const urlParams = new URLSearchParams(currentLink);
      const uid = urlParams.get('uid');
      if (checkTraineeICAccess(currentLink, uid)) {
        navigate('/leads/priority-list');
      }
    }
  }, []);

  useEffect(() => {
    // URL params are being converted to filters for the API calls made through axiosInstance.
    if (loc.search.length > 1) {
      // because loc.search[0] = "?"
      const tmpQueryParams = {};
      const items = loc.search.substring(1).split('&');
      items.forEach((item, index) => {
        let [key, val] = item.split('=');
        val = val.replace(/%20/g, ' '); // browser converts " " => "%20" so we need to convert all of them back to " ".
        tmpQueryParams[key] = val;
      });
      setSearchParams(tmpQueryParams);
    }
    return () => {};
  }, [loc]);

  // Fetches data on page load
  useEffect(() => {
    if (
      (loc.search.length > 1 && Object.keys(searchParams).length > 0) ||
      loc.search.length <= 1
    ) {
      fetchData();
    }
  }, [currentPage, limit, columnFilters, searchParams]);

  const getSearchUrl = () => {
    const presetFilters = { ...searchParams }; // this also only contains all the query params from the browser's search bar
    const offset = getOffsetFromPage(currentPage, limit);
    let params = getAllGlobalFilters();
    setQueryParams({ ...params, ...presetFilters });

    let queryString = buildQueryStringFiltersToFetchLeads({
      ...params,
      ...presetFilters,
    });
    queryString += `&limit=${limit}`;
    queryString += `&offset=${offset}`;

    let url = base_url() + '/api/users';
    if (columnFilters !== '') {
      queryString += '&columnFilters[]=' + columnFilters;
    }
    url += '?' + queryString;
    return url;
  };

  const fetchData = () => {
    let url = getSearchUrl();
    setFetachingLeadData(true);
    axiosInstance.get(url).then((res) => {
      if (res['data']) {
        setLeads(res.data.leads);
        setTotalLeads(res['data']['total']);
      }
      setFetachingLeadData(false);
    });
  };

  const getAllGlobalFilters = () => {
    let params = {};
    for (let filter in globalFilters) {
      if (globalFilters[filter] === '' || !globalFilters[filter]) {
        continue;
      }
      if (filter === 'assignee' && globalFilters[filter] === '') {
        continue;
      }
      if (filter === 'snoozed_until') {
        params['snoozed_till'] = `${globalFilters[filter]}T23:59:59`; // defaults to EOD.
        continue;
      }
      params[filter] = globalFilters[filter];
    }

    if (hotLeadsCheck) {
      params['is_hot'] = 1;
    }
    return params;
  };

  const handleHotLeadsSelect = (event) => {
    if (event === 'reset') {
      setHotLeadsCheck(false);
    } else {
      setHotLeadsCheck(!hotLeadsCheck);
    }
  };

  const resetAllFilters = (e) => {
    // this resets globalFilters as well as filters set in browser's search bar.
    setGlobalFilters(initialState);
    handleHotLeadsSelect('reset');
    setSearchParams({});
    setQueryParams({});
    navigate('/bucket-search');
  };

  return (
    <>
      <Typography
        variant='h4'
        display='inline-block'
        component='div'
        gutterBottom
        marginTop='-2.5rem'
      >
        Bucket search
      </Typography>
      <Divider />
      <br />
      <Search
        hotLeadsCheck={hotLeadsCheck}
        handleHotLeadsSelect={handleHotLeadsSelect}
        searchParams={searchParams}
        filters={globalFilters}
        setFilters={setGlobalFilters}
        handleReset={resetAllFilters}
        fetchData={fetchData}
        fetachingLeadData={fetachingLeadData}
      />
      <LeadsDataTable
        data={leads}
        refreshResults={fetchData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalLeads={totalLeads}
        perPage={limit}
        setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
        setColumnFilters={(filter) => setColumnFilters(filter)}
        queryParams={queryParams}
        fetachingLeadData={fetachingLeadData}
      />
    </>
  );
}
