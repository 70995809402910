import React, { useState, useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';
import {
  getFormattedDate,
  splitByUnderscoresAndCapitalize,
  getStatusLabel,
} from '../../utils/common';

function MyConversions() {
  const [conversions, setConversions] = useState([]);

  useEffect(() => {
    getMyConversions();
  }, []);

  const getMyConversions = () => {
    const url = base_url() + '/api/admins/my-conversions';
    axiosInstance.get(url).then((res) => {
      setConversions(res.data);
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='h5'
            marginTop='-3%'
          >
            My Conversions
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Paper>
            <TableContainer sx={{ border: '1px solid #e0e0e0' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Lead</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Complete amount date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conversions.map((conversion, index) => {
                    const {
                      status,
                      created_at: createdAt,
                      name,
                      id,
                    } = conversion;
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {name} <code>#{id}</code>
                        </TableCell>
                        <TableCell>{getStatusLabel(status)}</TableCell>
                        <TableCell>
                          {getFormattedDate(
                            new Date(Date.parse(createdAt)),
                            true
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default MyConversions;
