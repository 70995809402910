import React, { useState, useEffect } from 'react';
import FinanceHandoverForm from './FinanceHandoverForm';
import {
  currentAdminIsFinance,
  currentAdminIsPgm,
} from '../../../../utils/common';
import { Button, Stack } from '@mui/material';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
import { financeHandoverForm } from '../../../../utils/financeHandoverForm';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../Mode';
import constants from '../../../../utils/constants';

const {
  PGM_HANDOVER_INITIATED,
  FINANCE_HANDOVER_INITIATED,
  PGM_HANDOVER_REJECTED,
} = constants;

const hasAllMandatoryFields = (editedForm) => {
  if (
    (editedForm[1]?.value ?? '') !== 'No' &&
    ((editedForm[2]?.value ?? '') === '' ||
      (editedForm[3]?.value ?? '') === '' ||
      (editedForm[4]?.value ?? '') === '')
  ) {
    return false;
  }

  return true;
};

const FinanceHandoverTab = () => {
  const { leadData, setLeadData, setToastNotificationMessage } =
    useCurrentPostSalesLead();
  const [rawForm, setRawForm] = useState(financeHandoverForm);
  const [editedForm, setEditedForm] = useState({});
  const [hasUpdate, setHasUpdate] = useState(false);

  useEffect(() => {
    prefillForm(leadData?.finance_handover ?? []);
    return () => {};
  }, [leadData]);

  const prefillForm = (savedResponse) => {
    const tmp = {};
    savedResponse.forEach((response) => {
      tmp[response.id] = {
        ...response,
      };
    });
    setEditedForm(tmp);
    setHasUpdate(false);
  };

  const handleSave = () => {
    if (Object.keys(editedForm).length === 0) {
      return;
    }

    const form = {
      ...editedForm,
    };

    Object.keys(editedForm).forEach((id) => {
      if ((editedForm[id] ?? '') === '') {
        delete form[id];
      }
    });

    if (!hasAllMandatoryFields(form)) {
      setToastNotificationMessage(
        'error',
        'One or more mandatory fields are missing.'
      );
      return;
    }

    const body = [];

    Object.keys(form).forEach((id) => {
      body.push(form[id]);
    });

    const content = {
      finance_handover: JSON.stringify(body),
    };

    axiosInstance
      .patch(`/api/post-sales-leads/${leadData.id}/finance-handover`, content)
      .then((res) => {
        setLeadData((prev) => ({
          ...prev,
          finance_handover: body,
        }));
        prefillForm(body);
        setToastNotificationMessage(
          'success',
          'Form updates successfully saved'
        );
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          'An error occured while saving the form updates'
        );
      });
  };

  const handleStatusUpdate = (status) => {
    const form = {
      ...editedForm,
    };

    Object.keys(editedForm).forEach((id) => {
      if ((editedForm[id] ?? '') === '') {
        delete form[id];
      }
    });

    if (Object.keys(form).length === 0 && status !== PGM_HANDOVER_REJECTED) {
      setToastNotificationMessage('error', 'Form cannot be empty');
      return;
    }
    axiosInstance
      .patch(`/api/post-sales-leads/${leadData.id}`, {
        status,
      })
      .then((res) => {
        setLeadData((prev) => ({
          ...prev,
          status: res.data.status,
        }));

        setToastNotificationMessage('success', 'Status updated successfully');
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          'An error ocurred while updating the status'
        );
      });
  };

  const shouldShowInitiateHandover = () => {
    if (Object.keys(editedForm).length === 0) {
      return false;
    }
    if (!currentAdminIsFinance()) {
      return false;
    }
    if (
      leadData.status === FINANCE_HANDOVER_INITIATED ||
      leadData.status === PGM_HANDOVER_REJECTED
    ) {
      return true;
    }
    return false;
  };

  const shouldShowRejectHandover = () => {
    if (!currentAdminIsPgm()) {
      return false;
    }
    if (leadData.status === PGM_HANDOVER_INITIATED) {
      return true;
    }
    return false;
  };

  const shouldShowApproveHandover = () => {
    if (!currentAdminIsPgm()) {
      return false;
    }
    if (leadData.status === PGM_HANDOVER_INITIATED) {
      return true;
    }
    return false;
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        spacing={1}
      >
        <Button
          variant='contained'
          size='small'
          onClick={handleSave}
          disabled={
            !hasUpdate ||
            Object.keys(editedForm).length === 0 ||
            !currentAdminIsFinance()
          }
        >
          Save
        </Button>

        <Button
          variant='outlined'
          size='small'
          onClick={(e) => prefillForm(leadData?.finance_handover ?? [])}
          disabled={!hasUpdate}
          color='error'
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          sx={{
            display: !shouldShowInitiateHandover() && 'none',
          }}
          onClick={(e) => handleStatusUpdate('pgm_handover_initiated')}
        >
          Initiate Handover
        </Button>

        <Button
          variant='contained'
          size='small'
          sx={{
            display: !shouldShowRejectHandover() && 'none',
          }}
          onClick={(e) => handleStatusUpdate('pgm_handover_rejected')}
        >
          Reject Handover
        </Button>

        <Button
          variant='contained'
          size='small'
          sx={{
            display: !shouldShowApproveHandover() && 'none',
          }}
          onClick={(e) => handleStatusUpdate('pgm_handover_approved')}
        >
          Approve Handover
        </Button>
      </Stack>

      <FinanceHandoverForm
        editedForm={editedForm}
        setEditedForm={setEditedForm}
        rawForm={rawForm}
        setHasUpdate={setHasUpdate}
      />
    </Stack>
  );
};

export default FinanceHandoverTab;
