import { useEffect, useState } from 'react';
import {
  splitByUnderscoresAndCapitalize,
  currentAdminIsHr,
  currentAdminIsTa,
} from '../../../utils/common';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../components/Mode';

const AdminRolesColumn = ({
  params,
  roles,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
  setErrorMessage,
}) => {
  const getRole = (admin) => {
    const selectedRoles = {};
    admin.roles.forEach((role) => {
      if (!selectedRoles[admin.id]) {
        selectedRoles[admin.id] = [];
      }
      selectedRoles[admin.id].push(role.name);
    });

    return selectedRoles[admin.id] || [];
  };

  const [selectedRoleNames, setSelectedRoleNames] = useState(
    getRole(params.row)
  );

  const isHr = currentAdminIsHr();
  const isTa = currentAdminIsTa();
  roles =
    isHr || isTa
      ? roles.filter((ele, index) => {
          return (
            ele.name === 'hr' ||
            ele.name === 'talent_acquisition' ||
            ele.name === 'marketing' ||
            ele.name === 'senior_marketing_manager' ||
            ele.name === 'interviewer'
          );
        })
      : roles;

  const addRole = async (adminId, roleId) => {
    const data = {
      role_id: roleId,
      admin_id: adminId,
    };
    try {
      await axiosInstance.post(`/api/admin-roles`, data);
      const newRole = roles.find((role) => role.id === roleId).name;
      const updatedSelectedRoles = [...selectedRoleNames, newRole];
      setSelectedRoleNames(updatedSelectedRoles);

      setIsSuccess(true);
      setSuccessMessage('New role added');
      return newRole;
    } catch (error) {
      setHasError(true);
      setErrorMessage('Could not add the role');
      console.error('Error adding role', error);
    }
  };

  const deleteRole = async (adminId, id) => {
    try {
      const response = await axiosInstance.delete(
        `/api/admins/${adminId}/roles/${id}`,
        {
          params: {
            admin_id: adminId,
            role_id: id,
          },
        }
      );
      const deletedRoles = selectedRoleNames.filter(
        (name) => name !== roles.find((role) => role.id === id).name
      );
      setSelectedRoleNames(deletedRoles);
      setIsSuccess(true);
      setSuccessMessage('Role removed successfully');
      return response.data;
    } catch (error) {
      console.error('Error adding role', error);
      setHasError(true);
      setErrorMessage('Could not remove the role');
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '30em',
        width: 350,
      },
    },
  };

  const handleChange = async (event) => {
    const {
      target: { value, checked },
    } = event;

    try {
      if (checked) {
        await addRole(params.row.id, value);
      } else {
        if (selectedRoleNames.length > 1) {
          await deleteRole(params.row.id, value);
        } else {
          setHasError(true);
          setErrorMessage('Minimum one role is required');
        }
      }
    } catch (error) {
      console.error('Error adding role:', error);
    }
  };

  const renderCellValue = (selected) => {
    return selected
      .map((selectedRoleNames) => {
        const selectedRole = roles.find(
          (role) => role.name === selectedRoleNames
        );
        return selectedRole
          ? splitByUnderscoresAndCapitalize(selectedRole.name)
          : '';
      })
      .join(', ');
  };

  return (
    <FormControl sx={{ m: 1, width: 380 }}>
      <Select
        labelId='Roles_Dropdown'
        id='Roles_Dropdown'
        multiple
        value={selectedRoleNames}
        MenuProps={MenuProps}
        renderValue={renderCellValue}
      >
        {roles.map((role) => (
          <MenuItem
            key={role.id}
            value={role.id}
          >
            <Checkbox
              checked={selectedRoleNames.includes(role.name)}
              onChange={(event) =>
                handleChange({
                  target: {
                    value: role.id,
                    checked: event.target.checked,
                  },
                })
              }
            />
            <ListItemText
              primary={splitByUnderscoresAndCapitalize(role.name)}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AdminRolesColumn;
