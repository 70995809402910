import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Stack, Button } from '@mui/material';
import moment from 'moment';
import axiosInstance from 'apis/axiosInstance';
import { useCurrentLead } from '../CurrentLeadContext';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { base_url } from '../Mode';
import FormGeneratedModal from './FormGeneratedModal';

const presetValues = [
  { label: '10 minutes', value: 10 * 60 * 1000 },
  { label: '30 minutes', value: 30 * 60 * 1000 },
  { label: '1 hour', value: 60 * 60 * 1000 },
  { label: '2 hours', value: 2 * 60 * 60 * 1000 },
  { label: '3 hours', value: 3 * 60 * 60 * 1000 },
];

export default function UpdateEligibilityFormModal({
  openUpdateModal,
  closeUpdateModal,
}) {
  const { currentLead } = useCurrentLead();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const [form, setForm] = useState({});
  const [defaultExpiry, setDefaultExpiry] = useState(
    moment().add(2, 'hours').toDate()
  );
  const [selectedPreset, setSelectedPreset] = useState(2 * 60 * 60 * 1000);
  const [expiry, setExpiry] = useState(null);

  useEffect(() => {
    setExpiry(moment(defaultExpiry));
  }, [defaultExpiry]);

  const handlePresetClick = (value) => {
    setExpiry(moment().add(value, 'milliseconds'));
  };

  const isFormExpiryValid = () => {
    const value = expiry.clone();
    const maxAllowedTime = moment().add(3, 'hours');

    if (value.isSameOrBefore(moment())) {
      setHasError(true);
      setErrorMessage('Form expiry cannot be in past.');
      return false;
    }

    if (value.isAfter(maxAllowedTime)) {
      setHasError(true);
      setErrorMessage(
        `Form expiry must be less than ${maxAllowedTime.format(
          'MMM Do YYYY, h:mm A'
        )}`
      );
      return false;
    }

    return true;
  };

  const leadDetailsRequired = () => {
    const setFields = [];
    if (!currentLead.experience) {
      setFields.push('experience');
    }
    if (!currentLead.mobile) {
      setFields.push('phone');
    }
    if (!currentLead.graduation_year) {
      setFields.push('graduation year');
    }
    if (!currentLead.email) {
      setFields.push('email');
    }
    if (!currentLead.whatsapp) {
      setFields.push('whatsapp');
    }

    if (setFields.length > 0) {
      setHasError(true);
      setErrorMessage(`Update ${setFields.join(', ')} fields`);
      return true;
    } else {
      return false;
    }
  };

  const handleSave = () => {
    if (leadDetailsRequired() || !isFormExpiryValid()) {
      return;
    }

    const body = {
      validDays: expiry.toDate(),
    };

    axiosInstance
      .post(`/api/leads/${currentLead.lead_id}/eligibility-forms`, body)
      .then((res) => {
        setForm(res?.data);
        setOpen(true);
        setDefaultExpiry(moment().add(2, 'hours').toDate());
        closeUpdateModal();
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while updating the eligibility form.'
        );
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Modal
        open={openUpdateModal}
        onClose={closeUpdateModal}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40vw',
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 6,
            overflowY: 'auto',
            borderRadius: '20px',
          }}
        >
          <TextField
            label='Expiry Time'
            type='datetime-local'
            value={expiry?.format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setExpiry(moment(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Stack
            direction='row'
            spacing={1}
            mt={2}
          >
            {presetValues.map((preset) => (
              <Button
                key={preset.label}
                onClick={() => {
                  handlePresetClick(preset.value);
                  setSelectedPreset(preset.value);
                }}
                variant='text'
                color='inherit'
                size='large'
                sx={{
                  height: '2.5em',
                  borderRadius: '20px',
                  backgroundColor:
                    selectedPreset === preset.value ? '#b5e2ff' : '#eeeeee',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#b5e2ff',
                  },
                }}
              >
                {preset.label}
              </Button>
            ))}
          </Stack>
          <Box
            sx={{
              textAlign: 'center',
              mt: 4,
            }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={handleSave}
              sx={{
                px: 4,
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <FormGeneratedModal
        open={open}
        onClose={onClose}
        form={form}
      />
    </>
  );
}
