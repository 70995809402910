import React, { useEffect } from 'react';
import useTitle from './components/UseTitle';
import { useAuthStateValue } from './components/auth/AuthContext';
import Routing from './routes/routes';
import { useNavigate } from 'react-router-dom';
import { SocketProvider } from './socket/socketContext';
import ManagerScreen from './components/training/trainee/mockcalls/manager/ManagerScreen';
import ICScreen from './components/training/trainee/mockcalls/IC/ICScreen';
import { setupAxiosInterceptors } from './apis/axiosInstance';

function NewApp() {
  const [user, setUser, isUserLoggedIn] = useAuthStateValue();
  const history = useNavigate();
  useTitle('Sales | HeyCoach');

  useEffect(() => {
    setupAxiosInterceptors(setUser, history);
  }, [setUser, history]);

  return (
    <main
      style={{
        height: '98vh',
        overflow: 'auto',
      }}
    >
      <SocketProvider user={user}>
        <Routing />
        <ManagerScreen />
        <ICScreen />
      </SocketProvider>
    </main>
  );
}

export default NewApp;
