import React, { useEffect, useState, useRef } from 'react';
import {
  TextField,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Popper,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import {
  currentAdminIsAdmin,
  currentAdminIsMarketing,
} from '../../utils/common';

const defaultStyle = {
  width: 300,
};

export default function AdminsSearchDropdown({
  value,
  onChangeHandler,
  label = 'Admins',
  sx = defaultStyle,
  ...restProps
}) {
  const [inputValue, setInputValue] = useState('');
  const [admins, setAdmins] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const componentRef = useRef(null);
  const searchInputRef = useRef(null);
  const popperRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isClickInsideComponent =
        componentRef.current && componentRef.current.contains(event.target);
      const isClickInsideSearchInput =
        searchInputRef.current && searchInputRef.current.contains(event.target);
      const isClickInsidePopper =
        popperRef.current && popperRef.current.contains(event.target);

      if (
        !isClickInsideComponent &&
        !isClickInsideSearchInput &&
        !isClickInsidePopper
      ) {
        setAnchorEl(null);
        setInputValue('');
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (anchorEl) {
      getAdmins();
    }
  }, [anchorEl]);

  async function getAdmins() {
    try {
      let url = `/api/admins/search`;
      const response = await axiosInstance.get(url, {
        params: { text: inputValue },
      });

      let adminsData = response.data.map((admin) => ({
        id: admin.id,
        label: admin.fullname,
        value: `${admin.id}`,
      }));

      if (currentAdminIsAdmin() || currentAdminIsMarketing()) {
        adminsData = [
          {
            // should always be present on top
            id: 'none',
            label: 'None',
            value: 'none',
          },
          ...adminsData,
        ];
      }

      setAdmins(adminsData);
    } catch (error) {
      console.error('Error fetching admins:', error);
      setHasError(true);
      setErrorMessage(
        error?.response?.message ??
          'An error occurred while searching for the admin.'
      );
    }
  }

  const handleMenuItemClick = (admin) => {
    const alreadySelected = selectedAdmins.some(
      (selectedAdmin) => selectedAdmin.value === admin.value
    );

    const newSelectedAdmins = alreadySelected
      ? selectedAdmins.filter(
          (selectedAdmin) => selectedAdmin.value !== admin.value
        )
      : [...selectedAdmins, admin];

    setSelectedAdmins(newSelectedAdmins);
    setInputValue(inputValue.split(', ').pop() || '');

    const adminIds = newSelectedAdmins
      .map((selectedAdmin) => selectedAdmin.value)
      .join(',');

    onChangeHandler(adminIds);
  };

  const handleSearchBarChange = (event) => {
    const newSearchValue = event.target.value.trim();
    setInputValue(newSearchValue);

    if (newSearchValue !== '') {
      getAdmins();
    } else {
      setAdmins([]);
    }
  };

  const handleInputBlur = () => {
    if (inputValue !== '') {
      getAdmins();
    }
  };

  const handleSearchBarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const searchBoxStyle = {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    height: '40px',
    innerHeight: '10px',
  };

  const dropdownStyle = {
    backgroundColor: '#fafafa',
    zIndex: 1,
    padding: '0.5rem',
    border: '0.1rem solid black',
    borderRadius: '0.5rem',
    maxHeight: '30vh',
    overflow: 'auto',
  };

  return (
    <div ref={componentRef}>
      {hasError && (
        <ErrorNotifier
          {...{
            message: errorMessage,
            setHasError,
          }}
        />
      )}
      <TextField
        {...restProps}
        label={label}
        value={selectedAdmins.map((admin) => admin.label).join(', ')}
        onClick={handleSearchBarClick}
        inputRef={searchInputRef}
        sx={{ ...sx, ...searchBoxStyle }}
        InputProps={{
          readOnly: true,
        }}
      />

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='bottom-start'
        style={dropdownStyle}
        ref={popperRef}
      >
        <div>
          <TextField
            placeholder='Type admin name here...'
            label='Search Admins'
            value={inputValue}
            onChange={handleSearchBarChange}
            onBlur={handleInputBlur}
            sx={defaultStyle}
            size='small'
          />

          {admins.map((admin) => (
            <MenuItem key={admin.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedAdmins.some(
                      (selectedAdmin) => selectedAdmin.value === admin.value
                    )}
                    onChange={() => handleMenuItemClick(admin)}
                  />
                }
                label={admin.label}
              />
            </MenuItem>
          ))}
        </div>
      </Popper>
    </div>
  );
}
