import React from 'react';
import { Button, Chip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import constants from './constants';
import axiosInstance from 'apis/axiosInstance';
import moment from 'moment';
import { base_url } from '../components/Mode';
const {
  ADMIN,
  MANAGER,
  SENIOR_MANAGER,
  MARKETING,
  FINANCE,
  PROGRAM_MANAGER,
  INDEPENDENT_CONTRIBUTOR,
  HR,
  TA,
  INTERVIEWER,
  TRAINEE,
  SENIOR_MARKETING_MANAGER,
} = constants;

export const formatAMPM = (date) => {
  date = new Date(Date.parse(date));
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

// Eg: 'Apr 18, 2022 10:33 PM'
export function getFormattedTimestamp(timestamp) {
  const date = timestamp.toLocaleDateString('en-US', {
    day: 'numeric',
    year: 'numeric',
    month: 'short',
  });
  const time = timestamp.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return date + ' ' + time;
}

// Eg: '27 Feb 2023'
export function getFormattedTimestampWithYear(timestamp) {
  const day = timestamp.getDate();
  const month = timestamp.toLocaleDateString('en-US', {
    month: 'short',
  });
  const year = timestamp.getFullYear();
  return `${day} ${month} ${year}`;
}

// Eg: 'Apr 18, 2022 10:33 PM'
export function getFormattedTimestampWithoutYear(timestamp) {
  const date = timestamp.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
  });
  const time = timestamp.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return date + ', ' + time;
}

// Eg: 'Apr 18'
export function getFormattedDate(timestamp, year = false) {
  const configs = {
    day: 'numeric',
    month: 'short',
  };
  if (year) {
    configs['year'] = 'numeric';
  }
  const date = timestamp.toLocaleDateString('en-US', configs);
  return date;
}

// Eg: '2022-04-18'
export function getDatabaseFormattedDate(timestamp) {
  const year = timestamp.getFullYear();
  const month = timestamp.toLocaleDateString('en-US', {
    month: '2-digit',
  });
  const day = timestamp.toLocaleDateString('en-US', {
    day: '2-digit',
  });
  return year + '-' + month + '-' + day;
}

// Eg: '22:34'
export function getDatabaseFormattedTime(timestamp) {
  const hours =
    timestamp.getHours() < 10
      ? '0' + timestamp.getHours()
      : timestamp.getHours();
  const minutes =
    timestamp.getMinutes() < 10
      ? '0' + timestamp.getMinutes()
      : timestamp.getMinutes();
  return `${hours}:${minutes}`;
}

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function get24HourFormattedTimeFromPmAm(time) {
  time = String(time).toLowerCase().replace(/\s/g, '');

  // handled am:
  let index = time.indexOf('am');
  if (index !== -1) {
    const newTime = time.slice(0, index);
    let [hh, mm] = newTime.split(':');
    if (hh < 10) {
      hh = '0' + hh;
    }
    return `${hh}:${mm}:00+05:30`;
  }

  // handled pm:
  index = time.indexOf('pm');
  if (index !== -1) {
    const newTime = time.slice(0, index);
    const [hh, mm] = newTime.split(':');

    if (Number(hh) >= 12) {
      return `${hh}:${mm}:00+05:30`; // just removed pm because time was in 24 hour format already.
    } else {
      return `${String(Number(hh) + 12)}:${mm}:00+05:30`;
    }
  }

  index = time.length;
  const newTime = time.slice(0, index);
  const [hh, mm] = newTime.split(':');

  return `${hh}:${mm}:00+05:30`;
}

// Eg: '2022-04-18 22:34'
export function getDatabaseFormattedDateTime(timestamp) {
  return (
    getDatabaseFormattedDate(timestamp) +
    ' ' +
    getDatabaseFormattedTime(timestamp)
  );
}

// Eg: '2022-04-18T22:34'
// raw = true means, return date string, and raw = false means return date object
export function getTimeZoneFormattedTimestamp(
  date,
  hoursToAdd = 0,
  minutesToAdd = 0,
  raw = true
) {
  date = new Date(date);
  if (hoursToAdd !== 0) {
    date.setHours(date.getHours() + hoursToAdd);
  }
  if (minutesToAdd !== 0) {
    date.setMinutes(date.getMinutes() + minutesToAdd);
  }
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  return raw ? newDate.toISOString().slice(0, 19) : newDate;
}

export function capitalize(string) {
  if (string && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return string;
  }
}

export function getLeadStatusColor(status) {
  switch (status) {
    case 'created':
      return '#e3f2fd';
    case 'assigned':
      return '#e3f2fd';
    case 'connect_attempted':
      return '#ede7f6';

    case 'lead_qualification_done':
      return '#ede7f6';
    case 'need_generation_done':
      return '#fff176';
    case 'dream_selling_done':
      return '#fff176';
    case 'questioning_in_tech_done':
      return '#fff176';
    case 'complete_program_explained':
      return '#fff176';
    case 'eligibility_form_sent':
      return '#fff176';
    case 'eligibility_form_filled':
      return '#fff176';
    case 'intent_closure_call_done':
    case 'ips_call_done':
      return '#ffd54f';
    case 'offer_letter_payment_link_sent':
      return '#ffb74d';
    case 'token_amount_paid':
      return '#dce775';
    case 'complete_amount_paid':
      return '#81c784';
    case 'dropped_post_sales_refund_initiated':
      return '#d7ccc8';
    case 'dropped_post_sales_refund_denied':
      return '#d7ccc8';
    case 'reactivated':
      return '#e3f2fd';
    case 'dropped':
      return '#d7ccc8';
    case 'payment_link_sent':
      return '#ffb74d';
    case 'one_step_to_payment':
      return '#ffb74d';
    case 'screening_call_scheduled':
      return 'rgba(0, 0, 0, 0.08)';
    case 'screening_call_attended':
      return 'rgba(0, 0, 0, 0.08)';
    default:
      return 'primary';
  }
}
// Used Mui colors for lead funnel so seprate function for it.
export const getLeadStatusColorForLeadFunnel = (status) => {
  switch (status) {
    case 'created':
      return '#29b6f6';
    case 'assigned':
      return '#29b6f6';
    case 'connect_attempted':
      return '#9575cd';

    case 'lead_qualification_done':
      return '#9575cd';
    case 'need_generation_done':
      return '#fdd835';
    case 'dream_selling_done':
      return '#fdd835';
    case 'questioning_in_tech_done':
      return '#fdd835';
    case 'complete_program_explained':
      return '#fdd835';
    case 'eligibility_form_sent':
      return '#fdd835';
    case 'eligibility_form_filled':
      return '#fdd835';
    case 'intent_closure_call_done':
    case 'ips_call_done':
      return '#ffb300';
    case 'offer_letter_payment_link_sent':
      return '#ffca28';
    case 'token_amount_paid':
      return '#81c784';
    case 'complete_amount_paid':
      return '#4caf50';
    case 'dropped_post_sales_refund_initiated':
      return '#d7ccc8';
    case 'dropped_post_sales_refund_denied':
      return '#d7ccc8';
    case 'reactivated':
      return '#e3f2fd';
    case 'dropped':
      return '#d7ccc8';
    case 'payment_link_sent':
      return '#ffb74d';
    case 'one_step_to_payment':
      return '#ffb74d';
    case 'screening_call_scheduled':
      return '#ffca28';
    case 'dream_selling_done / need_generation_done / questioning_in_tech_done':
    case 'screening_call_done':
      return '#ffca28';
    default:
      return 'primary';
  }
};

export function getUserTokenFromLocalStorage() {
  return JSON.parse(localStorage.getItem('token')) ?? '';
}

export function getStatusLabel(label, is_hot = false, sx = {}, restProps) {
  if (label === 'intent_closure_call_done') {
    label = 'ips_call_done';
  }
  function renderLabel(label, bgColor) {
    return (
      <Chip
        label={label.toUpperCase()}
        size='small'
        sx={{
          backgroundColor: bgColor,
          fontSize: '12px',
          fontWeight: 500,
          '& .MuiChip-icon': {
            color: 'red',
          },
          ...sx,
        }}
        {...restProps}
      />
    );
  }

  return renderLabel(
    splitByUnderscoresAndCapitalize(label),
    getLeadStatusColor(label)
  );
}

export const CopyIcon = (
  copyText,
  selectedRows,
  showCopyButton = false,
  buttonText = ''
) => {
  const [showCopiedMsg, setShowCopiedMsg] = React.useState(false);

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        setShowCopiedMsg(true);
        setTimeout(() => {
          setShowCopiedMsg(false);
        }, 300);
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  }

  if (selectedRows && selectedRows.length === 0) {
    return '';
  }

  return (
    <>
      <span>
        {!showCopyButton ? (
          <>
            <ContentCopyIcon
              style={{
                marginLeft: '20px',
                color: '#9e9e9e',
                cursor: 'pointer',
                alignSelf: 'center',
                width: '20px',
              }}
              onClick={() => copyTextToClipboard(copyText)}
            />
            <Typography
              display={showCopiedMsg ? 'inline-block' : 'none'}
              ml={'10px'}
              color={'#9e9e9e'}
              bottom={'5px'}
              position='relative'
              alignSelf='center'
            >
              Copied!
            </Typography>
          </>
        ) : (
          <>
            <ContentCopyIcon
              style={{
                marginLeft: '20px',
                color: '#9e9e9e',
                cursor: 'pointer',
                alignSelf: 'center',
                width: '20px',
              }}
              onClick={() => copyTextToClipboard(copyText)}
            />
            <Typography
              display={showCopiedMsg ? 'inline-block' : 'none'}
              ml={'10px'}
              color={'#9e9e9e'}
              bottom={'5px'}
              position='relative'
              alignSelf='center'
            >
              Copied!
            </Typography>
          </>
        )}
      </span>
    </>
  );
};

// Input: Date 1, Date 2
// Returns: text
export function getHumanReadableTimeDifference(d1, d2) {
  var seconds = Math.floor((d1 - d2) / 1000);
  if (seconds < 60) {
    return seconds + ' seconds';
  }
  var minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return minutes + ' minutes';
  }
  // TODO: this is an approximate implementation. Need to complete it
  return 'More than an hour';
}

export const getOffsetFromPage = (currentPage, limit) => {
  return Math.max(0, currentPage * limit - limit);
};

export const getDurationText = (durationInSec) => {
  let durationText = '';

  if (durationInSec >= 3600) {
    durationText += Math.floor(durationInSec / 3600) + ' hours';
    durationInSec %= 3600;
  }

  if (durationInSec >= 60) {
    if (durationText.length > 0) {
      durationText += ' ';
    }
    durationText += Math.floor(durationInSec / 60) + ' minutes';
    durationInSec %= 60;
  }

  if (durationInSec > 0 || durationText.length === 0) {
    if (durationText.length > 0) {
      durationText += ' ';
    }
    durationText += durationInSec + ' seconds';
  }

  return durationText;
};

export const splitByCamelCaseAndCapitalize = (name, specialChars = ['_']) => {
  if (name.length === 0) return '';

  const result = [];
  let stack = [];
  let lastChar = '';
  const len = name.length;

  for (let i = 0; i < len; i += 1) {
    if (name[i] >= '0' && name[i] <= '9') {
      if (lastChar >= '0' && lastChar <= '9') {
        stack.push(name[i]);
      } else {
        stack.push(' ' + name[i]);
      }
      lastChar = name[i];
      continue;
    }

    if (specialChars.includes(name[i])) {
      // split point
      if (stack.length > 0) {
        result.push(stack.join(''));
        stack = [];
        lastChar = '';
      }
      continue;
    }

    if (name[i] === name[i].toUpperCase() && stack.length > 0) {
      // split point
      result.push(stack.join(''));
      stack = [];
    }
    lastChar = name[i].toLowerCase();
    stack.push(lastChar);
  }

  if (stack.length > 0) {
    result.push(stack.join(''));
  }

  return splitByUnderscoresAndCapitalize(result.join(' '));
};

export const splitByUnderscoresAndCapitalize = (name) => {
  if (!name) return '';
  if (name.length === 0) return '';
  name = capitalize(name); // capitalize first letter.
  name = name.split('_').join(' ');
  return name;
};

export const getSanitizedPhoneNumber = (number) => {
  number.trim();
  const ph = [];

  for (let i = 0; i < number.length; i += 1) {
    if (number[i] >= '0' && number[i] <= '9') {
      ph.push(number[i]);
    }
  }

  number = ph.join('');
  const startIndex = Math.max(0, number.length - 10);
  return number.slice(startIndex);
};

export const isValidLinkedinURL = (url) => {
  // https://regex101.com/r/mvg4IV/3
  const pattern = new RegExp(
    /(^((https?:\/\/)?((www|\w\w)\.)?)linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d\-&#?=])+\/?){1,}))$/,
    'gmi'
  );
  return pattern.test(url);
};

export const isValidEmailId = (email) => {
  const pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'gmi'
  );
  return pattern.test(email);
};

export const isValidPhoneNumber = (phone) => {
  const pattern = new RegExp(/^\+?[0-9]*$/, 'gmi');
  phone = getSanitizedPhoneNumber(phone);
  return phone.length === 10 && pattern.test(phone);
};

// Added Validation for Address for the Frontend , Eg - Hey Coach Road No. - 13 , Kormangal , Bengaluru
export const isValidAddress = (address) => {
  const pattern = new RegExp(/^[a-zA-Z0-9\s,.'-]{1,500}$/gim);
  return pattern.test(address);
};

export const slugify = (str) => {
  str = str.toLowerCase();
  str = str.split(' ').join('-');
  return str;
};

export const getCurrentAdminRole = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (
    Object.keys(currAdmin).length === 0 ||
    (currAdmin.roles ?? []).length === 0
  ) {
    return [];
  }
  return currAdmin.roles;
};

export const getCurrentAdminProduct = () => {
  const currentAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (
    Object.keys(currentAdmin).length > 0 &&
    (currentAdmin.admin_products ?? []).length > 0
  ) {
    return currentAdmin.admin_products;
  }
  return [];
};

export const currentAdminProductIsSuper30 = () => {
  const products = getCurrentAdminProduct();
  return products?.map((p) => p.product).includes('super30') ?? false;
};

export const getCurrentAdminId = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return '';
  }
  return currAdmin.id;
};

export const isCurrentAdminIsInFocusMode = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return false;
  }
  return currAdmin.enforce_focus_mode;
};

export const getCurrentAdminAllowLeadAssignment = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return '';
  }
  return currAdmin.allow_lead_assignment;
};

export const getCurrentAdminAccessType = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return '';
  }
  return currAdmin.access_type;
};

export const getCurrentAdminMissingReportsFlag = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return '';
  }
  return currAdmin.missingReportsFlag;
};

export const getCurrentAdminEmail = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return '';
  }
  return currAdmin.email;
};

export const getCurrentAdminName = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return '';
  }
  const { fname, lname } = currAdmin;
  return `${fname} ${lname}`;
};

export const getSubordinateAdminIds = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return [];
  }
  return currAdmin.subordinate_admin_ids;
};

export const currentAdminIsAdmin = () => {
  return getCurrentAdminRole().includes(constants.ADMIN);
};

export const currentAdminIsIC = () => {
  return getCurrentAdminRole().includes(constants.INDEPENDENT_CONTRIBUTOR);
};

export const currentAdminIsManager = () => {
  return getCurrentAdminRole().includes(constants.MANAGER);
};

export const currentAdminIsSeniorManager = () => {
  return getCurrentAdminRole().includes(constants.SENIOR_MANAGER);
};

export const currentAdminIsMarketing = () => {
  return getCurrentAdminRole().includes(constants.MARKETING);
};

export const currentAdminIsManagerOrAdmin = () => {
  return currentAdminIsAdmin() || currentAdminIsManager();
};

export const currentAdminIsMarketingOrAdmin = () => {
  return currentAdminIsAdmin() || currentAdminIsMarketing();
};

export const currentAdminIsManagerOrAdminOrMarketing = () => {
  return currentAdminIsManagerOrAdmin() || currentAdminIsMarketing();
};

export const currentAdminIsSeniorManagerOrAdminOrMarketing = () => {
  return currentAdminIsSeniorManager() || currentAdminIsMarketingOrAdmin();
};

export const currentAdminIsFinance = () => {
  return getCurrentAdminRole().includes(constants.FINANCE);
};

export const currentAdminIsPgm = () => {
  return getCurrentAdminRole().includes(constants.PROGRAM_MANAGER);
};

export const currentAdminIsHr = () => {
  return getCurrentAdminRole().includes(constants.HR);
};

export const currentAdminIsInterviewer = () => {
  return getCurrentAdminRole().includes(constants.INTERVIEWER);
};

export const currentAdminIsTa = () => {
  return getCurrentAdminRole().includes(constants.TA);
};

export const currentAdminIsTraineeOrIC = () => {
  return currentAdminIsTrainee() || currentAdminIsIC();
};

export const currentAdminIsTrainee = () => {
  return getCurrentAdminRole().includes(constants.TRAINEE);
};

export const currentAdminProfilePicture = () => {
  const currentAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currentAdmin).length === 0) {
    return '';
  }
  return currentAdmin?.profile_picture;
};

export const getAccessMode = (roles) => {
  if ((roles ?? []).length === 0) {
    roles = getCurrentAdminRole();
  }

  const accessModes = {
    [ADMIN]: 'full',
    [MANAGER]: 'restricted',
    [SENIOR_MANAGER]: 'restricted',
    [MARKETING]: 'restricted',
    [FINANCE]: 'restricted',
    [PROGRAM_MANAGER]: 'basic',
    [INDEPENDENT_CONTRIBUTOR]: 'full',
    [HR]: 'basic',
    [TA]: 'basic',
    [INTERVIEWER]: 'basic',
    [TRAINEE]: 'basic',
  };
  return roles.map((r) => accessModes[r]);
};

export const hasFullAccessMode = () => {
  return getAccessMode().includes(constants.FULL);
};

export const hasRestrictedAccessMode = () => {
  return getAccessMode().includes(constants.RESTRICTED);
};

export const hasBasicAccessMode = () => {
  return getAccessMode().includes(constants.BASIC);
};

export const shouldEnforceFocusMode = () => {
  const currAdmin = JSON.parse(localStorage.getItem('admin')) ?? {};
  if (Object.keys(currAdmin).length === 0) {
    return false;
  }
  return currAdmin.enforce_focus_mode ?? false;
};

export const formatNumberWithCommas = (number) => {
  return number.toLocaleString('en-IN');
};

export const getAdminDefaultColumns = async () => {
  try {
    const res = await axiosInstance.get(
      `/api/admins/${getCurrentAdminId()}/settings`
    );
    const parsedData = JSON.parse(res?.data ?? {});
    if (Object.keys(parsedData).length !== 0) {
      parsedData.last_interaction = false;
      parsedData.last_interaction_on = false;
      parsedData.last_interaction_by = false;
    }
    return parsedData;
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const checkTraineeICAccess = (link, uid) => {
  const isAdminIdMatch = uid === getCurrentAdminId().toString();
  const validLink = `access=allowed&uid=${getCurrentAdminId()}&k=lwejlffqpWKLFlwekjl32lIHKJHDlwerhe`;
  const isLinkValid = link === validLink;
  if (currentAdminIsTraineeOrIC() && isAdminIdMatch && isLinkValid) {
    return false;
  }

  return true;
};

export const getCurrentAdminPrimaryRole = () => {
  if (currentAdminIsAdmin()) {
    return ADMIN;
  } else if (currentAdminIsMarketing()) {
    return MARKETING;
  } else if (currentAdminIsManager()) {
    return MANAGER;
  } else if (currentAdminIsSeniorManager()) {
    return SENIOR_MANAGER;
  } else if (currentAdminIsIC()) {
    return INDEPENDENT_CONTRIBUTOR;
  } else if (currentAdminIsFinance()) {
    return FINANCE;
  } else if (currentAdminIsTrainee()) {
    return TRAINEE;
  }
  return PROGRAM_MANAGER;
};

export function getHumanReadableTimeDifferenceFromNow(inputDate) {
  return moment(inputDate).fromNow();
}
export const convertUnixTimestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getCurrentDriveName = (drives) => {
  const currentDate = new Date();

  for (const drive of drives) {
    const startsAt = new Date(drive.starts_at);
    const endsAt = new Date(drive.ends_at);

    if (currentDate >= startsAt && currentDate <= endsAt) {
      return drive.name;
    }
  }

  return ' ';
};

export const getCurrentDriveStartAndEndDate = (drives) => {
  const currentDate = new Date();

  for (const drive of drives) {
    const startsAt = new Date(drive.starts_at);
    const endsAt = new Date(drive.ends_at);

    if (currentDate >= startsAt && currentDate <= endsAt) {
      return { startsAt, endsAt };
    }
  }

  return ' ';
};

export const getDrives = async () => {
  return axiosInstance
    .get(`/api/drives`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error('Error fetching drives:', err);
      throw err;
    });
};

export const hrmsJobRoles = {
  business_development_associate: 'Business Development Associate',
  business_development_executive: 'Business Development Executive',
  operation_learning: 'Operation Learning',
  operation_placements: 'Operation Placements',
  tech_full_stack_mern: 'Tech Full Stack Mern',
  hr_talent_acquisition: 'HR Talent Acquisition',
  'cp_intern_(vertual)': 'Cp Intern (Virtual)',
  cp_full_time_engineer: 'Cp Full Time Engineer',
  marketing_digital_marketing_associate:
    'Marketing Digital Marketing Associate',
  marketing_performance_marketing_associate:
    'Marketing Performance Marketing Associate',
  marketing_performance_marketing_executive:
    'Marketing Performance Marketing Executive',
  marketing_social_media_associate: 'Marketing Social Media Associate',
  marketing_social_media_executive: 'Marketing Social Media Executive',
  marketing_search_engine_optimization_associate:
    'Marketing Search Engine Optimization Associate',
  marketing_search_engine_optimization_executive:
    'Marketing Search Engine Optimization Executive',
  graphic_designer_marketing_intern: 'Graphic Designer Marketing Intern',
};

export const hrmscandidateSources = {
  linkedin: 'Linkedin',
  indeed: 'Indeed',
  updazz: 'Updazz',
  referral: 'Referral',
  cutshort: 'Cutshort',
  well_found: 'Well Found',
  work_india: 'Work India',
  email_reachout: 'Email Reachout',
  internshala: 'Internshala',
  hirect: 'Hirect',
  naukri: 'Naukri',
  others: 'Others',
};

export const icMotivationTexts = [
  'Embrace every call as a chance to excel. Today is your canvas; paint it with success!',
  'In every call lies the potential for victory. Seize the opportunity and make today a triumph!',
  'Each call is a stepping stone to success. Embrace them with determination and turn today into a masterpiece!',
  "Every call is a golden opportunity. Let's turn today's conversations into the keys that unlock success!",
  'See every call as a doorway to achievement. Step through with confidence and make today a day to remember!',
  "Turn each call into a triumph. Today's success story starts with the conversations you engage in!",
  'Every call is a chance to shine. Embrace them with enthusiasm, and let today be a symphony of success!',
  'Opportunities are disguised as calls. Unveil them with determination and make today a chapter of accomplishment!',
  'In every call, find the path to success. Today is your opportunity to showcase your brilliance!',
  'View every call as a stairway to achievement. Climb with purpose and make today a summit of success!',
  "Turn every call into a triumph. Today's success is waiting for you to answer the call!",
  'Each call is a building block of success. Assemble them with dedication and construct a victorious day!',
  'Every call is a gateway to success. Step through with conviction and make today a journey of triumph!',
  'See every call as an opportunity to shine. Illuminate the path to success and make today extraordinary!',
  'Transform each call into a victory march. Today is your parade of success – lead it with confidence!',
  "Every call carries the seeds of success. Plant them with confidence and watch today's garden flourish!",
  'See every call as a puzzle piece of success. Piece them together with enthusiasm and make today a masterpiece!',
  'In every call, find the rhythm of success. Dance through them with purpose and make today a celebration!',
  "Turn each call into a triumph. Today's success is waiting for you to answer the call!",
  'Every call is a building block of success. Assemble them with dedication and construct a victorious day!',
  'See every call as an opportunity to shine. Illuminate the path to success and make today extraordinary!',
  'Transform each call into a victory march. Today is your parade of success – lead it with confidence!',
  'In every call, discover the seeds of success. Plant them with purpose and cultivate a garden of triumph today!',
  'Every call is a brushstroke on the canvas of success. Paint today with vibrant hues of achievement!',
  'Embrace every call as a challenge and turn it into an opportunity for success. Today is your day to conquer!',
  'See every call as a melody of success. Compose a symphony of achievement with the conversations you have today!',
  "Each call is a note in the song of success. Play them with enthusiasm and make today's melody unforgettable!",
  'Every call is a chapter in your success story. Write it with determination and make today a bestseller!',
  "Turn each call into a triumph. Today's success is waiting for you to answer the call!",
  'Every call is a building block of success. Assemble them with dedication and construct a victorious day!',
  'See every call as an opportunity to shine. Illuminate the path to success and make today extraordinary!',
  'Transform each call into a victory march. Today is your parade of success – lead it with confidence!',
  'In every call, discover the seeds of success. Plant them with purpose and cultivate a garden of triumph today!',
  'Every call is a brushstroke on the canvas of success. Paint today with vibrant hues of achievement!',
  'Embrace every call as a challenge and turn it into an opportunity for success. Today is your day to conquer!',
  'See every call as a melody of success. Compose a symphony of achievement with the conversations you have today!',
  "Each call is a note in the song of success. Play them with enthusiasm and make today's melody unforgettable!",
  'Every call is a chapter in your success story. Write it with determination and make today a bestseller!',
  "Turn each call into a triumph. Today's success is waiting for you to answer the call!",
  'Every call is a building block of success. Assemble them with dedication and construct a victorious day!',
  'See every call as an opportunity to shine. Illuminate the path to success and make today extraordinary!',
  'Transform each call into a victory march. Today is your parade of success – lead it with confidence!',
  'In every call, discover the seeds of success. Plant them with purpose and cultivate a garden of triumph today!',
];

// this logic has to be updated once the incentive structure changes
export const calculateIncentiveAndMilestone = (
  caps,
  prorataAndIncentiveData
) => {
  if (prorataAndIncentiveData) {
    const {
      onetoFiveProratavalue,
      sixthProratavalue,
      seventoTenProratavalue,
      eleventoEighteenProrata,
      ninteentoFourtysixProrata,
      sixthIncentiveAmount,
      seventhIncentiveAmount,
      tenthIncentiveAmount,
      eleventhIncentiveAmount,
      eighteenThIncentvieAmount,
      nineTeenthIncentiveAmount,
    } = prorataAndIncentiveData;

    let incentive = 0;
    let nextMilestone = 0;

    if (caps <= 5) {
      incentive = caps * (onetoFiveProratavalue || 5000);
      nextMilestone = sixthIncentiveAmount || 30000;
    } else if (caps === 6) {
      incentive = sixthIncentiveAmount || 30000;
      nextMilestone =
        (sixthIncentiveAmount || 30000) + (sixthProratavalue || 7000);
    } else if (caps >= 7 && caps <= 10) {
      incentive =
        (sixthIncentiveAmount || 30000) +
        (caps - 6) * (seventoTenProratavalue || 7000);
      nextMilestone = incentive + (seventoTenProratavalue || 7000);
    } else if (caps >= 11 && caps <= 18) {
      incentive =
        (tenthIncentiveAmount || 58000) +
        (caps - 10) * (eleventoEighteenProrata || 12000);
      nextMilestone = incentive + (eleventoEighteenProrata || 12000);
    } else {
      incentive =
        (eighteenThIncentvieAmount || 154000) +
        (caps - 18) * (ninteentoFourtysixProrata || 15000);

      nextMilestone = incentive + (ninteentoFourtysixProrata || 15000);
    }

    return { incentive, nextMilestone };
  } else {
    let incentive = 0;
    let nextMilestone = 0;

    if (caps < 5) {
      incentive = caps * 5000;
      nextMilestone = 30000;
    } else if (caps === 6) {
      incentive = 30000;
      nextMilestone = 30000 + 5000;
    } else if (caps >= 7 && caps <= 10) {
      incentive = 30000 + (caps - 6) * 7000;
      nextMilestone = incentive + 7000;
    } else if (caps >= 11 && caps <= 18) {
      incentive = 58000 + (caps - 10) * 12000;
      nextMilestone = incentive + 12000;
    } else {
      incentive = 154000 + (caps - 18) * 15000;
      nextMilestone = incentive + 15000;
    }

    return { incentive, nextMilestone };
  }
};

export const filterByDate = (array) => {
  const filteredLeads = [];
  const currentDate = new Date();

  const todayStart = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const todayEnd = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1
  );

  const yesterday = new Date();
  yesterday.setDate(currentDate.getDate() - 1);
  const yesterdayStart = new Date(
    yesterday.getFullYear(),
    yesterday.getMonth(),
    yesterday.getDate()
  );
  const yesterdayEnd = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const currentWeekStart = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDate.getDay()
  );
  const currentWeekEnd = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + (6 - currentDate.getDay())
  );

  const lastWeekStart = new Date(currentWeekStart);
  lastWeekStart.setDate(lastWeekStart.getDate() - 7);
  const lastWeekEnd = new Date(currentWeekEnd);
  lastWeekEnd.setDate(lastWeekEnd.getDate() - 7);

  filteredLeads.push(
    array.filter((item) => {
      const openedDate = new Date(item.opened_at);
      return openedDate >= todayStart && openedDate <= todayEnd;
    })
  );

  filteredLeads.push(
    array.filter((item) => {
      const openedDate = new Date(item.opened_at);
      return openedDate >= yesterdayStart && openedDate <= yesterdayEnd;
    })
  );

  filteredLeads.push(
    array.filter((item) => {
      const openedDate = new Date(item.opened_at);
      return openedDate >= currentWeekStart && openedDate <= currentWeekEnd;
    })
  );

  filteredLeads.push(
    array.filter((item) => {
      const openedDate = new Date(item.opened_at);
      return openedDate >= lastWeekStart && openedDate <= lastWeekEnd;
    })
  );

  return filteredLeads;
};

export const currentAdminIsSenior_marketing_manager = () => {
  return getCurrentAdminRole().includes(constants.SENIOR_MARKETING_MANAGER);
};

export function formatNumber(number) {
  const formattedNumber = (number / 100).toFixed(2);
  return formattedNumber;
}

export const convertToCSV = (data, excludeColumns) => {
  const filteredData = data.map((row) => {
    const filteredRow = {};
    Object.keys(row).forEach((field) => {
      if (!excludeColumns.includes(field)) {
        if (field === 'settled_at') {
          // Remove hyphens from the settled_at field
          filteredRow[field] = row[field].replace(/-/g, ' ');
        } else if (field === 'email') {
          // Replace 'lead not found' with a blank space for the email field
          filteredRow[field] =
            row[field].toLowerCase() === 'lead not found' ? '' : row[field];
        } else {
          filteredRow[field] = row[field];
        }
      }
    });
    return filteredRow;
  });

  const rows = filteredData.map((row) =>
    Object.values(row).map((value) => String(value))
  );
  const csvContent = rows.map((row) => row.join('\t')).join('\n');

  return csvContent;
};

export function getCurrentMonthYear() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
  return `${year}-${month}`;
}

export function convertUTCtoIST(utcTimestamp) {
  // Create a new Date object from the UTC timestamp
  const date = new Date(utcTimestamp);

  // Convert the UTC time to IST (Indian Standard Time)
  const istTime = date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });

  return istTime;
}

export const sortByFullName = (data, label) => {
  return data.sort((a, b) => {
    const labelA = a[label].toLowerCase();
    const labelB = b[label].toLowerCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
};

export const shouldShowPhonepePaymentGatewayOption = () => {
  const allowedAdminIds = [
    563, 452, 468, 688, 444, 440, 731, 417, 666, 667, 320, 658, 645, 714, 205,
    237, 366, 327, 383, 758, 620, 765, 764, 447, 123, 781, 408, 396, 423, 624,
    614, 204, 652, 778, 192, 92, 206, 101,
  ];
  return allowedAdminIds.includes(getCurrentAdminId());
};

export const bytesToMBsizeConverter = (bytes) => {
  return bytes / (1024 * 1024);
};

export function getNextWorkingDay(currentDate, holidaysArray) {
  let nextWorkingDay = moment(currentDate);
  let isHolidayOrOffDay = false;

  do {
    const formattedDate = formatDateToYYYYMMDD(nextWorkingDay);
    isHolidayOrOffDay = holidaysArray.some(
      (h) =>
        h.date === formattedDate &&
        (h.type === constants.FIXED_HOLIDAY || h.type === constants.WEEKDAY_OFF)
    );

    if (isHolidayOrOffDay) {
      nextWorkingDay.add(1, 'days');
    }
  } while (isHolidayOrOffDay);

  return nextWorkingDay;
}

export function formatDateToYYYYMMDD(date) {
  return moment(date).format('YYYY-MM-DD');
}
