import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import PersonalDetailArea from './PersonalDetailsArea';
import BioComp from './BioComp';
import HeatMap from './HeatMap';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import ManagerAndReporteeTable from './managerAndReporteeTable/ManagerAndReporteeTable';
import { Caps } from './Caps';
import { useNavigate } from 'react-router-dom';

const AdminProfile = () => {
  const [profilePage, setProfilePage] = useState();
  const [descData, setdescData] = useState('');
  const [heatMap, setHeatMap] = useState();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(true);
  };

  const saveEdit = (data) => {
    setEdit(false);
    updateProfileData(data);
  };

  const fetchData = async () => {
    const username = getUsernameFromUrl();
    try {
      const response = await axiosInstance.get(
        `/api/my-profile/?username=${username}`
      );
      setProfilePage(response.data);
      setHeatMap(response.data.heatMapData);
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.message);
    }
  };

  const getUsernameFromUrl = () => {
    const pathUrl = window.location.pathname.split('/');
    const usernameIndex = pathUrl.indexOf('admin-profile') + 1;

    return usernameIndex < pathUrl.length ? pathUrl[usernameIndex] : null;
  };

  const updateProfileData = async (data) => {
    if (
      !data.username ||
      (data.username && data.username.trim().length === 0)
    ) {
      setHasError(true);
      setErrorMessage('Username cannot be empty');
      return;
    }

    if (data.bio) {
      data.bio = data.bio.trim();
      data.username = data.username.trim();
    }
    if (descData?.about != null) {
      data.description = descData.about.trim();
    }

    try {
      const res = await axiosInstance.patch(`/api/my-profile`, data);
      const oldadmin = JSON.parse(localStorage.getItem('admin'));
      const { username } = oldadmin;
      oldadmin.username = res?.data?.updatedData.username;
      oldadmin.bio = res?.data?.updatedData.bio;
      localStorage.setItem('admin', JSON.stringify(oldadmin));
      setProfilePage(res?.data?.updatedData);
      if (username !== res.data.updatedData.username) {
        navigate('/bucket-search');
      }
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.message);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#EFF6FF',
        width: '100%',
        height: '90%',
        overflow: 'hidden',
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {profilePage && (
        <Container maxWidth='lg'>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '30%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  marginTop: '15px',
                  marginRight: '10px',
                  paddingTop: '10px',
                }}
              >
                <PersonalDetailArea
                  edit={edit}
                  onhandleEdit={handleEdit}
                  onsaveEdit={saveEdit}
                  personalData={profilePage?.adminDetails}
                  about={descData}
                  sx={{ flex: '1' }}
                />
                <BioComp
                  bioData={profilePage?.adminDetails}
                  edit={edit}
                  SetBio={setdescData}
                  onhandleEdit={handleEdit}
                  sx={{ flex: '1' }}
                />
              </Box>
            </Box>

            <Box sx={{ width: '70%', overflow: 'hidden', height: '20%' }}>
              <Box sx={{ width: '100%', marginRight: '10%' }}>
                <Caps />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  padding: '1% 0 0 0',
                  marginLeft: '10px',
                }}
              >
                <HeatMap statusData={heatMap} />
              </Box>

              <Box>
                <ManagerAndReporteeTable />
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default AdminProfile;
