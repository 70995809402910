import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../Mode';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TimeLineSections from './TimeLineSections';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function SimpleTimeline({
  handleSelectedModule,
  setIsAllmodulesCompleted,
  refreshPage,
  setTrnModuleAttemptId,
}) {
  const [nameOfSelectedModule, setNameOfSelectedModule] = useState('');
  useEffect(() => {
    handleFetchModules();
  }, [refreshPage]);

  const handleFetchModules = async () => {
    try {
      const moduleUrl = base_url() + '/api/training-modules';
      const module = await axiosInstance.get(moduleUrl);
      const modulesData = module.data;

      const completedModulesUrl =
        base_url() + '/api/training-modules/completed';
      const completedModules = await axiosInstance.get(completedModulesUrl);
      const completedModulesData = completedModules.data;

      let modulesIdsWhichAllQuestionsComplete = [];
      const url = base_url() + '/api/training-modules/questions-completed';
      const modulesIds = await axiosInstance.get(url);

      if (modulesIds.data.length > 0) {
        modulesIdsWhichAllQuestionsComplete = modulesIds.data.map(
          (e) => e.module_id
        );
      }

      let apiUrl;

      const allModules = modulesData.sort(
        (a, b) => a.module_order_id - b.module_order_id
      );

      if (completedModulesData.length === 0) {
        const {
          id,
          video_link,
          name,
          mockcall_description_trainee,
          mockcall_description_interviewer,
          flow_chart_link,
          has_assignment,
          video_transcript,
          is_mockcall_mandatory,
          video_drive_link,
        } = allModules[0];
        const isModuleQuestionsCompleted = has_assignment
          ? modulesIdsWhichAllQuestionsComplete.includes(id)
          : true;
        handleSelectedModule(
          id,
          video_link,
          name,
          mockcall_description_trainee,
          mockcall_description_interviewer,
          flow_chart_link,
          has_assignment,
          video_transcript,
          is_mockcall_mandatory,
          isModuleQuestionsCompleted,
          video_drive_link
        );
        setNameOfSelectedModule(name);
        apiUrl =
          isModuleQuestionsCompleted &&
          base_url() + `/api/training-modules/${id}/module-attempts`;
      } else {
        const completedModulesIds = completedModulesData.map(
          (e) => e.module_id
        );
        const notCompletedModules = allModules.filter(
          (e) => !completedModulesIds.includes(e.id)
        );
        if (notCompletedModules.length > 0) {
          const {
            id,
            video_link,
            name,
            mockcall_description_trainee,
            mockcall_description_interviewer,
            flow_chart_link,
            has_assignment,
            video_transcript,
            is_mockcall_mandatory,
            video_drive_link,
          } = notCompletedModules[0];
          const isModuleQuestionsCompleted = has_assignment
            ? modulesIdsWhichAllQuestionsComplete.includes(id)
            : true;
          handleSelectedModule(
            id,
            video_link,
            name,
            mockcall_description_trainee,
            mockcall_description_interviewer,
            flow_chart_link,
            has_assignment,
            video_transcript,
            is_mockcall_mandatory,
            isModuleQuestionsCompleted,
            video_drive_link
          );
          setNameOfSelectedModule(name);
          apiUrl =
            isModuleQuestionsCompleted &&
            base_url() + `/api/training-modules/${id}/module-attempts`;
        } else {
          setIsAllmodulesCompleted(true);
        }
      }

      if (apiUrl) {
        const result = await axiosInstance.get(apiUrl);
        setTrnModuleAttemptId(result?.data?.id);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Box
      sx={{
        marginLeft: '25px',
        marginTop: '10px',
        fontWeight: 'bold',
        color: 'blue',
      }}
    >
      {nameOfSelectedModule}
    </Box>
  );
}
