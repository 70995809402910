import React, { useState } from 'react';
import Button from '@mui/material/Button';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier.jsx';

const Error = ({ error, errorInfo }) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [clicked, setClicked] = useState(false);
  const handleSendError = async () => {
    const errorDetails = {
      message: error.toString(),
      stack: errorInfo.componentStack,
      url: window.location.href,
    };
    setClicked(true);
    try {
      const res = await axiosInstance.post(`/api/report-error`, errorDetails);
      if (res) {
        setIsSuccess(true);
        setSuccessMessage(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage('Could not send error details.');
    }
  };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <img
        src='/error-page.png'
        alt='Error'
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '30px',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
        }}
      >
        <Button
          style={{ backgroundColor: '#3994FF' }}
          variant='contained'
          onClick={() => {
            const url = '/bucket-search';
            const newTab = window.open('', '_blank');
            if (newTab) {
              newTab.opener = null;
              newTab.location.href = url;
            }
          }}
        >
          Back to home
        </Button>
        <Button
          style={{ backgroundColor: '#FF5733', marginLeft: '10px' }}
          variant='contained'
          onClick={handleSendError}
          disabled={clicked}
        >
          Send Error Details
        </Button>
      </div>
    </div>
  );
};

export default Error;
