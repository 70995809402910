import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const TabExistsDialog = ({ open, onClose, onLoadHere }) => {
  const handleLoadHere = () => {
    onLoadHere();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 20000 }}
    >
      <DialogTitle>Tab Already Exists</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This tab is already open. Would you like to load here or cancel?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleLoadHere}
          variant='contained'
          color='primary'
        >
          Load Here
        </Button>
        <Button
          onClick={onClose}
          variant='outlined'
          color='secondary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TabExistsDialog;
