import React, { useState } from 'react';
import { IconButton, Stack, Typography, Dialog, Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { base_url } from '../../components/Mode';
import AddCampaignConfirmationModal from './AddCampaignConfirmationModal';
import CampaignsFileUpload from './CampaignsFileUpload';
import CampaignBasic from './CampaignBasic';
import CampaignPerformance from './CampaignPerformance';
import CampaignBankDetails from './CampaignBankDetails';
import CampaignPayment from './CampaignPayment';
import moment from 'moment';
import axiosInstance from 'apis/axiosInstance';

const initialState = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  live_on: '',
  campaign_type: '',
  product: 'super30',
  long_url: '',
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function AddCampaignModal({
  isOpen,
  onCloseCampaignModal,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
  setErrorMessage,
  selectedCampaign,
  setTableData,
  setSelectedCampaign,
}) {
  const [campaignDetails, setCampaignDetails] = useState(
    selectedCampaign ?? initialState
  );
  const [longUrl, setLongUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(
    selectedCampaign?.campaignFiles ?? []
  );

  const islive = (campaignDetails) => {
    return campaignDetails?.status === 'live';
  };

  const islocked = (campaignDetails) => {
    return campaignDetails?.status === 'locked';
  };

  const isApproved = (campaignDetails) => {
    return campaignDetails?.status === 'approved';
  };

  const isRejected = (campaignDetails) => {
    return campaignDetails?.status === 'rejected';
  };

  const isPaid = (campaignDetails) => {
    return campaignDetails?.status === 'paid';
  };

  const isDraft = (campaignDetails) => {
    return campaignDetails?.status === 'draft';
  };

  const exitOpen = () => {
    if (
      campaignDetails.status === 'locked' ||
      campaignDetails.long_url !== '' ||
      longUrl !== ''
    ) {
      onCloseCampaignModal();
    } else {
      setOpen(true);
    }
  };

  const lockConfirmationOpener = () => {
    setOpen(true);
  };

  const onCloseExitConfirmation = () => {
    setOpen(false);
  };

  const validateCampaignDetails = (campaignDetails) => {
    const hasEmptyFields = Object.keys(initialState).some((key) => {
      return (campaignDetails[key] ?? '') === '' && key !== 'long_url';
    });

    let ok = true;
    let message = '';
    if (hasEmptyFields) {
      ok = false;
      message = 'All the fields are mandatory';
    } else {
      const { cost, utm_campaign } = campaignDetails;
      if (!/^[^,\s]+$/.test(utm_campaign)) {
        ok = false;
        message = 'Campaign name cannot contain spaces.';
      } else if (cost && isNaN(cost)) {
        ok = false;
        message = 'Cost cannot be empty';
      }
    }

    if (!ok) {
      setHasError(!ok);
      setErrorMessage(message);
    }
    return ok;
  };

  const fileUploadHandler = async (id) => {
    if (selectedFiles.length === 0) {
      return [];
    }
    const formBody = new FormData();
    selectedFiles.forEach((file) => {
      formBody.append('campaigns_files', file.data);
    });

    const res = await axiosInstance.post(
      base_url() + '/api/campaigns/' + id + '/files?context=campaigns',
      formBody,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (res) {
      setUploadedFiles(res.data);
      setSelectedFiles([]);
      return res.data;
    }
    return [];
  };

  const draftSaveHandler = async () => {
    if (!validateCampaignDetails(campaignDetails)) {
      return;
    }
    setOpen(false);
    onCloseCampaignModal();
    const isNew = !campaignDetails?.id;
    let req;
    if (!isNew) {
      req = axiosInstance.patch(
        `/api/campaigns/${campaignDetails.id}`,
        campaignDetails
      );
    } else {
      req = axiosInstance.post(`/api/campaigns`, campaignDetails);
    }

    try {
      const res = await req;
      setIsSuccess(true);
      setSuccessMessage('Campaign successfully added as draft');
      const { data } = res;

      if (isNew) {
        const uploadedCampaignFilesData = await fileUploadHandler(data.id);
        setTableData((prev) => [
          { ...data, campaignFiles: uploadedCampaignFilesData },
          ...prev,
        ]);
      } else {
        setTableData((prev) => {
          return prev.map((item) => (item.id === data.id ? { ...data } : item));
        });
      }
    } catch (err) {
      setHasError(true);
      setErrorMessage(
        err.response?.data?.message || 'Could not add the campaign.'
      );
    }
  };

  const createCampaignDetails = (e) => {
    let { name, value } = e.target;
    setCampaignDetails((prev) => ({
      ...prev,
      [name]: value.trim(),
    }));

    if (name === 'utm_source' && value === 'linkedin') {
      if (!campaignDetails.post_link) {
        setHasError(true);
        setErrorMessage('Link of the post is mandatory for LinkedIn');
        return;
      }
    }
  };

  const formatDateAndDay = (dateString) => {
    const date = moment(dateString);
    const day = date.date();
    const urlMonth = date.format('MM');
    const urlYear = date.format('YY');
    const dayName = date.format('dd').toLowerCase();
    const urlDate = `${dayName}_${day}${urlMonth}${urlYear}`;
    return urlDate;
  };

  const urlGenerator = () => {
    const source = campaignDetails.utm_source;
    const medium = campaignDetails.utm_medium.slice(0, 3);
    const campaign = campaignDetails.utm_campaign;
    let product = campaignDetails.product;
    const date = formatDateAndDay(campaignDetails.live_on);
    if (product === 'pc') {
      product = 'personalcoaching';
    }
    return `https://heycoach.in/super30?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}_${product}_${date}`;
  };

  const generateUrlHandler = async () => {
    if (validateCampaignDetails(campaignDetails) !== true) {
      return;
    }

    const demoObj = { ...campaignDetails };
    demoObj.long_url = urlGenerator(campaignDetails);
    demoObj.status = 'live';
    setLongUrl(demoObj.long_url);

    const isNew = !demoObj?.id;
    let req;
    if (!isNew) {
      req = axiosInstance.patch(`/api/campaigns/${demoObj.id}`, demoObj);
    } else {
      req = axiosInstance.post(`/api/campaigns`, demoObj);
    }

    try {
      const res = await req;
      setIsSuccess(true);
      setSuccessMessage('Campaign successfully added');
      const { data } = res;
      if (isNew) {
        const uploadedCampaignFilesData = await fileUploadHandler(data.id);
        setTableData((prev) => [
          { ...data, campaignFiles: uploadedCampaignFilesData },
          ...prev,
        ]);
      } else {
        setTableData((prev) => {
          return prev.map((item) => (item.id === data.id ? { ...data } : item));
        });
      }
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.response?.data?.message || 'Could not add Campaign');
    }
  };

  const lockHandler = () => {
    const shortUrl = campaignDetails?.short_url ?? '';
    const cost = campaignDetails?.cost ?? '';
    const clicks = campaignDetails?.total_click ?? '';
    const spentType = campaignDetails?.spent_type ?? '';
    const post = campaignDetails.post_link ?? '';
    const leadsAccounted = campaignDetails?.leads_accounted ?? '';
    const beneficiary_Email = campaignDetails?.beneficiary_email ?? '';
    const beneficiary_Name = campaignDetails?.beneficiary_name ?? '';
    const beneficiary_Ifsc = campaignDetails?.beneficiary_ifsc ?? '';
    const beneficiary_account =
      campaignDetails.beneficiary_account_number ?? '';
    if (
      shortUrl === '' ||
      clicks === '' ||
      leadsAccounted === '' ||
      cost === '' ||
      spentType === ''
    ) {
      setHasError(true);
      setErrorMessage('None of the fields can be empty');
      return;
    }
    if (
      beneficiary_Email === '' ||
      beneficiary_Ifsc === '' ||
      beneficiary_Name === '' ||
      beneficiary_account === ''
    ) {
      setHasError(true);
      setErrorMessage('Beneficiary Details are mandatory');
      return;
    }
    if (campaignDetails.utm_source === 'linkedin' && post === '') {
      setHasError(true);
      setErrorMessage('Link of the post is mandatory for LinkedIn utm source.');
      return;
    }
    if (isNaN(clicks) || isNaN(leadsAccounted)) {
      setHasError(true);
      setErrorMessage(
        'No. of Clicks, and Leads Accounted must be valid numbers'
      );
      return;
    }

    // if (
    //   !campaignDetails.campaignFiles ||
    //   campaignDetails.campaignFiles.length === 0
    // ) {
    //   setHasError(true);
    //   setErrorMessage(
    //     'Please upload at least one file before locking the campaign.'
    //   );
    //   return;
    // }

    const demoObj = { ...campaignDetails };
    demoObj.status = 'locked';
    if (spentType === 'fixed') {
      demoObj.amount_to_paid = campaignDetails.cost;
    } else {
      demoObj.amount_to_paid =
        campaignDetails.leads_accounted * campaignDetails.cost;
    }

    axiosInstance
      .patch(`/api/campaigns/${demoObj.id}`, demoObj)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Campaign Updates SucessFully');
        onCloseCampaignModal();
        setTableData((prev) => {
          return prev.map((item) =>
            item.id === res.data.id ? { ...res.data } : item
          );
        });
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could Update Campaign.'
        );
      });
  };

  const getConfirmationProps = () => {
    let confirmationProps = {
      message: 'Are you sure want to lock ?',
      yes: 'Lock',
      no: 'Cancel',
      yesHandler: lockHandler,
      noHandler: onCloseExitConfirmation,
    };

    if (campaignDetails.status !== 'live') {
      confirmationProps = {
        message: 'Are you sure want to exit ?',
        yes: 'Yes, exit',
        no: 'No, save as draft',
        yesHandler: onCloseCampaignModal,
        noHandler: draftSaveHandler,
      };
    }

    return confirmationProps;
  };

  const getAmountToPaidValue = () => {
    if (campaignDetails.spent_type === 'fixed' && campaignDetails?.cost) {
      return campaignDetails.cost;
    } else if (
      campaignDetails.spent_type === 'affiliate' &&
      campaignDetails?.cost &&
      campaignDetails?.leads_accounted
    ) {
      return campaignDetails.cost * campaignDetails.leads_accounted;
    } else {
      return 0;
    }
  };

  const updateStatusApiCall = async (newStatus) => {
    const data = {};
    if (newStatus) {
      data.status = newStatus;
    }
    if (data?.status === 'paid') {
      data.utr = campaignDetails.utr;
      data.processed_amount = campaignDetails.amount_to_paid;
    }

    try {
      if (!data?.status && Object.keys(data).length === 0) {
        setHasError(true);
        setErrorMessage('Status is missing.');
        return;
      } else if (data?.status === 'paid') {
        if (!data?.utr) {
          setHasError(true);
          setErrorMessage('Please add a utr number');
          return;
        } else if (
          (data?.processed_amount && isNaN(data?.processed_amount)) ||
          !data?.processed_amount
        ) {
          setHasError(true);
          setErrorMessage('Please enter valid processed amount');
          return;
        }
      }
      const res = await axiosInstance.patch(
        `/api/campaigns/${campaignDetails?.id}`,
        {
          data,
        }
      );
      setIsSuccess(true);
      setSuccessMessage('Campaign Updates SucessFully');
      onCloseCampaignModal();
      setTableData((prev) => {
        return prev.map((item) =>
          item.id === res.data.id ? { ...res.data } : item
        );
      });
    } catch (error) {
      setHasError(true);
      setErrorMessage(`Error updating details: ${error.message}`);
    }
  };

  // Allow to select  28 days previous date for creating campaign
  const previous28Days = moment().subtract(28, 'days').format('YYYY-MM-DD');

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  const textFieldStyle = {
    width: '60%',
    '& .MuiInputBase-root': {
      height: '70%',
    },
    '& .MuiInputBase-input': {
      height: '100%',
      padding: '0 14px',
    },
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseCampaignModal}
      maxWidth='md'
      PaperProps={{
        style: { maxHeight: '80vh' },
      }}
      fullWidth
    >
      {open && (
        <AddCampaignConfirmationModal
          exitConfirmation={getConfirmationProps()}
          open={open}
          onCloseExitConfirmation={onCloseExitConfirmation}
        />
      )}

      <Stack
        spacing={2}
        width='100%'
        padding='1rem'
      >
        <Stack
          direction='row'
          justifyContent='space-between'
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            style={{ margin: 'auto', width: '50%' }}
          >
            <EmailOutlinedIcon />
            <Typography
              variant='h6'
              style={{ marginLeft: 8 }}
            >
              Add Campaign
            </Typography>
          </Box>

          <IconButton
            onClick={exitOpen}
            edge='start'
            color='inherit'
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Box
          sx={{
            border: 1,
            borderColor: 'divider',
            borderRadius: '10px',
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab label='Basic' />
            <Tab
              label='Upload Image'
              sx={{ marginLeft: '7%' }}
            />
            <Tab
              label='Performance'
              sx={{ marginLeft: '7%' }}
            />
            <Tab
              label='Bank Details'
              sx={{ marginLeft: '7%' }}
            />
            <Tab
              label='Payment'
              sx={{ marginLeft: '7%' }}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={currentTab}
          index={0}
          style={{ width: '100%' }}
        >
          <CampaignBasic
            islive={islive}
            islocked={islocked}
            isApproved={isApproved}
            isPaid={isPaid}
            campaignDetails={campaignDetails}
            createCampaignDetails={createCampaignDetails}
            previous28Days={previous28Days}
            longUrl={longUrl}
            generateUrlHandler={generateUrlHandler}
            textFieldStyle={textFieldStyle}
          />
        </TabPanel>

        <TabPanel
          value={currentTab}
          index={1}
          style={{ width: '100%' }}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20, marginBottom: '4%' }}
          >
            Upload
          </Typography>
          <CampaignsFileUpload
            islive={islive}
            isRejected={isRejected}
            isDraft={isDraft}
            islocked={islocked}
            isApproved={isApproved}
            isPaid={isPaid}
            setIsSuccess={setIsSuccess}
            setSuccessMessage={setSuccessMessage}
            campaignDetails={campaignDetails}
            setHasError={setHasError}
            setErrorMessage={setErrorMessage}
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
            setTableData={setTableData}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </TabPanel>

        <TabPanel
          value={currentTab}
          index={2}
          style={{ width: '100%' }}
        >
          <CampaignPerformance
            islocked={islocked}
            isApproved={isApproved}
            isPaid={isPaid}
            campaignDetails={campaignDetails}
            createCampaignDetails={createCampaignDetails}
            textFieldStyle={textFieldStyle}
          />
        </TabPanel>

        <TabPanel
          value={currentTab}
          index={3}
          style={{ width: '100%' }}
        >
          <CampaignBankDetails
            islocked={islocked}
            isApproved={isApproved}
            isPaid={isPaid}
            campaignDetails={campaignDetails}
            createCampaignDetails={createCampaignDetails}
            textFieldStyle={textFieldStyle}
          />
        </TabPanel>

        <TabPanel
          value={currentTab}
          index={4}
          style={{ width: '100%' }}
        >
          <CampaignPayment
            islive={islive}
            islocked={islocked}
            isApproved={isApproved}
            isPaid={isPaid}
            campaignDetails={campaignDetails}
            createCampaignDetails={createCampaignDetails}
            lockConfirmationOpener={lockConfirmationOpener}
            updateStatusApiCall={updateStatusApiCall}
            onCloseCampaignModal={onCloseCampaignModal}
            getAmountToPaidValue={getAmountToPaidValue}
            textFieldStyle={textFieldStyle}
          />
        </TabPanel>
      </Stack>
    </Dialog>
  );
}
