import React, { useEffect, useState } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../components/Mode';
import {
  currentAdminIsAdmin,
  currentAdminIsSeniorManager,
  getCurrentAdminId,
  getTimeZoneFormattedTimestamp,
} from '../../../utils/common';
import { useLocation } from 'react-router-dom';
import CallInsightsFromReport from './CallInsightsFromReport';
import DailyUpdates from './DailyUpdates';
import DailyUpdatesV1 from './DailyUpdatesV1';
import ReportFilters from '../ReportFilters';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import { Stack } from '@chakra-ui/react';
import CustomBackdrop from '../../CustomBackdrop';

const AppReportsInsights = () => {
  const initialState = {
    admin_id: getCurrentAdminId(),
    date: getTimeZoneFormattedTimestamp(new Date()).split('T')[0],
  };

  const [filters, setFilters] = useState(initialState);
  const [reportId, setReportId] = useState(-1);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isFetchingResults, setIsFetchingResults] = useState(false);
  const location = useLocation();
  const endpoint = '/mobile/api/report-entries';

  useEffect(() => {
    const tmp = {};
    let { search } = location;
    if (search !== '') {
      search = search.substring(1).split('&');
      search.forEach((s) => {
        const [k, v] = s.split('=');
        tmp[k] = v;
      });
      setFilters(tmp);
    } else {
      const params = [];
      Object.entries(filters).forEach(([key, val], index) => {
        if (val && val !== '') {
          params.push(`${key}=${val}`);
        }
      });
      window.history.replaceState(null, '', '/reports?' + params.join('&'));
    }
    return () => {};
  }, []);

  return (
    <>
      {isFetchingResults && <CustomBackdrop open={isFetchingResults} />}

      <Stack
        spacing={3}
        sx={{ m: 3 }}
      >
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        {isSuccess && (
          <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
        )}

        <ReportFilters
          {...{
            filters,
            setFilters,
            reportId,
            setReportId,
          }}
        />
        {/* {currentAdminIsAdmin() ||
        (currentAdminIsSeniorManager() && getCurrentAdminId() === 101) ? ( */}
        <DailyUpdatesV1 //new component
          {...{
            filters,
            setFilters,
          }}
        />
        {/* ) : (
          <DailyUpdates //old component
            {...{
              filters,
              setFilters,
            }}
          />
        )} */}
        <CallInsightsFromReport
          {...{
            filters,
            reportId,
            setReportId,
            setHasError,
            endpoint,
            setErrorMessage,
            setIsSuccess,
            setSuccessMessage,
            setIsFetchingResults,
          }}
        />
      </Stack>
    </>
  );
};

export default AppReportsInsights;
