import { base_url } from '../../components/Mode';
const customLogger = {
  debug: (message) => {
    console.debug('[Socket.IO]', message);
  },
  info: (message) => {
    console.info('[Socket.IO]', message);
  },
  warn: (message) => {
    console.warn('[Socket.IO]', message);
  },
  error: (message) => {
    console.error('[Socket.IO]', message);
  },
};
function webSocketAuth(io, token) {
  const socket = io(base_url(), {
    auth: {
      token: token,
    },
    logger: customLogger,
  });

  return socket;
}

export default webSocketAuth;
