import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

export function FollowUpTimeConfirmationModal({
  openFollowUpTimeConfirmationModal,
  setOpenFollowUpTimeConfirmationModal,
  addInteraction,
}) {
  return (
    <Dialog
      open={openFollowUpTimeConfirmationModal}
      onClose={() => {
        setOpenFollowUpTimeConfirmationModal(false);
      }}
    >
      <DialogContent>
        <DialogContentText
          color={'error'}
          fontWeight={'bold'}
        >
          <span>
            The follow-up time has been scheduled outside of business hours.
            <br />
            Are you sure you want to proceed?
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setOpenFollowUpTimeConfirmationModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            setOpenFollowUpTimeConfirmationModal(false);
            addInteraction(true);
          }}
          autoFocus
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
