import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { base_url } from '../Mode';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';
import { hrms_candidate_status } from '../../utils/hrms/constants';
import CloseIcon from '@mui/icons-material/Close';

const initialState = {
  designation: '',
  joining_date: '',
};

const initialStateForManager = {
  lead_accessor_id: '',
};

export default function HrmsNewCandiadteJoiningModal({
  openCandidateJoiningModal,
  setOpenCandidateJoiningModal,
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
  selectedRowData,
  updateCandidateStatus,
  candidateData,
  roles,
  allAdmins,
  setAllAdmins,
}) {
  const [modalData, setModalData] = useState(initialState);
  const [modalDataForManager, setModalDataForManager] = useState(
    initialStateForManager
  );
  const [hasModalError, setHasModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [openConformationModal, setOpenConformationModal] = useState(false);
  const [managerList, setManagerList] = useState([]);

  useEffect(() => {
    if (hasModalError) {
      setTimeout(() => {
        setHasModalError(false);
      }, 6000);
    }
  }, [hasModalError]);

  useEffect(() => {
    getmanagerDropdown();
    return () => {};
  }, []);

  const designations = [
    {
      name: 'Business development associate',
      value: 'business_development_associate',
    },
    {
      name: 'Business development executive',
      value: 'business_development_executive',
    },
    {
      name: 'Business development manager',
      value: 'business_development_manager',
    },
    {
      name: 'Others',
      value: 'others',
    },
  ];

  const handleAddClick = (e) => {
    const emptyFields = [];
    const body = { ...modalData };
    if (body.designation === '') {
      emptyFields.push('dsignation');
    }
    if (body.joining_date === '') {
      emptyFields.push('joining date');
    }
    if (
      (modalData.designation === 'business_development_associate' ||
        modalData.designation === 'business_development_executive') &&
      modalDataForManager.lead_accessor_id === ''
    ) {
      emptyFields.push('manager');
    }
    if (candidateData.email1 == null || candidateData.email1 === '') {
      setHasError(true);
      setErrorMessage('Please enter the candidate email id.');
      return;
    }
    if (emptyFields.length > 0) {
      setHasModalError(true);
      setModalErrorMessage(
        `Please set ${emptyFields.join(', ')} for adding candidate details`
      );
      return;
    }

    axiosInstance
      .patch(
        base_url() + '/api/hrms/hrms_candidates/' + selectedRowData.id,
        body
      )
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Candidate joining details successfully added.');
        setOpenCandidateJoiningModal(false);
        updateCandidateStatus(hrms_candidate_status.JOINED);
        if (modalDataForManager.lead_accessor_id) {
          handleAddAdmin();
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not add the joining details.'
        );
      });
  };

  const handleAddAdmin = (e) => {
    const roleDetails = roles.filter((ele, index) => {
      return ele.name === 'trainee';
    });

    const name = candidateData.name.trim().split(' ');
    let fname;
    let lname;

    if (name.length === 1) {
      fname = name[0];
      lname = '';
    } else {
      fname = name[0];
      lname = name.slice(1).join(' ');
    }

    const initialState = {
      fname: fname,
      lname: lname,
      email: candidateData.email1,
      access_type: 'allowed',
      role_id: roleDetails[0]['id'],
      designation: modalData.designation,
    };

    const emptyFields = [];
    const body = { ...initialState };
    if (body.fname === '') {
      emptyFields.push('first name');
    }
    if (body.lname === '') {
      emptyFields.push('last name');
    }
    if (body.email === '' || body.email == null) {
      emptyFields.push('email');
    }
    if (body.role_id === '') {
      emptyFields.push('role');
    }
    if (emptyFields.length > 0 && !emptyFields.includes('last name')) {
      setHasError(true);
      setErrorMessage(
        `Please update ${emptyFields.join(', ')} for the new admin`
      );
      return;
    }

    axiosInstance
      .post(`/api/admins`, body)
      .then((res) => {
        setAllAdmins([
          ...allAdmins,
          { ...res.data.admin, roles: res.data.roles },
        ]);
        setIsSuccess(true);
        setSuccessMessage('Admin successfully added.');
        handleLeadAccessPolicyClick({
          lead_owner_id: res.data.admin.id,
          lead_accessor_id: modalDataForManager.lead_accessor_id,
        });
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not add the admin.'
        );
      });
  };

  const handleLeadAccessPolicyClick = (obj) => {
    const body = { ...obj };

    axiosInstance
      .post(`/api/lead-access-policies`, body)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Lead Access Policy successfully added.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not add the lead access policy.'
        );
      });
  };

  const selectedManagerName = () => {
    return managerList.filter(
      (manager) => manager?.Admin_id == modalDataForManager?.lead_accessor_id
    )[0]['Full_Name'];
  };

  const getConfirmationModal = () => {
    return (
      <Dialog
        open={openConformationModal}
        onClose={() => setOpenConformationModal(false)}
      >
        <DialogContent>
          <DialogContentText>
            {candidateData?.name} has joined HeyCoach as a{' '}
            {modalData?.designation} on {modalData?.joining_date}, and their
            manager is {selectedManagerName()}. Do you want to Proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpenConformationModal(false)}>
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              setOpenConformationModal(false);
              handleAddClick();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleUpdate = () => {
    if (modalDataForManager.lead_accessor_id) {
      setOpenConformationModal(true);
    } else {
      handleAddClick();
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getmanagerDropdown = async () => {
    const res = await axiosInstance.get(
      `/api/manager_actions/subordinate_managers`
    );
    setManagerList(res.data);
  };

  const managerDropdown = () => {
    return managerList.map((manager, index) => {
      return (
        <MenuItem
          key={index}
          value={manager?.Admin_id}
          style={{
            paddingBottom: '5px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            width: '100%',
            borderBottom: '1px solid #e0e0e0',
            textAlign: 'left !important',
          }}
        >
          {`${manager?.Full_Name}`}
        </MenuItem>
      );
    });
  };

  return (
    <Dialog
      open={openCandidateJoiningModal}
      onClose={(e) => setOpenCandidateJoiningModal(false)}
      fullWidth
      maxWidth='sm'
      sx={{
        width: '450px',
        height: '650px',
        marginLeft: '30%',
        borderRadius: '50px',
      }}
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <CloseIcon
          sx={{
            marginTop: '15px',
            marginRight: '15px',
            cursor: 'pointer',
          }}
          onClick={(e) => setOpenCandidateJoiningModal(false)}
        />
      </Box>
      <Box
        display='flex'
        justifyContent='flex-start'
      >
        <Typography
          sx={{
            marginLeft: '25px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          Add Details:
        </Typography>
      </Box>
      <Box
        display='flex'
        justifyContent='flex-start'
      >
        <Typography
          sx={{
            marginLeft: '25px',
            fontSize: '12px',
          }}
        >
          Add the joining date and designation
        </Typography>
      </Box>
      {openConformationModal && getConfirmationModal()}
      <DialogContent>
        <DialogContentText>
          <Stack spacing={3}>
            {hasModalError && (
              <Typography
                variant='body2'
                color='error'
              >
                *{modalErrorMessage}
              </Typography>
            )}
            <Box
              display='flex'
              justifyContent='space-between'
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginTop: '9px',
                }}
              >
                Joining Date:
              </Typography>

              <FormControl maxWidth='sm'>
                <TextField
                  type='date'
                  size='small'
                  sx={{
                    width: '220px',
                  }}
                  value={modalData.joining_date}
                  onChange={(e) =>
                    setModalData({ ...modalData, joining_date: e.target.value })
                  }
                  inputProps={{
                    max: formatDate(new Date()).split('/').reverse().join('-'),
                  }}
                />
              </FormControl>
            </Box>

            <Box
              display='flex'
              justifyContent='space-between'
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginTop: '9px',
                }}
              >
                Designation:
              </Typography>

              <FormControl
                maxWidth='sm'
                size='small'
              >
                <InputLabel>select Designation</InputLabel>
                <Select
                  label='Select Designation'
                  sx={{
                    width: '220px',
                  }}
                  value={modalData.designation}
                  onChange={(e) =>
                    setModalData({ ...modalData, designation: e.target.value })
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        height: '110px',
                        width: '250px',
                      },
                    },
                  }}
                >
                  {designations.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      style={{
                        marginLeft: '8px',
                        paddingBottom: '5px',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        width: '100%',
                        borderBottom: '1px solid #e0e0e0',
                        textAlign: 'left !important',
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {(modalData.designation === 'business_development_associate' ||
              modalData.designation === 'business_development_executive') && (
              <Box
                display='flex'
                justifyContent='space-between'
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginTop: '9px',
                  }}
                >
                  Manager:
                </Typography>

                <FormControl
                  maxWidth='sm'
                  size='small'
                >
                  <InputLabel>Select Manager</InputLabel>
                  <Select
                    label='Select Manager'
                    sx={{
                      width: '220px',
                    }}
                    value={modalDataForManager.lead_accessor_id}
                    onChange={(e) =>
                      setModalDataForManager({
                        ...modalDataForManager,
                        lead_accessor_id: e.target.value,
                      })
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          height: '110px',
                          width: '50px',
                          textAlign: 'left',
                        },
                      },
                    }}
                  >
                    {managerDropdown()}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Stack>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          onClick={() => handleUpdate()}
        >
          update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
