export const defaultBucketSearchAdminColumns = {
  admin: {
    id: true,
    name: true,
    status: true,
    assignee_name: true,
    label: true,
    // last_interaction: false,
    // last_interaction_on: false,
  },
  marketing: {
    id: true,
    status: true,
    created_at: true,
    lead_source_type: true,
    company: true,
    graduation_year: true,
    label: true,
    assignee_name: true,
  },
  lead: {
    name: true,
    assignee_name: true,
    status: true,
    snoozed_until: true,
    // last_interaction: false,
    // last_interaction_on: false,
    // last_interaction_by: false,
    graduation_year: true,
    company: true,
    id: true,
  },
  senior_manager: {
    name: true,
    assignee_name: true,
    status: true,
    snoozed_until: true,
    // last_interaction: false,
    // last_interaction_on: false,
    // last_interaction_by: false,
    graduation_year: true,
    company: true,
    id: true,
  },
  independent_contributor: {
    name: true,
    label: true,
    status: true,
    snoozed_until: true,
    graduation_year: true,
    company: true,
    id: true,
  },
  trainee: {
    name: true,
    label: true,
    status: true,
    snoozed_until: true,
    graduation_year: true,
    company: true,
    id: true,
  },
};
