import PlusIcon from '../Atoms/PlusIcon';
import React, { useState, useEffect } from 'react';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import { base_url } from '../../../components/Mode';
import axiosInstance from 'apis/axiosInstance';
import { getCurrentAdminId } from '../../../utils/common';
import { Box } from '@mui/material';
import CustomImageList from '../Atoms/CustomImageList';
import CustomBackdrop from '../../CustomBackdrop';

const ImageGallery = ({
  handleImagePreview,
  closeImagePreview,
  admin,
  previewImage,
  edit,
  personalData,
  setUpdate,
  update,
}) => {
  const [adminGalleryImages, setAdminGalleryImages] = useState(
    personalData?.admin_gallery ?? null
  );
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setAdminGalleryImages(personalData?.admin_gallery ?? null);
  }, [personalData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (!file) {
      return;
    }
    uploadImage(file);
  };

  const uploadImage = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('profile-gallery', file);

      const response = await axiosInstance.patch(
        `/api/admins/${getCurrentAdminId()}/profile-gallery?context=profile-gallery`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setUpdate(!update);
      setIsSuccess(true);
      setLoading(false);
      setSuccessMessage('Image uploaded successfully.');
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const galleryStyles = {
    height: '150px',
    width: '100%',
    marginLeft: '26px',
    image: {
      width: '170px',
      height: '140px',
    },
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <CustomBackdrop open={loading} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <CustomImageList
          edit={edit}
          handleImagePreview={handleImagePreview}
          closeImagePreview={closeImagePreview}
          previewImage={previewImage}
          admin={admin}
          imageUrls={adminGalleryImages}
          style={galleryStyles}
          setUpdate={setUpdate}
          update={update}
          onChange={handleFileChange}
        />
      </Box>
    </>
  );
};

export default ImageGallery;
