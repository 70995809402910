import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography, Button, Textarea } from '@mui/material';
import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ErrorNotifier from '../../../../ToastNotifications/ErrorNotifier';
import CircularProgress from '@mui/material/CircularProgress';
import { base_url } from '../../../../Mode';
import axiosInstance from 'apis/axiosInstance';

const FeedbackPopUp = ({
  openPopUp,
  setopenPopUp,
  isOpenFeedbackPopUp,
  setIsOpenFeedbackPopUp,
  traineeDetails,
  submissionDetails,
  setSuccessMessage,
  setIsSuccess,
}) => {
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const url = base_url() + `/api/training-mockcalls/feedback/interviewer`;
  const handleSubmitFeedback = async () => {
    if (!feedback || feedback.trim() === '') {
      setHasError(true);
      setErrorMessage('Feedback is empty!');
    } else {
      setIsLoading(true);
      await axiosInstance
        .patch(url, {
          feedback,
          id: submissionDetails?.id,
        })
        .then((res) => {
          setIsLoading(false);
          setIsOpenFeedbackPopUp(false);
          setopenPopUp(false);
          setIsSuccess(true);
          setSuccessMessage('Feedback Submitted Successfully.');
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage('An error occurred while submitting the feedback!');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <Dialog
      open={openPopUp}
      maxWidth='md'
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <DialogContent dividers>
        <Box
          p={3}
          style={{ minWidth: '450px' }}
        >
          <Box mb={1}>
            <Typography
              variant='body1'
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '16px',
                color: 'green',
              }}
            >
              You have been assigned to take a mockcall.
            </Typography>
          </Box>
          <Box
            mb={1}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography variant='body'>
              Scenario: {submissionDetails?.description?.interviewer}
            </Typography>
          </Box>
          <Box
            mb={1}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography variant='h6'>
              Mockcall with {traineeDetails?.traineeName}
            </Typography>
          </Box>
          <hr></hr>
          <Box
            mt={1}
            mb={1}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <TextareaAutosize
              style={{
                width: '100%',
                height: '50%',
                padding: '5px',
                fontSize: '16px',
                border: '1px solid #ccc',
                resize: 'none',
              }}
              minRows={7}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </Box>
          <Box
            mb={1}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography
              variant='body1'
              style={{ fontSize: '16px', fontWeight: 700 }}
            >
              Your feedback will be public.
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            mr={2}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                style={{ backgroundColor: '#3994FF', width: '150px' }}
                variant='contained'
                onClick={() => {
                  handleSubmitFeedback();
                }}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackPopUp;
