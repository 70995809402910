import React, { useState, useEffect } from 'react';
import AdminsAutocompleteDropdown from '../../../../components/admins/AdminAutoComplete';
import { TextField, Stack, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../../components/Mode';
import ErrorNotifier from '../../../../components/ToastNotifications/ErrorNotifier';
import {
  createChartData,
  createBackgroundObject,
  mergeDataSets,
} from './TeamActivity';
import { handleDrawerClose } from '../../../Dashboard';
import 'chartjs-plugin-zoom';

Chart.register(...registerables);
ChartJS.register(LinearScale, BarElement, Title, Tooltip, Legend);

const config = {
  plugins: {
    zoom: {
      pan: {
        enabled: true,
        mode: 'x',
      },
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'x',
      },
    },
    tooltip: {
      xAlign: 'left',
      callbacks: {
        label: (context) => {
          const datasetLabel = context.dataset.label;

          const [x1, x2] = context.raw;
          const hr1 = Math.floor(x1) % 24; // Use modulo 24 for 24-hour format
          const hr2 = Math.floor(x2) % 24;
          const min1 = Math.floor((x1 - hr1) * 60);
          const min2 = Math.floor((x2 - hr2) * 60);

          // Convert negative hours to positive for correct calculation
          const hr1Positive = hr1 < 0 ? 24 + hr1 : hr1;
          const hr2Positive = hr2 < 0 ? 24 + hr2 : hr2;

          const inactivityGapInMinutes =
            (hr2Positive - hr1Positive) * 60 + min2 - min1;
          const timeDiffInMinutes =
            inactivityGapInMinutes < 0
              ? 1440 + inactivityGapInMinutes
              : inactivityGapInMinutes;

          // Format minutes and hours for display
          const formattedStartTime = `${hr1Positive % 12 || 12}:${
            min1 < 10 ? `0${min1}` : min1
          } ${hr1Positive >= 12 ? 'PM' : 'AM'}`;
          const formattedEndTime = `${hr2Positive % 12 || 12}:${
            min2 < 10 ? `0${min2}` : min2
          } ${hr2Positive >= 12 ? 'PM' : 'AM'}`;

          if (datasetLabel === 'active') {
            return `${timeDiffInMinutes} minutes Online From ${formattedStartTime} to ${formattedEndTime}`;
          } else if (datasetLabel === 'inactive') {
            return `${timeDiffInMinutes} minutes Offline From ${formattedStartTime} to ${formattedEndTime}`;
          } else if (datasetLabel === 'call') {
            return `${timeDiffInMinutes} minutes On call From ${formattedStartTime} to ${formattedEndTime}`;
          }
        },
      },
    },
    legend: {
      display: false,
    },
    datalabels: {
      display: (context) => {
        return context.dataset.label === 'Inactive';
      },
      align: 'center',
      anchor: 'center',
      color: '#ffffff',
      font: {
        weight: 'bold',
      },
      formatter: (value, context) => {
        const [start, end] = value;
        const duration = end - start;
        return `${duration} hr`;
      },
    },
  },
  responsive: true,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        color: (context) => {
          const hour = context.tick.value % 24;
          if (hour === 10 || hour === 21) {
            return '#0288d1';
          } else if (hour === 11 || hour === 20) {
            return 'black';
          } else {
            return 'rgba(0, 0, 0, 0.1)';
          }
        },
        borderDash: [3, 3],
      },
      position: 'top',
      min: 8,
      max: 23,
      type: 'linear',
      ticks: {
        stepSize: 1,
        callback: (value, index) => {
          const formattedLabel = `${Math.floor(value) % 12 || 12} ${
            Math.floor(value) >= 12 ? 'PM' : 'AM'
          }`;
          return value >= 8 && value <= 23 ? formattedLabel : '';
        },
        color: (context) => {
          const value = context.tick.value;
          if (value > 21 || value < 10) {
            return '#000000';
          }
          return '#0288d1';
        },
      },
    },
    y: {
      grid: {
        color: 'rgba(0, 0, 0, 0.1)',
        borderDash: [5, 5],
      },
      max: 7,
      min: 0,
      ticks: {
        beginAtZero: true,
        color: '#000',
      },
      stacked: true,
    },
  },
};

const IndividualActivity = ({ data }) => {
  let chartData = {};
  const [individualData, setIndividualData] = useState(
    data
      ? data.individualData
      : Object.keys(chartData).length !== 0
      ? chartData
      : null
  );
  const id = data?.id;
  const date = data?.date;
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  const [queryParams, setQueryParams] = useState({
    start: date ? date : formattedCurrentDate,
    end: date ? date : formattedCurrentDate,
    of: id ? id : null,
  });
  const [graphHeight, setGraphHeight] = useState(
    individualData?.labels?.length * 90
  );
  const [activeData, setActiveData] = useState(null);
  const [inactiveData, setInactiveData] = useState(null);
  const [callData, setCallData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [labelData, setLabelData] = useState(null);

  useEffect(() => {
    handleDrawerClose();

    if (!queryParams.start || !queryParams.end || !queryParams.of) {
      setHasError(true);
      setErrorMessage(
        'Start date, End date and Activity of should not be empty to fetch data'
      );
      return;
    }
    const startDate = new Date(queryParams.start);
    const endDate = new Date(queryParams.end);
    const currentDate = new Date();
    if (startDate > endDate) {
      setHasError(true);
      setErrorMessage('Start date should be less than end date');
      return;
    }

    if (endDate > currentDate) {
      setHasError(true);
      setErrorMessage('End date should not be in the future');
      return;
    }

    fetchActivityData();
  }, [queryParams]);

  useEffect(() => {
    if (labelData && (activeData || callData || inactiveData)) {
      const callDataForChart = createChartData(callData, 'call');
      const activeDataForChart = createChartData(activeData, 'active');
      const inactiveDataForChart = createChartData(inactiveData, 'inactive');
      const backGroundObject = createBackgroundObject(labelData);
      const mergedDataset = mergeDataSets(
        callDataForChart,
        activeDataForChart,
        inactiveDataForChart,
        [backGroundObject]
      );

      if (mergedDataset && labelData) {
        const updatedChartData = {
          labels: labelData,
          datasets: mergedDataset,
        };

        setIndividualData(updatedChartData);
      }
    }
  }, [labelData, activeData, inactiveData]);

  useEffect(() => {
    setGraphHeight(individualData?.labels?.length * 90);
  }, [individualData]);

  const fetchActivityData = () => {
    axiosInstance
      .get(
        `/api/individual-admin-activities?id=${queryParams.of}&start=${queryParams.start}&end=${queryParams.end}`
      )
      .then((res) => {
        setActiveData(res?.data?.active);
        setInactiveData(res?.data?.inactive);
        setCallData(res?.data?.call);
        setLabelData(res?.data?.label);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch activity data.'
        );
        setActiveData(null);
        setInactiveData(null);
        setLabelData(null);
        setIndividualData(null);
      });
  };

  const handleStartDateChange = (e) => {
    setQueryParams({ ...queryParams, start: e.target.value });
  };

  const handleEndDateChange = (e) => {
    setQueryParams({ ...queryParams, end: e.target.value });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <div
        style={{
          height: '50px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            marginLeft: '65px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              marginRight: '50px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                height: 20,
                width: 20,
                border: 'solid 1px',
                backgroundColor: '#4fc3f7',
                mr: 1,
                borderRadius: 1,
              }}
            />
            <span>Active</span>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}
          >
            <Box
              sx={{
                height: 20,
                width: 20,
                border: 'solid 1px',
                backgroundColor: '#f44336',
                mr: 1,
                borderRadius: 1,
              }}
            />
            <span>Inactive</span>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}
          >
            <Box
              sx={{
                height: 20,
                width: 20,
                border: 'solid 1px',
                backgroundColor: '#1565c0',
                mr: 1,
                borderRadius: 1,
              }}
            />
            <span>On Call</span>
          </Box>
        </div>

        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row-reverse',
          }}
        >
          <TextField
            type='date'
            label='End Date'
            size='small'
            InputLabelProps={{ shrink: true }}
            onChange={handleEndDateChange}
            value={queryParams.end}
          />
          <TextField
            type='date'
            label='Start Date'
            size='small'
            InputLabelProps={{ shrink: true }}
            onChange={handleStartDateChange}
            value={queryParams.start}
            style={{ marginRight: '16px' }}
          />

          <AdminsAutocompleteDropdown
            label='Activity of'
            showNoneOption
            value={queryParams.of}
            onChangeHandler={(e, newValue) => {
              setQueryParams({
                ...queryParams,
                of: newValue.value === 'none' ? '' : newValue.value,
              });
            }}
            size='small'
            sx={{
              width: 200,
            }}
          />
        </div>
      </div>

      {individualData &&
      Object.keys(individualData).length !== 0 &&
      individualData.labels &&
      individualData.labels.length > 0 &&
      individualData.datasets &&
      individualData.datasets.length > 0 ? (
        <Box
          width='inherit'
          sx={{
            overflowX: 'auto',
            position: 'relative',
            height: `${graphHeight}px`,
          }}
        >
          <Bar
            options={config}
            data={individualData}
          />
        </Box>
      ) : (
        <div style={{ textAlign: 'center' }}>Admin Activity Data Not Found</div>
      )}
    </>
  );
};

export default IndividualActivity;
