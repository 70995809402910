import React, { useState, useEffect } from 'react';
import { Box, Switch, ButtonGroup, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import axiosInstance from 'apis/axiosInstance';
import clsx from 'clsx';
import { DataGrid } from '@mui/x-data-grid';
import { base_url } from '../../../components/Mode';
import {
  splitByUnderscoresAndCapitalize,
  currentAdminIsHr,
} from '../../../utils/common';
import AdminRolesColumn from './AdminRolesColumn';

export default function AdminsTable({
  allAdmins,
  setAllAdmins,
  roles,
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
}) {
  const [adminBeingUpdated, setAdminBeingUpdated] = useState({});
  const [adminIdToIndexMap, setAdminIdToIndexMap] = useState({});
  const [perPage, setPerPage] = useState(50);
  const [teleCmiButtonState, setTeleCmiButtonState] = useState(0);
  const isHr = currentAdminIsHr();

  useEffect(() => {
    const tmpMap = {};
    allAdmins.forEach((admin, index) => {
      tmpMap[admin.id] = index;
    });
    setAdminIdToIndexMap(tmpMap);
    return () => {};
  }, [allAdmins]);

  const setDesignation = (updatedAdmin) => {
    return designationObj[updatedAdmin.designation];
  };

  const updateAdminBeingUpdate = (updatedAdmin) => {
    let updatedAdminobj = { ...adminBeingUpdated };
    let updateAdminId = updatedAdmin.id;
    updatedAdminobj[updateAdminId].mode = 'view';
    updatedAdminobj[updateAdminId].ignoreModifications = true;
    setAdminBeingUpdated(updatedAdminobj);
  };

  const designationObj = {
    '': null,
    'Business development associate': 'business_development_associate',
    'Business development executive': 'business_development_executive',
    'Business development manager': 'business_development_manager',
  };

  const getDesignationKeyByValue = (value) => {
    for (let key in designationObj) {
      if (designationObj[key] == value) {
        return key;
      }
    }
  };

  const updateTelecmiAccess = async (adminId, updates) => {
    try {
      const response = await axiosInstance.patch(
        base_url() + `/api/admins/${adminId}/telecmi_user`,
        updates
      );
      const updatedAdminData = response?.data;
      setTeleCmiButtonState((prevState) => ({
        ...prevState,
        [adminId]: updatedAdminData?.telecmi_access,
      }));
    } catch (error) {
      console.error(
        'Error updating TeleCMI access:',
        error.response?.data || error.message
      );
      setHasError(true);
      setErrorMessage(`Error updating TeleCMI access ${error.message}`);
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'S.No',
      width: 80,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      paddingLeft: '25px',
      valueGetter: (params) => `${adminIdToIndexMap[params.row.id] + 1}`,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 280,
      align: 'left',
      headerAlign: 'left',
      paddingLeft: '25px',
      valueGetter: (params) => `${params.row.fname} ${params.row.lname}`,
      editable: isHr,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 260,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'access_type',
      headerName: 'Access type',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      editable: true,
      valueOptions: ['allowed', 'restricted', 'disabled'],
      valueGetter: (params) => params.row.access_type,
      renderCell: (params) => {
        const states = ['allowed', 'restricted', 'disabled'];
        return (
          <ButtonGroup
            color='primary'
            size='small'
          >
            {states.map((state) => {
              return (
                <Button
                  variant={
                    params.row.access_type === state ? 'contained' : 'outlined'
                  }
                  onClick={() => {
                    const body = {
                      access_type: state,
                    };
                    updateAdminApiCall(body, params.row.id);
                  }}
                >
                  {state}
                </Button>
              );
            })}
          </ButtonGroup>
        );
      },
    },
    !isHr && {
      field: 'enforce_focus_mode',
      headerName: 'Focus mode',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      editable: true,
      valueOptions: ['Enforced', 'Unenforced'],
      valueGetter: (params) =>
        params.row.enforce_focus_mode ? 'Enforced' : 'Unenforced',
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.enforce_focus_mode}
            onChange={(e) => {
              const body = {
                enforce_focus_mode: !params.row.enforce_focus_mode,
              };

              updateAdminApiCall(body, params.row.id);
            }}
            color={params.row.enforce_focus_mode ? 'success' : 'default'}
          />
        );
      },
      cellClassName: (params) => {
        return clsx('access', {
          disabled: params.value === 'Enforced',
          allowed: params.value === 'Unenforced',
        });
      },
    },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 380,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const { id } = params.row;
        const isInEditMode = adminBeingUpdated[id]?.mode === 'edit';
        const cellId = parseInt(Object.keys(adminBeingUpdated)[0]);
        if (isInEditMode) {
          return [
            <AdminRolesColumn
              params={params}
              roles={roles}
              setIsSuccess={setIsSuccess}
              setSuccessMessage={setSuccessMessage}
              setHasError={setHasError}
              setErrorMessage={setErrorMessage}
            />,
          ];
        } else {
          const selectedRoles = params.row.roles
            .map((role) => splitByUnderscoresAndCapitalize(role.name))
            .join(', ');
          return selectedRoles;
        }
      },
    },
    {
      field: 'telecmi',
      headerName: 'TeleCMI',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      editable: true,
      valueOptions: ['1', '0'],
      valueGetter: (params) => (params.row.telecmi_access ? '1' : '0'),
      renderCell: (params) => {
        const telecmiAccessState =
          teleCmiButtonState[params.row.id] ?? params.row.telecmi_access;
        const isIndependentContributorOrTrainee = params.row.roles.some(
          (role) => ['independent_contributor', 'trainee'].includes(role.name)
        );

        return (
          isIndependentContributorOrTrainee &&
          params.row.telecmi_access !== null && (
            <Switch
              checked={telecmiAccessState}
              onChange={(e) => {
                const newStatus = e.target.checked;
                updateTelecmiAccess(params.row.id, {
                  telecmi_access: newStatus,
                });
              }}
              color='primary'
            />
          )
        );
      },
      cellClassName: (params) => {
        return clsx('access', {
          disabled: params.value === '1',
          allowed: params.value === '0',
        });
      },
    },
    {
      field: 'designation',
      headerName: 'Designation',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      editable: true,
      valueOptions: Object.keys(designationObj),

      valueGetter: (params) => {
        return getDesignationKeyByValue(params.row.designation);
      },
    },
    !isHr && {
      field: 'show_inactivity',
      headerName: 'Inactivity',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      editable: true,
      valueOptions: ['Show', 'Hide'],
      valueGetter: (params) => (params.row.show_inactivity ? 'Show' : 'Hide'),
    },
    !isHr && {
      field: 'auto_assign_lead_type',
      headerName: 'Auto Assign Lead Type',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      type: 'singleSelect',
      valueOptions: ['None', 'New fresh inbound', 'Old fresh inbound'],
      editable: true,
      valueGetter: (params) =>
        !params.row.auto_assign_lead_type
          ? 'None'
          : splitByUnderscoresAndCapitalize(params.row.auto_assign_lead_type),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 180,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = adminBeingUpdated[id]?.mode === 'edit';
        if (isInEditMode) {
          return [
            <CheckIcon
              fontSize='medium'
              color='success'
              sx={{ cursor: 'pointer' }}
              onClick={(e) => handleAdminUpdate('update', id)}
            />,
            <ClearIcon
              fontSize='medium'
              color='error'
              onClick={(e) => handleAdminUpdate('clear', id)}
              sx={{ cursor: 'pointer' }}
            />,
          ];
        }
        return [
          <EditIcon
            onClick={(e) => {
              setAdminBeingUpdated({
                ...adminBeingUpdated,
                [id]: { mode: 'edit' },
              });
            }}
            sx={{ cursor: 'pointer' }}
          />,
        ];
      },
    },
  ].filter(Boolean);

  const handleAdminUpdate = (clickType, id) => {
    if (clickType === 'clear') {
      setAdminBeingUpdated({
        ...adminBeingUpdated,
        [id]: { mode: 'view', ignoreModifications: true },
      });
      return;
    }
    setAdminBeingUpdated({ ...adminBeingUpdated, [id]: { mode: 'view' } });
  };

  const processAdminUpdate = (updatedAdmin) => {
    const index = adminIdToIndexMap[updatedAdmin.id];
    const oldAdmin = allAdmins[index];
    const body = {};
    if (updatedAdmin.email !== '' && updatedAdmin.email !== oldAdmin.email) {
      body.email = updatedAdmin.email;
    }
    body.designation = setDesignation(updatedAdmin);

    updateAdminBeingUpdate(updatedAdmin);

    if (isHr) {
      const nameParts = updatedAdmin.name.split(' ');
      const fname = nameParts.shift();
      const lname = nameParts.join(' ');
      if (fname !== oldAdmin.fname || lname !== oldAdmin.lname) {
        body.fname = fname;
        body.lname = lname;
      }
    } else {
      body.auto_assign_lead_type =
        updatedAdmin.auto_assign_lead_type !== 'None'
          ? updatedAdmin.auto_assign_lead_type
              .toLowerCase()
              .split(' ')
              .join('_')
          : null;
      body.access_type = updatedAdmin.access_type;
      body.show_inactivity = updatedAdmin.show_inactivity === 'Show';
      body.enforce_focus_mode = updatedAdmin.enforce_focus_mode === 'Enforced';
    }

    updateAdminApiCall(body, updatedAdmin.id);
    return updatedAdmin;
    // for reference, refer to this link: https://mui.com/x/react-data-grid/editing/#full-featured-crud-component
  };

  const updateAdminApiCall = (body, adminId) => {
    axiosInstance
      .patch(`/api/admins/${adminId}`, body)
      .then((res) => {
        const tmpAdmins = [...allAdmins];
        const index = adminIdToIndexMap[adminId];
        tmpAdmins[index] = res.data;
        setAllAdmins(tmpAdmins);
        setIsSuccess(true);
        setSuccessMessage('Admin successfully updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the admin.'
        );
      });
  };

  return (
    <Box
      width='inherit'
      height='100%'
      sx={{
        mt: 5,
        '& .access.allowed': {
          color: 'green',
          fontWeight: '600',
        },
        '& .access.disabled': {
          color: 'red',
          fontWeight: '600',
        },
      }}
    >
      <DataGrid
        //Added filter model here to show only allowed admins by default
        initialState={{
          filter: {
            filterModel: {
              items: [
                {
                  columnField: 'access_type',
                  operatorValue: 'is',
                  value: 'allowed',
                },
              ],
            },
          },
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        rows={allAdmins}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
        onPageSizeChange={(page) => setPerPage(page)}
        pagination
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '15px',
          },
        }}
        editMode='row'
        rowModesModel={adminBeingUpdated}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processAdminUpdate}
        onRowEditStart={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        onRowEditStop={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
      />
    </Box>
  );
}
