import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import React, { useEffect, useState } from 'react';
import { base_url } from '../../../components/Mode';
import {
  currentAdminIsSeniorManager,
  getCurrentAdminId,
  getCurrentAdminRole,
  sortByFullName,
} from '../../../utils/common';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import { DataGrid } from '@mui/x-data-grid';
import constants from '../../../utils/constants';
import CustomBackdrop from '../../CustomBackdrop';
import SalsaAssignedLeadsManagerWiseCount from './SalsaAssignedLeadsManagerWiseCount';
const { ADMIN } = constants;

const LeadsAssignedBySalsa = () => {
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const [managerId, setManagerId] = useState(null);
  const [salsaAssignments, setSalsaAssignments] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalLeadCount, setTotalLeadCount] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!getCurrentAdminRole().includes(ADMIN) && getCurrentAdminId() !== 206) {
      setManagerId(getCurrentAdminId());
    }
    getmanagerDropdown();
  }, []);

  useEffect(() => {
    if (!managerId) {
      return;
    }
    fetchSalsaAssignments();
  }, [managerId]);

  const fetchSalsaAssignments = () => {
    if (!managerId) {
      setHasError(true);
      setErrorMessage('Select Manager to see data');
      return;
    }
    setLoading(true);
    axiosInstance
      .get(`/api/salsa/assigned-leads/${managerId}`)
      .then((res) => {
        setSalsaAssignments(res.data);
        countTotalLeadIds(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch lead ranks"
        );
        setLoading(false);
      });
  };

  const getmanagerDropdown = async () => {
    const res = await axiosInstance.get(
      `/api/manager_actions/subordinate_managers`
    );
    setSubordinateAdmins(res.data);
  };

  const managerDropdown = () => {
    return sortByFullName(subordinateAdmins, 'Full_Name').map(
      (manager, index) => {
        return (
          <MenuItem
            value={manager?.Admin_id}
          >{`${manager?.Full_Name}`}</MenuItem>
        );
      }
    );
  };

  const countTotalLeadIds = (data) => {
    let totalCount = 0;

    data?.forEach((entry) => {
      if (entry.lead_ids) {
        const leadIdsArray = entry.lead_ids.split(',');
        totalCount += leadIdsArray.length;
      }
    });

    setTotalLeadCount(totalCount);
  };

  const columns = [
    { field: 'fname', headerName: 'First Name', width: 150 },
    { field: 'lname', headerName: 'Last Name', width: 150 },
    { field: 'count', headerName: 'Lead Count', width: 100 },
    { field: 'lead_ids', headerName: 'Lead IDs Assigned', width: 800 },
  ];

  const rows = salsaAssignments?.map((row, index) => ({
    id: index + 1,
    lead_owner_id: row.lead_owner_id,
    fname: row.fname || '-',
    lname: row.lname || '-',
    lead_ids: row.lead_ids,
    count: row.lead_ids.split(',').length,
  }));

  return (
    <>
      <CustomBackdrop open={{ open: loading }} />
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {(currentAdminIsSeniorManager() ||
        getCurrentAdminRole().includes(ADMIN)) && (
        <SalsaAssignedLeadsManagerWiseCount />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: 160,
          margin: 2,
          gap: 2,
        }}
      >
        {(currentAdminIsSeniorManager() ||
          getCurrentAdminRole().includes(ADMIN)) && (
          <FormControl sx={{ minWidth: 180 }}>
            <InputLabel
              id='managers-label'
              sx={{
                marginBottom: 2,
                paddingRight: 1,
                marginTop: '-8px',
              }}
            >
              Managers
            </InputLabel>
            <Select
              labelId='managers-label'
              id='manager-select'
              value={managerId}
              label='Managers'
              size='small'
              onChange={(e) => {
                setManagerId(e.target.value);
              }}
            >
              {managerDropdown()}
            </Select>
          </FormControl>
        )}
      </Box>
      {salsaAssignments &&
      Array.isArray(salsaAssignments) &&
      salsaAssignments.length > 0 ? (
        <div style={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            showFooter
            footerHeight={40}
            components={{
              Footer: () => (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '8px',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    fontWeight='bold'
                    marginLeft='16px'
                  >
                    Total Leads Consumed By Team: {totalLeadCount}
                  </Typography>
                </Box>
              ),
            }}
          />
        </div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          No data available
        </div>
      )}
    </>
  );
};

export default LeadsAssignedBySalsa;
