import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfettiEffect from './users/ConfettingEffect';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from './Mode';

export const NotificationTapCapAchievement = (props) => {
  const { setNewAchievement, achieverDetailList } = props;
  const [seconds, setSeconds] = useState(10);
  const [displayStack, setDisplayStack] = useState([]);
  console.log(achieverDetailList);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0) {
      setNewAchievement(false);
    }
  }, [seconds]);

  useEffect(() => {
    if (achieverDetailList.length > 0) {
      const reversedList = [...achieverDetailList].reverse();
      const stack = reversedList.slice(0, 3);
      setDisplayStack(stack);

      // Schedule removal of items after a delay
      stack.forEach((_, index) => {
        setTimeout(() => {
          setDisplayStack((prevStack) => prevStack.slice(1)); // Remove the first item
          if (index === stack.length - 1) {
            // If it's the last element in the stack, remove the items from the original list
            const newList = [...achieverDetailList].slice(stack.length);
            setNewAchievement(newList); // Update the original list
          }
        }, (index + 1) * 3000); // Adjust delay as needed
      });
    }
  }, [achieverDetailList, setNewAchievement]);

  const handleClose = () => {
    setNewAchievement(false);
  };

  return (
    <div>
      <ConfettiEffect />
      <Box
        sx={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '5px', // Adjust spacing between notifications
          zIndex: '2000',
        }}
      >
        {displayStack.map((achieverDetail, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: '#F2FBFF',
              padding: '20px',
              width: '380px',
              borderRadius: '10px',
              border: '1.5px solid #1976D2',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              position: 'relative',
              transition: 'opacity 0.5s ease-in-out', // Smooth transition effect
              opacity: 1 - index * 0.3, // Adjust opacity based on index
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: '500',
                textAlign: 'center',
              }}
            >
              Celebration 🎇🎉
            </Typography>
            <Typography sx={{ fontSize: '16px', textAlign: 'center' }}>
              {achieverDetail.achievement === 'token_amount_paid' ? (
                <>
                  {achieverDetail.noOfTap === 0 &&
                    `${achieverDetail.achieverName} hits a TAP with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}`}
                  {achieverDetail.noOfTap === 1 &&
                    `${achieverDetail.achieverName} smashed a TAP with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}`}
                  {achieverDetail.noOfTap === 2 &&
                    `${achieverDetail.achieverName} strikes a TAP with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}`}
                  {achieverDetail.noOfTap === 3 &&
                    `Boom! ${achieverDetail.achieverName} blasts through a TAP with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}`}
                  {achieverDetail.noOfTap > 3 &&
                    `Boom! ${achieverDetail.achieverName} is unstoppable with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}. New TAP unlocked! `}
                </>
              ) : (
                <>
                  {achieverDetail.noOfCap === 0 &&
                    `${achieverDetail.achieverName} hits a CAP with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}`}
                  {achieverDetail.noOfCap === 1 &&
                    `${achieverDetail.achieverName} smashed a CAP with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}`}
                  {achieverDetail.noOfCap === 2 &&
                    `${achieverDetail.achieverName} strikes a CAP with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}`}
                  {achieverDetail.noOfCap === 3 &&
                    `Boom! ${achieverDetail.achieverName} blasts through a CAP with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}`}
                  {achieverDetail.noOfCap > 3 &&
                    `Boom! ${achieverDetail.achieverName} is unstoppable with lead ${achieverDetail.achieverDetail.fname} and manager: ${achieverDetail.manager[0].fname}. New CAP unlocked! `}
                </>
              )}
            </Typography>
            <IconButton
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </div>
  );
};
