import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import React, { useState } from 'react';
import AdminsAutocompleteDropdown from '../../../admins/AdminAutoComplete';
import { base_url } from '../../../Mode';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';

function EditPostSalesLeadDetails({ handleEditDetailsViewClose }) {
  const [editedFields, setEditedFields] = useState({}); // this only contains the fields that we want to update
  const { leadData, setToastNotificationMessage } = useCurrentPostSalesLead();

  const updateDetails = () => {
    const body = {
      ...editedFields,
      post_sales_lead_id: leadData.id,
    };

    if (Object.keys(body).length === 1) {
      return;
    }

    axiosInstance
      .patch(`/api/post-sales-leads/${leadData.id}`, body)
      .then((res) => {
        setEditedFields({});
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          err.response?.data?.message ||
            'An error occured while updating the details'
        );
      })
      .finally(() => {
        handleEditDetailsViewClose();
      });
  };

  const getFieldValue = (key) => {
    if (key in editedFields) {
      return editedFields[key];
    }
    return leadData[key] ?? '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedFields({
      ...editedFields,
      [name]: value,
    });
    return;
  };

  return (
    <Stack spacing={2}>
      <Stack
        spacing={1}
        direction='row'
        justifyContent='flex-end'
      >
        <Button
          variant='contained'
          color='success'
          onClick={updateDetails}
        >
          Update
        </Button>
        <Button
          variant='contained'
          onClick={handleEditDetailsViewClose}
        >
          Cancel
        </Button>
      </Stack>

      <Grid item>
        <FormControl fullWidth>
          <TextField
            type='text'
            name='payment_id'
            label='Payment id'
            onChange={handleChange}
            value={getFieldValue('payment_id')}
          />
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <TextField
            type='number'
            name='token_amount_paid'
            label='Token amount paid'
            onChange={handleChange}
            value={getFieldValue('token_amount_paid')}
          />
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <InputLabel id='payment_type'>Payment type</InputLabel>
          <Select
            type='text'
            name='payment_type'
            label='Payment type'
            labelId='payment_type'
            value={getFieldValue('payment_type')}
            onChange={handleChange}
          >
            <MenuItem value='bank_transfer'>Bank transfer</MenuItem>
            <MenuItem value='online_payment'>Online payment</MenuItem>
            <MenuItem value='emi'>EMI</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <InputLabel id='emi_provider'>EMI provider</InputLabel>
          <Select
            type='text'
            name='emi_provider'
            label='EMI provider'
            labelId='emi_provider'
            value={getFieldValue('emi_provider')}
            onChange={handleChange}
          >
            <MenuItem value='kuhoo'>Kuhoo</MenuItem>
            <MenuItem value='propelld'>Propelld</MenuItem>
            <MenuItem value='liquiloans'>Liquilans</MenuItem>
            <MenuItem value='eduvanz'>Eduvanz</MenuItem>
            <MenuItem value='varthana'>Varthana</MenuItem>
            <MenuItem value='other'>Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <AdminsAutocompleteDropdown
          label='POC'
          value={`${getFieldValue('poc')}`}
          onChangeHandler={(e, newValue) => {
            e.target.name = 'poc';
            e.target.value = newValue.value;
            handleChange(e);
          }}
          sx={{
            width: 'inherit',
          }}
        />
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <InputLabel id='status'>Status</InputLabel>
          <Select
            type='text'
            name='status'
            label='Status'
            labelId='status'
            value={getFieldValue('status')}
            onChange={handleChange}
          >
            <MenuItem value='in_progress'>In progress</MenuItem>
            <MenuItem value='converted'>Converted</MenuItem>
            <MenuItem value='dropped'>Dropped</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Stack>
  );
}

export default EditPostSalesLeadDetails;
