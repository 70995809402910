import React, { useState } from 'react';
import { Button, Stack, Box, Typography, TextField } from '@mui/material';
import UpdateEligibilityFormModal from './users/UpdateEligibilityFormModal';
import { base_url } from './Mode';
import axiosInstance from 'apis/axiosInstance';
import ErrorNotifier from './ToastNotifications/ErrorNotifier';
import SuccessNotifier from './ToastNotifications/SuccessNotifier';
import { useCurrentLead } from './CurrentLeadContext';
import constants from '../utils/constants';

const SendEligibilityFormBanner = ({ currentLead }) => {
  const [openDontSendReasonModal, setOpenDontSendReasonModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [newInteractionNote, setNewInteractionNote] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const closeUpdateModal = () => setOpenUpdateModal(false);
  const { getCurrentLeadData, resetCurrentLeadContext } = useCurrentLead();
  const addDontSendReasonAsInteraction = async () => {
    if (newInteractionNote) {
      setNewInteractionNote('');
      setOpenDontSendReasonModal(false);
    }

    try {
      const postData = {
        reason: newInteractionNote.trim(),
        activityName: constants.EF_NOT_SENT,
      };
      const response = await axiosInstance.post(
        base_url() + '/api/users/' + currentLead.lead_id + '/activitie',
        postData
      );

      if (response.data.code === 'SUCCESS') {
        setIsSuccess(true);
        setSuccessMessage(response.data.message);
        resetCurrentLeadContext(currentLead.lead_id);
        getCurrentLeadData(currentLead.lead_id);
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.response.data.message);
      console.log(error);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        border={'1px solid black'}
        p={2}
        borderRadius={5}
        width={'100%'}
      >
        <Typography
          variant={'h6'}
          color={'error'}
          p={1}
        >
          Action Required
        </Typography>
        <Typography p={1}>
          The CPE status has been updated, send the eligibility form to increase
          conversion chances
        </Typography>

        <Stack
          direction={'row'}
          spacing={2}
          p={1}
        >
          <Typography>continue to send the eligibility form ?</Typography>
          <Button
            variant={openUpdateModal ? 'contained' : 'outlined'}
            size='small'
            onClick={() => setOpenUpdateModal(true)}
          >
            Send
          </Button>
          <Button
            variant={openDontSendReasonModal ? 'contained' : 'outlined'}
            size='small'
            onClick={() => setOpenDontSendReasonModal(true)}
          >
            Don't send
          </Button>
        </Stack>
        <UpdateEligibilityFormModal
          openUpdateModal={openUpdateModal}
          closeUpdateModal={closeUpdateModal}
        />
        {openDontSendReasonModal && (
          <Box
            display='flex'
            flexDirection='column'
            position='relative'
          >
            <TextField
              multiline
              rows={3}
              fullWidth
              value={newInteractionNote}
              onChange={(e) => setNewInteractionNote(e.target.value)}
              sx={{
                marginRight: '0.3rem',
                paddingBottom: '0.3rem',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                paddingRight: '0.3rem',
              }}
            >
              <Button
                onClick={() => addDontSendReasonAsInteraction()}
                variant='contained'
              >
                save
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SendEligibilityFormBanner;
