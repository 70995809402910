import React, { useState, useEffect } from 'react';
import {
  Typography,
  Stack,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import CustomBackdrop from '../../pages/CustomBackdrop';
import LeadFunnelTable from './LeadFunnelTable';

const LeadFunnel = ({ defaultLeadIds = '' }) => {
  const [leadIds, setLeadIds] = useState(defaultLeadIds);
  const [showDropReason, setShowDropReason] = useState(false);
  const [showSecondaryDropReason, setShowSecondaryDropReason] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    axiosInstance
      .post(`/api/lead-funnel`, { leadId: leadIds.split(',') })
      .then((res) => {
        setTableData(res.data);
        setIsSuccess(true);
        setSuccessMessage('Lead funnel generated successfully.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An error occurred while generating the lead funnel.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (leadIds !== '') {
      handleSubmit();
    }
  }, []);

  return (
    <Stack
      spacing={2}
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'visible',
        alignSelf: 'center',
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <CustomBackdrop open={loading} />

      <Typography
        variant='h4'
        gutterBottom
      >
        Lead Funnel
      </Typography>
      <Stack
        spacing={1}
        left={1}
      >
        <TextField
          id='outlined-basic'
          label='Enter comma separated lead IDs here'
          variant='outlined'
          value={leadIds}
          onChange={(e) => setLeadIds(e.currentTarget.value)}
          multiline
          minRows={4}
          maxRows={6}
        />
        <Button
          onClick={handleSubmit}
          variant='contained'
          sx={{
            width: '20rem',
          }}
          disabled={leadIds === ''}
        >
          Generate funnel
        </Button>
      </Stack>

      {/* not required to show primary reason and secondary reason  */}
      {/* <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showDropReason}
              onChange={() => setShowDropReason(!showDropReason)}
            />
          }
          label='Show drop reasons'
        />
        <FormControlLabel
          required
          control={
            <Checkbox
              checked={showSecondaryDropReason}
              onChange={() =>
                setShowSecondaryDropReason(!showSecondaryDropReason)
              }
            />
          }
          label='Show secondary drop reasons'
        />
      </FormGroup> */}
      <LeadFunnelTable
        showDropReason={showDropReason}
        showSecondaryDropReason={showSecondaryDropReason}
        tableData={tableData}
      />
    </Stack>
  );
};

export default LeadFunnel;
