import {
  Button,
  OutlinedInput,
  Checkbox,
  Chip,
  Box,
  ListItemText,
  MenuProps,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { GetAdmins } from '../admins/GetAdmins';
import { formatName } from '../users/CreateLeadForm';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import {
  currentAdminIsAdmin,
  currentAdminIsIC,
  currentAdminIsManager,
  currentAdminIsManagerOrAdmin,
  currentAdminIsMarketing,
  currentAdminIsMarketingOrAdmin,
  currentAdminIsSeniorManager,
  currentAdminIsTrainee,
  getCurrentAdminRole,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminMultiComplete from '../admins/AdminMultiComplete';
import constants from '../../utils/constants';
export function Search({
  hotLeadsCheck,
  handleHotLeadsSelect,
  searchParams,
  filters,
  setFilters,
  handleReset,
  fetchData,
  fetachingLeadData,
}) {
  const [statuses, setStatuses] = useState([]);
  const [allDropReasons, setAllDropReasons] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [expanded, setExpanded] = useState(true);
  const isVisible = !(currentAdminIsTrainee() || currentAdminIsIC());

  useEffect(() => {
    fetchAllStatuses();
    fetchDropReasons();
    return () => {};
  }, []);

  const fetchDropReasons = () => {
    axiosInstance
      .get(`/api/users/drop_reasons`)
      .then((res) => {
        if (res.data) {
          setAllDropReasons(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the drop reasons."
        );
      });
  };

  const fetchAllStatuses = () => {
    axiosInstance
      .get(`/api/users/all_statuses`)
      .then((res) => {
        if (res.data) {
          setStatuses(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch all the statuses'
        );
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'snoozed') {
      setFilters({ ...filters, [name]: value, snoozed_until: '' });
    } else if (name === 'snoozed_until') {
      setFilters({ ...filters, [name]: value, snoozed: '' });
    } else if (name === 'pooled_state') {
      setFilters({ ...filters, [name]: value });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  function displayPresetSnoozed(dateTime) {
    const arr = dateTime.split('-'); // in [YYYY, MM, DD] format
    arr.reverse(); // in [DD, MM, YYYY] format
    return arr.join('/'); // in DD/MM/YYYY
  }

  const years = useMemo(() => {
    const startYear = 1990;
    const currentYear = 2040;
    const yearArray = [];

    for (let i = startYear; i <= currentYear; i++) {
      yearArray.push(String(i));
    }

    return yearArray;
  }, []);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '30em',
        width: 350,
      },
    },
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !fetachingLeadData) {
      fetchData();
    }
  };

  const isShowField = () => {
    if (currentAdminIsManager() && !currentAdminIsSeniorManager()) return false;
    if (!isVisible) return false;

    return true;
  };

  return (
    <div
      style={{
        marginRight: '1px',
        marginLeft: '1px',
        marginTop: '-12px',
        marginBottom: '-12px',
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant='h6'
            component='p'
          >
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={0.8}
            direction={{
              xs: 'column',
              sm: 'row',
            }}
          >
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                id='id'
                fullWidth
              >
                <TextField
                  disabled={'id' in searchParams}
                  size='small'
                  type='text'
                  name='id'
                  placeholder='Id'
                  onChange={handleChange}
                  value={searchParams?.id ?? filters?.id}
                  onKeyPress={handleKeyPress}
                />
              </FormControl>
            </Grid>
            {isVisible && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='name'
                  fullWidth
                >
                  <TextField
                    size='small'
                    type='text'
                    name='name'
                    placeholder='Name'
                    onChange={handleChange}
                    value={filters?.name}
                    onKeyPress={handleKeyPress}
                  />
                </FormControl>
              </Grid>
            )}
            {isVisible && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='mobile'
                  fullWidth
                >
                  <TextField
                    size='small'
                    type='text'
                    name='mobile'
                    placeholder='Mobile'
                    onChange={handleChange}
                    value={filters?.mobile}
                    onKeyPress={handleKeyPress}
                  />
                </FormControl>
              </Grid>
            )}
            {currentAdminIsMarketingOrAdmin() && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='pooled state'
                  size='small'
                  fullWidth
                >
                  <InputLabel id='pooled-label'>Pool Status</InputLabel>
                  <Select
                    labelId='pooled-label'
                    label='pool State'
                    name='pooled_state'
                    value={filters?.pooled_state}
                    onChange={handleChange}
                  >
                    <MenuItem value='all'>All</MenuItem>
                    <MenuItem value='pooled'>Pooled</MenuItem>
                    <MenuItem value='unpooled'>Unpooled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {isVisible && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='email'
                  fullWidth
                >
                  <TextField
                    size='small'
                    type='text'
                    name='email'
                    placeholder='Email'
                    onChange={handleChange}
                    value={filters?.email}
                    onKeyPress={handleKeyPress}
                  />
                </FormControl>
              </Grid>
            )}
            {isShowField() && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='snoozed'
                  size='small'
                  fullWidth
                  disabled={
                    'snoozed' in searchParams ||
                    'snoozed_from' in searchParams ||
                    'snoozed_till' in searchParams
                  }
                >
                  <InputLabel id='snoozed-label'>Snooze Status</InputLabel>
                  <Select
                    labelId='snoozed-label'
                    label='Snooze Status'
                    placeholder='Snooze status'
                    name='snoozed'
                    value={
                      ('snoozed' in searchParams && searchParams.snoozed) ||
                      ('snoozed_from' in searchParams && '1') ||
                      ('snoozed_till' in searchParams && '1') ||
                      filters.snoozed
                    }
                    onChange={handleChange}
                  >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='1'>Snoozed</MenuItem>
                    <MenuItem value='0'>Not snoozed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {isVisible && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='snoozed_until'
                  fullWidth
                >
                  <TextField
                    disabled={
                      'snoozed_from' in searchParams ||
                      'snoozed_till' in searchParams
                    }
                    size='small'
                    type='text'
                    placeholder='Snoozed until'
                    name='snoozed_until'
                    onFocus={(e) => {
                      e.target.type = 'date';
                    }}
                    value={
                      ('snoozed_till' in searchParams &&
                        displayPresetSnoozed(
                          searchParams.snoozed_till.split('T')[0]
                        )) ||
                      filters?.snoozed_until
                    }
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                  />
                </FormControl>
              </Grid>
            )}
            {isVisible && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='label'
                  fullWidth
                >
                  <TextField
                    disabled={'label' in searchParams}
                    size='small'
                    type='text'
                    name='label'
                    placeholder='Label'
                    onChange={handleChange}
                    value={
                      ('label' in searchParams && searchParams.label) ||
                      filters?.label
                    }
                    onKeyPress={handleKeyPress}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
            >
              <FormControl
                size='small'
                width={350}
                fullWidth
                disabled={
                  'status' in searchParams ||
                  filters?.dropped_reason?.length > 0
                }
                sx={{
                  width: 350,
                }}
              >
                <InputLabel id='status'>Status</InputLabel>
                <Select
                  labelId='status'
                  id='status'
                  multiple
                  name='status'
                  value={
                    ('status' in searchParams
                      ? [searchParams.status]
                      : filters['status']) || []
                  }
                  onChange={handleChange}
                  input={<OutlinedInput label />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {statuses.map((statusObj) => (
                    <MenuItem
                      key={statusObj.status}
                      value={statusObj.status}
                      sx={{ whiteSpace: 'normal' }}
                    >
                      <Checkbox
                        checked={
                          filters['status']?.indexOf(statusObj.status) > -1
                        }
                      />
                      <ListItemText
                        primary={splitByUnderscoresAndCapitalize(
                          statusObj.status
                        )}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {isVisible && (
              <>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <AdminMultiComplete
                    label='Assignee'
                    value={filters?.assignee || []}
                    onChangeHandler={(selectedAdmins) => {
                      setFilters({ ...filters, assignee: selectedAdmins });
                    }}
                    sx={{
                      width: '100%',
                    }}
                    size='small'
                  />
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    id='utm_campaign'
                    fullWidth
                  >
                    <TextField
                      size='small'
                      type='text'
                      name='utm_campaign'
                      placeholder='Marketing campaign'
                      onChange={handleChange}
                      value={filters?.utm_campaign}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    size='small'
                    width={350}
                    id='experience'
                    fullWidth
                    sx={{
                      width: 350,
                    }}
                  >
                    <InputLabel id='experience'>Experience</InputLabel>
                    <Select
                      labelId='experience'
                      id='experience'
                      multiple
                      name='experience'
                      value={
                        Array.isArray(filters['experience'])
                          ? filters['experience']
                          : []
                      }
                      onChange={handleChange}
                      input={<OutlinedInput label />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {[
                        'student',
                        'graduated',
                        'working_professional',
                        'working_professional_0_2_exp',
                        'working_professional_2_5_exp',
                        'working_professional_5_plus_exp',
                      ].map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          sx={{
                            whiteSpace: 'normal',
                          }}
                        >
                          <Checkbox
                            checked={filters['experience']?.indexOf(name) > -1}
                          />
                          <ListItemText
                            primary={splitByUnderscoresAndCapitalize(name)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    size='small'
                    width={350}
                    id='graduation_year'
                    fullWidth
                    sx={{
                      width: 350,
                    }}
                  >
                    <InputLabel id='graduation_year_label'>
                      Graduation year
                    </InputLabel>
                    <Select
                      labelId='graduation_year_label'
                      id='graduation_year'
                      multiple
                      name='graduation_year'
                      value={
                        Array.isArray(filters['graduation_year'])
                          ? filters['graduation_year']
                          : []
                      }
                      onChange={handleChange}
                      input={<OutlinedInput label />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {years.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          sx={{ whiteSpace: 'normal' }}
                        >
                          <Checkbox
                            checked={
                              filters['graduation_year']?.indexOf(name) > -1
                            }
                          />
                          <ListItemText
                            primary={splitByUnderscoresAndCapitalize(name)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {isShowField() && (
              <>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    id='college'
                    fullWidth
                  >
                    <TextField
                      size='small'
                      type='text'
                      name='college'
                      placeholder='College'
                      onChange={handleChange}
                      value={filters?.college}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    id='company'
                    fullWidth
                  >
                    <TextField
                      size='small'
                      type='text'
                      name='company'
                      placeholder='Company'
                      onChange={handleChange}
                      value={filters?.company}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {isVisible && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='tech_stack'
                  fullWidth
                >
                  <TextField
                    size='small'
                    type='text'
                    name='tech_stack'
                    placeholder='Tech stack'
                    onChange={handleChange}
                    value={filters?.tech_stack}
                    onKeyPress={handleKeyPress}
                  />
                </FormControl>
              </Grid>
            )}
            {isShowField() && (
              <>
                {/* <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    id='role'
                    fullWidth
                  >
                    <TextField
                      size='small'
                      type='text'
                      name='role'
                      placeholder='Role'
                      onChange={handleChange}
                      value={filters?.role}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Grid> */}

                {/* <Grid item xs={3} sm={3} md={3}>
              <FormControl 
                size="small" 
                width={350}
                id="dropped_reason"
                fullWidth
                sx={{ 
                  width: 350 
                }}
              >
                <InputLabel id="dropped_reason">Dropped reason</InputLabel>
                <Select
                  labelId="dropped_reason"
                  id="dropped_reason"
                  multiple
                  name="dropped_reason"
                  value={Array.isArray(filters["dropped_reason"]) ? filters["dropped_reason"] : []}
                  onChange={handleChange}
                  input={<OutlinedInput label />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {allDropReasons.map((reason) => (
                    <MenuItem
                      key={reason}
                      value={reason}
                      sx={{ whiteSpace: 'normal' }}
                    >
                      <Checkbox
                        checked={filters['dropped_reason']?.indexOf(reason) > -1}
                      />
                      <ListItemText primary={splitByUnderscoresAndCapitalize(reason)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    id='lead_source_type'
                    size='small'
                    fullWidth
                  >
                    <InputLabel id='lead_source_type-label'>
                      Lead Source Type
                    </InputLabel>
                    <Select
                      name='lead_source_type'
                      label='lead_source_type'
                      labelId='lead_source_type'
                      onChange={handleChange}
                      value={filters?.lead_source_type}
                    >
                      <MenuItem value=''>Select Option</MenuItem>
                      <MenuItem
                        value='inbound'
                        defaultValue
                      >
                        Inbound
                      </MenuItem>
                      <MenuItem value='outbound'>OutBound</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {isVisible && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControl
                  id='product'
                  size='small'
                  fullWidth
                  disabled={'product' in searchParams}
                >
                  <InputLabel id='product-label'>Product</InputLabel>
                  <Select
                    name='product'
                    label='product'
                    labelId='product'
                    onChange={handleChange}
                    value={
                      ('product' in searchParams && searchParams.product) ||
                      filters?.product
                    }
                  >
                    <MenuItem value=''>All</MenuItem>
                    <MenuItem value='null'>None</MenuItem>
                    <MenuItem value='super30'>Super 30</MenuItem>
                    <MenuItem value='personal_coaching'>
                      Personal coaching
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {/* <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    id='note'
                    fullWidth
                  >
                    <TextField
                      size='small'
                      type='text'
                      name='note'
                      placeholder='Interactions'
                      onChange={handleChange}
                      value={filters?.note}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Grid> */}
            {isShowField() && (
              <>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    id='utm_source'
                    fullWidth
                  >
                    <TextField
                      size='small'
                      type='text'
                      name='utm_source'
                      placeholder='Utm source'
                      onChange={handleChange}
                      value={filters?.utm_source}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <FormControl
                    id='utm_medium'
                    fullWidth
                  >
                    <TextField
                      size='small'
                      type='text'
                      name='utm_medium'
                      placeholder='Utm medium'
                      onChange={handleChange}
                      value={filters?.utm_medium}
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {isVisible && (
              <>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <TextField
                    size='small'
                    type='datetime-local'
                    name='lead_created_from'
                    label='From'
                    value={filters?.lead_created_from}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: '100%',
                      marginBottom: '10px',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={2}
                >
                  <TextField
                    size='small'
                    type='datetime-local'
                    name='lead_created_to'
                    label='To'
                    value={filters?.lead_created_to}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: '100%',
                    }}
                    onKeyPress={handleKeyPress}
                  />
                </Grid>
                {!currentAdminIsManager() && !currentAdminIsSeniorManager() && (
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={2}
                  >
                    <FormControl
                      id='tech_stack_type'
                      size='small'
                      fullWidth
                      disabled={'tech_stack_type' in searchParams}
                    >
                      <InputLabel id='tech_stacks-label'>
                        Tech stack type
                      </InputLabel>
                      <Select
                        name='tech_stack_type'
                        label='tech_stack_type'
                        labelId='tech_stack_type'
                        onChange={handleChange}
                        value={
                          ('tech_stack_type' in searchParams &&
                            searchParams.tech_stack_type) ||
                          filters?.tech_stack_type
                        }
                      >
                        <MenuItem value=''>None</MenuItem>
                        <MenuItem value='none'>Unknown background</MenuItem>
                        <MenuItem value='tech_background'>
                          Tech background
                        </MenuItem>
                        <MenuItem value='non_tech_background'>
                          Non Tech background
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {(currentAdminIsAdmin() || currentAdminIsSeniorManager()) && (
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={2}
                  >
                    <FormControl
                      id='role'
                      size='small'
                      fullWidth
                      disabled={'role' in searchParams}
                    >
                      <InputLabel id='role-label'>Role</InputLabel>
                      <Select
                        name='role'
                        label='role'
                        labelId='role'
                        onChange={handleChange}
                        value={
                          ('role' in searchParams && searchParams.role) ||
                          filters?.role
                        }
                      >
                        <MenuItem value=''>None</MenuItem>
                        <MenuItem value='trainee'>Trainee</MenuItem>
                        <MenuItem value='independent_contributor'>
                          Independent Contributor
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            {isVisible && (
              <Grid
                item
                xs={3}
                sm={3}
                md={2}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={'is_hot' in searchParams || hotLeadsCheck}
                      onChange={handleHotLeadsSelect}
                      value={hotLeadsCheck}
                      name='is_hot'
                      disabled={'is_hot' in searchParams}
                    />
                  }
                  label='Hot'
                />
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              item
              xs={12}
              sm={6}
              md={4}
            >
              <Grid
                item
                xs={6}
              >
                <Button
                  variant='outlined'
                  fullWidth
                  onClick={(e) => handleReset(e)}
                >
                  Reset
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Button
                  variant='contained'
                  fullWidth
                  onClick={() => fetchData()}
                  disabled={fetachingLeadData}
                >
                  {fetachingLeadData ? 'Loading...!' : 'Search'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
