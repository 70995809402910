import { Box } from '@mui/material';
import Text from '../pages/Profile/Atoms/Text';
import CustomTextField from '../pages/Profile/Atoms/CustomTextFiled';
import CustomButton from '../pages/Profile/Atoms/CustomButton';
import { base_url } from './Mode';
import axiosInstance from 'apis/axiosInstance';
import constants from '../utils/constants';
import ErrorNotifier from './ToastNotifications/ErrorNotifier';
import SuccessNotifier from './ToastNotifications/SuccessNotifier';
import { currentAdminIsAdmin } from '../utils/common';
const { useState, useEffect } = require('react');

const ManagerPoolSettings = () => {
  const [adminId, setAdminId] = useState(null);
  const [edit, setIsEdit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchAllSettings();
  }, []);

  const fetchAllSettings = () => {
    const url = base_url() + '/api/settings';
    axiosInstance
      .get(url)
      .then((res) => {
        setValuesToState(res?.data);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while fetching settings.'
        );
      });
  };

  const updatehPoolId = () => {
    const url = base_url() + '/api/settings/manager-pool';
    axiosInstance
      .patch(url, { id_for_pooling_lead: adminId })
      .then((res) => {
        setAdminId(res?.data?.updated_limit);
        setIsSuccess(true);
        setSuccessMessage('Admin id updated for pooling leads');
        setIsEdit(false);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ?? 'Error while updating pooling access'
        );
      });
  };

  const handleLimitChange = (e) => {
    setAdminId(e.target.value);
  };

  const saveDataHandler = async () => {
    updatehPoolId();
  };

  const cancelHandler = () => {
    setIsEdit(false);
  };

  const editHandler = async () => {
    setIsEdit(true);
  };

  const setValuesToState = (data) => {
    try {
      data?.forEach((item) => {
        if (item.key === constants.POOLING_ACCESS_KEY) {
          setAdminId(parseInt(item.value, 10));
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {currentAdminIsAdmin() && (
        <div>
          <Text
            text={'Enabled Id For Pooling Leads'}
            textStyle1={{
              fontWeight: 'bold',
              fontSize: '17px',
              marginRight: '20px',
            }}
          />
          {!edit && (
            <>
              <Text
                text={adminId}
                textStyle1={{
                  fontWeight: 'medium',
                  fontSize: '17px',
                  marginTop: '7px',
                }}
              />
              <Box>
                <CustomButton
                  variant={'contained'}
                  style={{ marginTop: '15px' }}
                  text={'Edit'}
                  clickHandler={editHandler}
                />
              </Box>
            </>
          )}

          {edit && (
            <>
              <CustomTextField
                changeHandler={handleLimitChange}
                value={adminId}
                customAttributes={{
                  type: 'number',
                  id: 'limit',
                  label: 'Enter id for pooling leads',
                  placeholder: 'Enter Limit',
                  variant: 'standard',
                }}
                customStyle={{
                  color: 'blue',
                  width: '50%',
                  marginTop: '5px',
                }}
              />
              <Box sx={{ marginTop: '30px' }}>
                <CustomButton
                  variant={'contained'}
                  style={{ marginRight: '10px' }}
                  text={'Save'}
                  clickHandler={saveDataHandler}
                />
                <CustomButton
                  variant={'contained'}
                  text={'Cancel'}
                  clickHandler={cancelHandler}
                />
              </Box>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ManagerPoolSettings;
