import { Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AdminsAutocompleteDropdown from '../../components/admins/AdminAutoComplete';
import { currentAdminIsTraineeOrIC } from '../../utils/common';

export default function ReportFilters({
  filters,
  setFilters,
  reportId,
  setReportId,
}) {
  useEffect(() => {
    if (
      ((filters.admin_id ?? '') === '' || (filters.date ?? '') === '') &&
      reportId !== -1
    ) {
      setReportId(-1);
    }
    return () => {};
  }, [filters]);

  return (
    <Stack
      direction='row'
      spacing={2}
      sx={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      <AdminsAutocompleteDropdown
        label='Admin'
        value={filters.admin_id}
        onChangeHandler={(e, newValue) => {
          setFilters({ ...filters, admin_id: newValue.value });
        }}
        sx={{
          width: 182,
        }}
        size='small'
      />
      {!currentAdminIsTraineeOrIC() && (
        <TextField
          value={filters.date}
          type='date'
          label='date'
          size={'small'}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setFilters({ ...filters, date: e.target.value })}
        />
      )}
    </Stack>
  );
}
