import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { ShowFilterLabels } from '../search/Filters';
import LeadsDataTable from '../data-table/LeadsDataTable';

import React, { useEffect, useState } from 'react';
import { base_url } from '../Mode';
import {
  currentAdminIsTraineeOrIC,
  getOffsetFromPage,
  getTimeZoneFormattedTimestamp,
} from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'apis/axiosInstance';

export const buildQueryStringFiltersToFetchLeads = (params) => {
  const filters = [];
  for (let key in params) {
    if (key === 'due_now') {
      const date = getTimeZoneFormattedTimestamp(new Date());
      filters.push(`snoozed_till=${date}`);
      filters.push(`include_unsnoozed=1`);
    } else if (key === 'due_in_3h') {
      const now = getTimeZoneFormattedTimestamp(new Date());
      const dueIn3Hours = getTimeZoneFormattedTimestamp(new Date(), 3);
      filters.push(`snoozed_from=${now}`);
      filters.push(`snoozed_till=${dueIn3Hours}`);
    } else {
      filters.push(key + '=' + params[key]);
    }
  }
  return filters.join('&');
};

export function GetDataTableWithTitleAndFilters(title, parentParams) {
  const [params, setParams] = useState(parentParams);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(50);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, [params, currentPage, limit, columnFilters]);

  useEffect(() => {
    if (currentAdminIsTraineeOrIC()) {
      navigate('/leads/priority-list');
    }
  }, []);

  const fetchData = () => {
    const offset = getOffsetFromPage(currentPage, limit);
    let queryString = buildQueryStringFiltersToFetchLeads(params);
    queryString += `&limit=${limit}`;
    queryString += `&offset=${offset}`;

    let url = base_url() + '/api/users';
    if (params) {
      url += '?' + queryString;
    }
    if (columnFilters !== '') {
      url += '&columnFilters[]=' + columnFilters;
    }

    axiosInstance
      .get(url)
      .then((res) => {
        if (res['data']) {
          setTableData(res['data']['leads']);
          setTotalLeads(res['data']['total']);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Typography
        variant='h5'
        component='div'
        gutterBottom
      >
        {title} ({totalLeads})
      </Typography>
      <Divider />
      <br />
      {totalLeads === 0 ? (
        <center style={{ marginTop: 40, marginBottom: 40 }}>
          No Leads Found
        </center>
      ) : (
        <>
          <span style={{ visibility: 'hidden' }}>
            <Box>{ShowFilterLabels(parentParams)}</Box>
          </span>
          <LeadsDataTable
            data={tableData}
            refreshResults={fetchData}
            totalLeads={totalLeads}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={limit}
            setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
            setColumnFilters={(filter) => setColumnFilters(filter)}
            queryParams={params}
          />
        </>
      )}
    </>
  );
}
