import React, { useEffect, useState } from 'react';
import { ReactDiagram } from 'gojs-react';
import * as go from 'gojs';
import {
  Dialog,
  Typography,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../components/Mode';
import CustomBackdrop from '../../CustomBackdrop';

function initDiagram() {
  const $ = go.GraphObject.make;
  const diagram = $(go.Diagram, {
    'undoManager.isEnabled': true,

    'clickCreatingTool.archetypeNodeData': {
      text: 'new node',
      color: 'lightblue',
    },
    model: new go.GraphLinksModel({
      linkKeyProperty: 'key',
    }),
  });

  diagram.nodeTemplate = $(
    go.Node,
    'Auto',
    new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
      go.Point.stringify
    ),
    {
      toolTip: $(
        'ToolTip',
        $(go.TextBlock, { margin: 4 }, new go.Binding('text', 'tooltip'))
      ),
    },
    $(
      go.Shape,
      'RoundedRectangle',
      { name: 'SHAPE', fill: 'white', strokeWidth: 0 },

      new go.Binding('fill', 'color')
    ),
    $(
      go.TextBlock,
      { margin: 8, editable: false },
      new go.Binding('text').makeTwoWay()
    )
  );

  const layout = $(go.ForceDirectedLayout);
  layout.defaultSpringLength = 20;
  diagram.layout = layout;

  return diagram;
}

export default function LeadAccessPoliciesGraph({
  openGraphModal,
  setOpenGraphModal,
}) {
  const [adminMap, setAdminMap] = useState({});
  const [graph, setGraph] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(`/api/admins/active-admin-graph-state`)
      .then((res) => {
        setGraph(res.data?.active_admins_graph);
        setAdminMap(res.data?.adminMap);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getNodesAndLinks = () => {
    const nodes = [];
    const links = [];

    for (const adminId in graph) {
      const admin = graph[adminId];

      const node = {
        key: adminId,
        text: adminMap[adminId],
        color: 'lightblue',
        tooltip: graph[adminId].roles.join(', '),
      };
      nodes.push(node);

      admin.children.forEach((childId) => {
        links.push({ from: adminId, to: childId });
      });
    }

    return { nodes, links };
  };

  const { nodes, links } = getNodesAndLinks();

  return (
    <Dialog
      open={openGraphModal}
      onClose={(e) => setOpenGraphModal(false)}
      scroll='paper'
      fullScreen
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant='h5'
          component='p'
        >
          Lead Access Policies Visualization
        </Typography>

        <IconButton onClick={(e) => setOpenGraphModal(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          height: 'inherit',
        }}
      >
        <CustomBackdrop loading={loading} />
        {!loading && (
          <ReactDiagram
            initDiagram={initDiagram}
            style={{ height: 'inherit', backgroundColor: 'white' }}
            nodeDataArray={nodes}
            linkDataArray={links}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
