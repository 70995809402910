import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import axiosInstance from 'apis/axiosInstance';
import { DataGrid } from '@mui/x-data-grid';
import { getFormattedTimestamp } from '../../../utils/common';
import { base_url } from '../../../components/Mode';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
export default function DrivesTable({
  allDrives,
  setAllDrives,
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
}) {
  const [driveBeingUpdated, setDriveBeingUpdated] = useState({});
  const [driveIdToIndexMap, setDriveIdToIndexMap] = useState({});
  const [incentiveStructureName, setIncentiveStructureName] = useState([]);
  const [selectedIncentiveStructureId, setSelectedIncentiveStructureId] =
    useState();

  const [perPage, setPerPage] = useState(50);
  const handleSelect = (selectedValue) => {
    setSelectedIncentiveStructureId(selectedValue);
  };

  useEffect(() => {
    const tmpMap = {};
    allDrives.forEach((drive, index) => {
      tmpMap[drive.id] = index;
    });
    fetchAllIncentiveStructureName();
    setDriveIdToIndexMap(tmpMap);

    return () => {};
  }, []);

  const fetchAllIncentiveStructureName = async () => {
    try {
      await axiosInstance.get(`/api/drives/incentive/structure`).then((res) => {
        setIncentiveStructureName(res?.data);
      });
    } catch (error) {
      setHasError(true);
      setErrorMessage(
        'Something went Wrong while fetching the incentive Structure'
      );
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'S.No',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'incentive_structure_id',
      headerName: 'Incentive Structure',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const isInEditMode = driveBeingUpdated[params.id]?.mode === 'edit';
        const selectedStructure = incentiveStructureName.find(
          (structure) => structure.id === selectedIncentiveStructureId
        );
        if (isInEditMode) {
          return (
            <Select
              sx={{ width: 300 }}
              value={selectedStructure ? selectedStructure.id : ''}
              onChange={(e) => handleSelect(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: 'auto',
                  },
                },
              }}
            >
              {incentiveStructureName.map((structure) => (
                <MenuItem
                  key={structure.id}
                  value={structure.id}
                >
                  {structure.name}
                </MenuItem>
              ))}
            </Select>
          );
        } else {
          const incentiveStructure = incentiveStructureName?.find(
            (structure) => structure.id === params.row.incentive_structure_id
          );
          return incentiveStructure ? incentiveStructure.name : 'NA';
        }
      },
    },
    {
      field: 'starts_at',
      headerName: 'Start time',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      type: 'date',
      editable: true,
      valueGetter: (params) =>
        getFormattedTimestamp(new Date(params.row.starts_at)),
    },
    {
      field: 'ends_at',
      headerName: 'End time',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      type: 'dateTime',
      editable: true,
      valueGetter: (params) =>
        params.row?.ends_at
          ? getFormattedTimestamp(new Date(params.row.ends_at))
          : 'NA',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = driveBeingUpdated[id]?.mode === 'edit';
        if (isInEditMode) {
          return [
            <CheckIcon
              fontSize='medium'
              color='success'
              sx={{ cursor: 'pointer' }}
              onClick={(e) => handleDriveUpdate('update', id)}
            />,
            <ClearIcon
              fontSize='medium'
              color='error'
              onClick={(e) => handleDriveUpdate('clear', id)}
              sx={{ cursor: 'pointer' }}
            />,
          ];
        }
        return [
          <EditIcon
            onClick={(e) => {
              setDriveBeingUpdated({
                ...driveBeingUpdated,
                [id]: { mode: 'edit' },
              });
            }}
            sx={{ cursor: 'pointer' }}
          />,
        ];
      },
    },
  ];

  const handleDriveUpdate = (clickType, id) => {
    if (clickType === 'clear') {
      setDriveBeingUpdated({
        ...driveBeingUpdated,
        [id]: { mode: 'view', ignoreModifications: true },
      });
      return;
    }
    setDriveBeingUpdated({ ...driveBeingUpdated, [id]: { mode: 'view' } });
  };

  const processDriveUpdate = (updatedDrive) => {
    const body = {};
    const starts_at = new Date(updatedDrive.starts_at);
    const ends_at = new Date(updatedDrive.ends_at);
    const index = driveIdToIndexMap[updatedDrive.id];

    body.starts_at = starts_at.toUTCString();
    body.ends_at = ends_at.toUTCString();
    body.name = updatedDrive.name;
    body.incentive_structure_id = selectedIncentiveStructureId;

    axiosInstance
      .patch(`/api/drives/${updatedDrive.id}`, body)
      .then((res) => {
        const tmpDrives = [...allDrives];
        tmpDrives[index] = res.data;
        setAllDrives(tmpDrives);
        setIsSuccess(true);
        setSuccessMessage('Drive has been successfully updated.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the drive.'
        );
      });
    return updatedDrive;
    // for reference, refer to this link: https://mui.com/x/react-data-grid/editing/#full-featured-crud-component
  };

  return (
    <Box
      width='inherit'
      height='100%'
      sx={{ mt: 5 }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'desc' }],
          },
        }}
        rows={allDrives}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
        onPageSizeChange={(page) => setPerPage(page)}
        pagination
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '20px',
          },
        }}
        rowModesModel={driveBeingUpdated}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processDriveUpdate}
        onRowEditStart={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        onRowEditStop={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
      />
    </Box>
  );
}
