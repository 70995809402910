import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../components/Mode';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import CustomLoader from '../../customLoader';
import { currentAdminIsTraineeOrIC } from '../../../utils/common';

const DailyUpdatesV1 = ({ filters, setFilters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    fetchDailyUpdates();
    return () => {};
  }, [filters]);

  const fetchDailyUpdates = () => {
    setIsLoading(true);
    const params = [];
    Object.entries(filters).forEach(([key, val], index) => {
      if (val && val !== '') {
        params.push(`${key}=${val}`);
      }
    });

    let url = `/api/reports/daily-updates/v1`;
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }

    axiosInstance
      .get(url)
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    tableCell: {
      padding: '8px',
      height: '20px',
    },
  });
  const classes = useStyles();

  return (
    <>
      <Typography
        variant='h4'
        textAlign='left'
      >
        Summary
      </Typography>

      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label='simple table'
        >
          {isLoading ? (
            <CustomLoader height={50} />
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  component='th'
                  scope='row'
                >
                  Output
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align='center'
                >
                  Complete amount paid / Total amount paid
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{
                    backgroundColor: data.tap_or_cap_achieved?.color,
                  }}
                  align='right'
                >
                  {data.tap_or_cap_achieved?.value}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  component='th'
                  scope='row'
                >
                  Input
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align='center'
                >
                  Offer letters rolled out
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{
                    backgroundColor: data.input?.offer_letters_rolled?.color,
                  }}
                  align='right'
                >
                  {data.input?.offer_letters_rolled?.value}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  component='th'
                  scope='row'
                ></TableCell>
                <TableCell align='center'>Eligibility forms filled</TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{
                    backgroundColor: data.input?.eligibility_forms?.color,
                  }}
                  align='right'
                >
                  {data.input?.eligibility_forms?.value}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  component='th'
                  scope='row'
                ></TableCell>
                <TableCell
                  className={classes.tableCell}
                  align='center'
                >
                  Leads with detailed calls (30+ minutes)
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: data.input?.detailed_call?.color,
                  }}
                  align='right'
                >
                  {data.input?.detailed_call?.value}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  component='th'
                  scope='row'
                />
                <TableCell align='center'>Leads touched</TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{
                    backgroundColor: data.input?.leads_touched?.color,
                  }}
                  align='right'
                >
                  {data.input?.leads_touched?.value}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  component='th'
                  scope='row'
                >
                  Discipline
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align='center'
                >
                  First call start time
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{
                    backgroundColor: data.discipline?.first_call?.color,
                  }}
                  align='right'
                >
                  {data.discipline?.first_call?.value ?? '-'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  component='th'
                  scope='row'
                />
                <TableCell align='center'>Last call end time</TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{
                    backgroundColor: data.discipline?.last_call?.color,
                  }}
                  align='right'
                >
                  {data.discipline?.last_call?.value ?? '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {!currentAdminIsTraineeOrIC() && (
        <>
          <Typography
            variant='h4'
            textAlign='left'
          >
            App Info
          </Typography>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label='simple table'
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    component='th'
                    scope='row'
                  />
                  <TableCell align='center'>Last Sync time</TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align='right'
                  >
                    {new Date(
                      data.discipline?.last_sync_time?.value
                    ).toString() ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    component='th'
                    scope='row'
                  />
                  <TableCell align='center'>App version</TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align='right'
                  >
                    {data.discipline?.app_version?.value ?? '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default DailyUpdatesV1;
