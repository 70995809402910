import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import { Stack } from '@chakra-ui/react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../components/Mode';
import fireImg from '../../images/icons8-hot.gif';
const BulkHotLeadsAssign = () => {
  const [leadIds, setLeadIds] = useState('');
  const [leadArray, setLeadArray] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    setTableData([]);
    axiosInstance
      .put(`/api/users/bulk-hot-lead-update`, {
        leadIds: leadIds.split(',').map((id) => parseInt(id.trim(), 10)),
        is_hot: true,
      })
      .then((res) => {
        setTableData(res.data);
        if (res.data?.message) {
          setIsSuccess(true);
          setSuccessMessage(res.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An error occurred while making leads hot.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    setLeadIds(event.target.value);
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(leadIds);
  };
  const handleCreateBulkLeads = () => {
    const leads = leadIds.split(',').map((id) => id.trim());
    setLeadArray(leads);
    handleSubmit();
  };
  return (
    <Stack
      spacing={2}
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'visible',
        alignSelf: 'center',
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Typography
        gutterBottom
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          fontSize: '26px',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        }}
      >
        Hot Leads
        <img
          src={fireImg}
          style={{
            marginLeft: '5px',
            height: '30px',
            width: 'auto',
            objectFit: 'cover',
          }}
          alt='hot-lead'
        />
      </Typography>
      <Stack
        spacing={1}
        left={1}
      >
        <TextField
          id='outlined-basic'
          label='Enter comma separated lead IDs here'
          variant='outlined'
          value={leadIds}
          onChange={handleInputChange}
          multiline
          minRows={4}
          maxRows={6}
        />
        <Box style={{ marginTop: '20px' }}>
          <Button
            variant='contained'
            onClick={handleCopy}
            disabled={leadIds === ''}
            sx={{ marginRight: 2, width: '15rem' }}
          >
            Copy Leads Ids
          </Button>
          <Button
            variant='contained'
            sx={{ width: '15rem' }}
            disabled={leadIds === ''}
            onClick={handleCreateBulkLeads}
          >
            Mark As Hot
          </Button>
        </Box>
        <Stack
          spacing={5}
          style={{ marginTop: '20px' }}
        >
          {tableData.ErrorMessage &&
            tableData.ErrorMessage?.map((message, index) => (
              <Alert
                severity='warning'
                key={index}
              >
                {message}
              </Alert>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BulkHotLeadsAssign;
