import React, { useState, useEffect, forwardRef } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Divider,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LabelTextFieldWithColorPicker } from '../users/LabelTextFieldWithColorPicker';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import AdminsAutocompleteDropdown from '../admins/AdminAutoComplete';
import AppliedRoleAutocompleteDropdown from './AppliedRolesDropdown';
import CandidatesSourceAutocompleteDropdown from './CandidateSourcesDropdown';
import {
  getSanitizedPhoneNumber,
  isValidPhoneNumber,
  isValidEmailId,
} from '../../utils/common';

import moment from 'moment/moment';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

function CreateCandidateForm({ openModal, setOpenModal }) {
  const [candidateDetail, setcandidateDetail] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isColorPalleteVisible, setIsColorPalleteVisible] = useState(false);
  const [isFontColorPalleteVisible, setIsFontColorPalleteVisible] =
    useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const updatecandidateDetails = (e) => {
    let { name, value } = e.target;

    setcandidateDetail({
      ...candidateDetail,
      [name]: value,
    });
  };

  const setLabelColor = (color) => {
    setcandidateDetail({
      ...candidateDetail,
      label_bg_color: color.hex,
    });
    setIsColorPalleteVisible(false);
  };

  const setLabelFontColor = (color) => {
    setcandidateDetail({
      ...candidateDetail,
      label_font_color: color.hex,
    });
    setIsFontColorPalleteVisible(false);
  };

  function submitHandler() {
    const errorMessages = [];
    // shallow copy
    const postData = Object.assign({}, candidateDetail);

    if ((postData?.mobile1 ?? '') !== '') {
      if (!isValidPhoneNumber(postData.mobile1)) {
        setHasError(true);
        setErrorMessage(`Invalid mobile number`);
        return;
      }

      postData.mobile1 = getSanitizedPhoneNumber(postData.mobile1);
    }

    if ((postData?.mobile2 ?? '') !== '') {
      if (!isValidPhoneNumber(postData.mobile2)) {
        setHasError(true);
        setErrorMessage(`Invalid mobile number`);
        return;
      }

      postData.mobile2 = getSanitizedPhoneNumber(postData.mobile2);
    }

    if (postData.email1 && !isValidEmailId(postData.email1)) {
      setHasError(true);
      setErrorMessage(`Invalid email id`);
      return;
    }

    if (postData.email2 && !isValidEmailId(postData.email2)) {
      setHasError(true);
      setErrorMessage(`Invalid email id`);
      return;
    }

    if (!candidateDetail?.name) {
      errorMessages.push('Name');
    }

    if (!candidateDetail?.mobile1) {
      errorMessages.push('Mobile');
    }

    if (!candidateDetail?.candidate_source_id) {
      errorMessages.push('Candidate source');
    }

    if (errorMessages.length > 0) {
      const len = errorMessages.length;
      setHasError(true);
      setErrorMessage(
        errorMessages.join(', ') +
          (len > 1 ? ' are' : ' is a') +
          ' required field' +
          (len > 1 ? 's' : '')
      );
      return;
    }

    postData.snoozed_until = moment();

    if (postData.assignee) {
      postData.status = 'assigned';
    }

    axiosInstance
      .post(base_url() + '/api/hrms/hrms_candidates', postData)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage(`Candidate successfully created`);
        fileUploadHandler(res.data.candidate.id);
        setOpenModal(!openModal);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || `Couldn't create the Candidate`
        );
      });
  }

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).map((file) => ({
      name: file.name,
      data: file,
    }));

    const selectedFile = files.length > 0 ? [files[0]] : [];

    setSelectedFiles(selectedFile);
  };

  const fileUploadHandler = async (candidate_id) => {
    if (selectedFiles.length === 0) {
      return;
    }

    const formBody = new FormData();

    selectedFiles.forEach((file) => {
      formBody.append('hrms_candidate_resume', file.data);
    });

    const resumeUrl =
      base_url() +
      '/api/hrms/hrms_candidates/' +
      candidate_id +
      '/hrms_candidate_resumes?context=candidate_resume';

    await axiosInstance
      .post(resumeUrl, formBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Resume Upload Sucessfully');
        setSelectedFiles([]);
        setOpenModal(!openModal);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Error while uploading resume'
        );
      });
  };

  const workExperienceMenuItems = () => {
    const menuItems = [];

    for (let years = 0; years <= 15; years += 0.25) {
      const formattedYears = parseFloat(years.toFixed(2));
      const yearPart = Math.floor(years);
      const monthPart = ((years % 1) * 12).toFixed(0);

      const label = `${yearPart} Year${
        yearPart > 1 ? 's' : ''
      } ${`${monthPart} Month${monthPart > 1 ? 's' : ''}`}`;

      menuItems.push(
        <MenuItem
          key={formattedYears}
          value={formattedYears}
        >
          {label}
        </MenuItem>
      );
    }

    return menuItems;
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        TransitionComponent={Transition}
      >
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        {isSuccess && (
          <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
        )}

        <AppBar
          sx={{
            position: 'relative',
          }}
        >
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpenModal(!openModal)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant='h6'
              component='div'
            >
              Create Candidate
            </Typography>
            <Button
              autoFocus
              color='inherit'
              onClick={submitHandler}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={5}
          p={4}
        >
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='name'
                label='Full Name'
                onChange={updatecandidateDetails}
                value={candidateDetail?.name}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='email'
                name='email1'
                label='Email Address'
                onChange={updatecandidateDetails}
                value={candidateDetail?.email1}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='email'
                name='email2'
                label='Email Address (optional)'
                onChange={updatecandidateDetails}
                value={candidateDetail?.email2}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='mobile1'
                label='Mobile Number'
                value={candidateDetail?.mobile1}
                onChange={updatecandidateDetails}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='mobile2'
                label='Mobile Number (optional)'
                value={candidateDetail?.mobile2}
                onChange={updatecandidateDetails}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='college'
                label='College'
                value={candidateDetail?.college}
                onChange={updatecandidateDetails}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='branch'
                label='Branch'
                value={candidateDetail?.branch}
                onChange={updatecandidateDetails}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='number'
                name='graduation_year'
                label='Graduation year'
                value={candidateDetail?.graduation_year}
                onChange={updatecandidateDetails}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <InputLabel id='experience_label'>Work Experience</InputLabel>
              <Select
                type='text'
                name='work_experience'
                label='Experience'
                labelId='experience_label'
                value={candidateDetail?.experience}
                onChange={updatecandidateDetails}
              >
                {workExperienceMenuItems()}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='current_company'
                label='Current Company'
                value={candidateDetail?.company}
                onChange={updatecandidateDetails}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='current_role'
                label='Current Role'
                value={candidateDetail?.current_role}
                onChange={updatecandidateDetails}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <AppliedRoleAutocompleteDropdown
              value={candidateDetail?.applied_role}
              onChangeHandler={(e, newValue) => {
                e.target.name = 'applied_role';
                e.target.value = newValue.value;
                updatecandidateDetails(e);
              }}
              sx={{
                width: '100%',
              }}
              label='Applied Role'
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <CandidatesSourceAutocompleteDropdown
              value={candidateDetail?.candidate_source_id}
              onChangeHandler={(e, newValue) => {
                e.target.name = 'candidate_source_id';
                e.target.value = newValue.value;
                updatecandidateDetails(e);
              }}
              sx={{
                width: '100%',
              }}
              label='Candidate Source'
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <AdminsAutocompleteDropdown
              value={candidateDetail?.assignee}
              onChangeHandler={(e, newValue) => {
                e.target.name = 'assignee';
                e.target.value = newValue.value;
                updatecandidateDetails(e);
              }}
              sx={{
                width: '100%',
              }}
              label='Assignee'
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <LabelTextFieldWithColorPicker
              label={candidateDetail?.label}
              labelColor={candidateDetail?.label_bg_color}
              labelFontColor={candidateDetail?.label_font_color}
              setLabelColor={setLabelColor}
              setLabelFontColor={setLabelFontColor}
              updateLeadDetails={updatecandidateDetails}
              isColorPalleteVisible={isColorPalleteVisible}
              isFontColorPalleteVisible={isFontColorPalleteVisible}
              setIsColorPalleteVisible={setIsColorPalleteVisible}
              setIsFontColorPalleteVisible={setIsFontColorPalleteVisible}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
          >
            <FormControl fullWidth>
              <TextField
                type='text'
                name='resume_path'
                id='resume'
                placeholder='Upload resume'
                multiple
                accept='pdf/*'
                onChange={handleFileChange}
                onFocus={(e) => {
                  e.target.type = 'file';
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default CreateCandidateForm;
