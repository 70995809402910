import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';

import { base_url } from '../../components/Mode';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import { SalsaAssignments } from './SalsaDashboard/SalsaAssignments';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SalsaDashboard() {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    return () => {};
  }, []);

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  return (
    <Box sx={{ mr: 5, marginTop: '-2.5rem' }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
        >
          <Tab label='Salsa Assignments' />
        </Tabs>
      </Box>
      <TabPanel
        value={currentTab}
        index={0}
      >
        <SalsaAssignments />
      </TabPanel>
    </Box>
  );
}
