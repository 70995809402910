import React, { useEffect, useState } from 'react';
import { Text, Center } from '@chakra-ui/react';
import {
  Checkbox,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  Stack,
  Divider,
  ListItemText,
} from '@mui/material';
import DataTable from 'react-data-table-component';
import {
  getFormattedTimestampWithoutYear,
  CopyIcon,
  getStatusLabel,
  currentAdminIsAdmin,
  splitByUnderscoresAndCapitalize,
  getCurrentAdminPrimaryRole,
  getFormattedTimestamp,
  currentAdminIsMarketingOrAdmin,
  currentAdminIsSeniorManager,
  currentAdminIsSeniorManagerOrAdminOrMarketing,
  currentAdminIsTraineeOrIC,
} from '../../utils/common';
import { HotButton } from '../../utils/HotButton';
import moment from 'moment';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import MessageIcon from '@mui/icons-material/Message';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LabelIcon from '@mui/icons-material/Label';
import TodayIcon from '@mui/icons-material/Today';
import TableRowsIcon from '@mui/icons-material/TableRows';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import GroupByLabelView from './GroupByLabelView';
import CalendarView from './CalendarView';
import { LabelTextFieldWithColorPicker } from '../users/LabelTextFieldWithColorPicker';
import { useCurrentLead } from '../CurrentLeadContext';
import AdminsAutocompleteDropdown from '../admins/AdminAutoComplete';
import { currentAdminIsMarketing } from '../../utils/common';
import constants from '../../utils/constants';
import ShieldIcon from '@mui/icons-material/Shield';
import { getTechStackProperties } from '../users/LeadDetailsModal';
import {
  getAdminDefaultColumns,
  getCurrentAdminId,
  currentAdminIsManager,
} from '../../utils/common';
import { defaultBucketSearchAdminColumns } from '../../utils/default-bucket-search-admin-columns';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import LeadFunnelModal from '../lead-funnel/LeadFunnelModal';
import { useAllAdmins } from '../AdminsContext';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import GroupedSelect from '../../pages/ManagerDashboard/ManagerPoolGroupSelect';
import { useLocation } from 'react-router-dom';

function getSnoozedText(lead) {
  if (!lead.snoozed_until_1) {
    return;
  }
  return (
    <Center display={'inline-block'}>
      <Text
        fontSize='sm'
        display='inline-block'
        textAlign={'center'}
        m={1}
        background={'#FFF9C4'}
        fontWeight={500}
        p={'2px 4px'}
      >
        {getFormattedTimestampWithoutYear(new Date(lead.snoozed_until_1))}
      </Text>
    </Center>
  );
}

function getHotLeadText(lead) {
  if (!lead.is_hot) return '';
  return (
    <Typography
      display={'inline-block'}
      color={'red'}
      px={'5px'}
    >
      <WhatshotIcon />
    </Typography>
  );
}

function getReachoutText(lead) {
  let reachoutText = [];
  reachoutText.push(getHotLeadText(lead));
  return reachoutText;
}

function SplitByUnderscoresAndCapitalize(name) {
  if (name.length === 0) return '';
  return `${name[0].toUpperCase()}${name
    .toLowerCase()
    .split('_')
    .join(' ')
    .substring(1)}`; // capitalize first letter.
}

function BulkActionsModal({
  openBulkActionsModal,
  setOpenBulkActionsModal,
  selectedRows,
  copyIds,
  removeSelectionsAndRefresh,
  currentLead,
}) {
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [leadIds, setLeadIds] = useState([]);
  const [snoozedUntil, setSnoozedUntil] = useState(moment());
  const [interactionText, setInteractionText] = useState('');
  const [isColorPalleteVisible, setIsColorPalleteVisible] = useState(false);
  const [isFontColorPalleteVisible, setIsFontColorPalleteVisible] =
    useState(false);
  const [labelObj, setLabelObj] = useState({
    label: '',
    label_color: '',
    label_font_color: '',
  });
  const [removeSnooze, setRemoveSnooze] = useState(false);
  const [shouldKeepLabel, setShouldKeepLabel] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLeadIds(
      selectedRows.map((row, index) => {
        return row.lead_id;
      })
    );
    return () => {};
  }, []);

  function getAssigneeDropdown() {
    return (
      <AdminsAutocompleteDropdown
        label='Assignee'
        showNoneOption={currentAdminIsAdmin()}
        value={selectedAssignee}
        onChangeHandler={(e, newValue) => {
          setSelectedAssignee(newValue.value === 'none' ? '' : newValue.value);
        }}
        adminOptions={{
          access_type: ['allowed', 'restricted'],
          roles: [
            constants.ADMIN,
            constants.INDEPENDENT_CONTRIBUTOR,
            constants.TRAINEE,
          ],
        }}
        size='medium'
        shouldShowOnlyICTraineeManagerSM
      />
    );
  }

  function getSnoozedUntilBox() {
    return (
      <TextField
        min={new Date().toISOString().slice(0, 16)}
        disabled={removeSnooze}
        type='datetime-local'
        name='snoozed_until_1'
        value={
          snoozedUntil
            ? snoozedUntil.format('YYYY-MM-DDTHH:mm:ss')
            : snoozedUntil
        }
        label='Snooze until'
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          const value = e.target.value ? moment(e.target.value) : null;
          setSnoozedUntil(value);
        }}
        sx={{ width: 350 }}
      />
    );
  }

  function getInteractionTextField() {
    return (
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        multiline
        minRows={4}
        maxRows={4}
        label='Interaction notes'
        placeholder='Add notes here'
        value={interactionText}
        onChange={(e) => setInteractionText(e.target.value)}
      />
    );
  }

  function getRemoveSnoozeCheckbox() {
    return (
      <FormControlLabel
        control={<Checkbox />}
        label='Remove snooze'
        checked={removeSnooze}
        onClick={(e) => {
          setRemoveSnooze(!removeSnooze);
          setSnoozedUntil(null);
        }}
      />
    );
  }

  function getKeepLabelsCheckbox() {
    return (
      <FormControlLabel
        control={<Checkbox />}
        label='Keep label'
        checked={shouldKeepLabel}
        onClick={(e) => {
          let ok = !shouldKeepLabel;
          const { label, label_color, label_font_color } = labelObj;
          if (label !== '' || label_color !== '' || label_font_color !== '') {
            setLabelObj({
              label: '',
              label_color: '',
              label_font_color: '',
            });
          }

          setShouldKeepLabel(ok);
        }}
      />
    );
  }

  const handleLabelColorChange = (color) => {
    setShouldKeepLabel(false);
    setLabelObj({ ...labelObj, label_color: color.hex });
    setIsColorPalleteVisible(false);
  };

  const handleLabelFontColorChange = (color) => {
    setShouldKeepLabel(false);
    setLabelObj({ ...labelObj, label_font_color: color.hex });
    setIsFontColorPalleteVisible(false);
  };

  const handleUpdate = (event) => {
    setLoading(true);
    let updateBody = {};
    const { label, label_color, label_font_color } = labelObj;

    if (label) {
      updateBody['label'] = label;
      if (label_color) {
        updateBody['label_color'] = label_color;
      }
      if (label_font_color) {
        updateBody['label_font_color'] = label_font_color;
      }
    }

    if (shouldKeepLabel) {
      updateBody['keep_label'] = shouldKeepLabel;
    }

    if (selectedAssignee !== '') {
      updateBody['assignee'] = selectedAssignee;
    }
    if (interactionText) {
      updateBody['notes'] = interactionText;
    }

    if (removeSnooze) {
      updateBody['snoozed_until_1'] = null;
    } else if (
      !currentLead?.snoozed_until_1 &&
      snoozedUntil instanceof moment
    ) {
      updateBody['snoozed_until_1'] = moment(snoozedUntil).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }

    if (Object.keys(updateBody).length === 0 || leadIds.length === 0) {
      return;
    }

    axiosInstance
      .patch(`/api/users/bulk-update`, {
        ...updateBody,
        leadIds,
      })
      .then((res) => {
        if (res?.data?.message) {
          setHasError(true);
          setLoading(false);
          setErrorMessage(res?.data?.message);
          setTimeout(() => {
            resetStates();
          }, 5000);
        } else {
          resetStates();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err?.response?.data?.message ?? 'An error occured.');
        setLoading(false);
      });
  };

  const handleModalClose = (event) => {
    setOpenBulkActionsModal(false);
  };

  function resetStates() {
    setSelectedAssignee('');
    setInteractionText('');
    setSnoozedUntil(
      !currentLead?.snoozed_until_1 ? moment() : currentLead.snoozed_until_1
    );
    setIsColorPalleteVisible(false);
    setIsFontColorPalleteVisible(false);
    setLabelObj({ label: '', label_color: '', label_font_color: '' });
    setRemoveSnooze(false);
    handleModalClose();
    removeSelectionsAndRefresh();
  }

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Dialog
        open={openBulkActionsModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth='md'
      >
        <Box
          display='flex'
          justifyContent='flex-end'
        >
          <Button onClick={handleModalClose}>Close</Button>
        </Box>
        <DialogContent>
          <DialogContentText>
            <Stack
              direction='row'
              spacing={2}
            >
              {getAssigneeDropdown()}
              <LabelTextFieldWithColorPicker
                {...{
                  isColorPalleteVisible,
                  isFontColorPalleteVisible,
                  setIsColorPalleteVisible,
                  setIsFontColorPalleteVisible,
                  label: labelObj.label,
                  labelColor: labelObj.label_color,
                  labelFontColor: labelObj.label_font_color,
                  setLabelColor: handleLabelColorChange,
                  setLabelFontColor: handleLabelFontColorChange,
                  updateLeadDetails: (e) => {
                    setShouldKeepLabel(false);
                    setLabelObj({ ...labelObj, label: e.target.value });
                  },
                }}
              />
            </Stack>
            {/*
              Temporarily hiding this so that it doesn't interfer with the new `lead_assignment` update.
            */}
            {/* {getInteractionTextField()} */}
            <Stack
              direction='row'
              spacing={2}
              sx={{ mt: 2 }}
            >
              {getKeepLabelsCheckbox()}
            </Stack>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            onClick={handleUpdate}
            disabled={loading}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function LeadsSearchResults({
  leads,
  updateCurrentLead,
  refreshResults,
  totalLeads,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  setColumnFilters,
  queryParams,
  fetachingLeadData,
  isCalledFromDnp,
  isCalledFromRcb,
  setSortPriority,
}) {
  const { currentLead } = useCurrentLead();
  const [highlightedLeadId, setHighlightedLeadId] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [openBulkActionsModal, setOpenBulkActionsModal] = useState(false);
  const [copyIds, setCopyIds] = useState('');
  const [openChangeTableView, setOpenChangeTableView] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showDefaultColumns, setShowDefaultColumns] = useState(true);
  const [viewType, setViewType] = useState('tabular'); // { "tabular", "group_by_label", "calendar" }
  const currentAdmin = JSON.parse(localStorage.getItem('admin')) || {};
  const [defaultSortFields, setDefaultSortFields] = useState([]);
  const [openLeadFunnel, setOpenLeadFunnel] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setCopyIds(selectedRows.map((row, index) => row?.lead_id).join(', '));
    return () => {};
  }, [selectedRows]);

  useEffect(() => {
    if (viewType !== 'tabular') {
      setViewType('tabular');
    }
    return () => {};
  }, [queryParams]);

  const location = useLocation();

  useEffect(() => {
    fetchAdminDefaultColumns();
    return () => {};
  }, [showDefaultColumns, defaultSortFields]);

  const fetchAdminDefaultColumns = async () => {
    if (showDefaultColumns) {
      let defaultColumns = await getAdminDefaultColumns();
      if (Object.keys(defaultColumns).length === 0) {
        defaultColumns =
          defaultBucketSearchAdminColumns[
            getCurrentAdminPrimaryRole(currentAdmin)
          ];
      }
      if (defaultColumns) {
        defaultColumns = Object.entries(defaultColumns).filter(
          ([key, value]) => value
        );
        if (defaultColumns.length > 0) {
          defaultColumns = defaultColumns.map(([key, value]) => key);
        } else {
          defaultColumns = [];
        }
      } else {
        defaultColumns = [];
      }

      let defaultColumnsSet;
      if (defaultColumns.length > 0) {
        defaultColumnsSet = new Set([...defaultColumns]);
      } else {
        const role = getCurrentAdminPrimaryRole();
        defaultColumnsSet = new Set([
          ...Object.keys(defaultBucketSearchAdminColumns[role] ?? {}),
        ]);
      }
      setSelectedColumns(
        Array.from(defaultColumnsSet, (colName) => {
          if (colName === 'snoozed_until') {
            colName = 'snoozed_until_1';
          }
          return columns.find((col) => col.sortField === colName);
        })
      );
      setShowDefaultColumns(false);
    }
  };

  const snoozeSort = (rowA, rowB) => {
    const distantFuture = new Date(8640000000000000);
    const rowDateA = rowA.snoozed_until_1
      ? new Date(rowA.snoozed_until_1)
      : distantFuture;
    const rowDateB = rowB.snoozed_until_1
      ? new Date(rowB.snoozed_until_1)
      : distantFuture;

    if (rowDateA > rowDateB) {
      return 1;
    }

    if (rowDateB > rowDateA) {
      return -1;
    }

    return 0;
  };

  const renderLabel = (row) => {
    if (!row.label) return;
    return (
      <Chip
        label={row.label}
        sx={{ backgroundColor: row.label_color, color: row.label_font_color }}
      />
    );
  };

  if (!leads) return '';

  function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength - 3) + '...';
  }

  let columns = [
    {
      name: 'Lead id',
      selector: (row) => {
        const { color, tooltipMessage } = getTechStackProperties(
          row.techstack_type
        );
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            onMouseUp={(e) => handleMouseUp(e, row)}
            data-tag='allowRowEvents'
          >
            {(currentAdminIsMarketing() || currentAdminIsAdmin()) && (
              <Tooltip title={tooltipMessage}>
                <ShieldIcon
                  sx={{
                    color,
                    marginRight: '5px',
                    fontSize: '16px',
                  }}
                />
              </Tooltip>
            )}
            <Typography
              variant='body2'
              component='p'
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                  color: 'blue',
                },
              }}
            >
              {row.disabled ? '#####' : row.lead_id}
            </Typography>
          </div>
        );
      },
      sortable: true,
      width: '100px',
      maxWidth: '20px',
      sortField: 'id',
      reorder: true,
    },
    {
      name: 'Category',
      selector: (row) => row?.category,
      width: '150px',
      sortField: 'category',
      center: true,
      reorder: true,
    },
    {
      name: 'Name',
      selector: (row) => {
        const hotButtonStyle = {
          height: 'calc(100% - 10px)',
          fontSize: '12px',
        };
        return (
          <div
            onMouseUp={(e) => handleMouseUp(e, row)}
            data-tag='allowRowEvents'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '230px',
            }}
          >
            <Typography
              variant='body2'
              component='p'
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                  color: 'blue',
                },
              }}
            >
              {row.disabled
                ? '##### #####'
                : truncateString(
                    (row.fname ?? '') + ' ' + (row.lname ?? ''),
                    16
                  )}
            </Typography>
            {row.is_hot ? <HotButton style={hotButtonStyle} /> : ''}
          </div>
        );
      },
      width: '300px',
      maxWidth: '300px',
      center: true,
      sortField: 'name',
      reorder: true,
    },
    {
      name: 'Mobile',
      selector: (row) => row.mobile,
      width: '150px',
      center: true,
      sortField: 'mobile',
      reorder: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      width: '250px',
      center: true,
      sortField: 'email',
      reorder: true,
    },
    {
      name: currentAdminIsMarketingOrAdmin() ? 'Pool Status' : 'Not Available',
      selector: (row) =>
        currentAdminIsMarketingOrAdmin() ? (
          <h4
            style={{ color: row?.pooled_status === 'pooled' ? 'blue' : 'grey' }}
          >
            {row?.pooled_status}
          </h4>
        ) : (
          'hidden'
        ),
      width: 'max-width',
      center: true,
      wrap: true,
      sortable: true,
      sortField: 'pooled state',
      reorder: true,
    },
    {
      name: 'Experience',
      selector: (row) => (
        <Typography
          variant='bod1'
          textAlign='center'
        >
          {splitByUnderscoresAndCapitalize(row.experience ?? '')}
        </Typography>
      ),
      width: '270px',
      center: true,
      wrap: true,
      sortField: 'experience',
      reorder: true,
    },
    {
      name: 'Tech stack',
      selector: (row) => row?.tech_stack ?? '-',
      width: '200px',
      center: true,
      wrap: true,
      sortable: true,
      sortField: 'tech_stack',
      reorder: true,
    },
    {
      name: 'Product',
      selector: (row) =>
        row.product ? (
          <Chip label={SplitByUnderscoresAndCapitalize(row.product)} />
        ) : (
          <></>
        ),
      width: '180px',
      center: true,
      sortField: 'product',
      reorder: true,
    },
    {
      name: 'Label',
      selector: (row) => renderLabel(row),
      width: '200px',
      center: true,
      wrap: true,
      sortable: true,
      sortField: 'label',
      reorder: true,
    },
    {
      name: 'Status',
      selector: (row) => getStatusLabel(row.status, row.is_hot) ?? '',
      width: 'max-width',
      center: true,
      wrap: true,
      sortable: true,
      sortField: 'status',
      reorder: true,
    },
    // {
    //   name: 'Reachout',
    //   selector: (row) => getReachoutText(row),
    //   width: '150px',
    //   center: true,
    //   wrap: true,
    //   sortable: true,
    //   sortField: 'is_hot',
    // },
    // {
    //   name: 'Snoozed',
    //   selector: (row) => getSnoozedText(row),
    //   width: '160px',
    //   center: true,
    //   wrap: true,
    //   sortable: true,
    //   sortFunction: snoozeSort,
    //   sortField: 'snoozed_until',
    // },
    {
      name: 'Snoozed',
      selector: (row) => getSnoozedText(row),
      width: '160px',
      center: true,
      wrap: true,
      sortable: true,
      sortFunction: snoozeSort,
      sortField: 'snoozed_until_1',
      reorder: true,
    },

    {
      name: <MessageIcon />,
      selector: (row) => row.interactions_count,
      width: '50px',
      center: true,
      wrap: true,
      sortField: 'interactions_count',
      reorder: true,
    },
    // {
    //   name: 'Last interaction',
    //   selector: (row) => (
    //     <Typography
    //       align='center'
    //       variant='body2'
    //       sx={{ backgroundColor: '#ebf8ff', padding: '2px', margin: 1 }}
    //     >
    //       {row?.last_interaction?.notes ?? ''}
    //     </Typography>
    //   ),
    //   width: '500px',
    //   center: true,
    //   wrap: true,
    //   sortField: 'last_interaction',
    //   reorder: true,
    // },
    // {
    //   name: 'Last interaction on',
    //   selector: (row) => (
    //     <Typography
    //       align='center'
    //       variant='body2'
    //       sx={{ backgroundColor: '#ebf8ff', padding: '2px', margin: 1 }}
    //     >
    //       {row.last_interaction &&
    //         Object.keys(row.last_interaction).length > 0 &&
    //         getFormattedTimestamp(
    //           new Date(Date.parse(row.last_interaction.created_at))
    //         )}
    //     </Typography>
    //   ),
    //   width: '200px',
    //   center: true,
    //   wrap: true,
    //   sortable: true,
    //   sortField: 'last_interaction_on',
    //   reorder: true,
    // },
    // {
    //   name: 'Last interaction by',
    //   selector: (row) =>
    //     Object.keys(row?.last_interaction ?? {}).length > 0 ? (
    //       <Typography
    //         align='center'
    //         variant='body2'
    //         sx={{
    //           backgroundColor: 'rgb(255, 255, 255)',
    //           p: '2px 4px',
    //           m: 1,
    //           color: '#512da8',
    //         }}
    //       >
    //         {row.last_interaction.interaction_by}
    //       </Typography>
    //     ) : (
    //       <></>
    //     ),
    //   width: '200px',
    //   center: true,
    //   wrap: true,
    //   sortable: true,
    //   sortField: 'last_interaction_by',
    //   reorder: true,
    // },
    {
      name: 'Graduation year',
      selector: (row) => row.graduation_year,
      width: '150px',
      maxWidth: '100px',
      center: true,
      sortable: true,
      sortField: 'graduation_year',
      reorder: true,
    },
    {
      name: 'Company',
      selector: (row) => row.company,
      width: '150px',
      maxWidth: '100px',
      center: true,
      sortable: true,
      sortField: 'company',
      reorder: true,
    },
    {
      name: 'Created at',
      selector: (row) => addRecencyText(row.created_at),
      width: '120px',
      sortable: true,
      center: true,
      sortField: 'created_at',
      reorder: true,
    },
    {
      name: (
        <Stack
          direction='row'
          spacing={0.5}
        >
          Assignee
        </Stack>
      ),
      selector: (row) =>
        row.assignee_name && row.assignee_name !== '' ? (
          <Chip
            label={row.assignee_name}
            sx={{ backgroundColor: '#D1C4E9', fontWeight: 500 }}
          />
        ) : (
          <></>
        ),
      width: '190px',
      center: true,
      sortField: 'assignee_name',
      reorder: true,
    },
    {
      name: 'Lead source',
      selector: (row) => (
        <Text
          fontSize='sm'
          style={{
            color: 'blue',
            textTransform: 'uppercase',
          }}
          display='inline-block'
          m={1}
          p={'1px 4px'}
          fontWeight={500}
        >
          {row?.lead_source_type?.charAt(0)}
        </Text>
      ),
      width: '50px',
      center: true,
      sortField: 'lead_source_type',
      reorder: true,
    },
    {
      name: 'Dropped',
      selector: (row) =>
        row.status === constants.DROPPED ? <CheckIcon color='primary' /> : null,
      width: '100px',
      center: true,
      sortField: 'dropped',
      reorder: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
    cells: {
      style: {
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
    pagination: {
      style: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
  };

  const conditionalRowStyles = () => {
    return [
      {
        when: (row) => {
          return row.lead_id === highlightedLeadId;
        },
        style: {
          backgroundColor: '#ebf8ff',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
      {
        when: (row) => row.is_hot,
        style: {
          backgroundColor: '#fdf4e3',
        },
      },
      {
        when: (row) => {
          return row.status === constants.DROPPED; // different background color for dropped leads
        },
        style: {
          backgroundColor: '#cecece',
        },
      },
      {
        when: (row) => {
          return row.is_blacklisted === 1;
        },
        style: {
          backgroundColor: '#ef9a9a',
        },
      },
      {
        when: (row) => {
          return (
            row.disabled &&
            location.pathname === '/leads/priority-list' &&
            row.category !== 'Other_leads'
          );
        },
        style: {
          filter: 'blur(8px)',
        },
      },
    ];
  };

  const addRecencyText = (created_at_d) => {
    if (!created_at_d) return '--';

    const created_at = new Date(created_at_d);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const days_old = (today - created_at) / (24 * 60 * 60 * 1000);
    const tooltipText = getFormattedTimestamp(new Date(created_at_d));
    let displayText = '';

    if (days_old < 1) {
      const timePeriodInHours = days_old * 24;
      const timeUnits = ['hours', 'minutes', 'seconds'];
      const index =
        timePeriodInHours >= 1 ? 0 : timePeriodInHours >= 0.0166 ? 1 : 2;
      displayText = `${Math.floor(
        timePeriodInHours * (index === 1 ? 60 : index === 2 ? 3600 : 1)
      )} ${timeUnits[index]} ago`;
    } else if (created_at.toDateString() === yesterday.toDateString()) {
      displayText = 'Yesterday';
    } else if (days_old < 7) {
      displayText = `${Math.floor(days_old)} day${
        Math.floor(days_old) === 1 ? '' : 's'
      } ago`;
    } else if (days_old < 30) {
      displayText = `${Math.floor(days_old / 7)} week${
        Math.floor(days_old / 7) === 1 ? '' : 's'
      } ago`;
    } else if (days_old < 30 * 12) {
      displayText = `${Math.round(Math.floor(days_old) / 30)} month${
        Math.round(Math.floor(days_old) / 30) === 1 ? '' : 's'
      } ago`;
    } else {
      displayText = `${Math.round(Math.floor(days_old) / 30 / 12)} year${
        Math.round(Math.floor(days_old) / 30 / 12) === 1 ? '' : 's'
      } ago`;
    }

    return (
      <>
        <Typography
          display={'inline'}
          fontSize='12px'
          title={tooltipText}
        >
          {displayText}
        </Typography>
      </>
    );
  };

  const removeSelectionsAndRefresh = () => {
    handleClearRows();
    refreshResults();
  };

  const handleChangePage = (page) => {
    if (!fetachingLeadData) {
      setCurrentPage(page);
    }
    // clearTimeout(timerId);

    // const newTimerId = setTimeout(() => {
    //   setCurrentPage(page);
    // }, 500);

    // setTimerId(newTimerId);
  };

  const handleRowsPerPage = (newPerPage, currPage) => {
    setPerPage(newPerPage);
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleClearRows = () => {
    setSelectedRows([]);
    setToggleClearRows(!toggledClearRows);
  };

  const handleSort = (column, sortDirection) => {
    if (location.pathname === '/leads/priority-list') {
      setSortPriority({ column: column.sortField, order: sortDirection });
    }
    setColumnFilters(`${column.sortField}=${sortDirection}`);
  };

  const handleMouseUp = (event, row) => {
    if (row.disabled) {
      return;
    }
    if (event.button === 0) {
      const currentUrl =
        location.pathname === '/leads/priority-list'
          ? `/leads/${row.lead_id}?source=priority-list`
          : `/leads/${row.lead_id}`;

      if (currentAdminIsTraineeOrIC()) {
        window.location.href = currentUrl;
      } else {
        window.open(currentUrl, '_blank');
      }
    }
  };

  const handleSetAsDefaultColumnsClick = async (sortFieldIndices) => {
    const trueValues = {};
    const falseValues = {};

    sortFieldIndices.forEach((index) => {
      const column = columns[index];
      const key = column.sortField;
      trueValues[key] = true;
    });

    columns.forEach((column) => {
      const key = column.sortField;
      if (!trueValues[key]) {
        falseValues[key] = false;
      }
    });

    const updatedSettings = { ...trueValues, ...falseValues };

    // Convert object to a string
    const settingsString = JSON.stringify(updatedSettings);
    setDefaultSortFields([]);
    try {
      const adminId = getCurrentAdminId();

      const res = await axiosInstance.patch(`{adminId}/settings`, {
        settings: settingsString,
      });
      return res.data;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const mapSortFieldsToIndices = (defaultSortFields) => {
    const sortFieldIndices = defaultSortFields.map((sortField) => {
      const index = columns.findIndex(
        (column) => column.sortField === sortField
      );
      return index;
    });

    handleSetAsDefaultColumnsClick(sortFieldIndices);
  };

  // Handler to capture sortFields
  const handleColumnOrderChange = (columns) => {
    const sortFields = columns
      .map((column) => column.sortField)
      .filter(Boolean);
    // Store sortFields in state
    setDefaultSortFields(sortFields);
  };

  // Handler for "Save as Default Columns" button
  const handleSaveAsDefault = () => {
    mapSortFieldsToIndices(defaultSortFields);
  };
  const toggleLeadFunnelModal = () => {
    setOpenLeadFunnel((p) => !p);
  };

  const handleBulkActions = () => {
    const notHotLeads = selectedRows.filter(
      (lead) =>
        !lead.is_hot &&
        [
          constants.ASSIGNED,
          constants.CONNECT_ATTEMPTED,
          constants.COMPLETE_PROGRAM_EXPLAINED,
          constants.ELIGIBILITY_FORM_SENT,
          constants.ELIGIBILITY_FORM_FILLED,
          constants.SCREENING_CALL_SCHEDULED,
          constants.SCREENING_CALL_ATTENDED,
          constants.OFFER_LETTER_PAYMENT_LINK_SENT,
          constants.TOKEN_AMOUNT_PAID,
        ].includes(lead.status)
    );

    if (notHotLeads.length > 0 && currentAdminIsMarketing()) {
      setHasError(true);
      setErrorMessage('You can only assigned hot leads.');
      return;
    }

    setOpenBulkActionsModal(true);
  };

  const showBulkActionsBtn = () => {
    const CAPsLeads = selectedRows.filter(
      (lead) => [constants.COMPLETE_AMOUNT_PAID].includes(lead.status) && lead
    );
    if (CAPsLeads.length > 0) return false;

    const CreatedDroppedAndReactivatedLeads = selectedRows.filter(
      (lead) =>
        [constants.CREATED, constants.DROPPED, constants.REACTIVATED].includes(
          lead.status
        ) && lead
    );
    if (
      !(currentAdminIsAdmin() || getCurrentAdminId() === 101) &&
      CreatedDroppedAndReactivatedLeads.length > 0
    )
      return false;

    const assignedToTAPLeads = selectedRows.filter(
      (lead) =>
        [
          constants.ASSIGNED,
          constants.CONNECT_ATTEMPTED,
          constants.COMPLETE_PROGRAM_EXPLAINED,
          constants.ELIGIBILITY_FORM_SENT,
          constants.ELIGIBILITY_FORM_FILLED,
          constants.SCREENING_CALL_SCHEDULED,
          constants.SCREENING_CALL_ATTENDED,
          constants.OFFER_LETTER_PAYMENT_LINK_SENT,
          constants.TOKEN_AMOUNT_PAID,
        ].includes(lead.status) && lead
    );
    if (
      assignedToTAPLeads.length > 0 &&
      !(
        currentAdminIsSeniorManagerOrAdminOrMarketing() ||
        currentAdminIsManager()
      )
    )
      return false;

    const DroppedPostSalesLeads = selectedRows.filter(
      (lead) =>
        [
          constants.DROPPED_POST_SALES_REFUND_DENIED,
          constants.DROPPED_POST_SALES_REFUND_INITIATED,
        ].includes(lead.status) && lead
    );
    if (
      DroppedPostSalesLeads.length > 0 &&
      !(
        currentAdminIsSeniorManagerOrAdminOrMarketing() ||
        currentAdminIsManager()
      )
    )
      return false;

    return true;
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <br />
      {leads && (
        <div style={{ width: '100%' }}>
          <Box
            display='flex'
            width='inherit'
            alignItems='center'
          >
            <Box display='flex'>
              {selectedRows?.length > 0 &&
                (currentAdminIsSeniorManagerOrAdminOrMarketing() ||
                  currentAdminIsManager()) && (
                  <>
                    <Button
                      onClick={handleClearRows}
                      variant='contained'
                      size='small'
                    >
                      Clear {selectedRows.length} Selected Rows
                    </Button>
                    {showBulkActionsBtn() && (
                      <Button
                        variant='contained'
                        size='small'
                        sx={{ ml: 1 }}
                        onClick={handleBulkActions}
                      >
                        Bulk actions
                      </Button>
                    )}
                    <Button
                      onClick={toggleLeadFunnelModal}
                      variant='contained'
                      size='small'
                      sx={{ ml: 1 }}
                    >
                      Generate funnel
                    </Button>
                  </>
                )}
              {CopyIcon(copyIds, selectedRows, true, 'Copy lead ids')}
            </Box>
            {currentAdminIsMarketingOrAdmin() && selectedRows.length > 0 && (
              <ManagersDropdown
                selectedRows={selectedRows}
                handleClearRows={handleClearRows}
                setSelectedRows={setSelectedRows}
              />
            )}

            {openLeadFunnel && (
              <LeadFunnelModal
                isOpen={openLeadFunnel}
                onClose={toggleLeadFunnelModal}
                copyIds={copyIds}
              />
            )}
          </Box>
          {location.pathname === '/leads/priority-list' ? (
            ''
          ) : (
            <Box
              display='flex'
              justifyContent={'end'}
            >
              {defaultSortFields.length !== 0 ? (
                <Button
                  variant='outlined'
                  onClick={() => {
                    handleSaveAsDefault();
                    setShowDefaultColumns(true);
                  }}
                  disabled={defaultSortFields.length === 0}
                  sx={{
                    marginRight: '10px',
                    fontSize: '12px',
                    width: '190px',
                    height: '30px',
                    padding: '0px',
                  }}
                >
                  Save as default columns
                </Button>
              ) : (
                ''
              )}

              <Tooltip title='Show/Hide columns'>
                <IconButton
                  onClick={(e) => setOpenChangeTableView(true)}
                  size='small'
                >
                  <FilterAltIcon />
                </IconButton>
              </Tooltip>
              {!currentAdminIsManager() && (
                <Tooltip title='Group by label'>
                  <IconButton
                    onClick={(e) => setViewType('group_by_label')}
                    size='small'
                  >
                    <LabelIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title='Tabular view'>
                <IconButton
                  onClick={(e) => setViewType('tabular')}
                  sx={{ mr: 1 }}
                  size='small'
                >
                  <TableRowsIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          {openBulkActionsModal && selectedRows?.length > 0 && (
            <BulkActionsModal
              openBulkActionsModal={openBulkActionsModal}
              setOpenBulkActionsModal={setOpenBulkActionsModal}
              selectedRows={selectedRows}
              copyIds={copyIds}
              removeSelectionsAndRefresh={removeSelectionsAndRefresh}
              currentLead={currentLead}
            />
          )}
          {openChangeTableView && (
            <SelectColumns
              {...{
                openChangeTableView,
                setOpenChangeTableView,
                selectedColumns,
                setSelectedColumns,
                columns,
                setShowDefaultColumns,
              }}
            />
          )}

          {viewType === 'tabular' && (
            <DataTable
              customStyles={customStyles}
              pagination={location.pathname !== '/leads/priority-list'}
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalLeads}
              paginationRowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
              highlightOnHover={true}
              pointerOnHover={true}
              onRowClicked={(row, event) => {
                if (row.disabled) {
                  return;
                }
                updateCurrentLead(row.lead_id);
                setHighlightedLeadId(row.lead_id);
              }}
              columns={selectedColumns.length === 0 ? columns : selectedColumns}
              data={leads}
              onSort={handleSort}
              sortServer
              conditionalRowStyles={conditionalRowStyles(currentLead)}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleRowsPerPage}
              selectableRows
              onSelectedRowsChange={handleChange}
              clearSelectedRows={toggledClearRows}
              selectableRowsComponent={Checkbox}
              dense
              onColumnOrderChange={handleColumnOrderChange}
              expandableRowDisabled={(row) => row.disabled}
              selectableRowDisabled={(row) => row.disabled}
            />
          )}
          {viewType === 'group_by_label' && (
            <GroupByLabelView queryParams={queryParams} />
          )}
        </div>
      )}
    </>
  );
}

function SelectColumns({
  openChangeTableView,
  setOpenChangeTableView,
  selectedColumns,
  setSelectedColumns,
  columns,
  setShowDefaultColumns,
}) {
  const [selectedColumnIndexes, setSelectedColumnIndexes] = useState([]);
  useEffect(() => {
    if (selectedColumns.length > 0) {
      const arrOfIndexes = [];
      selectedColumns.forEach((col, index) => {
        for (let i = 0; i < columns.length; i += 1) {
          if (columns[i].sortField === col.sortField) {
            arrOfIndexes.push(i);
            break;
          }
        }
      });
      setSelectedColumnIndexes(arrOfIndexes);
    }
    return () => {};
  }, []);

  const handleColumnSelect = (e) => {
    if (
      e.target.value !== '' &&
      selectedColumnIndexes.indexOf(e.target.value) === -1
    ) {
      setSelectedColumnIndexes([...selectedColumnIndexes, e.target.value]);
    }
  };

  const handleUpdate = (e) => {
    const arr = [];
    selectedColumnIndexes.forEach((colIndex, index) => {
      arr.push(columns[colIndex]);
    });
    setSelectedColumns(arr);
    setOpenChangeTableView(false);
  };

  const handleDelete = (colIndex) => {
    setSelectedColumnIndexes(
      selectedColumnIndexes.filter((idx) => colIndex !== idx)
    );
  };

  const handleSetAsDefaultColumnsClick = async () => {
    const trueValues = {};
    const falseValues = {};

    columns.forEach((column) => {
      falseValues[column.sortField] = false;
    });

    selectedColumnIndexes.forEach((selectedIndex) => {
      const selectedColumn = columns[selectedIndex];
      trueValues[selectedColumn.sortField] = true;
      delete falseValues[selectedColumn.sortField];
    });

    const updatedSettings = { ...trueValues, ...falseValues };

    const settingsString = JSON.stringify(updatedSettings);

    try {
      const adminId = getCurrentAdminId();

      const res = await axiosInstance.patch(`{adminId}/settings`, {
        settings: settingsString,
      });
      return res.data;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const handleShowAllColumnsClick = (e) => {
    setSelectedColumns([]);
    setOpenChangeTableView(false);
  };

  const handleDefaultColumnsClick = (e) => {
    setShowDefaultColumns(true);
    setOpenChangeTableView(false);
  };

  const updateSelectedColumnIndexes = (newIndexes) => {
    setSelectedColumnIndexes(newIndexes);
  };

  return (
    <>
      <Dialog
        open={openChangeTableView}
        onClose={() => setOpenChangeTableView(false)}
        fullWidth
        maxWidth='md'
      >
        <Box
          display='flex'
          justifyContent='flex-end'
        >
          <Button onClick={() => setOpenChangeTableView(false)}>Close</Button>
        </Box>
        <DialogContent>
          <DialogContentText>
            {selectedColumnIndexes.length > 0 && (
              <ChipArray
                {...{
                  columns,
                  selectedColumnIndexes,
                  handleDelete,
                  updateSelectedColumnIndexes,
                }}
              />
            )}

            <FormControl
              id='selected-columns'
              size='small'
              fullWidth
            >
              <InputLabel id='selected-columns'>Columns</InputLabel>
              <Select
                name='columns'
                label='Columns'
                labelId='selected-columns'
                onChange={handleColumnSelect}
                value=''
              >
                <MenuItem value=''>None</MenuItem>
                {columns.map((col, index) => {
                  return <MenuItem value={index}>{col.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              handleSetAsDefaultColumnsClick();
              handleUpdate(true);
            }}
          >
            Set as Default Columns
          </Button>
          <Button
            variant='contained'
            onClick={handleDefaultColumnsClick}
          >
            Show default columns
          </Button>
          <Button
            variant='contained'
            onClick={handleShowAllColumnsClick}
            disabled={selectedColumnIndexes.length === 0}
          >
            Show all columns
          </Button>
          <Button
            variant='contained'
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ChipArray({
  selectedColumnIndexes,
  handleDelete,
  columns,
  updateSelectedColumnIndexes,
}) {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(selectedColumnIndexes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateSelectedColumnIndexes(items);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId='chipList'
          direction='horizontal'
        >
          {(provided) => (
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 0.5,
                mb: 2,
                flexWrap: 'wrap',
              }}
              component='ul'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {selectedColumnIndexes.map((colIndex, index) => (
                <Draggable
                  key={columns[colIndex].name}
                  draggableId={String(columns[colIndex].name)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Chip
                        label={columns[colIndex].name}
                        onDelete={(e) => handleDelete(colIndex)}
                        sx={{ mb: 1, mr: 0.5, cursor: 'grab' }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Paper>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function ManagersDropdown({ selectedRows, setSelectedRows, handleClearRows }) {
  const admin = JSON.parse(localStorage.getItem('admin')) ?? {};
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [freshleads, setFreshleads] = useState([]);
  const [SelectedValue, setSelectedValue] = useState(null);
  const [tag, setTag] = useState();

  const managers = useAllAdmins().allAdmins.filter((e) => {
    const roles = e.roles;
    if (e.access_type === 'allowed' || e.access_type === 'restricted') {
      for (const role of roles) {
        if (role.name === 'lead') {
          return true;
        }
      }
    }
    return false;
  });

  const managersDetails = managers.map((e) => {
    return { id: e.id, name: e.fname + ' ' + e.lname };
  });

  const mapfunctionForGroups = () => {
    let managers = managersDetails.map((e) => {
      return {
        name: e.name,
        id: e.id,
        options: [],
      };
    });

    for (let i = 0; i < freshleads?.length; i++) {
      managers[i] = {
        name: managers[i]['name'],
        id: managers[i]['id'],
        options: [],
      };
    }

    return managers;
  };

  const handleAssignment = async () => {
    const leadIds = selectedRows
      .slice(0, selectedRows.length)
      .map((obj) => obj.lead_id);
    try {
      const res = await axiosInstance.post(`/api/manager/manager-pools`, {
        leadIds,
        pooled_by: admin.id,
        pooled_to: SelectedValue.id,
        label: tag,
        isFromBucket: true,
      });
      if (res.status === 200) {
        setIsSuccess(true);
        setSuccessMessage(
          `Assigned ${leadIds.length} fresh leads to ${SelectedValue.name}`
        );
      } else {
        setHasError(true);
        setErrorMessage(
          `Failed to assign fresh leads to ${SelectedValue.name}`
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setHasError(true);
        setErrorMessage(`Failed to assign leads: ${error.response.data.error}`);
      } else {
        setHasError(true);
        setErrorMessage(`Failed to assign fresh leads`);
      }
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      handleClearRows();
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'flex-end',
          gap: '10px',
        }}
      >
        <TextField
          label='Enter Tag'
          variant='outlined'
          size='small'
          style={{ width: '100%' }}
          onChange={(e) => setTag(e.target.value)}
        />
        <GroupedSelect
          setSelectedValue={setSelectedValue}
          SelectedValue={SelectedValue}
          label='Select Manager'
          groups={mapfunctionForGroups()}
        />
        <Button
          variant='contained'
          size='small'
          onClick={() => handleAssignment()}
        >
          Pool
        </Button>
      </Box>
    </>
  );
}
