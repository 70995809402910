import React, { useRef } from 'react';
import { useState } from 'react';
import {
  Stack,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Pagination, Box, List } from '@mui/material';
import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import { getOffsetFromPage } from '../../utils/common';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { getFormattedTimestamp } from '../../utils/common';
import { getTimePeriod } from '../Sidebar';
import NotificationDetails from './NotificationDetails';

const Notifications = () => {
  const initialState = {
    is_acknowledged: 0,
  };
  const currentPage = useRef(1);
  const [limit, setLimit] = useState(25);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [filters, setFilters] = useState(initialState);
  const [notificationDetail, setNotificationDetail] = useState({});

  useEffect(() => {
    currentPage.current = 1;
    fetchNotifications();
    return () => {};
  }, [filters]);

  const fetchNotifications = (p) => {
    const offset = getOffsetFromPage(currentPage.current, limit);
    const queryParams = [`limit=${limit}`, `offset=${offset}`];

    Object.entries(filters).forEach(([key, val], index) => {
      if ((val ?? '') !== '') {
        queryParams.push(`${key}=${val}`);
      }
    });

    const url = `/api/notifications?${queryParams.join('&')}`;
    axiosInstance
      .get(url)
      .then((res) => {
        setTotalNotifications(res.data.total);
        setNotifications(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch new notifications'
        );
      })
      .finally(() => {
        setNotificationDetail(false);
      });
  };

  const updateNotification = (id, index) => {
    const tmpNotifcations = [...notifications];

    const body = {
      acknowledged: tmpNotifcations[index].acknowledged_at ? false : true,
    };

    const url = `/api/notifications/${id}`;
    axiosInstance
      .patch(url, body)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Notifications successfully acknowledged');
        fetchNotifications();
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            "Couldn't acknowledge the notifications."
        );
      });
  };

  const handlePageChange = (event, value) => {
    currentPage.current = value;
    fetchNotifications();
  };

  function getAcknowledgedDropdown() {
    const handleAcknowledgment = (e) => {
      setFilters({ ...filters, is_acknowledged: e.target.value });
    };
    return (
      <FormControl
        size='small'
        sx={{ width: 200 }}
      >
        <InputLabel id='is_acknowledged'>Acknowledgement status</InputLabel>
        <Select
          label='is_acknowledged'
          labelId='is_acknowledged '
          value={filters?.is_acknowledged}
          onChange={handleAcknowledgment}
        >
          <MenuItem value=''>All</MenuItem>
          <MenuItem value='1'>Acknowledged</MenuItem>
          <MenuItem value='0'>Not acknowledged</MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <>
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      <Typography
        variant='h4'
        sx={{ textAlign: 'left' }}
        gutterBottom
      >
        Notifications
      </Typography>
      <Stack
        direction='row'
        display='flex'
        justifyContent='flex-end'
        mx={5}
      >
        {getAcknowledgedDropdown()}
      </Stack>
      {notifications.length > 0 ? (
        <>
          <Stack
            direction='row'
            m={5}
          >
            <List
              style={{
                width: '60%',
                paddingTop: '0px',
                paddingBottom: '0px',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              {notifications?.map((notification, index) => {
                let title = '';

                if (notification.acknowledged_at) {
                  const date = getFormattedTimestamp(
                    new Date(Date.parse(notification.acknowledged_at))
                  );
                  const timeSpan = Math.floor(
                    getTimePeriod(
                      new Date(notification.acknowledged_at),
                      new Date(notification.created_at),
                      true
                    )
                  );
                  title = `Acknowledged at ${date}  approximately ${timeSpan} hours after the notification was sent`;
                }

                return (
                  <ListItem>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      onClick={() => setNotificationDetail(notification)} // in future replace with metadata
                    >
                      <Typography
                        variant='body1'
                        component='p'
                        width={0.5}
                        margin='auto 0'
                        style={{
                          cursor: 'pointer',
                          color:
                            notification.id === notificationDetail.id
                              ? 'blue'
                              : '',
                        }}
                      >
                        {notification?.notes}
                      </Typography>

                      <Tooltip
                        placement='top'
                        title={title}
                      >
                        <Typography
                          variant='body3'
                          component='p'
                        >
                          <Text
                            fontSize='sm'
                            display='inline-block'
                            textAlign={'center'}
                            m={1}
                            background={
                              notification.acknowledged_at
                                ? '#beddad'
                                : '#f28f93'
                            }
                            fontFamily='Roboto, sans-serif'
                            p={'6px'}
                            borderRadius='5px'
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {notification.acknowledged_at
                              ? 'Acknowledged'
                              : 'Not acknowledged'}
                          </Text>
                        </Typography>
                      </Tooltip>

                      <Typography
                        variant='body4'
                        component='p'
                        fontFamily='Roboto, sans-serif'
                        style={{
                          cursor: 'pointer',
                        }}
                        margin='auto 0'
                      >
                        {getFormattedTimestamp(
                          new Date(Date.parse(notification?.created_at))
                        )}
                      </Typography>

                      <Checkbox
                        checked={notification.acknowledged_at ? true : false}
                        onChange={() =>
                          updateNotification(notification.id, index)
                        }
                      />
                    </Box>
                  </ListItem>
                );
              })}
            </List>
            <NotificationDetails {...{ notificationDetail }} />
          </Stack>
          <Pagination
            style={{
              marginTop: '50px',
              display: 'flex',
              justifyContent: 'center',
            }}
            page={currentPage.current}
            pageSize={limit}
            count={Math.ceil(totalNotifications / limit)}
            onChange={handlePageChange}
          />
        </>
      ) : (
        <Typography
          variant='body1'
          component='p'
          textAlign='center'
        >
          No notifications found.
        </Typography>
      )}
    </>
  );
};

const ListItem = ({ children }) => {
  return (
    <li className='list-item'>
      {children}
      <style jsx>{`
        .list-item {
          display: flex;
          padding: 22px;
          flex-direction: column;
          justify-content: space-between;
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          border-left: 1px solid rgba(224, 224, 224, 1);
          border-right: 1px solid rgba(224, 224, 224, 1);
        }
        .list-item:hover {
          background-color: #cceeff;
        }
      `}</style>
    </li>
  );
};

export default Notifications;
