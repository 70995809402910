import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  convertUTCtoIST,
  currentAdminIsAdmin,
  currentAdminIsPgm,
  splitByUnderscoresAndCapitalize,
} from '../../../../utils/common';
import { getFormattedTimestamp } from '../../../../utils/common';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../Mode';
import CustomBackdrop from '../../../../pages/CustomBackdrop';
import { DataGrid } from '@mui/x-data-grid';

function PostSalesLeadDetails({ setOpenEditDetailsView }) {
  const {
    leadData,
    setToastNotificationMessage,
    fetchPostSalesLeadData,
    postSalesLeadActivity,
  } = useCurrentPostSalesLead();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fields = [
    {
      label: 'Payment id',
      key: 'payment_id',
      getValue(value) {
        if ((value ?? '') === '') {
          return detailsNotAvailable();
        }
        return value;
      },
    },
    {
      label: 'Token amount paid',
      key: 'token_amount_paid',
      getValue(value) {
        if ((value ?? '') === '') {
          return detailsNotAvailable();
        }
        return value;
      },
    },
    {
      label: 'Payment type',
      key: 'payment_type',
      getValue(value) {
        if ((value ?? '') === '') {
          return detailsNotAvailable();
        }
        return splitByUnderscoresAndCapitalize(value);
      },
    },
    {
      label: 'EMI provider',
      key: 'emi_provider',
      getValue(value) {
        if ((value ?? '') === '') {
          return detailsNotAvailable();
        }
        return splitByUnderscoresAndCapitalize(value);
      },
    },
    {
      label: 'POC',
      key: 'poc_name',
      getValue(value) {
        if ((value ?? '') === '') {
          return detailsNotAvailable();
        }
        return value;
      },
    },
    {
      label: 'Status',
      key: 'status',
      getValue(value) {
        if ((value ?? '') === '') {
          return detailsNotAvailable();
        }
        return splitByUnderscoresAndCapitalize(value);
      },
    },
    {
      label: 'Token amount paid on',
      key: 'created_at',
      getValue(value) {
        if ((value ?? '') === '') {
          return detailsNotAvailable();
        }
        return getFormattedTimestamp(new Date(value));
      },
    },
  ];

  const detailsNotAvailable = () => {
    return <span style={{ color: '#cecece' }}>Unavailable</span>;
  };

  const getRow = (label, value) => {
    return (
      <TableRow key={label}>
        <TableCell
          sx={{
            width: '30%',
            padding: '5px 10px',
            border: '1px solid #e0e0e0',
            fontSize: '1rem',
            p: 1,
          }}
        >
          {label}
        </TableCell>
        <TableCell
          sx={{
            width: '70%',
            padding: '5px 10px',
            border: '1px solid #e0e0e0',
            fontSize: '1rem',
            p: 1,
          }}
        >
          {value}
        </TableCell>
      </TableRow>
    );
  };

  const getEditIcon = () => {
    return (
      <IconButton
        onClick={(e) => setOpenEditDetailsView(true)}
        size='medium'
        sx={{
          float: 'right',
          mr: 1,
          color: 'black',
          display: 'inline-table',
        }}
      >
        <EditIcon />
      </IconButton>
    );
  };

  const handleCloseApplication = () => {
    setLoading(true);
    axiosInstance
      .patch(`/api/post-sales-leads/${leadData.id}`, {
        close: 1,
        lead_id: leadData.lead_id,
      })
      .then((res) => {
        setToastNotificationMessage(
          'success',
          'Successfully closed the application'
        );
        fetchPostSalesLeadData(leadData.id);
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          err.response.data?.message ||
            'An error occured while closing the application'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { field: 'POC', headerName: 'POC', width: 200 },
    { field: 'activity_status', headerName: 'Status', width: 250 },
    {
      field: 'Status_date_time',
      headerName: 'Status Date and Time',
      width: 180,
    },
  ];

  const rows = postSalesLeadActivity?.map((activity, index) => ({
    id: index + 1,
    POC: activity?.POC,
    activity_status: activity?.activity_status,
    Status_date_time: activity?.Status_date
      ? convertUTCtoIST(activity?.Status_date)
      : '',
  }));

  return (
    <Stack justifyContent='space-between'>
      <CustomBackdrop open={loading} />

      <Table sx={{ width: '90%', marginBottom: '20px' }}>
        <TableHead>
          <TableRow sx={{ border: '1px solid #e0e0e0' }}>
            <TableCell
              colSpan={2}
              display='flex'
              justifyContent='space-between'
            >
              <Typography
                fontSize='20px'
                display='inline-table'
              >
                Profile
              </Typography>

              {getEditIcon()}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fields.map((field) => {
            const { label, key } = field;
            return getRow(label, field.getValue(leadData[key]));
          })}
        </TableBody>
      </Table>

      {postSalesLeadActivity.length > 0 &&
        (currentAdminIsAdmin() || currentAdminIsPgm()) && (
          <>
            <Typography
              variant='h5'
              gutterBottom
            >
              Post Sales Lead Activity
            </Typography>
            <Box style={{ width: '40%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pagination
                autoHeight
              />
            </Box>
          </>
        )}

      {/* temporarily hidden */}
      {/* <Box
        sx={{
          width: "inherit",
          position: "absolute",
          bottom: "1em",
          left: "1em"
        }}
      >
        <Button
          variant='contained'
          color="error"
          onClick={(e) => setOpenModal(true)}
          disabled={leadData.status !== "in_progress"}
        >
          Close application
        </Button>
      </Box> */}

      {openModal && (
        <ConfirmCloseApplication
          {...{
            openModal,
            setOpenModal,
            handleCloseApplication,
          }}
        />
      )}
    </Stack>
  );
}

export default PostSalesLeadDetails;

function ConfirmCloseApplication({
  openModal,
  setOpenModal,
  handleCloseApplication,
}) {
  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogContent>
        <DialogContentText>
          Are you sure you want to close this application?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={(e) => setOpenModal(false)}>Cancel</Button>
        <Button
          onClick={(e) => {
            setOpenModal(false);
            handleCloseApplication();
          }}
          autoFocus
        >
          Yes, I'm sure
        </Button>
      </DialogActions>
    </Dialog>
  );
}
