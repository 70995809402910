import React, { useEffect, useState } from 'react';
import { Stack, Typography, Paper, IconButton, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { background, border } from '@chakra-ui/react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../../Mode';
import ErrorNotifier from '../../../../ToastNotifications/ErrorNotifier';
import { useSocket } from '../../../../../socket/socketContext';
import { getCurrentAdminId } from '../../../../../utils/common';

const MockcallAssign = ({
  isAccepted,
  setIsAccepted,
  onlineIcs,
  traineeDetails,
  handleMockcallRequest,
}) => {
  const [onLineIcsList, setOnlineIcsList] = useState(onlineIcs);
  const [selectedIC, setSelectedIC] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submittedDetails, setSubmittedDetails] = useState();
  const [isICOffline, setIsICOffline] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [submissionData, setSubmissionData] = useState();
  const managerId = getCurrentAdminId();
  const [socket, disconnectSocket] = useSocket();

  useEffect(() => {
    setOnlineIcsList(onlineIcs);
    if (socket) {
      socket.on('onlineICs', (onlineIcs) => {});
      socket.on('selectedICOffline', (selectedIC) => {
        setIsICOffline(true);
        setHasError(true);
        setErrorMessage('Selected IC went offline!');
      });
    }
    return () => {
      // Cleanup event listeners when the component unmounts
      if (socket) {
        socket.off('onlineICs');
        socket.off('selectedICOffline');
      }
    };
  }, [socket, onlineIcs, isConfirmClicked]);
  // }, [socket]);
  useEffect(() => {
    if (submissionData) {
      handleAssignIC(
        submissionData?.currentManager,
        submissionData?.currentTrainee,
        submissionData?.currentSelectedIC
      );
    }
  }, [submissionData]);

  const handleConfirmMockcallAssign = async () => {
    if (selectedIC === '') {
      setHasError(true);
      setErrorMessage('Please select an IC before confirmation.');
    } else {
      if (isICOffline) {
        console.log('Selected IC offline');
      } else {
        if (socket) {
          socket.emit('confirmMockcallAssign', {
            ...traineeDetails,
            selectedIC,
            managerId,
          });
          socket.on('mockCallSubmissionDetails', (submissionData) => {
            const {
              currentManager: { userId: managerId },
              currentTrainee: { userId: traineeId },
              currentSelectedIC: { userId: interviewerId },
            } = submissionData;
            setSubmissionData({
              managerId,
              traineeId,
              interviewerId,
            });
          });
        }
      }
    }
  };

  const handleAssignIC = async (managerId, interviewerId, traineeId) => {
    if (submissionData) {
      const url = base_url() + `/api/training-mockcalls/submissions`;
      await axiosInstance
        .post(url, {
          managerId: submissionData.managerId,
          interviewerId: submissionData.interviewerId,
          traineeId: submissionData.traineeId,
        })
        .then((res) => {
          setSubmittedDetails(res.data);
          setIsAccepted(false);
          if (res.data) {
            socket.emit('submittedMockcallDetails', res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Dialog open={isAccepted}>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <DialogContent dividers>
        <Box textAlign='center'>
          <Typography variant='h5'>Mockcall Assign</Typography>
        </Box>
        <Box
          textAlign='center'
          mt={1}
        >
          <Typography variant='body'>
            Select an executive to assign the mockcall.
          </Typography>
        </Box>
        <Box textAlign='center'>
          <Typography variant='body'>
            They need to be available right now and they need to be online.
          </Typography>
        </Box>
        <Box
          mt={1}
          display='flex'
          justifyContent='center'
        >
          <FormControl
            sx={{ m: 1, minWidth: 120 }}
            size='small'
          >
            <InputLabel id='demo-select-small-label'>Select</InputLabel>
            <Select
              labelId='demo-select-small-label'
              id='demo-select-small'
              value={selectedIC}
              label='Select'
              onChange={(e) => setSelectedIC(e.target.value)}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {onLineIcsList.map((ic) => {
                return (
                  <MenuItem
                    key={ic.userId}
                    value={ic}
                  >
                    {ic.fullName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          display='flex'
          justifyContent='center'
          mt={1}
        >
          <Box>
            <Button
              style={{ backgroundColor: 'green', width: '100px' }}
              variant='contained'
              onClick={() => {
                handleConfirmMockcallAssign();
                setIsConfirmClicked((prev) => !prev);
              }}
            >
              Confirm
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              style={{ backgroundColor: '#3994FF', width: '100px' }}
              variant='contained'
              onClick={() => {
                handleMockcallRequest('cancel');
                setIsAccepted(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MockcallAssign;
