import { Divider, Typography, Button, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogContentText, Stack } from '@mui/material';
import { Search } from '../../components/hrms/HrmsSearch';
import { base_url } from '../../components/Mode';
import HrmsCandidatesDataTable from '../../components/hrms/HrmsCandidatesDataTable';
import { CurrentCandidateDataProvider } from '../../components/hrms/CurrentCandidateContext';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';

import axiosInstance from 'apis/axiosInstance';

const initialState = {
  id: '',
  name: '',
  email1: '',
  email2: '',
  mobile1: '',
  mobile2: '',
  assignee: '',
  status: '',
  label: '',
  snoozed_until: '',
  college: '',
  branch: '',
  graduation_year: '',
  work_experience: '',
  current_company: '',
  current_role: '',
  applied_role: '',
};

function CandidateSearch() {
  const [hrmsFilters, setHrmsFilters] = useState(initialState);
  const [candidateData, setCandidateData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchData();
    return () => {};
  }, [hrmsFilters]);

  const fetchData = () => {
    const url = getQueryUrl(hrmsFilters);
    axiosInstance
      .get(url)
      .then((res) => {
        if (res['data']) {
          setCandidateData(res['data']);
        } else {
          setHasError(true);
          setErrorMessage(`No data`);
        }
      })
      .catch((err) => {
        console.log('error', err);
        setHasError(true);
        setErrorMessage(err?.response?.data?.message);
      });
  };

  const getQueryUrl = (updatedState) => {
    let url = base_url() + '/api/hrms/hrms_candidates';

    const queryString = Object.keys(updatedState)
      .map((key) => {
        if (updatedState[key] === '') {
          return '';
        }
        let val = `${key}=${updatedState[key]}`;
        if (key === 'snoozed_until') {
          val += 'T23:59:59';
        }
        return val;
      })
      .filter(Boolean) // remove empty values
      .join('&');

    if (queryString.length > 0) {
      url += '?' + queryString;
    }

    return url;
  };

  const resetAllFilters = (e) => {
    setHrmsFilters(initialState);
  };

  return (
    <>
      <CurrentCandidateDataProvider>
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        <Box display='inline-block'>
          <Typography
            variant='h4'
            display='inline-block'
            component='div'
            gutterBottom
            marginTop='-2.5rem'
          >
            Candidate search
          </Typography>
        </Box>
        <Divider />
        <br />
        <Search
          filters={hrmsFilters}
          setFilters={setHrmsFilters}
          handleReset={resetAllFilters}
        />
        <HrmsCandidatesDataTable candidateData={candidateData} />
      </CurrentCandidateDataProvider>
    </>
  );
}

export default CandidateSearch;
