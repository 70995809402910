import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
function EligibilityFormDialog({
  selectedEligbilityForm,
  selectedEfConversionFlow,
  setSelectedEligibilityForm,
}) {
  let interviewPreparationChallenges = [];

  if (selectedEfConversionFlow?.length > 0) {
    interviewPreparationChallenges = Array.from(
      new Set([
        ...(selectedEfConversionFlow[0]
          ?.challenges_faced_during_interview_prep || []),
        `I don't know how to get started.`,
        `Managing time effectively.`,
        `Finding reliable study materials.`,
        `Understanding complex technical concepts.`,
        `Unable to get interview calls.`,
        `Other`,
      ])
    );
  }

  const aspectsOfOtherProgram = [
    `Live Sessions`,
    `Doubt Solving`,
    `interview Preparation`,
    `Tools for practicing DSA`,
    `Structured DSA & System Design Curriculum`,
    `Guidance from top mentor`,
  ];

  const goalSelectionOptions = [
    `Move from a service-based role to a product company.`,
    `Move from a product company to a leading tech company.`,
    `Move to a senior role in a rapidly growing unicorn startup.`,
    `Other`,
  ];

  const stepsTakenForInterviewsOption = ['Yes', 'No'];

  const dailyHoursCommitment = [
    `<1 hours`,
    `1-2 hours`,
    `3-4 hours`,
    `5+ hours`,
  ];

  let formTwoResponses;

  if (selectedEfConversionFlow?.length > 0) {
    formTwoResponses = selectedEfConversionFlow[0];
  }
  return (
    <Dialog
      open={selectedEligbilityForm.length > 0}
      onClose={() => setSelectedEligibilityForm([])}
      maxWidth='md'
      fullWidth
      sx={{
        maxHeight: '100vh',
        scroll: 'auto',
      }}
    >
      <DialogActions>
        <Button onClick={(e) => setSelectedEligibilityForm([])}>
          <CloseIcon />
        </Button>
      </DialogActions>

      <DialogContent>
        <Stack
          m={2}
          spacing={2}
        >
          <Typography
            variant='h5'
            fontWeight={'bold'}
          >
            Eligibility Form Responses:
          </Typography>
          <Typography fontWeight={'bold'}>Eligibility Form 1</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell fontWeight={'600'}>Key Notes</TableCell>
                  <TableCell fontWeight={'600'}>Values</TableCell>
                </TableRow>
              </TableHead>

              {selectedEligbilityForm.map((form) => {
                if (!form.question.includes('(SOP)')) {
                  return (
                    <TableBody>
                      <TableRow key={form.question}>
                        <TableCell>
                          <Typography fontWeight={'500'}>
                            {form.question}
                          </Typography>{' '}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant='body1'
                            sx={{
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {form?.answer ?? ''}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                } else {
                  return (
                    <TableCell colSpan={2}>
                      <Stack
                        key={form.question}
                        spacing={2}
                      >
                        <Typography fontWeight={'500'}>
                          {form.question}
                        </Typography>

                        <Box
                          style={{
                            backgroundColor: '#ebf8ff',
                            padding: '12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          borderRadius={'5px'}
                        >
                          <Typography mt={1}>{form?.answer ?? ''}</Typography>
                        </Box>
                      </Stack>
                    </TableCell>
                  );
                }
              })}
            </Table>
          </TableContainer>
          {selectedEfConversionFlow?.length > 0 && formTwoResponses ? (
            <>
              {' '}
              <Typography fontWeight={'bold'}>Eligibility Form 2</Typography>
              <Stack
                sx={{
                  padding: '10px',
                }}
                component={Paper}
              >
                <div>
                  <Typography
                    variant='body1'
                    fontWeight={'500'}
                    sx={{ padding: '10px' }}
                  >
                    1. Let's start by selecting a goal.
                  </Typography>
                  <Box padding='0px 15px'>
                    <FormControl component='fieldset'>
                      <RadioGroup value={formTwoResponses?.goal_selection}>
                        {goalSelectionOptions.map((option) => (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            disabled={
                              !formTwoResponses?.goal_selection?.includes(
                                option
                              )
                            }
                            style={{ cursor: 'default', pointerEvents: 'none' }}
                            label={option}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Typography
                    fontWeight={'500'}
                    variant='body1'
                    sx={{ padding: '10px' }}
                  >
                    2. What's your desired salary range?
                  </Typography>

                  <Box padding='0px 15px'>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        value={
                          formTwoResponses?.desired_salary_range
                            ? `${formTwoResponses?.desired_salary_range} LPA`
                            : ''
                        }
                      >
                        <FormControlLabel
                          value={
                            formTwoResponses?.desired_salary_range
                              ? `${formTwoResponses?.desired_salary_range} LPA`
                              : ''
                          }
                          control={<Radio />}
                          style={{ cursor: 'default', pointerEvents: 'none' }}
                          label={
                            formTwoResponses?.desired_salary_range
                              ? `${formTwoResponses?.desired_salary_range} LPA`
                              : ''
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Typography
                    fontWeight={'500'}
                    variant='body1'
                    sx={{ padding: '10px' }}
                  >
                    3. Have you taken any steps to prepare for interview with
                    these companies?
                  </Typography>
                  <Box padding='0px 15px'>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        value={formTwoResponses?.steps_taken_for_interviews}
                      >
                        {stepsTakenForInterviewsOption.map((option) => (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            disabled={
                              !formTwoResponses?.steps_taken_for_interviews?.includes(
                                option
                              )
                            }
                            style={{ cursor: 'default', pointerEvents: 'none' }}
                            label={option}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Typography
                    fontWeight={'500'}
                    variant='body1'
                    sx={{ padding: '10px' }}
                  >
                    4. How many hours can you commit to learning daily?
                  </Typography>

                  <Box padding='0px 15px'>
                    <FormControl component='fieldset'>
                      <RadioGroup
                        value={formTwoResponses?.daily_hours_commitment}
                      >
                        {dailyHoursCommitment?.length > 0 &&
                          dailyHoursCommitment?.map((option) => (
                            <FormControlLabel
                              key={option}
                              value={option}
                              control={<Radio />}
                              disabled={
                                !formTwoResponses?.daily_hours_commitment?.includes(
                                  option
                                )
                              }
                              style={{
                                cursor: 'default',
                                pointerEvents: 'none',
                              }}
                              label={option}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Typography
                    fontWeight={'500'}
                    variant='body1'
                    sx={{ padding: '10px' }}
                  >
                    5. What were the biggest challenges you faced during your
                    interview preparation.?
                  </Typography>
                  <Box padding='0px 15px'>
                    <FormGroup>
                      {interviewPreparationChallenges.length > 0 &&
                        interviewPreparationChallenges?.map((option) => (
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                checked={formTwoResponses?.challenges_faced_during_interview_prep?.includes(
                                  option
                                )}
                                disabled={
                                  !formTwoResponses?.challenges_faced_during_interview_prep?.includes(
                                    option
                                  )
                                }
                                style={{
                                  cursor: 'default',
                                  pointerEvents: 'none',
                                }}
                              />
                            }
                            label={option}
                            style={{ cursor: 'default' }}
                          />
                        ))}
                    </FormGroup>
                  </Box>

                  <Typography
                    fontWeight={'500'}
                    variant='body1'
                    sx={{ padding: '10px' }}
                  >
                    6. Which aspects of our program do you believe will most
                    significantly enhance your profile?
                  </Typography>
                  <Box padding='0px 15px'>
                    <FormGroup>
                      {aspectsOfOtherProgram.length > 0 &&
                        aspectsOfOtherProgram?.map((option) => (
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                checked={formTwoResponses?.profile_enhancement_program_aspects?.includes(
                                  option
                                )}
                                disabled={
                                  !formTwoResponses?.profile_enhancement_program_aspects?.includes(
                                    option
                                  )
                                }
                                style={{
                                  cursor: 'default',
                                  pointerEvents: 'none',
                                }}
                              />
                            }
                            label={option}
                            style={{
                              cursor: 'default',
                            }}
                          />
                        ))}
                    </FormGroup>
                  </Box>
                </div>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default EligibilityFormDialog;
