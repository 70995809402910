import React, { useState, useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAllAdmins } from '../AdminsContext';
import moment from 'moment';

const ScreeningCallMetaDataDialog = ({
  openRecordingOffModal,
  setOpenRecordingOffModal,
  setHasError,
  setErrorMessage,
  leadStatusActivity,
  getLeadActivitiesMetadata,
  setMetadataForScreeningCallAttended,
  updateLeadDetailState,
  refreshLeadContext,
  setSuccessMessage,
  setIsSuccess,
  leadScheduledStatusActivity,
  getLeadScheduledActivitiesMetadata,
}) => {
  const { allAdmins } = useAllAdmins();
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [validAdmins, setValidAdmins] = useState({});
  const MAX_ALLOWED_SCREENING_CALL_TIME = 240;

  let gmtTimeForStartTime = moment.utc(
    leadScheduledStatusActivity?.metadata?.start_time,
    'YYYY-MM-DD HH:mm'
  );
  let gmtTimeForEndTime = moment.utc(
    leadStatusActivity?.metadata?.end_time,
    'YYYY-MM-DD HH:mm'
  );
  const startTimeISTConversion = leadScheduledStatusActivity?.metadata
    ?.start_time
    ? moment(gmtTimeForStartTime).local().format('YYYY-MM-DD HH:mm')
    : null;
  const endTimeISTConversion = leadStatusActivity?.metadata?.end_time
    ? moment(gmtTimeForEndTime).local().format('YYYY-MM-DD HH:mm')
    : null;

  useEffect(() => {
    getAllEnabledAndRestrictedAdmins();
  }, []);

  useEffect(() => {
    setSelectedAdmin(leadScheduledStatusActivity?.metadata?.admin_id ?? null);
    setStartTime(startTimeISTConversion);
    setEndTime(endTimeISTConversion);
  }, [leadStatusActivity, leadScheduledStatusActivity]);

  const getAllEnabledAndRestrictedAdmins = () => {
    let admins = {};
    allAdmins.map((admin) => {
      if (
        admin.access_type === 'allowed' ||
        admin.access_type === 'restricted'
      ) {
        admin.roles.map((role) => {
          if (role.name === 'independent_contributor' || role.name === 'lead') {
            admins[admin.id] = `${admin.fname + ' ' + admin.lname}`;
          }
        });
      }
    });
    setValidAdmins(admins);
  };

  const handleCloseRecordingOffModal = () => {
    setOpenRecordingOffModal(false);
    setSelectedAdmin(leadScheduledStatusActivity?.metadata?.admin_id ?? null);
    setStartTime(startTimeISTConversion);
    setEndTime(endTimeISTConversion);
  };

  const handleSetAdminId = (e) => {
    setSelectedAdmin(parseInt(e.target.value), 10);
  };

  const handleSetErrorMessage = (duration, selectedAdmin) => {
    if (duration > MAX_ALLOWED_SCREENING_CALL_TIME) {
      setErrorMessage("Screening call can't be more than 4 hours.");
    } else if (duration < 0) {
      setErrorMessage("End time can't be in past from start time.");
    } else if (selectedAdmin === null) {
      setErrorMessage('Please select an interviewer');
    } else {
      setErrorMessage('Error while saving screening call details.');
    }
  };

  const handleSCMetadata = () => {
    try {
      const startDateTime = startTime ? new Date(startTime) : null;
      const endDateTime = endTime ? new Date(endTime) : null;
      const timeDifference =
        startDateTime && endDateTime ? endDateTime - startDateTime : null;
      const durationInMinutes = timeDifference
        ? timeDifference / (1000 * 60)
        : null;
      const checkDuration =
        isNaN(durationInMinutes) || durationInMinutes > 0 ? true : false;

      const startTimeInUTC = startTime
        ? moment(startTime).utc().format('YYYY-MM-DD HH:mm')
        : null;
      const endTimeInUTC = endTime
        ? moment(endTime).utc().format('YYYY-MM-DD HH:mm')
        : null;
      let body = {
        admin_id: selectedAdmin,
        lead_status: leadStatusActivity?.status,
        start_time: startTimeInUTC,
        end_time: endTimeInUTC,
        duration: durationInMinutes,
      };
      setMetadataForScreeningCallAttended((prev) => ({
        ...prev,
        admin_id: selectedAdmin,
        lead_status: leadStatusActivity?.status,
        start_time: startTimeInUTC,
        end_time: endTimeInUTC,
        duration: durationInMinutes,
      }));
      const isDurationValid =
        durationInMinutes === null || (checkDuration && durationInMinutes > 0);

      const isEndTimeValid = endTime !== null && endDateTime > startDateTime;

      if (
        (isDurationValid || isEndTimeValid) &&
        durationInMinutes <= MAX_ALLOWED_SCREENING_CALL_TIME &&
        selectedAdmin !== null
      ) {
        if (Object.keys(leadStatusActivity).length !== 0) {
          axiosInstance
            .patch(`/api/status_activities/${leadStatusActivity.id}`, body)
            .then(() => {
              setIsSuccess(true);
              setSuccessMessage('Details updated');
              refreshLeadContext();
              getLeadActivitiesMetadata();
            })
            .catch((error) => {
              console.log(error);
              setHasError(true);
              setErrorMessage(
                'An error occurred while saving screening call details.'
              );
            });
        } else {
          updateLeadDetailState('status', 'screening_call_attended');
          getLeadActivitiesMetadata();
          getLeadScheduledActivitiesMetadata();
        }
        handleCloseRecordingOffModal();
      } else {
        setHasError(true);
        handleSetErrorMessage(durationInMinutes, selectedAdmin);
        handleCloseRecordingOffModal();
      }
    } catch (error) {
      console.log('Error while saving screening call details:', error);
      setHasError(true);
      setErrorMessage('An error occurred while saving screening call details.');
    }
  };

  return (
    <>
      <Dialog open={openRecordingOffModal}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            padding: '10px 10px',
            width: 'fir-content',
          }}
        >
          <Button
            onClick={handleCloseRecordingOffModal}
            sx={{ color: 'black' }}
          >
            <CloseRoundedIcon />
          </Button>
        </Box>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              gap: '15px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                textAlign: 'center',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography>Interviewer</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '73%',
                }}
              >
                <FormControl size='small'>
                  <InputLabel id='select-admin'>Interviewer name</InputLabel>
                  <Select
                    labelId='select-admin'
                    variant='outlined'
                    label='Interviewer name'
                    onChange={handleSetAdminId}
                    value={selectedAdmin}
                    //disabled={true}
                  >
                    {Object.keys(validAdmins).map((adminId) => (
                      <MenuItem
                        key={adminId}
                        value={adminId}
                      >
                        {validAdmins[adminId]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                textAlign: 'center',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography>Start Time</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <TextField
                  type='datetime-local'
                  variant='outlined'
                  onChange={(e) => setStartTime(e.target.value)}
                  value={startTime}
                  // disabled={true}
                  size='small'
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                textAlign: 'center',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography>End Time</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <TextField
                  variant='outlined'
                  type='datetime-local'
                  onChange={(e) => setEndTime(e.target.value)}
                  value={endTime}
                  size='small'
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSCMetadata}
            autoFocus
            variant='contained'
            disabled={!endTime}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScreeningCallMetaDataDialog;
