import React, { useState, createContext, useContext, useCallback } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from './Mode';
import CustomBackdrop from '../pages/CustomBackdrop';
import ErrorNotifier from '../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from './ToastNotifications/SuccessNotifier';

const CurrentPostSalesLeadContext = createContext();

function CurrentPostSalesLeadComponent({ children }) {
  const [leadData, setLeadData] = useState(null);
  const [comments, setComments] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [postSalesLeadActivity, setPostSalesLeadActivtiy] = useState([]);

  const getCurrentPostSalesLeadData = (postSalesLeadId) => {
    setLoading(true);
    fetchPostSalesLeadData(postSalesLeadId);
    fetchPostSalesComments(postSalesLeadId);
    fetchPostSalesLeadActivities(postSalesLeadId);
    fetchPostSalesLeadActivtiesTrack(postSalesLeadId);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const resetCurrentPostSalesLeadContext = () => {
    setLeadData(null);
    setComments([]);
    setActivities([]);
  };

  const setToastNotificationMessage = (type, message) => {
    if (type === 'error') {
      setHasError(true);
      setErrorMessage(message);
    } else {
      setIsSuccess(true);
      setSuccessMessage(message);
    }
  };

  const fetchPostSalesLeadData = useCallback(
    (postSalesLeadId) => {
      axiosInstance
        .get(`/api/post-sales-leads/${postSalesLeadId}`)
        .then((res) => {
          if (typeof res.data.sales_handover === 'string') {
            res.data.sales_handover = JSON.parse(res.data.sales_handover);
          }

          if (typeof res.data.finance_handover === 'string') {
            res.data.finance_handover = JSON.parse(res.data.finance_handover);
          }
          setLeadData(res.data);
        })
        .catch((err) => {
          console.log(err);
          // setToastNotificationMessage(
          //   'error',
          //   err.response?.data?.message ||
          //     'An error occured while fetching post sales data of the selected lead.'
          // );
        });
    },
    [setLeadData]
  );

  const fetchPostSalesComments = useCallback(
    (postSalesLeadId) => {
      axiosInstance
        .get(`/api/post-sales-leads/${postSalesLeadId}/comments`)
        .then((res) => {
          setComments(res.data ?? []);
        })
        .catch((err) => {
          console.log(err);
          setToastNotificationMessage(
            'error',
            err.response?.data?.message ||
              'An error occured while fetching post sales comments of the selected lead.'
          );
        });
    },
    [setComments]
  );

  const fetchPostSalesLeadActivities = useCallback(
    (postSalesLeadId) => {
      axiosInstance
        .get(`/api/post-sales-leads/${postSalesLeadId}/activities`)
        .then((res) => {
          setActivities(res.data ?? []);
        })
        .catch((err) => {
          console.log(err);
          setToastNotificationMessage(
            'error',
            err.response?.data?.message ||
              'An error occured while fetching post sales activities of the selected lead.'
          );
        });
    },
    [setActivities]
  );

  const fetchPostSalesLeadActivtiesTrack = useCallback(
    (postSalesLeadId) => {
      axiosInstance
        .get(`/api/post-sales-lead-activity-track/${postSalesLeadId}`)
        .then((res) => {
          if (
            Array.isArray(res?.data) &&
            res?.data !== undefined &&
            res?.data !== null
          ) {
            setPostSalesLeadActivtiy(res?.data);
          }
        })
        .catch((err) => console.log(err));
    },
    [setPostSalesLeadActivtiy]
  );

  return (
    <CurrentPostSalesLeadContext.Provider
      value={{
        getCurrentPostSalesLeadData,
        resetCurrentPostSalesLeadContext,
        fetchPostSalesLeadData,
        fetchPostSalesComments,
        fetchPostSalesLeadActivities,
        setToastNotificationMessage,
        leadData,
        setLeadData,
        activities,
        setActivities,
        comments,
        setComments,
        postSalesLeadActivity,
        fetchPostSalesLeadActivtiesTrack,
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <CustomBackdrop open={loading} />

      {children}
    </CurrentPostSalesLeadContext.Provider>
  );
}

export default CurrentPostSalesLeadComponent;
export const useCurrentPostSalesLead = () =>
  useContext(CurrentPostSalesLeadContext);
