import { Stack, Typography } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { base_url } from '../../components/Mode';
import ImagesList from '../ManagerDashboard/ImagesList';

export default function ViewUploadedImages({
  reportId,
  setHasError,
  setErrorMessage,
}) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (reportId != -1) {
      fetchReportImages();
    } else {
      setImages([]);
    }
    return () => {};
  }, [reportId]);

  function fetchReportImages() {
    axiosInstance
      .get(`/api/reports/${reportId}/images`)
      .then((res) => {
        const tmpImages = (res.data ?? []).map((img) => {
          return {
            url: img.url,
            title: img.file_name ?? 'No filename',
          };
        });
        setImages(tmpImages ?? []);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch report images"
        );
      });
  }

  return (
    <Stack spacing={2}>
      <Typography
        variant='h4'
        component='h4'
      >
        Uploaded images
      </Typography>

      <Stack alignItems='center'>
        {images.length > 0 ? (
          <ImagesList images={images} />
        ) : (
          <Typography
            variant='body1'
            component='p'
          >
            No images found
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
