import React, { useEffect, useState } from 'react';
import { useSocket } from '../../../../../socket/socketContext';
import { Stack, Typography, Paper, IconButton, Button } from '@mui/material';
import Box from '@mui/material/Box';
import MockcallRequestPopup from './MockcallRequestPopup';
import MockcallAssign from './MockcallAssign';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../../Mode';
import { getCurrentAdminId } from '../../../../../utils/common';
import ErrorNotifier from '../../../../ToastNotifications/ErrorNotifier';

const ManagerScreen = () => {
  const [socket, disconnectSocket] = useSocket();
  const [isMockCallPopupOpen, setIsMockCallPopupOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [onlineIcs, setOnlineIcs] = useState([]);
  const [traineeDetails, setTraineeDetails] = useState();
  const [openPopUp, setopenPopUp] = useState(true);
  const [newStatus, setNewStatus] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [click, setClick] = useState(false);
  const managerId = getCurrentAdminId();

  useEffect(() => {
    const handleMockCallRequest = (data) => {
      setTraineeDetails(data);
      setIsMockCallPopupOpen(true);
      setopenPopUp(true);
    };

    const handleOnlineIcsUpdate = (ics) => {
      setOnlineIcs(ics);
    };

    if (socket) {
      socket.on('mockCallRequest', handleMockCallRequest);
      socket.on('onlineIcs', handleOnlineIcsUpdate);

      if (newStatus !== undefined) {
        socket.emit('mockCallRequestAcknowledgment', {
          status: newStatus,
          traineeDetails,
          managerId,
        });
        setNewStatus('');
      }

      socket.on('mockCallRequestTimeOut', (data) => {
        setIsMockCallPopupOpen(false);
      });

      socket.on('traineeOffline', (data) => {
        setIsAccepted(false);
        setIsMockCallPopupOpen(false);
        setHasError(true);
        setErrorMessage('The requested trainee went offline!');
      });
    }

    return () => {
      if (socket) {
        socket.off('mockCallRequest', handleMockCallRequest);
        socket.on('onlineIcs', handleOnlineIcsUpdate);
      }
    };
  }, [socket, newStatus, click]);

  const handleMockcallRequest = async (statusData) => {
    socket.emit('requestOnlineIcs', managerId);
    if (traineeDetails) {
      const status = statusData === 'accept' ? statusData : 'reject';
      const url = base_url() + `/api/training-mockcalls/requests`;
      await axiosInstance
        .post(url, {
          ...traineeDetails,
          managerId,
          status,
        })
        .then((res) => {
          setNewStatus(statusData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (openPopUp) {
    return (
      <div>
        {hasError && (
          <ErrorNotifier
            message={errorMessage}
            setHasError={setHasError}
          />
        )}
        {isMockCallPopupOpen && !isAccepted && (
          <Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <MockcallRequestPopup
                isMockCallPopupOpen={isMockCallPopupOpen}
                setIsMockCallPopupOpen={setIsMockCallPopupOpen}
                setIsAccepted={setIsAccepted}
                handleMockcallRequest={handleMockcallRequest}
                traineeDetails={traineeDetails}
                setClick={setClick}
              />
            </Box>
          </Box>
        )}
        {isAccepted && (
          <MockcallAssign
            isAccepted={isAccepted}
            setIsAccepted={setIsAccepted}
            traineeDetails={traineeDetails}
            onlineIcs={onlineIcs}
            handleMockcallRequest={handleMockcallRequest}
          />
        )}
      </div>
    );
  }
};

export default ManagerScreen;
