import React, { useEffect, useRef, useState } from 'react';
import { base_url } from '../../Mode';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import PostSalesLeadsTable from './PostSalesLeadsTable';
import {
  currentAdminIsFinance,
  currentAdminIsPgm,
  getOffsetFromPage,
  splitByUnderscoresAndCapitalize,
} from '../../../utils/common';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import CurrentPostSalesLeadComponent from '../../CurrentPostSalesLeadContext';
import axiosInstance from 'apis/axiosInstance';

const getDefaultStatus = () => {
  if (currentAdminIsFinance()) {
    return { status: 'finance_handover_initiated' };
  }
  if (currentAdminIsPgm()) {
    return { status: 'pgm_handover_initiated' };
  }
  return {
    status: 'in_progress',
    tap_on: 'desc',
  };
};

function PostSalesDashboard() {
  const [postSalesData, setPostSalesData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [filters, setFilters] = useState(getDefaultStatus());
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPostSalesData();
    return () => {};
  }, [filters, perPage, page]);

  const fetchPostSalesData = () => {
    setLoading(true);
    const offset = getOffsetFromPage(page, perPage);
    const queryParams = [`limit=${perPage}`, `offset=${offset}`];

    Object.keys(filters).forEach((key) => {
      if ((filters[key] ?? '') !== '') {
        queryParams.push(`${key}=${filters[key]}`);
      }
    });

    axiosInstance
      .get(`/api/post-sales-leads?${queryParams.join('&')}`)
      .then((res) => {
        setPostSalesData(res.data.data);
        setTotalRows(res.data.total);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch post sales leads."
        );
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPerPage(size);
  };

  const getDownloadCsv = () => {
    axiosInstance
      .get(`/api/post-sales-leads/download`)
      .then((res) => {
        const leadData = res.data;
        if (leadData.length === 0) {
          return;
        }

        const salesHandoverHeader = [
          'Program Type',
          'If Personalized Coaching duration for the coaching (Months)',
          'Programming Language',
          'Year of Graduation',
          'Field of Graduation',
          'Work Experience',
          'Job Role',
          'Notice Period (Days) Mandatory if Working Professional',
        ];
        const financeHandoverHeader = ['Gold Enabled'];
        const columnHeaders = [
          'id',
          'lead_id',
          'fname',
          'lname',
          'email',
          'mobile',
          'status',
        ];
        const headers = [
          ...columnHeaders,
          ...salesHandoverHeader,
          ...financeHandoverHeader,
        ];

        const csvData = leadData.map((lead) => {
          const financeHandover = JSON.parse(
            lead['finance_handover'] ?? '[{}]'
          );

          const salesHandover = JSON.parse(lead['sales_handover'] ?? '[{}]');

          const financeHandoverValues = financeHandoverHeader.map((header) => {
            const item = financeHandover.find((obj) => obj.title === header);
            return item ? item.value : '';
          });

          const salesHandoverValues = salesHandoverHeader.map((header) => {
            const item = salesHandover.find((obj) => obj.title === header);
            return item ? item.value : '';
          });

          const rowData = [
            ...columnHeaders.map((header) => lead[header] ?? ''),
            ...salesHandoverValues,
            ...financeHandoverValues,
          ];

          return rowData.join(',');
        });

        const csvContent = [headers.join(','), ...csvData].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'lead_data.csv';
        link.click();
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't download post sales leads."
        );
      });
  };

  const getFilters = () => {
    return (
      <Grid
        item
        xs={3}
        sm={3}
        md={2}
      >
        <FormControl
          id='status'
          size='small'
          sx={{
            width: '17em',
          }}
        >
          <InputLabel id='status'>Status</InputLabel>
          <Select
            labelId='status'
            label='Status'
            name='status'
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
          >
            <MenuItem
              sx={{
                display:
                  (currentAdminIsFinance() || currentAdminIsPgm()) && 'none',
              }}
              value='in_progress'
            >
              In progress
            </MenuItem>

            <MenuItem
              value='converted'
              sx={{
                display: currentAdminIsFinance() && 'none',
              }}
            >
              Converted
            </MenuItem>
            <MenuItem
              value='dropped'
              sx={{
                display:
                  (currentAdminIsFinance() || currentAdminIsPgm()) && 'none',
              }}
            >
              Dropped
            </MenuItem>

            <MenuItem
              value='finance_handover_initiated'
              sx={{
                display: currentAdminIsPgm() && 'none',
              }}
            >
              Finance handover initiated
            </MenuItem>

            <MenuItem
              value='finance_handover_rejected'
              sx={{
                display: currentAdminIsPgm() && 'none',
              }}
            >
              Finance handover rejected
            </MenuItem>

            <MenuItem value='pgm_handover_initiated'>
              Pgm handover initiated
            </MenuItem>

            <MenuItem value='pgm_handover_rejected'>
              Pgm handover rejected
            </MenuItem>

            <MenuItem value='pgm_handover_approved'>
              Pgm handover approved
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  };

  return (
    <CurrentPostSalesLeadComponent>
      <Stack spacing={2}>
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        {isSuccess && (
          <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
        )}

        {getFilters()}

        <Button
          variant='contained'
          onClick={getDownloadCsv}
          sx={{
            alignSelf: 'flex-end',
            width: '8rem',
            display:
              (filters.status !== 'pgm_handover_approved' ||
                !currentAdminIsPgm()) &&
              'none',
          }}
        >
          Download
        </Button>

        {postSalesData.length > 0 ? (
          <PostSalesLeadsTable
            {...{
              postSalesData,
              handlePageChange,
              handlePageSizeChange,
              fetchPostSalesData,
              perPage,
              page,
              totalRows,
              loading,
              setFilters,
            }}
          />
        ) : (
          <Typography
            variant='body1'
            component='p'
            textAlign='center'
          >
            No <b>'{splitByUnderscoresAndCapitalize(filters.status)}'</b> leads
            found
          </Typography>
        )}
      </Stack>
    </CurrentPostSalesLeadComponent>
  );
}

export default PostSalesDashboard;
