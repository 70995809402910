/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axiosInstance from 'apis/axiosInstance';
import PaymentDetailsTable from './paymentTable';
import { base_url } from '../Mode';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { TabPanel } from '../../pages/Admins/AdminDashboard';
import PaymentFinanceDashboard from '../users/PostSalesPaymentsFinance/PaymentFinanceDashboard';
const apiEndpoint = `/api/settlements/all_settlement_details`;
const Payment = () => {
  const [paymentsData, setPaymentsData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(apiEndpoint);
      if (response.status === 200) {
        setPaymentsData(response.data);
      } else {
        setHasError(true);
        setErrorMessage(
          `Error: ${response.status}, ${response.data.error_description}`
        );
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(error);
    }
  };

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  return (
    <>
      <Box sx={{ mr: 2, mt: 4 }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '-3rem' }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
          >
            <Tab label='Settlements' />
            <Tab label='Payments' />
          </Tabs>
        </Box>
        <TabPanel
          value={currentTab}
          index={0}
        >
          <PaymentDetailsTable paymentsData={paymentsData} />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index={1}
        >
          <PaymentFinanceDashboard />
        </TabPanel>
      </Box>
    </>
  );
};

export default Payment;
