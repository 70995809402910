import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import { base_url } from '../../../components/Mode';

const SetAdminClass = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setEmail(null);
    setOpen(false);
  };

  const handleSetClass = async () => {
    if (!email) {
      setHasError(true);
      setErrorMessage('Please enter email');
      return;
    }

    setLoading(true);
    const setClass = await axiosInstance
      .post(base_url() + '/api/admins/class-set', {
        email,
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Admin class set to champion successfully.');
        setEmail(null);
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        setHasError(true);
        setErrorMessage(
          error.response?.data?.message ||
            'An error occured while setting the class'
        );
        setEmail(null);
        setLoading(false);
        handleClose();
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={handleOpen}
        >
          Set Admin Class
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-title'
          aria-describedby='modal-description'
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id='modal-title'
              variant='h6'
              component='h2'
            >
              Set Admin Class
            </Typography>
            <TextField
              id='email'
              label='Email'
              variant='outlined'
              fullWidth
              margin='normal'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                onClick={handleClose}
                variant='outlined'
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSetClass}
                variant='contained'
                color='primary'
                disabled={loading}
              >
                {loading ? 'Setting...' : 'Set Class'}
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default SetAdminClass;
