import axiosInstance from 'apis/axiosInstance';
import React, { useEffect, useState } from 'react';
import { base_url } from '../components/Mode';
import { Box, Modal, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesomeOutlined';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, DialogContentText, Stack } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LeadsDataTable from '../components/data-table/LeadsDataTable';
import {
  currentAdminIsIC,
  currentAdminIsTrainee,
  currentAdminProductIsSuper30,
  getCurrentAdminId,
} from '../utils/common';
import LeadRequestModal from '../components/ToastNotifications/LeadRequestModal';
import Spinner from '../components/ToastNotifications/LeadRequestModal/Spinner';
import AllLeadsInfo from '../components/AllLeadsInfo';
import constants from '../utils/constants';

const FocusedModeLeads = () => {
  const [leads, setLeads] = useState([]);
  const [otherLeads, setOtherLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [columnFilters, setColumnFilters] = useState('');
  const [leadRequestStatusMessage, setLeadRequestStatusMessage] =
    useState(undefined);
  const [requestLeadButtonClicked, setRequestLeadButtonClicked] =
    useState(false);
  const [openLeadRequestDescModal, setOpenLeadRequestDescModal] =
    useState(false);
  const [isOpenLeadRequestModal, setIsOpenLeadRequestModal] = useState(false);
  const [leadRequestSuccessful, setLeadRequestSuccessful] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [requestedLeadData, setRequestedLeadData] = useState('');
  const [isDisableRequestLeadButton, setIsDisableRequestLeadButton] =
    useState(false);
  const [isRequestNewLeadHovered, setIsRequestNewLeadHovered] = useState(false);
  const [isClassSet, setIsClassSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortPrirority, setSortPriority] = useState({});

  useEffect(() => {
    fetchFocusedLeads();
    canLeadRequested();

    const intervalId = setInterval(() => {
      fetchFocusedLeads();
    }, 300000); // 300000 ms = 5 minutes

    return () => clearInterval(intervalId);
  }, [sortPrirority]);

  const fetchFocusedLeads = async () => {
    try {
      const response = await axiosInstance.get(
        base_url() + '/api/focus-mode/lead-list',
        {
          params: {
            column: sortPrirority.column,
            order: sortPrirority.order === 'asc' ? 1 : 0,
          },
        }
      );
      // filter by    category: 'Other_leads',
      const otherLeadsResponse = response.data.data.filter(
        (lead) => lead.category === 'Other_leads'
      );
      setOtherLeads(otherLeadsResponse);
      const focusedLeadsResponse = response.data.data.filter(
        (lead) => lead.category !== 'Other_leads'
      );

      const data = focusedLeadsResponse.map((item, index) => {
        let disabled = false;

        if (index > constants.visibleOne) {
          disabled = true;
        }
        return {
          ...item,
          disabled,
        };
      });
      setLeads(data);
    } catch (error) {
      console.log(error);
    }
  };

  const openLeadRequestModal = () => {
    setIsOpenLeadRequestModal(true);
  };

  const closeLeadRequestModal = () => {
    setIsOpenLeadRequestModal(false);
  };

  const getLeadRequestUrl = () => {
    return base_url() + '/api/assignments';
  };

  const getLeadRequestValidationUrl = () => {
    return base_url() + '/api/salsa/lead-request-validation';
  };

  const handleMouseEnterForLeadRequest = () => {
    setIsRequestNewLeadHovered(true);
  };

  const handleMouseLeaveForLeadRequest = () => {
    setIsRequestNewLeadHovered(false);
  };

  const canLeadRequested = () => {
    setIsLoading(true);
    let leadRequestValidationUrl = getLeadRequestValidationUrl();
    if (leadRequestValidationUrl) {
      axiosInstance
        .get(leadRequestValidationUrl)
        .then((res) => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsDisableRequestLeadButton(true);
          setLeadRequestStatusMessage(
            err.response ? err.response.data.message : err
          );
          setIsLoading(false);
        });
    }
  };

  async function requestLead() {
    setIsSpinner(true);
    let url = getLeadRequestUrl();
    // await is required so that we only proceed further after the button is disabled. This helps us avoid repeat assignments.
    await setRequestLeadButtonClicked(true);
    setLeadRequestStatusMessage(undefined);
    setLeadRequestSuccessful(false);
    axiosInstance
      .post(url)
      .then((res) => {
        setRequestedLeadData(res.data);
        setIsSpinner(false);
        openLeadRequestModal();
      })
      .catch((err) => {
        setIsSpinner(false);
        setIsDisableRequestLeadButton(true);
        setLeadRequestStatusMessage(
          err.response ? err.response.data.message : err
        );
      })
      .finally(() => {
        setRequestLeadButtonClicked(false);
        setIsSpinner(false);
      });
  }

  const RequestNewLeadTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'black',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(15),
      border: '1px solid #dadde9',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  }));

  const salsaDrivenLeadAssignment = () => {
    return (
      <>
        {isLoading ? (
          <Skeleton
            variant='rectangular'
            width={210}
            height={40}
            animation='wave'
            sx={{
              borderRadius: '5px',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              borderWidth: 1,
            }}
          />
        ) : (
          <Box
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onMouseEnter={handleMouseEnterForLeadRequest}
            onMouseLeave={handleMouseLeaveForLeadRequest}
          >
            <RequestNewLeadTooltip
              open={
                (isRequestNewLeadHovered && isDisableRequestLeadButton) ||
                isLoading
              }
              title={
                leadRequestStatusMessage
                  ? leadRequestStatusMessage
                  : 'Validating User For Request'
              }
              placement='top'
            >
              <IconButton
                variant='contained'
                onClick={async () => {
                  await requestLead();
                }}
                disabled={
                  requestLeadButtonClicked ||
                  isDisableRequestLeadButton ||
                  isLoading
                }
                sx={{
                  marginBottom: '10px',
                  width: '210px',
                  padding: '10px',
                  height: '40px',
                  borderRadius: '5px',
                  background:
                    'linear-gradient(90deg, rgba(111, 0, 255, 0.60) 0%, #6F00FF 100%)',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  borderColor: '#4285f4',
                  borderWidth: 1,
                  color: 'white',
                  fontSize: '15px',
                  fontWeight: 600,
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'linear-gradient(to right, #6f00ff, #9a4cff)',
                    cursor: 'pointer',
                  },
                }}
              >
                <AutoAwesomeIcon
                  sx={{
                    marginRight: '10px',
                  }}
                />
                Request new lead
              </IconButton>
            </RequestNewLeadTooltip>
          </Box>
        )}

        {/* <Typography
          variant='body1'
          sx={{
            marginLeft: 1,
            fontFamily: "'Roboto'",
            fontWeight: 400,
            color: '#1976d2',
            cursor: 'pointer',
            display: leadRequestSuccessful ? 'inline-block' : 'none',
          }}
          gutterBottom
          onClick={() => setOpenLeadRequestDescModal(true)}
        >
          Learn more
        </Typography>

        <Dialog
          open={openLeadRequestDescModal}
          onClose={() => setOpenLeadRequestDescModal(false)}
          fullWidth
          maxWidth='md'
        >
          <DialogContent>
            <DialogContentText>{getHelpModalDescription()}</DialogContentText>
          </DialogContent>
        </Dialog> */}
      </>
    );
  };

  const salsaValidAdminIds = [
    842, 366, 327, 383, 396, 614, 204, 652, 447, 579, 805, 893, 891, 472, 668,
    714, 688, 444, 365, 417, 666, 320, 658, 468, 902, 904, 907, 914, 667, 645,
    766, 276, 386, 678, 681, 758, 769, 829, 866, 896, 870, 890, 892, 905, 913,
    773, 780, 649, 718, 748, 744, 232, 897, 898, 917, 884, 908,
  ];

  // const isCurrentAdminValid = () => {
  //   const currentAdminId = getCurrentAdminId();
  //   return salsaValidAdminIds.includes(currentAdminId);
  // };

  const showSalsaBtn = () => {
    return currentAdminIsIC() && currentAdminProductIsSuper30();
  };

  const getHelpModalDescription = () => {
    let text = [,];

    return (
      <Stack spacing={2}>
        <Box>
          <Typography variant='h5'>Salsa-driven lead assignment</Typography>

          <hr style={{ marginTop: '10px' }} />

          <Typography
            variant='body1'
            component='p'
            my={2}
          >
            Salsa considers more than 12 parameters and selects the BEST
            possible lead available for you to dial. Salsa takes into account
            the source of the lead, their background, past interactions if any
            and how other leads that are similar to this lead have performed and
            many more conditions.
          </Typography>
          <Typography
            variant='body1'
            component='p'
            my={2}
          >
            Leads are distributed across more than 64 ranks with the 1st rank
            leads having the highest chances of getting converted. The leads
            that get assigned to you come from a well-defined algorithm which
            takes into account multiple parameters like lead bucket hygiene and
            the quality of interactions logged. However, the primary factor
            determining the rank of the leads is the number of CAP payments in
            the past 7 days.
          </Typography>
          <Typography variant='h6'>FAQs</Typography>
          <Typography
            variant='h6'
            mt={2}
          >
            How many leads can I request?
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            You are expected to request 7 leads every day. These are the best
            possible leads that the system assigns based on the floor dynamics,
            the market conditions, your strengths and your bucket hygiene. For a
            four week drive, you can get close to 170 leads. In case you are a
            high consumer and need more leads, you can request 5 additional
            leads. For a four week drive, this makes it close to 290 leads. In
            any case, you cannot request more than 12 leads per day.
          </Typography>
          <Typography
            variant='h6'
            mt={2}
          >
            Can I request leads on a non-working day?
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            Yes, you can request leads on a non-working day, a holiday or even
            late at night. The only thing that you need to ensure is that the
            moment a lead is assigned to you, you need to call them in less than
            15 minutes. You are not expected to add an interaction within 15
            minutes if the call is ongoing.
          </Typography>
          <Typography
            variant='h6'
            mt={2}
          >
            How can I get better-ranked leads?
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            Improve your weekly productivity (number of complete amount payments
            in the past seven days). Dial the newly assigned leads within 15
            minutes, add detailed interactions, follow the lead reachout and
            drop policies well. In short, maintain a good bucket hygiene.
          </Typography>
          <Typography
            variant='h6'
            mt={2}
          >
            My colleague got a better-ranked lead than me. Why?
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            Leads are assigned based on several parameters like bucket hygiene,
            productivity of the past 7 days and availability of leads for a
            particular set of ranks in the global pool. Some of these parameters
            are beyond our direct control and depends on the behavior of the end
            user. The best thing to do to ensure that you get the best quality
            leads is to focus on improving your weekly productivity.
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <>
      <Box
        display='flex'
        alignItems='baseline'
        justifyContent='space-between'
      >
        <Box>
          <Typography
            variant='h5'
            component='div'
            gutterBottom
            marginTop='-3%'
          >
            {leads.length === 0 ? 'All Leads' : 'Priority Leads'}
          </Typography>
          {leads.length === 0 && <AllLeadsInfo />}
        </Box>

        <Box
          display='flex'
          alignItems='flex-end'
          flexDirection='column'
        >
          {showSalsaBtn() && salsaDrivenLeadAssignment()}
        </Box>
      </Box>
      <LeadsDataTable
        data={leads.length === 0 ? otherLeads : leads}
        isCalledFromRcb={true}
        currentPage={currentPage}
        refreshResults={() => fetchFocusedLeads()}
        setCurrentPage={setCurrentPage}
        perPage={limit}
        setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
        setColumnFilters={(filter) => setColumnFilters(filter)}
        setSortPriority={setSortPriority}
      />
      <Modal
        open={isSpinner}
        onClose={!isSpinner}
        container={() => document.body}
      >
        <Spinner
          text='Requesting a new lead ......'
          modalStyle={{
            height: '75%',
            width: '65%',
          }}
          textStyle={{
            fontSize: '40px',
          }}
          SpinnerStyle={{
            size: 80,
            thickness: 8,
            color: '#6f00ff',
          }}
        />
      </Modal>
      <Modal
        open={isOpenLeadRequestModal}
        onClose={!isOpenLeadRequestModal}
        container={() => document.body}
      >
        <LeadRequestModal
          requestedLeadData={requestedLeadData}
          isOpen={isOpenLeadRequestModal}
          closeHandler={closeLeadRequestModal}
        />
      </Modal>
    </>
  );
};

export default FocusedModeLeads;
