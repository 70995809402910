import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Logo } from '../../Logo';
import SimpleTimeline from './SimpleTimeLine';
import { useNavigate, Link } from 'react-router-dom';
import ModuleContainer from './ModuleContainer';
import { getCurrentAdminId } from '../../../utils/common';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../Mode';
import { CongratulationComponent } from './CongratulationComponent';
import LeadsToAssignedBySalsa from '../../../pages/ManagerDashboard/SalsaNextAssignment/LeadsToAssignedBySalsa';

const drawerWidth = 0;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    marginTop: '-5px',
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TraineeDashboard({ ComponentProp }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState();
  const [videoLink, setVideoLink] = useState();
  const [videoDriveLink, setVideoDriveLink] = useState();
  const [flowChartLink, setFlowChartLink] = useState();
  const [hasAssignment, setHasAssignment] = useState();
  const [videoTranscript, setVideoTranscript] = useState('');
  const [hasModuleQuestionsCompleted, setHasModuleQuestionsCompleted] =
    useState();
  const [description, setDescription] = useState();
  const navigate = useNavigate();
  const loggedinUserId = getCurrentAdminId();
  const [isMockcallMandatory, setIsMockcallMandatory] = useState();
  const [isAllmodulesCompleted, setIsAllmodulesCompleted] = useState(false);
  const [refreshPage, setRefreshPage] = useState(0);
  const [trnModuleAttemptId, setTrnModuleAttemptId] = useState(0);

  const handleSelectedModule = (
    id,
    video_link,
    name,
    mockcall_description_trainee,
    mockcall_description_interviewer,
    flow_chart_link,
    has_assignment,
    video_transcript,
    is_mockcall_mandatory,
    isModuleQuestionsCompleted,
    video_drive_link
  ) => {
    setSelectedModule(id);
    setVideoLink(video_link);
    setDescription({
      trainee: mockcall_description_trainee,
      interviewer: mockcall_description_interviewer,
    });
    setFlowChartLink(flow_chart_link);
    setHasAssignment(has_assignment);
    setVideoTranscript(video_transcript);
    setIsMockcallMandatory(is_mockcall_mandatory);
    setHasModuleQuestionsCompleted(isModuleQuestionsCompleted);
    setVideoDriveLink(video_drive_link);
  };

  const sendClickDataForTrainee = (action, moduleId) => {
    const url =
      base_url() + `/api/training-modules/${moduleId}/activity-tracking`;
    axiosInstance
      .post(url, {
        adminId: loggedinUserId,
        action,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      {!isAllmodulesCompleted && (
        <SimpleTimeline
          handleSelectedModule={handleSelectedModule}
          setIsAllmodulesCompleted={setIsAllmodulesCompleted}
          refreshPage={refreshPage}
          setTrnModuleAttemptId={setTrnModuleAttemptId}
        />
      )}

      {!isAllmodulesCompleted && (
        <Main open={open}>
          <ModuleContainer
            selectedModule={selectedModule}
            videoLink={videoLink}
            description={description}
            sendClickDataForTrainee={sendClickDataForTrainee}
            flowChartLink={flowChartLink}
            hasAssignment={hasAssignment}
            videoTranscript={videoTranscript}
            hasModuleQuestionsCompleted={hasModuleQuestionsCompleted}
            isMockcallMandatory={isMockcallMandatory}
            setRefreshPage={setRefreshPage}
            trnModuleAttemptId={trnModuleAttemptId}
            videoDriveLink={videoDriveLink}
          />
        </Main>
      )}
      {isAllmodulesCompleted && <CongratulationComponent />}
    </Box>
  );
}
