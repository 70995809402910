import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import Button from '@mui/material/Button';
import { base_url } from '../../../components/Mode';
import { Container } from '@mui/material';
import { marked } from 'marked';

export default function AudioToText({
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
}) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [file, setFile] = useState();

  function fetchFeedback() {
    if (!file) {
      setHasError(true);
      setErrorMessage('Please first select the file.');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    axiosInstance
      .post(`/api/ai_feedback`, formData)
      .then((res) => {
        setText(res.data);
        setLoading(false);
        setIsSuccess(true);
        setSuccessMessage('Feedback generated successfully');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || "Couldn't AI feedback");
      });
  }

  const markdownContent = `${text}`;
  const createMarkup = () => {
    return { __html: marked(markdownContent) };
  };

  const extractOverallAverageRating = (markdown) => {
    const match1 = markdown.match(
      /\*\*Overall Average Rating:\s*(\d+(\.\d+)?)\/10\*\*/
    );
    const match2 = markdown.match(
      /Overall Average Rating\s*=\s*\(([\d+.?]+)\+([\d+.?]+)\+([\d+.?]+)\+([\d+.?]+)\+([\d+.?]+)\)\s*\/\s*5\s*=\s*([\d+.?]+)/
    );
    const match3 = markdown.match(
      /[:\s]*\(\s*([\d+.?\s*]+)\s*\+\s*([\d+.?\s*]+)\s*\+\s*([\d+.?\s*]+)\s*\+\s*([\d+.?\s*]+)\s*\+\s*([\d+.?\s*]+)\s*\)\s*\/\s*5\s*=\s*([\d+.?\s*]+)/
    );

    if (match1) {
      return parseFloat(match1[1]);
    } else if (match2) {
      return parseFloat(match2[6]);
    } else if (match3) {
      return parseFloat(match3[6].replace(/\s/g, '').slice(2));
    } else {
      return null;
    }
  };

  const overallAverageRating = extractOverallAverageRating(markdownContent);

  function bytesToMB(bytes) {
    return bytes / (1024 * 1024);
  }

  const handleFileChange = (e) => {
    const sizeInMB = bytesToMB(e.target.files[0].size);

    if (sizeInMB > 0 && sizeInMB < 100) {
      setFile(e.target.files[0]);
    } else {
      setHasError(true);
      setErrorMessage(
        'Please compresse the file and the size less than 100MB and file types are mp3, mp4, mpeg, mpga, m4a, wav, webm'
      );
    }
  };

  return (
    <>
      <Box width={'100%'}>
        <Typography marginBottom={2}>
          <Button
            variant='contained'
            sx={{ marginRight: '40px', width: '230px' }}
          >
            <input
              type='file'
              id='interaction-files'
              name='interaction-files'
              multiple
              accept='.mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm,.aac'
              onChange={handleFileChange}
            />
          </Button>

          <Button
            variant='contained'
            sx={{ width: '100px' }}
            onClick={() => fetchFeedback()}
          >
            save
          </Button>
        </Typography>
      </Box>

      {loading && (
        <Typography
          sx={{
            marginLeft: '45%',
            marginTop: '10%',
            fontWeight: 'bold',
            fontSize: '20px',
            color: 'blue',
          }}
        >
          {' '}
          Loading...!{' '}
        </Typography>
      )}

      <Container>
        {overallAverageRating !== null && (
          <Box
            sx={{
              boxShadow: 7,
              width: '20rem',
              height: '3rem',
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? 'blue' : 'yellow',
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
              p: 1,
              m: 2,
              borderRadius: 2,
              textAlign: 'center',
              fontSize: '0.875rem',
              fontWeight: '700',
            }}
          >
            <Typography variant='h6'>
              Overall Average Rating: {overallAverageRating}/10
            </Typography>
          </Box>
        )}
      </Container>

      <Container>
        <Typography
          component='div'
          dangerouslySetInnerHTML={createMarkup()}
        />
      </Container>
    </>
  );
}
