import { Grid } from '@mui/material';
import React from 'react';
import PostSalesLeadDetailsSection from '../PostSalesLeadDetailsSection/PostSalesLeadDetailsSection';
// import PostSalesComments from '../PostSalesCommentsSection/PostSalesComments';

function PostSalesModalContent() {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        overflow: 'auto',
        padding: 2,
        paddingRight: 1,
        height: 'fit-content',
      }}
    >
      <Grid
        item
        sx={{
          borderRight: '2px solid grey',
          overflow: 'auto',
          height: '100%',
          width: '100%',
        }}
      >
        <PostSalesLeadDetailsSection />
      </Grid>

      {/* <Grid
        item
        xs={7}
      >
        <PostSalesComments />
      </Grid> */}
    </Grid>
  );
}

export default PostSalesModalContent;
