import React, { useState } from 'react';
import { Typography, Stack, Button, TextField, Box } from '@mui/material';
import axios from 'axios';
import { base_url } from '../../../components/Mode';
import CustomBackdrop from '../../CustomBackdrop';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import AdminManualReshuffleTable from './AdminManualReshuffleTable';
import axiosInstance from 'apis/axiosInstance';

const AdminManualReshuffle = () => {
  const [leadIds, setLeadIds] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [nonReshuffleLeads, setNonReshuffleLeads] = useState([]);
  const [isTableGenerated, setIsTableGenerated] = useState(false);

  const handleShuffleLeads = () => {
    setLoading(true);
    axiosInstance
      .post(`${base_url()}/api/manual_leads_reshuffle`, originalData)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage(res?.data?.message || 'Leads shuffled successfully');
        setIsTableGenerated(false);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An error occurred while shuffling leads.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShuffleLeadsTableGenerate = () => {
    if (!leadIds || leadIds.trim().length === 0) {
      setHasError(true);
      setErrorMessage('Enter lead IDs.');
      return;
    }
    setLoading(true);
    axiosInstance
      .get(`${base_url()}/api/manual_leads_reshuffle`, {
        params: {
          leadIds: leadIds,
        },
      })
      .then((res) => {
        const data = simplifyData(res.data) || [];
        setTableData(data);
        setOriginalData(res.data);
        setIsTableGenerated(true);
      })
      .catch((err) => {
        setNonReshuffleLeads(err.response?.data?.data?.leadIds || []);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An error occurred while showing shuffling leads.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const simplifyData = (data) => {
    try {
      const result = {};
      let serialNumber = 1;

      data?.forEach((entry) => {
        const { lead_id, ic_id, role, fullname } = entry;

        const key = `${ic_id}_${role}`;

        if (!result[key]) {
          result[key] = {
            id: serialNumber++,
            role,
            ic_id: Number(ic_id),
            lead_ids: [],
            fullname: fullname || '-',
          };
        }

        if (!result[key].lead_ids.includes(lead_id)) {
          result[key].lead_ids.push(lead_id);
        }
      });

      return Object.values(result);
    } catch (error) {
      setHasError(true);
      setErrorMessage('Error while arranging data for showing on table');
    }
  };

  const copyLeadsToClipboard = () => {
    if (nonReshuffleLeads.length > 0) {
      const leadsString = nonReshuffleLeads.join(', ');
      navigator.clipboard
        .writeText(leadsString)
        .then(() => {
          setIsSuccess(true);
          setSuccessMessage('Lead IDs copied to clipboard!');
        })
        .catch(() => {
          setHasError(true);
          setErrorMessage('Failed to copy lead IDs.');
        });
    }
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'visible',
          alignSelf: 'center',
        }}
      >
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        {isSuccess && (
          <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
        )}

        <CustomBackdrop open={loading} />

        <Typography
          variant='h4'
          gutterBottom
        >
          Manual Reshuffle
        </Typography>
        <Stack
          spacing={1}
          left={1}
        >
          <TextField
            id='outlined-basic'
            label='Enter comma separated lead IDs here'
            variant='outlined'
            value={leadIds}
            onChange={(e) => setLeadIds(e.currentTarget.value)}
            multiline
            minRows={4}
            maxRows={6}
          />
          <Box sx={{ display: 'flex', justifyContent: 'left' }}>
            <Button
              onClick={handleShuffleLeadsTableGenerate}
              variant='contained'
              sx={{
                width: '15rem',
                marginRight: '10px',
              }}
              disabled={leadIds.length === 0}
            >
              Generate Reshuffle Table
            </Button>
            <Button
              onClick={handleShuffleLeads}
              variant='contained'
              sx={{
                width: '15rem',
                marginRight: '10px',
              }}
              disabled={!isTableGenerated}
            >
              Reshuffle Leads
            </Button>
            {nonReshuffleLeads.length > 0 && (
              <Box>
                <Button
                  onClick={copyLeadsToClipboard}
                  variant='outlined'
                  sx={{
                    width: '15rem',
                  }}
                >
                  Copy Non-Reshuffle Leads
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>

      {tableData && tableData.length > 0 && (
        <AdminManualReshuffleTable data={tableData} />
      )}
    </>
  );
};

export default AdminManualReshuffle;
