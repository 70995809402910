import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import CryptoJS from 'crypto-js';
import EncryptionKeyModal from './EncryptionKeyModal';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import SalaryFilters from './SalaryFilters';
import { formatDate } from './SalaryTable';
import moment from 'moment';

export const calculateTotalSalary = (entry, incentiveData = null) => {
  const salaryTillDate = calculateTotalSalaryTillDate(entry, incentiveData);
  const dueSalary = calculateDueSalary(entry);

  const totalSalary = +salaryTillDate + +dueSalary;
  return totalSalary ?? 0;
};
const parseSalary = (salary) => {
  // Remove commas from the salary string and then parse it as a float
  return parseInt(salary.replace(/,/g, ''), 10) || 0;
};

export const calculateTotalSalaryTillDate = (entry, incentiveData = null) => {
  let totalSalaryTillDate = 0;

  if (Array.isArray(entry?.salary) && entry.salary.length > 0) {
    for (const salary of entry?.salary) {
      totalSalaryTillDate += parseSalary(salary);
    }
  }

  if (incentiveData && Array.isArray(incentiveData)) {
    const matchingIncentive = incentiveData?.find(
      (item) => item?.email === entry?.email
    );

    if (matchingIncentive && Array.isArray(matchingIncentive?.salary)) {
      for (const salary of matchingIncentive?.salary) {
        totalSalaryTillDate += parseSalary(salary) || 0;
      }
    }
  }

  return totalSalaryTillDate ?? 0;
};

export const calculateDaysTillDate = (targetDate) => {
  const currentDate = new Date();
  const endDate = new Date(targetDate);

  const timeDifference = currentDate - endDate;

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) - 1;

  return daysDifference ?? 0;
};

export const calculateDueSalary = (entry) => {
  const baseYearlySalary = entry?.base_salary;
  const daysFromLastSalary = calculateDaysTillDate(entry?.salary_cleared_till);
  const salaryPerday = baseYearlySalary / 365;
  const dueSalary = (salaryPerday * daysFromLastSalary).toFixed(0);

  return dueSalary ?? 0;
};

export const calculateSalaryToRevenuePercent = (data, incentiveData = null) => {
  const totalSalary = calculateTotalSalary(data, incentiveData) ?? 0;

  const totalRevenue = data?.total_revenue ?? 0;
  let percent;

  if (
    (totalSalary || totalSalary === 0) &&
    (totalRevenue || totalRevenue === 0)
  ) {
    percent = (totalSalary / totalRevenue) * 100;

    if (percent) {
      return percent === Infinity ? 100 : percent.toFixed(2);
    } else {
      return 100;
    }
  }
};

export const getTenureDuration = (date) => {
  const startDate = moment(date);
  const currentDate = moment();

  const years = currentDate.diff(startDate, 'years');
  startDate.add(years, 'years');

  const months = currentDate.diff(startDate, 'months');

  if (years === 0) {
    return `${months} month${months !== 1 ? 's' : ''}`;
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ${months} month${
      months !== 1 ? 's' : ''
    }`;
  }
};

const SalesPerformanceTable = ({ setHasError, setErrorMessage }) => {
  const [encryptionKey, setEncryptionKey] = useState('');
  const [encryptionKeyError, setEncryptionKeyError] = useState('');
  const [showKey, setShowKey] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [isPerformance, setIsPerformance] = useState(true);
  const [sliderValue, setSliderValue] = useState(-20);
  const [salaryData, setSalaryData] = useState([]);
  const [incentiveData, setIncentiveData] = useState([]);

  useEffect(() => {
    handleShowSalary();
  }, []);

  const fetchPerformanceData = (url) => {
    setFetchingData(true);
    axiosInstance
      .get(`${url ? url : '/api/sales_performance'}`)
      .then((res) => {
        const salaryData = res?.data?.salaryData;
        const incentiveData = res?.data?.incentiveData;

        salaryData.length === 0 && incentiveData.length === 0
          ? setIsAccess(true)
          : setIsAccess(false);
        setSalaryData(salaryData);
        setIncentiveData(incentiveData);
        handleShowSalary(salaryData, incentiveData);
        setFetchingData(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error in fetching revenue data');
        setFetchingData(false);
      });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 185,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'total_revenue',
      headerName: 'Total Revenue (Rs)',
      width: 155,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'salaryTillDate',
      headerName: 'Salary Till Date (Rs)',
      align: 'center',
      headerAlign: 'center',
      width: 155,
      sortable: true,
      valueGetter: (params) => {
        return calculateTotalSalaryTillDate(params.row, incentiveData);
      },
      renderCell: (params) => {
        const salaryTillDate = calculateTotalSalaryTillDate(
          params.row,
          incentiveData
        );

        return (
          <span>{salaryTillDate ? salaryTillDate.toLocaleString() : '0'}</span>
        );
      },
    },
    {
      field: 'salaryClearedTill',
      headerName: 'Salary Cleared Till ',
      align: 'center',
      headerAlign: 'center',
      width: 155,
      renderCell: (params) => {
        return <span>{formatDate(params?.row?.salary_cleared_till) ?? 0}</span>;
      },
    },

    {
      field: 'dueSalary',
      headerName: 'Due Salary (Rs)',
      align: 'center',
      headerAlign: 'center',
      width: 135,
      sortable: true,
      valueGetter: (params) => {
        const dueSalary = calculateDueSalary(params?.row);
        return dueSalary && !isNaN(dueSalary) ? dueSalary : 0;
      },
      renderCell: (params) => {
        const dueSalary = calculateDueSalary(params?.row);
        return <span>{dueSalary && !isNaN(dueSalary) ? dueSalary : 0}</span>;
      },
    },

    {
      field: 'totalSalary',
      headerName: 'Total Salary (Rs)',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      valueGetter: (params) => calculateTotalSalary(params?.row, incentiveData),
      renderCell: (params) => {
        const totalSalary = params?.value;
        return totalSalary ? <span>{totalSalary}</span> : <span>0</span>;
      },
    },
    {
      field: 'salaryToRevenue',
      headerName: 'Salary To Revenue (%)',
      align: 'center',
      headerAlign: 'center',

      width: 175,
      valueGetter: (params) => {
        const percent = calculateSalaryToRevenuePercent(
          params?.row,
          incentiveData
        );
        return parseFloat(percent);
      },
      renderCell: (params) => {
        const salaryToRevenuePercent = params?.value;
        return (
          <Typography
            variant='body1'
            style={{
              color: salaryToRevenuePercent > 10 ? 'red' : 'inherit',
              marginLeft: '4px',
            }}
          >
            {salaryToRevenuePercent}
          </Typography>
        );
      },
    },
    {
      field: 'tenure',
      headerName: 'Tenure',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        const tenure = getTenureDuration(params?.row?.admin_created);

        return tenure || '-';
      },
    },
  ];

  const decryptField = (field) => {
    try {
      if (Array.isArray(field)) {
        return field.map((item) => {
          if (typeof item === 'string' && item.startsWith('U2FsdGVkX')) {
            const decryptedValue = CryptoJS.AES.decrypt(
              item,
              encryptionKey
            ).toString(CryptoJS.enc.Utf8);
            setIsAccess(true);
            setEncryptionKeyError('');

            if (decryptedValue === '') {
              setEncryptionKeyError('Wrong encryption key entered');
              setEncryptionKeyError('');
              setHasError(true);
              setErrorMessage('Wrong encryption key entered');
              setIsAccess(false);
              return item;
            } else {
              return decryptedValue;
            }
          }
          return item;
        });
      } else if (typeof field === 'string' && field.startsWith('U2FsdGVkX')) {
        const decryptedValue = CryptoJS.AES.decrypt(
          field,
          encryptionKey
        ).toString(CryptoJS.enc.Utf8);
        setIsAccess(true);
        setEncryptionKeyError('');

        if (decryptedValue === '') {
          setEncryptionKeyError('Wrong encryption key entered');
          setEncryptionKeyError('');
          setHasError(true);
          setErrorMessage('Wrong encryption key entered');
          setIsAccess(false);
          return field;
        } else {
          return decryptedValue;
        }
      }
      return field;
    } catch (error) {
      console.error('Decryption error:', error);
      setEncryptionKeyError('Wrong encryption key entered');
      setHasError(true);
      setErrorMessage('Wrong encryption key entered');
      setIsAccess(false);
      return field;
    }
  };

  const handleShowSalary = (
    salaryDataFromFilter = null,
    incentiveDataFromFilter = null
  ) => {
    let salaryDataToDecrypt;
    let incentiveDataToDecrypt;
    if (
      Array.isArray(salaryDataFromFilter) &&
      Array.isArray(incentiveDataFromFilter)
    ) {
      salaryDataToDecrypt = salaryDataFromFilter;
      incentiveDataToDecrypt = incentiveDataFromFilter;
    } else {
      salaryDataToDecrypt = salaryData;
      incentiveDataToDecrypt = incentiveData;
    }
    if (
      Array.isArray(incentiveDataToDecrypt) &&
      Array.isArray(salaryDataToDecrypt) &&
      incentiveDataToDecrypt?.length === 0 &&
      salaryDataToDecrypt?.length === 0
    ) {
      setEncryptionKeyError('');
    }

    if (!encryptionKey) {
      setEncryptionKeyError('Encryption key is required');
      return;
    }
    if (encryptionKey.includes(' ')) {
      setEncryptionKeyError('Encryption key should not contain spaces');
      return;
    }

    const decryptedSalaryData = salaryDataToDecrypt?.map((entry) => {
      return {
        ...entry,
        base_salary: decryptField(entry?.base_salary),
        salary: decryptField(entry?.salary),
      };
    });

    const decryptedIncentiveData = incentiveDataToDecrypt?.map((entry) => {
      return {
        ...entry,
        base_salary: decryptField(entry?.base_salary),
        salary: decryptField(entry?.salary),
      };
    });

    setIncentiveData(decryptedIncentiveData);

    if (sliderValue !== -20) {
      const revenueFilterData =
        filterDataForRevenuePercent(decryptedSalaryData);
      setSalaryData(revenueFilterData);
    } else {
      setSalaryData(decryptedSalaryData);
    }
  };

  const filterDataForRevenuePercent = (data) => {
    const result = [];
    const filtredEntries = data?.map((entry) => {
      const revenueToSalaryPercent = calculateSalaryToRevenuePercent(
        entry,
        incentiveData
      );

      if (sliderValue < 0 && revenueToSalaryPercent < 0) {
        result.push(entry);
      }

      if (sliderValue > 100 && revenueToSalaryPercent > 100) {
        result.push(entry);
      }

      if (
        sliderValue >= 0 &&
        sliderValue <= 100 &&
        revenueToSalaryPercent >= 0 &&
        revenueToSalaryPercent <= 100
      ) {
        if (revenueToSalaryPercent <= sliderValue) result.push(entry);
      }
    });
    return result;
  };

  return (
    <>
      <SalaryFilters
        setSliderValue={setSliderValue}
        sliderValue={sliderValue}
        setHasError={setHasError}
        setErrorMessage={setErrorMessage}
        fetchingData={fetchingData}
        fetchSalaryData={fetchPerformanceData}
        isPerformance={isPerformance}
      />

      <div style={{ height: 500, width: '100%' }}>
        <Box sx={{ height: 500, width: '100%' }}>
          {salaryData?.length > 0 && isAccess ? (
            <DataGrid
              rows={salaryData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
              }}
            >
              No Salary Data
            </div>
          )}
        </Box>
      </div>
      <EncryptionKeyModal
        setEncryptionKey={setEncryptionKey}
        setEncryptionKeyError={setEncryptionKeyError}
        bulkUploadSalary={handleShowSalary}
        encryptionKey={encryptionKey}
        encryptionKeyError={encryptionKeyError}
        setShowKey={setShowKey}
        showKey={showKey}
        decrypt={true}
      />
    </>
  );
};

export default SalesPerformanceTable;
