import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CopyIcon } from '../../../utils/common';
import { base_url } from '../../../components/Mode';

export default function DroppedLeads({ setHasError, setErrorMessage }) {
  const [overview, setOverview] = useState([]);
  const [perPage, setPerPage] = useState(50);

  useEffect(() => {
    fetchDroppedLeads();
    return () => {};
  }, []);

  function fetchDroppedLeads() {
    axiosInstance
      .get(`/api/admins/summary?type=archived_leads`)
      .then((res) => {
        const tmpOverview = [];
        Object.keys(res.data).forEach((id) => {
          if (!('archivedLeadIds' in res.data[id])) {
            res.data[id]['archivedLeadIds'] = [];
          }
          tmpOverview.push(res.data[id]);
        });
        setOverview(tmpOverview);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch assignee overview"
        );
      });
  }

  const columns = [
    {
      field: 'id',
      headerName: 'S.No',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      valueGetter: (params) => params.row.id,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => params.row.name,
    },
    {
      field: 'archived_lead_count',
      headerName: 'Dropped leads count',
      width: 170,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
      valueGetter: (params) => params.row.archivedLeadIds.length,
    },
    {
      field: 'action',
      headerName: 'Copy dropped lead ids',
      width: 190,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        let ids = [];
        if (params.row.archivedLeadIds.length > 0) {
          ids = params.row.archivedLeadIds
            .map((id) => id.toString())
            .join(', ');
        }
        return CopyIcon(ids, true);
      },
    },
  ];

  return (
    <Box width={800}>
      {overview.length > 0 && (
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          rows={overview}
          columns={columns}
          pageSize={perPage}
          rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
          onPageSizeChange={(page) => setPerPage(page)}
          pagination
          autoHeight
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#e3f2fd',
            },
            '& .MuiDataGrid-cell': {
              paddingLeft: '25px',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              paddingLeft: '20px',
            },
          }}
        />
      )}

      {overview.length === 0 && (
        <Typography
          variant='body1'
          alignSelf='center'
        >
          No data found
        </Typography>
      )}
    </Box>
  );
}
