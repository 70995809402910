import { createContext, useCallback, useContext, useState } from 'react';
import { base_url } from '../Mode';
import axiosInstance from 'apis/axiosInstance';
import CustomBackdrop from '../../pages/CustomBackdrop';

const CurrentCandidateContext = createContext();

export const CurrentCandidateDataProvider = ({ children }) => {
  const [candidateData, setCandidateData] = useState([]);
  const [interactionData, setInteractionData] = useState([]);
  const [currentCandidateActivities, setCurrentCandidateActivities] = useState(
    []
  );
  const [candidateInterviews, setCandidateInterviews] = useState([]);

  const [loading, setLoading] = useState(false);

  const getCurrentLeadData = (candidateId) => {
    getCurrentCandidateAndUpdateContext(candidateId);
    getCurrentCandidateActivities(candidateId);
    getCurrentCandidateInterviewsAndUpdateContext(candidateId);
  };

  const getCurrentCandidateAndUpdateContext = useCallback(
    (candidateId) => {
      setLoading(true);
      const url = base_url() + '/api/hrms/hrms_candidates/' + candidateId;
      axiosInstance
        .get(url)
        .then((res) => {
          setCandidateData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [setCandidateData]
  );

  const getCurrentCandidateInterviewsAndUpdateContext = useCallback(
    (candidateId) => {
      setLoading(true);
      const url = base_url() + '/api/hrms/hrms_interviews/' + candidateId;
      axiosInstance
        .get(url)
        .then((res) => {
          setCandidateInterviews(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [setCandidateInterviews]
  );

  const getCurrentCandidateActivities = useCallback(
    (candidateId) => {
      setLoading(true);
      const url = `/api/hrms/hrms_candidates/${candidateId}/candidate_activites`;

      axiosInstance
        .get(url)
        .then((res) => {
          setCurrentCandidateActivities(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log('Error: ' + err.message);
        });
    },
    [setCurrentCandidateActivities]
  );

  return (
    <CurrentCandidateContext.Provider
      value={{
        candidateData,
        setCandidateData,
        candidateInterviews,
        setCandidateInterviews,
        interactionData,
        setInteractionData,
        getCurrentCandidateAndUpdateContext,
        getCurrentLeadData,
        getCurrentCandidateActivities,
        currentCandidateActivities,
        getCurrentCandidateInterviewsAndUpdateContext,
      }}
    >
      <CustomBackdrop open={loading} />
      {children}
    </CurrentCandidateContext.Provider>
  );
};

export const useCurrentCandidateContext = () => {
  return useContext(CurrentCandidateContext);
};
