import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { List, ListItem } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../../Mode';
import { getCurrentAdminId } from '../../../../../utils/common';
import SubmissionsTable from './SubmissionsTable';
import ResultModal from './ResultModal';
import ErrorNotifier from '../../../../ToastNotifications/ErrorNotifier';
import Dialog from '@mui/material/Dialog';
import MockcallSubmission from './MockcallSubmission';
import SuccessNotifier from '../../../../ToastNotifications/SuccessNotifier';

const MockcallRequest = ({
  selectedModule,
  setIsAccepted,
  setIsMockcallRequested,
  handleMockCallRequest,
  isLoading,
  description,
}) => {
  const [submissions, setSubmissions] = useState([]);
  const [reAttempt, setReAttempt] = useState(false);
  const [isOpenResultModal, setIsOpenResultModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [submissionId, setSubmissionId] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [uploads, setUploads] = useState([]);

  const instructions = [
    {
      text: 'Ensure you’re fully prepared before requesting the mock call.',
    },
    {
      text: 'Mock call requests cannot be cancelled once you click on the button.',
    },
    {
      text: 'Please wait for some time after sending the request, as it can take a while.',
    },
    {
      text: 'You’ll be given a scenario, and you have to communicate keeping that in mind.',
    },
    {
      text: 'Make sure to keep this pointer in mind: Dream-Selling, Need Generation.',
    },
    {
      text: 'Use this opportunity to practice effective communication and problem-solving under time constraints.',
    },
    {
      text: "Once you're ready to proceed, click the 'Request a Mock Call' button below to schedule your session.",
    },
  ];

  useEffect(() => {
    handleFetchTraineeAttempts();
  }, [selectedModule, uploads]);

  const handleFetchTraineeAttempts = async () => {
    const id = getCurrentAdminId();
    const url = base_url() + `/api/training-mockcalls/trainee-attempts/${id}`;
    await axiosInstance
      .get(url, {
        params: { moduleId: selectedModule },
      })
      .then((res) => {
        setSubmissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Paper
      elevation={3}
      style={{
        padding: '15px',
        borderRadius: '7px',
        marginTop: '15px',
        height: '75vh',
      }}
    >
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {isUploadModalOpen && (
        <MockcallSubmission
          isUploadModalOpen={isUploadModalOpen}
          setIsUploadModalOpen={setIsUploadModalOpen}
          submissionId={submissionId}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setUploads={setUploads}
        />
      )}
      {!reAttempt && submissions.length > 0 ? (
        <>
          {isOpenResultModal && (
            <ResultModal
              isOpenResultModal={isOpenResultModal}
              setIsOpenResultModal={setIsOpenResultModal}
              selectedRequest={selectedRequest}
            />
          )}
          <Box
            display='flex'
            justifyContent='flex-end'
            mb={1}
          >
            <Button
              style={{ backgroundColor: '#3994FF' }}
              variant='contained'
              onClick={() => {
                setReAttempt(true);
              }}
            >
              Reattempt
            </Button>
          </Box>
          <SubmissionsTable
            submissions={submissions}
            setIsOpenResultModal={setIsOpenResultModal}
            setSelectedRequest={setSelectedRequest}
            setIsUploadModalOpen={setIsUploadModalOpen}
            setSubmissionId={setSubmissionId}
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <Dialog
              open={isLoading}
              maxWidth='lg'
            >
              <Box
                sx={{ textAlign: 'center' }}
                style={{ minWidth: '450px' }}
                pt={3}
                pb={5}
              >
                <Box marginTop={2}>
                  <Typography variant='h5'>Please wait here...</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                  }}
                >
                  <CircularProgress />
                </Box>
                <Box marginTop={2}>
                  <Typography>Your request has been sent.</Typography>
                  <Typography>You'll soon be receiving a mockcall.</Typography>
                </Box>
              </Box>
            </Dialog>
          ) : (
            <Box>
              <Box
                display='flex'
                justifyContent='space-between'
              >
                <Typography variant='h6'>Instructions</Typography>
                {submissions.length !== 0 && (
                  <Box>
                    <Button
                      style={{ backgroundColor: '#3994FF' }}
                      variant='contained'
                      onClick={() => {
                        setReAttempt(false);
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                )}
              </Box>
              {/* <List>
                {instructions.map((point, index) => (
                  <ListItem key={index}>
                    <Typography>- {point.text}</Typography>
                  </ListItem>
                ))}
              </List> */}
              <Typography variant='body'>{description?.trainee}</Typography>
              <Box
                mt={2}
                style={{
                  display: 'flex',
                }}
              >
                <Button
                  style={{ backgroundColor: '#3994FF' }}
                  variant='contained'
                  onClick={() => {
                    setIsMockcallRequested(true);
                    handleMockCallRequest();
                  }}
                >
                  Request a mock call
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};

export default MockcallRequest;
