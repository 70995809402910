const constants = {
  INDEPENDENT_CONTRIBUTOR: 'independent_contributor',
  MANAGER: 'lead',
  SENIOR_MANAGER: 'senior_manager',
  ADMIN: 'admin',
  PROGRAM_MANAGER: 'program_manager',
  MARKETING: 'marketing',
  FINANCE: 'finance',
  HR: 'hr',
  TA: 'talent_acquisition',
  INTERVIEWER: 'interviewer',
  TRAINEE: 'trainee',
  TRAINER: 'trainer',
  FULL: 'full',
  RESTRICTED: 'restricted',
  BASIC: 'basic',
  ACCOUNTANT: 'accountant',
  SENIOR_MARKETING_MANAGER: 'senior_marketing_manager',
  ALLOWED: 'allowed',
  DISABLED: 'disabled',
  SALES1_HOST_NAME: 'sales1.heycoach.in',
  SALES_HOST_NAME: 'sales.heycoach.in',
  SALES2_HOST_NAME: 'sales2.heycoach.in',
  SALES3_HOST_NAME: 'sales3.heycoach.in',
  SALSA_LIMIT_SETTING_KEY: 'request_lead_limit_for_salsa',
  SALSA_STATUS_SETTING_KEY: 'salsa_status',
  POOLING_ACCESS_KEY: 'id_for_pooling_lead',

  NEW_INTERACTION: 'interaction_update',

  CREATED: 'created',
  ASSIGNED: 'assigned',
  CONNECT_ATTEMPTED: 'connect_attempted',
  LEAD_QUALIFICATION_DONE: 'lead_qualification_done',
  COMPLETE_PROGRAM_EXPLAINED: 'complete_program_explained',
  ONE_STEP_TO_PAYMENT: 'one_step_to_payment',
  TOKEN_AMOUNT_PAID: 'token_amount_paid',
  COMPLETE_AMOUNT_PAID: 'complete_amount_paid',
  REACTIVATED: 'reactivated',
  DROPPED: 'dropped',
  DROPPED_POST_SALES_REFUND_INITIATED: 'dropped_post_sales_refund_initiated',
  DROPPED_POST_SALES_REFUND_DENIED: 'dropped_post_sales_refund_denied',
  PAYMENT_LINK_SENT: 'payment_link_sent',
  NEED_GENERATION_DONE: 'need_generation_done',
  DREAM_SELLING_DONE: 'dream_selling_done',
  QUESTIONING_IN_TECH_DONE: 'questioning_in_tech_done',
  ELIGIBILITY_FORM_SENT: 'eligibility_form_sent',
  ELIGIBILITY_FORM_FILLED: 'eligibility_form_filled',
  INTENT_CLOSURE_CALL_DONE: 'intent_closure_call_done',
  SCREENING_CALL_SCHEDULED: 'screening_call_scheduled',
  SCREENING_CALL_ATTENDED: 'screening_call_attended',
  OFFER_LETTER_PAYMENT_LINK_SENT: 'offer_letter_payment_link_sent',
  THIRTY_MINUTES: 30,
  WEEKDAY_OFF: 'weekday_off',
  COMPANY_DECLARED_HOLIDAY: 'company_declared_holiday',
  PERSONAL_LEAVE: 'personal_leave',
  PERSONAL_LEAVE_HALF_DAY: 'personal_leave_half_day',

  MY_LEADS_DUE_NOW: 'my_leads_due_now',
  LEADS_NOT_TOUCHED_SINCE_ASSIGNMENT: 'leads_not_touched_since_assignment',
  LEADS_HAVING_SNOOZED_UNTIL_IN_PAST: 'leads_having_snoozed_until_in_past',
  TOTAL_ASSIGNED_LEADS: 'total_assigned_leads',
  TOTAL_INTERACTIONS_IN_PAST_3_HOURS: 'total_interactions_in_past_3_hours',
  FREQUENT_LEADS_DROP: 'frequent_leads_drop',
  SCHEDULED_FOLLOW_UP_IN_10M: 'scheduled_follow_up_in_10m',
  SCHEDULED_FOLLOW_UP_RIGHT_NOW: 'scheduled_follow_up_right_now',
  LEAD_SEARCH_LIMIT_EXCEEDED: 'lead_search_limit_exceeded',
  LEAD_SOURCE_TYPE: 'lead_source_type_update',
  LEAD_CREATED_BY: 'lead_created',
  TRAINING: 'training',

  COMPLETED: 'completed',
  CONVERTED: 'converted',
  IN_PROGRESS: 'in_progress',
  FINANCE_HANDOVER_INITIATED: 'finance_handover_initiated',
  FINANCE_HANDOVER_REJECTED: 'finance_handover_rejected',
  PGM_HANDOVER_INITIATED: 'pgm_handover_initiated',
  PGM_HANDOVER_APPROVED: 'pgm_handover_approved',
  PGM_HANDOVER_REJECTED: 'pgm_handover_rejected',

  EXPECTED_UNIQUE_LEADS: 60,
  EXPECTED_CPE: 4,
  EXPECTED_AAP: 1,
  EXPECTED_OLS: 0,
  EXPECTED_TAP: 0,
  EXPECTED_CAP: 0,

  MISSED_LEAD_NOTIFICATION: 'Oops! A lead slipped by.',

  POOL_LEAD_STATUS_CREATED: 'created',
  POOL_LEAD_STATUS_POOLED: 'pooled',
  POOL_LEAD_STATUS_ASSIGNED: 'assigned',
  LOCAL_STORAGE_UNIQUE_TAB_KEY: 'tab_unique_id',

  ANUP_ID: 206,
  OMAR_ID: 101,
  IC_ID_FOR_ONE_TAB: [447],

  // Scheduling automation constants

  HEYCOACH_TEAM_EMAIL_ID: 'team@heycoach.in',
  ENTER: 'Enter',
  DID_NOT_PICK: 'dnp',
  REQUESTED_CALLBACK: 'rcb',
  EF_NOT_SENT: 'ef_not_sent',
  DNP_NOT_MARKED: 'dnp_not_marked',
  BUCKET_SEARCH_RESTRICTED_USERS: [
    276, // Ujjwal Gupta
    386, // Vidyasree S
    681, // Rafia  Rub
    890, // VIVEK  M
    892, // SAKIVELU AETHESHAMUDDIN
    896, // Sarthak K
    866, // Yamini  Thakre
    905, // Vincent Raj
    913, // MohammadRafiq
    678, // Karthick Raja Murugesan
    780, // Maria Jebin Justin
    758, // Sreejoy Mazumder
    769, // VIJAY TITUS
    829, // Kamlesh Mahajan
  ],

  visibleOne: 0,

  FIXED_HOLIDAY: 'fixed_holiday',
  MAX_ALLOWED_SNOOZE_DAY: 1,
  SNOOZE_INFO: `You can set follow ups for only upto 1 business day`,
  RCB_SNOOZE_INFO: `You can accept call back requests for only upto 1 business day`,

  FOLLOW_UP_TIME_IS_MISSING: 'Follow up time is missing.',
  ALL_LEADS_INFO: `Excellent progress! Now that the priority leads are handled, it's time to give the rest of your leads the same attention.`,

  QUICK_SEARCH: 'quick-search',

  VISIBLE: 'visible',
  VISIBILITY_CHANGE: 'visibilitychange',
};

export default constants;
