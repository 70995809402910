import React, { useState, createContext, useContext, useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import {
  currentAdminIsAdmin,
  currentAdminIsMarketing,
  currentAdminIsHr,
  currentAdminIsManagerOrAdmin,
  currentAdminIsTa,
  currentAdminIsManager,
} from '../utils/common';

const AdminsContext = createContext();

function AdminsComponent({ children }) {
  const [allAdmins, setAllAdmins] = useState([]);
  const [updateAdminsContext, setUpdateAdminsContext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showRecordingColumn, setShowRecordingColumn] = useState(false);
  const [showImportBtn, setShowImportBtn] = useState(false);

  useEffect(() => {
    const fetchAdminsData = async () => {
      setLoading(true);

      try {
        // Fetch all assignees
        const assignees = await getAllAssignees();

        // Get current admin data from local storage
        const currAdmin = JSON.parse(localStorage.getItem('admin'));
        let subordinateAdminIds = new Set(
          assignees,
          currAdmin.subordinate_admin_ids ?? [currAdmin.id]
        );

        if (currentAdminIsManager()) {
          // Filter subordinateAdminIds to only include those present in assignees if current admin is manager
          subordinateAdminIds = new Set(
            [...subordinateAdminIds].filter((id) => assignees.includes(id))
          );
        }

        subordinateAdminIds.add(currAdmin.id);

        // Fetch all admins
        const res = await axiosInstance.get(`/api/admins`);

        const subordinateAdmins = [];
        const enabledAccessAdmins = [];
        const disabledAccessAdmins = [];

        if (currentAdminIsManagerOrAdmin()) {
          setShowRecordingColumn(true);
          setShowImportBtn(true);
        }

        res.data.forEach((admin) => {
          // Separate admins by access type
          if (admin.access_type === 'allowed') {
            enabledAccessAdmins.push(admin);
          } else {
            disabledAccessAdmins.push(admin);
          }

          // Filter based on the current admin role and subordinateAdminIds
          if (
            currentAdminIsAdmin() ||
            currentAdminIsMarketing() ||
            currentAdminIsHr() ||
            currentAdminIsTa() ||
            subordinateAdminIds.has(admin.id)
          ) {
            subordinateAdmins.push(admin);

            // For certain roles, dynamically add to subordinateAdminIds
            if (
              currentAdminIsAdmin() ||
              currentAdminIsHr() ||
              currentAdminIsTa()
            ) {
              subordinateAdminIds.add(admin.id);
            }
          }
        });

        setAllAdmins([...enabledAccessAdmins, ...disabledAccessAdmins]);
        localStorage.setItem('admins', JSON.stringify(subordinateAdmins));

        // Update the current admin's subordinate IDs in local storage
        currAdmin.subordinate_admin_ids = Array.from(subordinateAdminIds);
        localStorage.setItem('admin', JSON.stringify(currAdmin));
      } catch (error) {
        console.error('Error fetching admins data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminsData();
  }, [updateAdminsContext]);

  const getAllAssignees = async () => {
    try {
      const res = await axiosInstance.get(`/api/admins/lead-access-policies`);
      return res.data.data;
    } catch (error) {
      console.error('Error fetching assignees:', error);
      return [];
    }
  };

  return (
    <AdminsContext.Provider
      value={{
        allAdmins,
        setAllAdmins,
        updateAdminsContext,
        setUpdateAdminsContext,
        showRecordingColumn,
        showImportBtn,
        loading,
      }}
    >
      {children}
    </AdminsContext.Provider>
  );
}

export default AdminsComponent;
export const useAllAdmins = () => useContext(AdminsContext);
