import React, { useState, useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../components/Mode.js';
import { hrmsJobRoles } from '../../utils/common';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import { useCurrentCandidateContext } from '../../components/hrms/CurrentCandidateContext.jsx';
import { getTimeZoneFormattedTimestamp } from '../../utils/common';
import constants from '../../utils/constants.js';
import AdminsAutocompleteDropdown from '../../components/admins/AdminAutoComplete';
import { LabelTextFieldWithColorPicker } from '../../components/users/LabelTextFieldWithColorPicker.js';

const HRMSCandidateEditForm = ({
  openCandidateEditFormToggle,
  selectedRowData,
}) => {
  const [editedFields, setEditedFields] = useState({}); // this only contains the fields that we want to update
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [sourceOptions, setSourceOptions] = useState([]);
  const [appliedRolesOptions, setApplicationRolesOptions] = useState([]);
  const [isColorPalleteVisible, setIsColorPalleteVisible] = useState(false);
  const [isFontColorPalleteVisible, setIsFontColorPalleteVisible] =
    useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fetchSourceOptions = async () => {
    try {
      const response = await axiosInstance.get(
        base_url() + '/api/hrms/hrms_sources'
      );
      const data = response.data;
      setSourceOptions(
        data.map((source) => ({ value: source.id, label: source.platform }))
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchAppliedRolesOptions = async () => {
    try {
      const response = await axiosInstance.get(
        base_url() + '/api/hrms/hrms_job_roles'
      );
      const data = response.data;
      setApplicationRolesOptions(
        data.map((role) => ({ value: role.id, label: hrmsJobRoles[role.name] }))
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const {
    candidateData,
    setCandidateData,
    interactionData,
    setInteractionData,
    getCurrentCandidateAndUpdateContext,
  } = useCurrentCandidateContext();

  useEffect(() => {
    getCurrentCandidateAndUpdateContext(selectedRowData.id);
    fetchSourceOptions();
    fetchAppliedRolesOptions();
  }, []);

  const getFieldValue = (key) => {
    if (key in editedFields) {
      return editedFields[key];
    }

    if (key !== 'dropReason') {
      return candidateData[key];
    }
    return '';
  };

  const updateCandidateDetails = (e) => {
    let { name, value } = e.target;

    if (name === 'notify_for_follow_up') {
      value = e.target.checked;
    }
    updateCandidateDetailState(name, value);
  };

  const updateCandidateDetailState = (name, value) => {
    if (candidateData[name] !== value) {
      setEditedFields({ ...editedFields, [name]: value });
    } else {
      const unEditedFeilds = { ...editedFields };
      delete unEditedFeilds[name];
      setEditedFields(unEditedFeilds);
    }
  };

  const submitHandler = () => {
    openCandidateEditFormToggle();
    const postData = { ...editedFields };
    axiosInstance
      .patch(
        base_url() + '/api/hrms/hrms_candidates/' + candidateData.id,
        postData
      )
      .then((res) => {
        getCurrentCandidateAndUpdateContext(selectedRowData.id);
        setEditedFields({});
      });
    fileUploadHandler(candidateData.id);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).map((file) => ({
      name: file.name,
      data: file,
    }));

    const selectedFile = files.length > 0 ? [files[0]] : [];

    setSelectedFiles(selectedFile);
  };

  const fileUploadHandler = async (candidate_id) => {
    if (selectedFiles.length === 0) {
      return;
    }

    const formBody = new FormData();

    selectedFiles.forEach((file) => {
      formBody.append('hrms_candidate_resume', file.data);
    });

    const resumeUrl =
      base_url() +
      '/api/hrms/hrms_candidates/' +
      candidate_id +
      '/hrms_candidate_resumes?context=candidate_resume';

    await axiosInstance
      .post(resumeUrl, formBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Resume Upload Sucessfully');
        setSelectedFiles([]);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Error while uploading resume'
        );
      });
  };

  const updateSnoozeUntil = (schedule) => {
    let date = new Date();
    switch (schedule) {
      case 'today_12pm':
        date.setMinutes(0);
        date.setHours(12);
        break;
      case 'today_3pm':
        date.setMinutes(0);
        date.setHours(15);
        break;
      case 'today_6pm':
        date.setMinutes(0);
        date.setHours(18);
        break;
      case 'today_9pm':
        date.setMinutes(0);
        date.setHours(21);
        break;
      case 'tomorrow_9am':
        date.setMinutes(0);
        date.setDate(date.getDate() + 1);
        date.setHours(9);
        break;
      case 'tomorrow_12pm':
        date.setMinutes(0);
        date.setDate(date.getDate() + 1);
        date.setHours(12);
        break;
      case 'tomorrow_6pm':
        date.setMinutes(0);
        date.setDate(date.getDate() + 1);
        date.setHours(18);
        break;
      case 'tomorrow_9pm':
        date.setMinutes(0);
        date.setDate(date.getDate() + 1);
        date.setHours(21);
        break;
      default:
    }

    updateCandidateDetailState(
      'snoozed_until',
      getTimeZoneFormattedTimestamp(date)
    );
  };

  const updateSnoozeUntilToday = () => {
    const listOfTime = [
      { label: '12 PM', value: 'today_12pm' },
      { label: '3 PM', value: 'today_3pm' },
      { label: '6 PM', value: 'today_6pm' },
      { label: '9 PM', value: 'today_9pm' },
    ];

    return (
      <Box display={'inline-block'}>
        {listOfTime.map((time) => (
          <Button
            key={time.value}
            onClick={() => updateSnoozeUntil(time.value)}
          >
            {time.label}
          </Button>
        ))}
      </Box>
    );
  };
  const updateSnoozeUntilTomorrow = () => {
    const listOfTime = [
      { label: '9 AM', value: 'tomorrow_9am' },
      { label: '12 PM', value: 'tomorrow_12pm' },
      { label: '6 PM', value: 'tomorrow_6pm' },
      { label: '9 PM', value: 'tomorrow_9pm' },
    ];

    return (
      <Box display={'inline-block'}>
        {listOfTime.map((time) => (
          <Button
            key={time.value}
            onClick={() => updateSnoozeUntil(time.value)}
          >
            {time.label}
          </Button>
        ))}
      </Box>
    );
  };

  const shouldShowAssigneeDropdown = () => {
    const { status = '' } = editedFields;
    if (
      status === constants.ASSIGNED &&
      [constants.CREATED, constants.REACTIVATED].includes(candidateData.status)
    ) {
      return true;
    }
    return false;
  };

  const workExperienceMenuItems = () => {
    const menuItems = [];

    for (let years = 0; years <= 15; years += 0.25) {
      const formattedYears = parseFloat(years.toFixed(2));
      const yearPart = Math.floor(years);
      const monthPart = ((years % 1) * 12).toFixed(0);

      const label = `${yearPart} Year${
        yearPart > 1 ? 's' : ''
      } ${`${monthPart} Month${monthPart > 1 ? 's' : ''}`}`;

      menuItems.push(
        <MenuItem
          key={formattedYears}
          value={formattedYears}
        >
          {label}
        </MenuItem>
      );
    }

    return menuItems;
  };

  function getAssigneeDropdown() {
    return (
      <Grid item>
        <AdminsAutocompleteDropdown
          label='Assignee'
          value={getFieldValue('assignee')}
          onChangeHandler={(e, newValue) => {
            e.target.name = 'assignee';
            e.target.value = newValue.value;
            updateCandidateDetails(e);
          }}
          sx={{
            width: 'inherit',
          }}
        />
      </Grid>
    );
  }

  const setLabelColor = (color) => {
    updateCandidateDetailState('label_bg_color', color.hex);
    setIsColorPalleteVisible(false);
  };

  const setLabelFontColor = (color) => {
    updateCandidateDetailState('label_font_color', color.hex);
    setIsFontColorPalleteVisible(false);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Stack
        direction='row-reverse'
        spacing={2}
        sx={{ mr: 2 }}
      >
        <Button
          variant='contained'
          size='medium'
          onClick={openCandidateEditFormToggle}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          size='medium'
          onClick={submitHandler}
          disabled={Object.keys(editedFields).length === 0}
        >
          Update
        </Button>
      </Stack>
      <Stack
        spacing={2}
        direction='column'
        p={2}
      >
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='name'
              label='Name'
              value={getFieldValue('name')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='email1'
              label='Email'
              value={getFieldValue('email1')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='email2'
              label='Alternate  Email'
              value={getFieldValue('email2')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='mobile1'
              label='Mobile'
              value={getFieldValue('mobile1')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='mobile2'
              label='Alternate Mobile'
              value={getFieldValue('mobile2')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='college'
              label='College'
              value={getFieldValue('college')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='branch'
              label='Branch'
              value={getFieldValue('branch')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='graduation_year'
              label='Graduation year'
              value={getFieldValue('graduation_year')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl fullWidth>
            <InputLabel id='experience_label'>Experience</InputLabel>
            <Select
              type='text'
              name='work_experience'
              label='Experience'
              labelId='experience_label'
              value={getFieldValue('work_experience')}
              onChange={updateCandidateDetails}
            >
              {workExperienceMenuItems()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='current_company'
              label='Current Company'
              value={getFieldValue('current_company')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='current_role'
              label='Current Role'
              value={getFieldValue('current_role')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel id='applied_role'>Applied Role</InputLabel>
            <Select
              name='applied_role'
              label='Applied Role'
              labelId='applied_role'
              value={getFieldValue('applied_role')}
              onChange={updateCandidateDetails}
            >
              {appliedRolesOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {getAssigneeDropdown()}
        <Grid item>
          <FormControl fullWidth>
            <Typography>Next follow up</Typography>

            <Typography>Today</Typography>
            {updateSnoozeUntilToday()}

            <Typography>Tomorrow</Typography>
            {updateSnoozeUntilTomorrow()}
            <TextField
              min={getTimeZoneFormattedTimestamp(new Date()).split(0, 16)}
              type='datetime-local'
              name='snoozed_until'
              value={getFieldValue('snoozed_until')}
              onChange={updateCandidateDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <LabelTextFieldWithColorPicker
            label={getFieldValue('label')}
            labelColor={getFieldValue('label_bg_color')}
            labelFontColor={getFieldValue('label_font_color')}
            setLabelColor={setLabelColor}
            setLabelFontColor={setLabelFontColor}
            updateLeadDetails={updateCandidateDetails}
            isColorPalleteVisible={isColorPalleteVisible}
            isFontColorPalleteVisible={isFontColorPalleteVisible}
            setIsColorPalleteVisible={setIsColorPalleteVisible}
            setIsFontColorPalleteVisible={setIsFontColorPalleteVisible}
          />
        </Grid>
        <Grid item>
          <Typography
            marginTop={2}
            fontWeight={'bold'}
            fontSize={'17px'}
            display={'inline-table'}
          >
            Upload Resume :
          </Typography>
          <FormControl fullWidth>
            <TextField
              type='text'
              name='resume_path'
              id='pictures'
              placeholder='Upload resume'
              multiple
              accept='pdf/*'
              onChange={handleFileChange}
              onFocus={(e) => {
                e.target.type = 'file';
              }}
            />
          </FormControl>
        </Grid>
      </Stack>
    </>
  );
};

export default HRMSCandidateEditForm;
