import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { base_url } from '../Mode';
import { useEffect, useState } from 'react';
import axiosInstance from 'apis/axiosInstance';
import {
  List,
  IconButton,
  ListItem,
  ListItemText,
  Checkbox,
  Box,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import NotificationPopup from './NotificationPopup';
import constants from '../../utils/constants';
import { shouldEnforceFocusMode } from '../../utils/common';
import { addRecencyText } from '../../utils/AddRecencyText';
import './bellVibrate.css';

const { SCHEDULED_FOLLOW_UP_IN_10M, SCHEDULED_FOLLOW_UP_RIGHT_NOW } = constants;

const BellNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldOpenNotificationPopup, setShouldOpenNotificationPopup] =
    useState(false);
  const [popupNotifications, setPopupNotifications] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenNotification = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   // if we remove this, then initial notifications will be fetched after 3 minutes.
  //   fetchNotifications();

  //   // polls the server for new notifications every 3 minutes
  //   const interval = setInterval(() => {
  //     fetchNotifications();
  //   }, 3 * 60 * 1000);

  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   // this is used to vibrate the bell icon if there are unacknowledged notifications.
  //   const intervalId = setInterval(() => {
  //     if (unreadNotificationsCount > 0) {
  //       document.getElementById('bell-icon').style.animation =
  //         'vibrate 0.5s infinite';

  //       // stopping vibration after one second.
  //       setTimeout(() => {
  //         document.getElementById('bell-icon').style.animation = 'none';
  //       }, 1000);
  //     }
  //   }, 10 * 1000); // trigger the vibration effect every 10 seconds

  //   return () => clearInterval(intervalId);
  // }, [unreadNotificationsCount]);

  const updateNotification = (id, index) => {
    const tmpNotifcations = [...notifications];
    const body = {
      acknowledged: tmpNotifcations[index].acknowledged_at ? false : true,
    };
    const url = `/api/notifications/${id}`;

    axiosInstance
      .patch(url, body)
      .then((res) => {
        fetchNotifications();
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            "Couldn't acknowledge the notification."
        );
      });
  };

  const handlePopupNotifications = (notifications) => {
    const unReadNotifications = {};
    const notificationsToShow = [
      SCHEDULED_FOLLOW_UP_IN_10M,
      SCHEDULED_FOLLOW_UP_RIGHT_NOW,
    ];

    notifications.forEach((notification, index) => {
      const { acknowledged_at, name } = notification;
      if (!acknowledged_at && notificationsToShow.includes(name)) {
        unReadNotifications[index] = notification;
      }
    });

    setPopupNotifications(unReadNotifications);
    setShouldOpenNotificationPopup(Object.keys(unReadNotifications).length > 0);
  };

  const fetchNotifications = () => {
    const queryParams = [`limit=10`, `offset=0`, `is_acknowledged=0`];

    if (shouldEnforceFocusMode()) {
      queryParams.push(`is_acknowledged=0`);
    }

    const url = `/api/notifications?${queryParams.join('&')}`;
    axiosInstance
      .get(url)
      .then((res) => {
        const unacknowledgedNotifications = [];
        const acknowledgedNotifications = [];

        res.data.data.map((notification) => {
          const { acknowledged_at } = notification;
          acknowledged_at
            ? acknowledgedNotifications.push(notification)
            : unacknowledgedNotifications.push(notification);
        });
        setNotifications([
          ...unacknowledgedNotifications,
          ...acknowledgedNotifications,
        ]);
        setUnreadNotificationsCount(res.data.unread_notifications);
        handlePopupNotifications(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch new notifications.'
        );
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <IconButton
        id='bell-icon'
        aria-describedby={open ? 'simple-popover' : undefined}
        onClick={handleOpenNotification}
      >
        <Badge
          badgeContent={Number(unreadNotificationsCount)}
          color='error'
          fontSize='small'
          sx={{
            position: 'relative',
            top: '2px',
          }}
          showZero={false}
        >
          <NotificationsIcon
            sx={{
              position: 'relative',
              top: '0px',
              fontSize: '27px',
            }}
          />
        </Badge>
      </IconButton>

      <Popover
        id={open ? 'simple-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {notifications.length > 0 ? (
          <List
            sx={{
              width: 'clamp(300px, 50vw, 650px)',
              maxHeight: '40vh',
              overflow: 'auto',
              fontSize: '14px',
              '& .MuiListItem-root': {
                display: 'flex',
                justifyContent: 'space-between',
                '&: hover': {
                  backgroundColor: 'rgb(240, 247, 255)',
                },
                '&:hover .MuiCheckbox-root': {
                  position: 'absolute',
                  display: 'block',
                  right: 0,
                },
              },
            }}
          >
            {notifications?.map((notification, key) => (
              <ListItem
                sx={{
                  margin: '0',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: notification.acknowledged_at && '#F5F5F5',
                }}
              >
                <Box
                  component='div'
                  width='100%'
                  display='flex'
                  justifyContent='flex-end'
                >
                  <Typography
                    variant='span'
                    sx={{
                      display: 'inline-block',
                      color: '#9e9e9e',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                    }}
                  >
                    {addRecencyText(
                      new Date(Date.parse(notification.created_at))
                    )}
                  </Typography>
                </Box>

                <Box
                  display='flex'
                  justifyContent='space-between'
                  width='100%'
                >
                  <ListItemText
                    sx={{
                      display: 'inline-block',
                    }}
                    primary={notification?.notes}
                  />
                  <Checkbox
                    sx={{ display: 'none' }}
                    checked={notification?.acknowledged_at !== null}
                    onChange={() => updateNotification(notification?.id, key)}
                  />
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant='body2'
            component='p'
            textAlign='center'
            p={5}
            width='clamp(300px, 50vw, 650px)'
          >
            No unread notifications
          </Typography>
        )}
        {!shouldEnforceFocusMode() && (
          <Link
            to='/notifications'
            style={{
              textDecoration: 'none',
            }}
            onClick={handleCloseNotification}
          >
            <List
              style={{
                textAlign: 'center',
                backgroundColor: '#1976d2',
                padding: '9px 0',
                cursor: 'pointer',
                color: '#fff',
                fontFamily: 'Lato, sans-serif',
              }}
            >
              Show all notifications
            </List>
          </Link>
        )}
      </Popover>

      {shouldOpenNotificationPopup && (
        <NotificationPopup
          open={shouldOpenNotificationPopup}
          setOpen={setShouldOpenNotificationPopup}
          popupNotifications={popupNotifications}
          setNotifications={setNotifications}
          setPopupNotifications={setPopupNotifications}
          fetchNotifications={fetchNotifications}
        />
      )}
    </>
  );
};

export default BellNotifications;
