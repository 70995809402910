import React, { useState, useEffect } from 'react';
import {
  TextField,
  Stack,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../../components/Mode';
import { getCurrentAdminId } from '../../../../utils/common';
import ErrorNotifier from '../../../../components/ToastNotifications/ErrorNotifier';
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Chart, registerables } from 'chart.js';
import { handleDrawerClose } from '../../../Dashboard';
import 'chartjs-plugin-zoom';
import { sortByFullName } from '../../../../utils/common';

Chart.register(...registerables);
ChartJS.register(LinearScale, BarElement, Title, Tooltip, Legend);

// Function for  creating dotted pattern in chart.js bar
const createDottedPattern = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = 4;
  canvas.height = 4;
  ctx.fillStyle = '#4fc3f7';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, 2, 2);
  return ctx.createPattern(canvas, 'repeat');
};

// For creatiing Button
const GraphButton = ({ onClick }) => {
  const buttonStyle = {
    backgroundColor: '#757575',
    color: 'white',
    borderRadius: '10px',
    cursor: 'pointer',
  };

  return (
    <Button
      onClick={onClick}
      style={buttonStyle}
    >
      Dive Deep
    </Button>
  );
};

// Function for returning highest length for creating data for chart
const getHighestLength = (data) => {
  let highestLength = 0;

  for (const id in data) {
    const periods = data[id];
    const length = periods.length;

    if (length > highestLength) {
      highestLength = length;
    }
  }

  return highestLength;
};

// Function for inserting background bar to chart
export const createBackgroundObject = (labels) => {
  const numberOfEntries = labels?.length;
  const defaultDataEntry = [8, 23];

  const dataEntries = Array.from(
    { length: numberOfEntries },
    () => defaultDataEntry
  );

  return {
    label: 'background',
    data: dataEntries,
    backgroundColor: '#f5f5f5',
    borderRadius: Number.MAX_VALUE,
    borderSkipped: false,
    // barThickness: 40,
    // categoryPercentage: 1,
  };
};

// Function for  Converting raw data into a format suitable for Chart.js representation.
export const createChartData = (data, isActive, id = null) => {
  const highestLength = getHighestLength(data);
  const resultObjects = [];
  const dottedPattern = createDottedPattern();

  for (let i = 0; i < highestLength; i++) {
    const resultObject = {
      data: [],
      // categoryPercentage: 1,
      // barThickness: 40,
    };

    for (const dataId in data) {
      // If is is present filter data according to id
      if (id !== null && parseInt(dataId) !== id) {
        continue;
      }

      const periods = data[dataId];
      const entry = periods[i] || [0, 0];

      if (entry) {
        const startHour = new Date(entry[0]);
        const endHour = new Date(entry[1]);
        const utcOffset = 5.5;
        let startTime, endTime;

        if (!entry.includes(0)) {
          startTime =
            startHour.getUTCHours() +
            startHour.getUTCMinutes() / 60 +
            utcOffset;
          endTime =
            endHour.getUTCHours() + endHour.getUTCMinutes() / 60 + utcOffset;
        } else {
          startTime = startHour.getUTCHours() + startHour.getUTCMinutes() / 60;
          endTime = endHour.getUTCHours() + endHour.getUTCMinutes() / 60;
        }

        // Push the start and end hours into the resultObject data array
        resultObject.data.push([startTime, endTime]);

        if (isActive === 'call') {
          resultObject.label = isActive;
          resultObject.backgroundColor = '#1565c0';
          resultObject.hoverBackgroundColor = '#1565c0';
        }
        if (isActive === 'active') {
          resultObject.label = isActive;
          resultObject.backgroundColor = '#4fc3f7';
          resultObject.hoverBackgroundColor = '#4fc3f7';
          resultObject.backgroundColor = dottedPattern;
          resultObject.hoverBackgroundColor = dottedPattern;
        }
        if (isActive === 'inactive') {
          resultObject.label = isActive;
          resultObject.backgroundColor = '#f44336';
          resultObject.hoverBackgroundColor = '#f44336';
        }
      }
    }
    resultObjects.push(resultObject);
  }
  return resultObjects;
};

// Function for merging complete converted data for chart.js
export const mergeDataSets = (
  activeDataForChart,
  inactiveDataForChart,
  callDataForChart,
  backgroundObject
) => {
  const dataset = [];

  activeDataForChart?.forEach((entry) => {
    dataset.push(entry);
  });

  inactiveDataForChart?.forEach((entry) => {
    dataset.push(entry);
  });

  callDataForChart?.forEach((entry) => {
    dataset.push(entry);
  });

  backgroundObject?.forEach((entry) => {
    dataset.push(entry);
  });

  return dataset;
};

// Function will return  data in activeIdsOrder according to active data
const getDataInActiveOrder = (
  labelData,
  activeData,
  returnLabelData = false
) => {
  const activeIdsOrder = activeData && Object.keys(activeData);

  if (returnLabelData) {
    // Sort labelData based on activeIdsOrder
    const sortedLabelData = activeIdsOrder.map((id) =>
      labelData.find((label) => label?.id === parseInt(id, 10))
    );
    return sortedLabelData.filter((entry) => entry !== undefined);
  }

  const fullNameArray = activeIdsOrder?.map((id) => {
    const labelEntry = labelData?.find(
      (label) => label?.id === parseInt(id, 10)
    );
    return labelEntry ? labelEntry.name : null;
  });

  return fullNameArray;
};

const TeamActivity = ({ tabChange }) => {
  const [chartData, setChartData] = useState(null);
  const [activeData, setActiveData] = useState(null);
  const [callData, setCallData] = useState(null);
  const [inactiveData, setInactiveData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [labelData, setLabelData] = useState(null);
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  const [date, setDate] = useState(formattedCurrentDate);
  const [graphHeight, setGraphHeight] = useState();
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const [adminId, setAdminId] = useState(getCurrentAdminId());

  useEffect(() => {
    if (adminId) {
      fetchActivityData();
      handleDrawerClose();
    } else {
      setHasError(true);
      setErrorMessage('Manager Id require to fetch Team activity data');
    }
  }, [date, adminId]);

  useEffect(() => {
    if (labelData && (activeData || inactiveData || callData)) {
      const namesForChart = getDataInActiveOrder(labelData, activeData);
      const callDataForChart = createChartData(callData, 'call');
      const activeDataForChart = createChartData(activeData, 'active');
      const inactiveDataForChart = createChartData(inactiveData, 'inactive');
      const backGroundObject = createBackgroundObject(namesForChart);
      const mergedDataset = mergeDataSets(
        callDataForChart,
        activeDataForChart,
        inactiveDataForChart,
        [backGroundObject]
      );

      if (mergedDataset && namesForChart) {
        setChartData({ labels: namesForChart, datasets: mergedDataset });
      }
    }
  }, [labelData, activeData, inactiveData]);

  useEffect(() => {
    setGraphHeight(chartData?.labels?.length * 90);
  }, [chartData]);

  useEffect(() => {
    getmanagerDropdown();
  }, [adminId]);

  const fetchActivityData = () => {
    axiosInstance
      .get(`/api/admin-activities?id=${adminId}&date=${date}`)
      .then((res) => {
        setActiveData(res?.data?.active);
        setInactiveData(res?.data?.inactive);
        setCallData(res?.data?.call);
        setLabelData(res?.data?.label);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch activity data.'
        );
        setActiveData(null);
        setInactiveData(null);
        setLabelData(null);
        setChartData(null);
      });
  };

  const getmanagerDropdown = async () => {
    const res = await axiosInstance.get(
      `/api/manager_actions/subordinate_managers`
    );
    setSubordinateAdmins(res.data);
  };

  const managerDropdown = () => {
    return sortByFullName(subordinateAdmins, 'Full_Name').map(
      (manager, index) => {
        return (
          <MenuItem
            value={manager?.Admin_id}
          >{`${manager?.Full_Name}`}</MenuItem>
        );
      }
    );
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  // Function for handling deep diving.
  const handleButtonClick = (label) => {
    let IndividualChartData = {};
    const id = label?.id;
    const name = label?.name;
    const activeDataForChart = createChartData(activeData, 'active', id);
    const inactiveDataForChart = createChartData(inactiveData, 'inactive', id);
    const backGroundObject = createBackgroundObject([name]);
    const mergedDataset = mergeDataSets(
      activeDataForChart,
      inactiveDataForChart,
      [backGroundObject]
    );
    if (mergedDataset && name) {
      IndividualChartData.labels = [date];
      IndividualChartData.datasets = mergedDataset;
    }
    const dataToSend = {
      id: id,
      individualData: IndividualChartData,
      date: date,
    };
    tabChange(null, 3, dataToSend);
  };

  let activeOrderLabelData;
  if (labelData && activeData) {
    activeOrderLabelData = getDataInActiveOrder(labelData, activeData, true);
  }

  const buttonPositions = activeOrderLabelData?.map((label, index) => {
    return { label, position: index * 70 + 35 };
  });

  // Configs for chart.js representation
  const config = {
    onClick: (event, elements) => {
      if (elements?.length > 0) {
        const clickedLabelName = chartData?.labels[elements[0]?.index];

        const clickedLabel = labelData?.filter((label) => {
          return label?.name === clickedLabelName;
        });
        handleButtonClick(clickedLabel?.[0]);
      }
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      tooltip: {
        xAlign: 'left',
        callbacks: {
          label: (context) => {
            const datasetLabel = context.dataset.label;

            const [x1, x2] = context.raw;
            const hr1 = Math.floor(x1) % 24; // Use modulo 24 for 24-hour format
            const hr2 = Math.floor(x2) % 24;
            const min1 = Math.floor((x1 - hr1) * 60);
            const min2 = Math.floor((x2 - hr2) * 60);

            // Convert negative hours to positive for correct calculation
            const hr1Positive = hr1 < 0 ? 24 + hr1 : hr1;
            const hr2Positive = hr2 < 0 ? 24 + hr2 : hr2;

            const inactivityGapInMinutes =
              (hr2Positive - hr1Positive) * 60 + min2 - min1;
            const timeDiffInMinutes =
              inactivityGapInMinutes < 0
                ? 1440 + inactivityGapInMinutes
                : inactivityGapInMinutes;

            // Format minutes and hours for display
            const formattedStartTime = `${hr1Positive % 12 || 12}:${
              min1 < 10 ? `0${min1}` : min1
            } ${hr1Positive >= 12 ? 'PM' : 'AM'}`;
            const formattedEndTime = `${hr2Positive % 12 || 12}:${
              min2 < 10 ? `0${min2}` : min2
            } ${hr2Positive >= 12 ? 'PM' : 'AM'}`;

            if (datasetLabel === 'active') {
              return `${timeDiffInMinutes} minutes Online From ${formattedStartTime} to ${formattedEndTime}`;
            } else if (datasetLabel === 'inactive') {
              return `${timeDiffInMinutes} minutes Offline From ${formattedStartTime} to ${formattedEndTime}`;
            } else if (datasetLabel === 'call') {
              return `${timeDiffInMinutes} minutes on call From ${formattedStartTime} to ${formattedEndTime}`;
            } else if (datasetLabel === 'On Call') {
              return `${timeDiffInMinutes} minutes On Call`;
            }
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: (context) => {
          return context.dataset.label === 'inactive';
        },
        align: 'center',
        anchor: 'center',
        color: '#ffffff',
        font: {
          weight: 'bold',
        },
        formatter: (value, context) => {
          const [start, end] = value;
          const duration = end - start;
          return `${duration} hr`;
        },
      },
    },
    responsive: true,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        // barPercentage: 10,
        grid: {
          color: (context) => {
            const hour = context.tick.value % 24;
            if (hour === 10 || hour === 21) {
              return '#0288d1';
            } else if (hour === 11 || hour === 20) {
              return 'black';
            } else {
              return 'rgba(0, 0, 0, 0.1)';
            }
          },
          borderDash: [3, 3],
        },
        position: 'top',
        min: 8,
        max: 23,
        type: 'linear',
        ticks: {
          stepSize: 1,
          callback: (value, index) => {
            const formattedLabel = `${Math.floor(value) % 12 || 12} ${
              Math.floor(value) >= 12 ? 'PM' : 'AM'
            }`;
            return value >= 8 && value <= 23 ? formattedLabel : '';
          },
          color: (context) => {
            const value = context.tick.value;
            if (value > 21 || value < 10) {
              return '#000000';
            }
            return '#0288d1';
          },
        },
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [5, 5],
        },
        max: 7,
        min: 0,
        ticks: {
          beginAtZero: true,
          color: '#000',
        },
        stacked: true,
      },
    },
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      <div
        style={{
          height: '50px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '140px',
          }}
        >
          <Box
            sx={{
              display: 'flex',

              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                height: 20,
                width: 20,
                border: 'solid 1px',
                backgroundColor: '#4fc3f7',
                mr: 1,
                borderRadius: 1,
              }}
            />
            <span>Active</span>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                height: 20,
                width: 20,
                border: 'solid 1px',
                backgroundColor: '#f44336',
                mr: 1,
                borderRadius: 1,
              }}
            />
            <span>Inactive</span>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                height: 20,
                width: 20,
                border: 'solid 1px',
                backgroundColor: '#1565c0',
                mr: 1,
                borderRadius: 1,
              }}
            />
            <span>On Call</span>
          </Box>
        </div>

        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {subordinateAdmins && subordinateAdmins.length > 0 ? (
            <FormControl sx={{ minWidth: 180 }}>
              <InputLabel
                id='managers-label'
                sx={{
                  marginBottom: 2,
                  paddingRight: 1,
                }}
              >
                Managers
              </InputLabel>
              <Select
                labelId='managers-label'
                id='manager-select'
                value={adminId}
                label='Managers'
                size='small'
                onChange={(e) => {
                  setAdminId(e.target.value);
                }}
              >
                {managerDropdown()}
              </Select>
            </FormControl>
          ) : (
            ''
          )}
          <TextField
            type='date'
            label='Date'
            size='small'
            InputLabelProps={{ shrink: true }}
            onChange={handleDateChange}
            value={date}
          />
        </div>
      </div>

      {chartData &&
      Object.keys(chartData).length !== 0 &&
      chartData.labels &&
      chartData.labels.length > 0 &&
      chartData.datasets &&
      chartData.datasets.length > 0 ? (
        <>
          <Box
            width='inherit'
            sx={{
              height: '100%',
              overflowX: 'auto',
              position: 'relative',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <div
                style={{
                  top: '20%',
                  right: '50px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '30px',
                  alignItems: 'stretch',
                  height: '70%',
                  position: 'absolute',
                }}
              >
                {buttonPositions?.map((button, index) => (
                  <GraphButton
                    key={index}
                    onClick={() => handleButtonClick(button.label)}
                    style={{ top: button.position }}
                  />
                ))}
              </div>
            </div>
            <Box
              sx={{
                width: '80%',
                height: `${graphHeight}px`,
              }}
            >
              <Bar
                options={config}
                data={chartData}
              />
            </Box>
          </Box>
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>Admin Activity Data Not Found</div>
      )}
    </>
  );
};

export default TeamActivity;
