import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import constants from '../../../utils/constants';

const AdminManualReshuffleTable = ({ data }) => {
  const columns = [
    {
      field: 'fullname',
      headerName: 'Assignee Name',
      width: 300,
      renderCell: (params) => {
        const assigneeName = params.row.fullname || '-';
        return assigneeName;
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 200,
      renderCell: (params) => {
        let role;

        switch (params.row.role) {
          case constants.INDEPENDENT_CONTRIBUTOR:
            role = 'IC';
            break;
          case constants.TRAINEE:
            role = 'Trainee';
            break;
          default:
            role = '-';
            break;
        }
        return role;
      },
    },
    {
      field: 'leads_count',
      headerName: 'Count',
      width: 300,
      renderCell: (params) => {
        const leadCount = params.row.lead_ids.length || 0;

        return leadCount;
      },
    },
    {
      field: 'lead_ids',
      headerName: 'Lead IDs',
      width: 300,
      renderCell: (params) => params.value.join(', '),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%', marginTop: '30px' }}>
      <DataGrid
        rows={data}
        columns={columns}
      />
    </div>
  );
};

export default AdminManualReshuffleTable;
