import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { base_url } from '../../../components/Mode';
import { Chip, Stack } from '@mui/material';
import { CopyIcon } from '../../../utils/common';

export function SalsaAssignments({ setHasError, setErrorMessage }) {
  const [salsaAssignments, setSalsaAssignments] = useState([]);
  const [perPage, setPerPage] = useState(100);
  const localStorageAdmins = JSON.parse(localStorage.getItem('admins')) || [];
  console.log(localStorageAdmins);

  useEffect(() => {
    fetchSalsaAssignments();
    return () => {};
  }, []);

  function fetchSalsaAssignments() {
    axiosInstance
      .get(`/api/admins/salsa/assignments`)
      .then((res) => {
        setSalsaAssignments(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch lead ranks"
        );
      });
  }

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 80,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ id, row }) => {
        return [<Typography>{row.id}</Typography>];
      },
    },
    {
      field: 'admin_id',
      headerName: 'Admin',
      width: 280,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ id, row }) => {
        const ad = localStorageAdmins.find((a) => a.id == row.admin_id);
        return [
          <Typography>
            {ad.fname} {ad.lname}
          </Typography>,
        ];
      },
    },
    {
      field: 'leads',
      headerName: 'Number of leads',
      width: 150,
      align: 'right',
      headerAlign: 'left',
      renderCell: ({ id, row }) => {
        return [
          <Typography>
            {row.leads ? row.leads.length : 0}
            {CopyIcon(
              row.leads ? row.leads.join(',') : '',
              false,
              'Copy lead ids'
            )}
          </Typography>,
        ];
      },
    },
  ];

  return (
    <>
      <Box width={580}>
        {salsaAssignments.length > 0 && (
          <DataGrid
            rows={salsaAssignments}
            columns={columns}
            pageSize={perPage}
            rowsPerPageOptions={[50, 100]} // 100 is the max page size in non-pro version
            onPageSizeChange={(page) => setPerPage(page)}
            pagination
            autoHeight
            disableSelectionOnClick
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#e3f2fd',
              },
              '& .MuiDataGrid-cell': {
                paddingLeft: '25px',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                paddingLeft: '20px',
              },
            }}
          />
        )}
      </Box>
      {salsaAssignments.length === 0 && (
        <Typography
          variant='body1'
          alignSelf='center'
        >
          No data found
        </Typography>
      )}
    </>
  );
}
