import { Divider, FormGroup, Typography } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import React, { useEffect, useState } from 'react';
import { base_url } from './../components/Mode';
import LeadsDataTable from './../components/data-table/LeadsDataTable';
import {
  currentAdminIsAdmin,
  currentAdminIsIC,
  currentAdminIsManager,
  currentAdminIsManagerOrAdmin,
  currentAdminIsMarketing,
  currentAdminIsTrainee,
  getCurrentAdminId,
  getDatabaseFormattedDate,
  getOffsetFromPage,
} from '../utils/common';
import {
  Box,
  Input,
  FormControl,
  Stack,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { buildQueryStringFiltersToFetchLeads } from '../components/users/GetLeads';
import ErrorNotifier from '../components/ToastNotifications/ErrorNotifier';
import AdminsSearchDropdown from '../components/admins/AdminSearch';

export function RecentlyUpdated() {
  const getDefaultParams = () => {
    const date_str = getDatabaseFormattedDate(new Date());
    return {
      from: date_str,
      to: date_str,
    };
  };
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(50);
  const [totalLeads, setTotalLeads] = useState(0);
  const [params, setParams] = useState(getDefaultParams());
  const [currentPage, setCurrentPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchData();
  }, [params, currentPage, limit, columnFilters]);

  const fetchData = () => {
    let url = base_url() + '/api/interactions/recent';
    const offset = getOffsetFromPage(currentPage, limit);
    let queryString = buildQueryStringFiltersToFetchLeads(params);
    queryString += `&limit=${limit}`;
    queryString += `&offset=${offset}`;

    url += '?' + queryString;
    if (columnFilters !== '') {
      url += '&columnFilters[]=' + columnFilters;
    }

    axiosInstance
      .get(url)
      .then((res) => {
        if (res['data']) {
          setTableData(res['data']['leads']);
          setTotalLeads(res['data']['total']);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching recent interactions'
        );
      });
  };

  const updateParam = (e, param_name) => {
    let value = e.target.value;
    let paramsClone = Object.assign({}, params);

    if (param_name === 'lead_status') {
      if (Number(value) === 1) {
        param_name = 'status';
        value = 'dropped';
        delete paramsClone['closed_at'];
      } else if (Number(value) === 0) {
        param_name = 'closed_at';
        value = 'null';
        delete paramsClone['status'];
      } else {
        delete paramsClone['closed_at'];
        delete paramsClone['status'];
        value = '';
      }
    }

    if (value === '') {
      delete paramsClone[param_name];
    } else {
      paramsClone[param_name] = value;
    }

    setParams(paramsClone);
  };

  const filters = () => {
    const shouldShowOptionsToSeniorManager = () => {
      if (currentAdminIsManager()) {
        return true;
        //return getCurrentAdminId() === 101 || getCurrentAdminId() === 206; // show drop leads to  Abhishek Omar & Anup CJ
      }
      return !(currentAdminIsIC() || currentAdminIsTrainee());
    };

    const shouldDisableDropdown = () => {
      return (
        !currentAdminIsAdmin() &&
        !currentAdminIsManager() &&
        !currentAdminIsMarketing()
      );
    };

    const shouldShowCurrentAdmin = () => {
      return (
        !currentAdminIsAdmin() &&
        !currentAdminIsManager() &&
        !currentAdminIsMarketing()
      );
    };

    const shouldShowAllOption = () => {
      return currentAdminIsManagerOrAdmin() || currentAdminIsMarketing();
    };

    const handleDropdownChange = (newValue) => {
      let array = newValue.split(',');
      updateParam(
        {
          target: {
            name: 'assignee',
            value: array || [],
          },
        },
        'admin'
      );
    };

    return (
      <>
        <FormGroup
          row
          style={{ float: 'right', marginBottom: '22px', paddingRight: '10px' }}
        >
          <Stack
            direction='row'
            spacing='10px'
            style={{ width: '100%' }}
          >
            <FormControl
              id='from'
              style={{ width: '180px' }}
            >
              <InputLabel id='from-label'>From</InputLabel>
              <Input
                type='text'
                placeholder='From'
                name='from'
                onFocus={(e) => {
                  e.target.type = 'date';
                }}
                onChange={(e) => {
                  updateParam(e, 'from');
                }}
                defaultValue={getDatabaseFormattedDate(new Date())}
              />
            </FormControl>

            <FormControl
              id='to'
              style={{ width: '180px' }}
            >
              <InputLabel id='to-label'>To</InputLabel>
              <Input
                type='text'
                placeholder='To'
                name='to'
                onFocus={(e) => {
                  e.target.type = 'date';
                }}
                defaultValue={getDatabaseFormattedDate(new Date())}
                onChange={(e) => {
                  updateParam(e, 'to');
                }}
              />
            </FormControl>
            {shouldShowOptionsToSeniorManager() && (
              <AdminsSearchDropdown
                label='Assignee'
                value={params.admin}
                onChangeHandler={handleDropdownChange}
                sx={{
                  width: '100%',
                }}
                size='small'
                disabled={shouldDisableDropdown()}
                showCurrentAdmin={shouldShowCurrentAdmin()}
                showAllOption={shouldShowAllOption()}
              />
            )}

            <FormControl
              id='lead_status'
              size='small'
              style={{ width: '180px' }}
            >
              <InputLabel id='admin-label'>Lead status</InputLabel>
              <Select
                label='Lead status'
                labelId='admin-label'
                placeholder='Lead status'
                name='lead_status'
                onChange={(e) => updateParam(e, 'lead_status')}
              >
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value={0}>Open</MenuItem>
                {shouldShowOptionsToSeniorManager() && (
                  <MenuItem value={1}>Dropped</MenuItem>
                )}
              </Select>
            </FormControl>
          </Stack>
          {/* <FormControlLabel control={<Checkbox />} label="Hide Action Required" /> */}
        </FormGroup>
      </>
    );
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Box
        component='div'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography
          variant='h5'
          component='div'
          gutterBottom
          style={{ marginRight: 10 }}
          marginTop='-3%'
        >
          Recent Interactions ({totalLeads})
        </Typography>
      </Box>
      <Divider />
      <br />
      <Box>{filters()}</Box>
      <LeadsDataTable
        data={tableData}
        refreshResults={fetchData}
        totalLeads={totalLeads}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={limit}
        setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
        setColumnFilters={(filter) => setColumnFilters(filter)}
      />
    </>
  );
}
