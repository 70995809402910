import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import SalesHandoverForm from './SalesHandoverForm';
import {
  currentAdminIsFinance,
  currentAdminIsPgm,
} from '../../../../utils/common';
import { Stack } from '@mui/material';
import { useCurrentPostSalesLead } from '../../../CurrentPostSalesLeadContext';
import constants from '../../../../utils/constants';
import { salesHandoverForm } from '../../../../utils/salesHandoverForm';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../Mode';

const {
  COMPLETE_AMOUNT_PAID,
  FINANCE_HANDOVER_INITIATED,
  IN_PROGRESS,
  FINANCE_HANDOVER_REJECTED,
  PGM_HANDOVER_REJECTED,
} = constants;

// client/src/utils/salesHandoverForm.js
const RESUME_QUESTION_ID = 14;

const hasAllMandatoryFields = (editedForm) => {
  if (
    (editedForm[6]?.value === 'Personalised Coaching' ||
      editedForm[6]?.value === 'Program management personal coaching') &&
    !(7 in editedForm)
  ) {
    return false;
  }

  if (
    editedForm[6]?.value === 'Super 30' &&
    (editedForm[17]?.value ?? '') === ''
  ) {
    return false;
  }

  if (
    (editedForm[11]?.value ?? '') !== '' &&
    ((editedForm[12]?.value ?? '') === '' ||
      (editedForm[13]?.value ?? '') === '')
  ) {
    return false;
  }

  return true;
};

const SalesHandoverTab = ({ setHandleProfileEditClose }) => {
  const { leadData, setLeadData, setToastNotificationMessage } =
    useCurrentPostSalesLead();
  const [rawForm, setRawForm] = useState(salesHandoverForm);
  const [editedForm, setEditedForm] = useState({});
  const [resume, setResume] = useState({});
  const [hasUpdate, setHasUpdate] = useState(false);

  useEffect(() => {
    prefillForm(leadData?.sales_handover ?? []);
    return () => {};
  }, [leadData]);

  const prefillForm = (savedResponse) => {
    const tmp = {};
    savedResponse.forEach((response) => {
      tmp[response.id] = {
        ...response,
      };
    });
    setEditedForm(tmp);
    setHasUpdate(false);
  };

  const resetStates = () => {
    setResume({});
  };

  const uploadResume = async (body) => {
    const formBody = new FormData();
    formBody.append('resume_sales_handover', resume.data);
    const { sales_handover } = leadData;
    let index = -1;
    if (sales_handover) {
      index = sales_handover.findIndex(
        (item) => item.id === RESUME_QUESTION_ID
      );
    }
    const delete_old =
      index !== -1 ? (sales_handover[index].value !== '' ? 1 : 0) : 0;
    try {
      const res = await axiosInstance.post(
        base_url() +
          `/api/post-sales-leads/${leadData.id}/sales-handover/file?context=resume&delete_old=${delete_old}`,
        formBody,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const { link = null } = res.data;
      if (link) {
        body[RESUME_QUESTION_ID] = {
          ...rawForm[RESUME_QUESTION_ID],
          value: `${link}`,
        };
      }
      return true;
    } catch (err) {
      console.log(err);
      setToastNotificationMessage(
        'error',
        'An error occured while uploading the resume file.'
      );
      return false;
    }
  };

  const handleSave = async () => {
    if (
      Object.keys(editedForm).length === 0 &&
      Object.keys(resume).length === 0
    ) {
      return;
    }

    const form = {
      ...editedForm,
    };

    Object.keys(editedForm).forEach((id) => {
      if ((editedForm[id] ?? '') === '') {
        delete form[id];
      }
    });

    if (!hasAllMandatoryFields(form)) {
      setToastNotificationMessage(
        'error',
        'One or more mandatory fields are missing.'
      );
      return;
    }

    if (Object.keys(resume).length > 0) {
      const ok = await uploadResume(form);
      if (!ok) {
        return;
      }
    }

    const body = [];

    Object.keys(form).forEach((id) => {
      body.push(form[id]);
    });

    const content = {
      sales_handover: JSON.stringify(body),
    };

    axiosInstance
      .patch(`/api/post-sales-leads/${leadData.id}/sales-handover`, content)
      .then((res) => {
        setLeadData((prev) => ({
          ...prev,
          sales_handover: body,
        }));

        prefillForm(body);
        resetStates();
        setHandleProfileEditClose(false);
        setToastNotificationMessage(
          'success',
          'Form updates successfully saved'
        );
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          'An error occured while saving the form updates'
        );
      });
  };

  const handleStatusUpdate = (status) => {
    const form = {
      ...editedForm,
    };

    Object.keys(editedForm).forEach((id) => {
      if ((editedForm[id] ?? '') === '') {
        delete form[id];
      }
    });

    if (
      Object.keys(form).length === 0 &&
      status !== FINANCE_HANDOVER_REJECTED
    ) {
      setToastNotificationMessage('error', 'Form cannot be empty');
      return;
    }

    axiosInstance
      .patch(`/api/post-sales-leads/${leadData.id}`, {
        status,
      })
      .then((res) => {
        setLeadData((prev) => ({
          ...prev,
          status: res.data.status,
        }));

        setToastNotificationMessage('success', 'Status updated successfully');
      })
      .catch((err) => {
        console.log(err);
        setToastNotificationMessage(
          'error',
          err.response?.data?.message ||
            'An error ocurred while updating the status'
        );
      });
  };

  const shouldShowInitiateHandover = () => {
    if (currentAdminIsFinance() || currentAdminIsPgm()) {
      return false;
    }
    if (Object.keys(editedForm).length === 0) {
      return false;
    }
    if (leadData.status === FINANCE_HANDOVER_REJECTED) {
      return true;
    }

    if (
      leadData.status === IN_PROGRESS &&
      leadData.lead_status === COMPLETE_AMOUNT_PAID
    ) {
      return true;
    }
    return false;
  };

  const shouldShowRejectHandover = () => {
    if (!currentAdminIsFinance()) {
      return false;
    }
    if (leadData.status === FINANCE_HANDOVER_INITIATED) {
      return true;
    }
    return false;
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        spacing={1}
      >
        <Button
          variant='contained'
          size='small'
          onClick={handleSave}
          disabled={
            !hasUpdate ||
            (Object.keys(editedForm).length === 0 &&
              Object.keys(resume).length === 0) ||
            currentAdminIsFinance() ||
            currentAdminIsPgm()
          }
        >
          Save
        </Button>

        <Button
          variant='outlined'
          size='small'
          onClick={(e) => {
            prefillForm(leadData?.sales_handover ?? []);
            setHandleProfileEditClose(false);
          }}
          color='error'
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          size='small'
          sx={{
            display: !shouldShowInitiateHandover() && 'none',
          }}
          onClick={(e) => handleStatusUpdate('finance_handover_initiated')}
        >
          Initiate Handover
        </Button>

        <Button
          variant='contained'
          size='small'
          value='finance_handover_rejected'
          sx={{
            display: !shouldShowRejectHandover() && 'none',
          }}
          onClick={(e) => handleStatusUpdate('finance_handover_rejected')}
        >
          Reject Handover
        </Button>
      </Stack>

      <SalesHandoverForm
        resume={resume}
        setResume={setResume}
        rawForm={rawForm}
        editedForm={editedForm}
        setEditedForm={setEditedForm}
        setHasUpdate={setHasUpdate}
        setToastNotificationMessage={setToastNotificationMessage}
      />
    </Stack>
  );
};

export default SalesHandoverTab;
