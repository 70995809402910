import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material';
import { base_url } from '../../../components/Mode';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';
import AdminsAutocompleteDropdown from '../../../components/admins/AdminAutoComplete';

const initialState = {
  lead_owner_id: '',
  lead_accessor_id: '',
};

export default function NewLeadAccessPolicyModal({
  open,
  setOpen,
  allLeadAccessPolicies,
  setAllLeadAccessPolicies,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
}) {
  const [modalData, setModalData] = useState(initialState);
  const [hasModalError, setHasModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');

  useEffect(() => {
    if (hasModalError) {
      setTimeout(() => {
        setHasModalError(false);
      }, 6000);
    }
  }, [hasModalError]);

  const handleAddClick = (e) => {
    const emptyFields = [];
    const body = { ...modalData };
    if (body.lead_owner_id === '') {
      emptyFields.push('lead owner name');
    }
    if (body.lead_accessor_id === '') {
      emptyFields.push('lead accessor name');
    }
    if (emptyFields.length > 0) {
      setHasModalError(true);
      setModalErrorMessage(
        `Please set ${emptyFields.join(', ')} for the new lead access policy`
      );
      return;
    }

    axiosInstance
      .post(`/api/lead-access-policies`, body)
      .then((res) => {
        setAllLeadAccessPolicies([...allLeadAccessPolicies, res.data]);
        setIsSuccess(true);
        setSuccessMessage('Lead Access Policy successfully added.');
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not add the lead access policy.'
        );
      });
  };

  const getLeadOwnerName = () => {
    return (
      <AdminsAutocompleteDropdown
        label='Reportee'
        value={modalData.lead_owner_id}
        onChangeHandler={(e, newValue) => {
          setModalData((prev) => {
            return {
              ...prev,
              lead_owner_id: newValue.value,
            };
          });
        }}
        sx={{
          width: '100%',
          padding: '10px',
        }}
      />
    );
  };
  const getLeadAccessorName = () => {
    return (
      <AdminsAutocompleteDropdown
        label='Manager'
        value={modalData.lead_accessor_id}
        onChangeHandler={(e, newValue) => {
          setModalData((prev) => {
            return {
              ...prev,
              lead_accessor_id: newValue.value,
            };
          });
        }}
        sx={{
          width: '100%',
          padding: '10px',
        }}
      />
    );
  };

  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(false)}
      fullWidth
      maxWidth='sm'
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          onClick={(e) => setOpen(false)}
          style={{ fontSize: '24px' }}
        >
          &times;
        </Button>
      </Box>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            {hasModalError && (
              <Typography
                variant='body2'
                color='error'
              >
                *{modalErrorMessage}
              </Typography>
            )}
            {getLeadAccessorName()}
            {getLeadOwnerName()}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleAddClick}
        >
          Add new lead access policy
        </Button>
      </DialogActions>
    </Dialog>
  );
}
