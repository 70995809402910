import React from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import constants from '../utils/constants';
const AllLeadsInfo = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      width={'max-content'}
      padding='8px'
      margin='8px 0px 20px 0px'
      bgcolor='#f7f9fc'
      border='1px solid #e0e0e0'
      borderRadius='4px'
    >
      <InfoIcon
        color='info'
        fontSize='small'
      />

      <Typography style={{ fontSize: '0.875rem' }}>
        {constants.ALL_LEADS_INFO}
      </Typography>
    </Box>
  );
};

export default AllLeadsInfo;
