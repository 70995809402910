import Modal from '@mui/material/Modal';
import { useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box } from '@mui/material';
import CustomTextField from '../Atoms/CustomTextFiled';
import Text from '../Atoms/Text';
import CustomButton from '../Atoms/CustomButton';
import CustomDropdown from '../Atoms/CustomDropdown';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../components/Mode';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import LinkedinIcon from '../Atoms/SocialMediaIcons/LinkedinIcon';
import InstagramIcon from '../Atoms/SocialMediaIcons/InstagramIcon';
import SnapchatIcon from '../Atoms/SocialMediaIcons/SnapchatIcon';
import DiscordIcon from '../Atoms/SocialMediaIcons/DiscordIcon';
import TwitterIcon from '../Atoms/SocialMediaIcons/TwitterIcon';

const AddSocialMediaLink = ({
  updateSocialMediaLinkData,
  openAddSocialMediaModal,
  closeAddSocialMediaModalHandler,
}) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [socialMediaLinks, setSocialMediaLinks] = useState({
    platform: null,
    url: null,
  });

  const handleClose = () => {
    closeAddSocialMediaModalHandler();
  };

  const handlePlatformChange = (e) => {
    setSocialMediaLinks({
      ...socialMediaLinks,
      platform: e.target.value,
    });
  };

  const handleUrlChange = (e) => {
    setSocialMediaLinks({
      ...socialMediaLinks,
      url: e.target.value,
    });
  };

  const SocialMediaSaveHandler = async () => {
    if (!socialMediaLinks.platform || !socialMediaLinks.url) {
      setHasError(true);
      setErrorMessage('Both URL and Platform field are mandatory');
      return;
    }
    try {
      const dataToSend = {
        link: socialMediaLinks,
      };
      const res = await axiosInstance
        .put(`/api/my-profile/social-links`, dataToSend)

        .then((res) => {
          updateSocialMediaLinkData(res?.data?.updatedData?.link);
          setIsSuccess(true);
          setSuccessMessage('Link added successfully.');
          closeAddSocialMediaModalHandler();
        })
        .catch((err) => {
          setHasError(true);
          setErrorMessage(err.message || 'Error in adding  link.');
        });
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.message);
    }
  };

  const Modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    height: '300px',
    padding: '24px',
    bgcolor: 'background.paper',
    border: '3px dotted white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    overflow: 'hidden',
    borderRadius: '8px',
  };

  const textStyle = {
    fontWeight: 'normal',
    fontSize: '20px',
    fontFamily: 'Arial, sans-serif',
  };
  const text = 'Connect with me';

  const dropdownOptions = [
    {
      value: 'twitter',
      label: 'Twitter',
      icon: (
        <TwitterIcon
          style={{ height: '15px', width: '15px', marginRight: '10px' }}
        />
      ),
    },
    {
      value: 'instagram',
      label: 'Instagram',
      icon: (
        <InstagramIcon
          style={{ height: '15px', width: '15px', marginRight: '10px' }}
        />
      ),
    },
    {
      value: 'snapchat',
      label: 'Snapchat',
      icon: (
        <SnapchatIcon
          style={{ height: '15px', width: '15px', marginRight: '10px' }}
        />
      ),
    },
    {
      value: 'linkedin',
      label: 'Linkedin',
      icon: (
        <LinkedinIcon
          style={{ height: '15px', width: '15px', marginRight: '10px' }}
        />
      ),
    },
    {
      value: 'discord',
      label: 'Discord',
      icon: (
        <DiscordIcon
          style={{ height: '15px', width: '15px', marginRight: '10px' }}
        />
      ),
    },
  ];

  const dropdownAttributes = {
    placeholder: 'Select Platform',
  };

  const dropdownStyle = {
    marginTop: '10px',
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Modal
        open={openAddSocialMediaModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={Modalstyle}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                marginTop: '-10px',
              }}
            >
              <Text
                textStyle1={textStyle}
                text={text}
              />
            </Box>
            <Box sx={{ marginRight: '-20px', marginTop: '-15px' }}>
              <CustomButton
                text={
                  <CloseOutlinedIcon
                    style={{
                      color: '#bdbdbd',
                      fontSize: 30,
                    }}
                  />
                }
                clickHandler={closeAddSocialMediaModalHandler}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '10px',
              marginTop: '15px',
            }}
          >
            <Box
              sx={{
                marginBottom: '15px',
              }}
            >
              <CustomDropdown
                options={dropdownOptions}
                inputAttributes={dropdownAttributes}
                inputStyle={dropdownStyle}
                placeholder={'Select Platform'}
                onChange={handlePlatformChange}
              />
            </Box>
            <Box>
              <CustomTextField
                changeHandler={handleUrlChange}
                customAttributes={{
                  type: 'text',
                  id: 'designation',
                  label: 'Enter URL',
                  placeholder: 'Enter URL',
                }}
                customStyle={{ width: '100%' }}
              />
            </Box>
            <Box
              sx={{
                marginTop: '50px',
                alignSelf: 'center',
              }}
            >
              <CustomButton
                variant={'contained'}
                style={{ width: '200px', height: '40px' }}
                text={'Save'}
                clickHandler={SocialMediaSaveHandler}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddSocialMediaLink;
