import { useEffect, useState } from 'react';
import { base_url } from '../Mode';
import axiosInstance from 'apis/axiosInstance';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import CryptoJS from 'crypto-js';
import EncryptionKeyModal from './EncryptionKeyModal';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';

const BaseSalaryTable = ({ isAccess, setIsAccess }) => {
  const [encryptionKey, setEncryptionKey] = useState('');
  const [encryptionKeyError, setEncryptionKeyError] = useState('');
  const [showKey, setShowKey] = useState(false);
  const [salaryData, setSalaryData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchSalaryData();
  }, []);

  const fetchSalaryData = (url) => {
    axiosInstance
      .get(`${url ? url : '/api/base_salaries'}`)
      .then((res) => {
        res?.data?.length === 0 ? setIsAccess(true) : setIsAccess(false);
        setSalaryData(res?.data);
        const data = res?.data;
        handleShowSalary(data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('Error in fetching Salary');
      });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      renderCell: (params) =>
        params?.row?.name.charAt(0).toUpperCase() + params?.row?.name?.slice(1),
    },
    { field: 'email', headerName: 'Email', width: 350 },
    {
      field: 'base_salary',
      headerName: 'Base Salary (Rs)',
      width: 150,
    },
  ];

  const handleShowSalary = (data) => {
    let dataToDecrypt;
    if (Array.isArray(data) && data?.length !== 0) {
      dataToDecrypt = data;
    } else {
      dataToDecrypt = salaryData;
    }
    if (Array.isArray(dataToDecrypt) && dataToDecrypt.length === 0) {
      setEncryptionKeyError('');
    }

    if (!encryptionKey) {
      setEncryptionKeyError('Encryption key is required');
      return;
    }
    if (encryptionKey.includes(' ')) {
      setEncryptionKeyError('Encryption key should not contain spaces');
      return;
    }

    try {
      const updatedData = dataToDecrypt?.map((entry) => {
        if (entry?.base_salary) {
          const encryptedSalary = entry?.base_salary;
          if (encryptedSalary.startsWith('U2FsdGVkX')) {
            try {
              const decryptedSalary = CryptoJS.AES.decrypt(
                encryptedSalary,
                encryptionKey
              ).toString(CryptoJS.enc.Utf8);
              if (decryptedSalary === '') {
                throw new Error('Wrong encryption key entered');
              }
              setIsAccess(true);
              setEncryptionKeyError('');
              return { ...entry, base_salary: decryptedSalary };
            } catch (error) {
              setEncryptionKeyError('');
              setHasError(true);
              setErrorMessage('Wrong encryption key entered');
              setIsAccess(false);
              return entry;
            }
          }
        }
        return entry;
      });

      setSalaryData(updatedData);
    } catch (error) {
      setHasError(true);
      setErrorMessage('Error processing data decryption');
      setIsAccess(false);
      console.error('Error processing data decryption:', error);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Box sx={{ height: 500, width: '100%' }}>
        {salaryData?.length > 0 && isAccess ? (
          <div style={{ height: 500, width: '70%' }}>
            <DataGrid
              rows={salaryData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              components={{
                Footer: () => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      padding: '10px',
                    }}
                  ></div>
                ),
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
            }}
          >
            No Salary Data
          </div>
        )}
      </Box>

      <EncryptionKeyModal
        setEncryptionKey={setEncryptionKey}
        setEncryptionKeyError={setEncryptionKeyError}
        bulkUploadSalary={handleShowSalary}
        encryptionKey={encryptionKey}
        encryptionKeyError={encryptionKeyError}
        setShowKey={setShowKey}
        showKey={showKey}
        decrypt={true}
      />
    </>
  );
};

export default BaseSalaryTable;
