import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../components/Mode';
import { SelectedAdminOverviewTable } from '../../ManagerDashboard/InsightsVerificationModal';

export function InsightsTable({ date, admin, setHasError, setErrorMessage }) {
  const [insights, setInsights] = useState({});

  useEffect(() => {
    getAdminInsights();
    return () => {};
  }, [date, admin]);

  function getAdminInsights() {
    const queryParams = [
      `team_reports=0`,
      `of=${admin}`,
      `from=${date}`,
      `to=${date}`,
    ];

    const url = `/api/manager?${queryParams.join('&')}`;
    axiosInstance
      .get(url)
      .then((res) => {
        delete res.data.rows.verification;
        setInsights(res.data.rows);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch admin insights"
        );
      });
  }

  return (
    <>
      {Object.keys(insights)?.length > 0 ? (
        <SelectedAdminOverviewTable data={insights} />
      ) : (
        <Typography
          variant='body1'
          component='p'
          textAlign='center'
        >
          No report data found
        </Typography>
      )}
    </>
  );
}
