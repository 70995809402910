import React, { useEffect, useState } from 'react';
import { Switch, Button, Stack, FormControlLabel, Paper } from '@mui/material';
import { getCurrentAdminId } from '../../utils/common';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axiosInstance from 'apis/axiosInstance';

const CronSettings = () => {
  const [settings, setSettings] = useState({});
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    axiosInstance
      .get(`${base_url()}/api/admins/${getCurrentAdminId()}/cron-settings`) // Replace with your API URL
      .then((response) => {
        const data = response.data.reduce((acc, curr) => {
          acc[curr.name] = curr.status; // Convert the array to an object
          return acc;
        }, {});
        setSettings(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setHasError(true);
        setErrorMessage('Error fetching settings data');
      });
  }, []);

  const handleToggle = (name) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: !prevSettings[name],
    }));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Dropped outside the list

    const reorderedSettings = Array.from(Object.entries(settings));
    const [movedItem] = reorderedSettings.splice(result.source.index, 1);
    reorderedSettings.splice(result.destination.index, 0, movedItem);

    const newSettings = Object.fromEntries(
      reorderedSettings.map(([name, status]) => [name, status])
    );
    setSettings(newSettings);
  };

  const handleSave = async () => {
    const formattedSettings = Object.entries(settings).map(
      ([name, status]) => ({ name, status })
    );

    if (formattedSettings.length > 0) {
      try {
        const response = await axiosInstance.post(
          `${base_url()}/api/admins/${getCurrentAdminId()}/update-cron-settings`,
          formattedSettings
        );
        setIsSuccess(true);
        setSuccessMessage('Settings successfully updated');
      } catch (error) {
        console.error('Error updating data:', error);
        setHasError(true);
        setErrorMessage(
          "An error occurred while updating the user's saved settings"
        );
      }
    } else {
      setIsSuccess(true);
      setSuccessMessage('No changes detected');
    }
  };

  return (
    <Stack spacing={2}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Stack spacing={2}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId='columnSettings'
            direction='vertical'
          >
            {(provided) => (
              <Stack
                sx={{
                  height: '35vh',
                  overflow: 'auto',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
                spacing={1}
              >
                {Object.keys(settings).map((col, index) => (
                  <Draggable
                    key={col}
                    draggableId={col}
                    index={index}
                  >
                    {(provided) => (
                      <Paper
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        sx={{
                          padding: '0.2rem',
                          width: '80%',
                        }}
                        variant='elevation'
                        elevation={2}
                      >
                        <FormControlLabel
                          label={`Cron ${col}`}
                          control={
                            <Switch
                              checked={settings[col]}
                              onChange={() => handleToggle(col)}
                            />
                          }
                          sx={{
                            width: 'fit-content',
                          }}
                        />
                      </Paper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
        <Stack direction='row-reverse'>
          <Button
            variant='contained'
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CronSettings;
