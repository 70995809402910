import React, { useState, useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import { Modal } from '@mui/material';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Typography } from '@mui/material';

function LeaderboardIncentiveStructureTable({
  toggleLeaderboardIncentiveStructure,
}) {
  const [selectedIncentive, setSelectedIncentive] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchIncentiveStructureForLeaderboard();
  }, []);

  const fetchIncentiveStructureForLeaderboard = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/drives/currentdrive/incentive/structure`
      );
      const responseData = res?.data;
      const incentiveStructureArray = responseData.map((item) =>
        JSON.parse(item.metadata)
      );
      setSelectedIncentive(incentiveStructureArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    { id: 'No. of Caps', name: 'No. of Caps' },
    { id: 'Incentive Amount', name: 'Incentive Amount' },
    { id: 'Prorata Message', name: 'Prorata Explanation' },
  ];

  return (
    <Modal
      open={true}
      onClose={toggleLeaderboardIncentiveStructure}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          width: '70vw',
          maxWidth: '80vw',
          maxHeight: '80vh',
          overflow: 'auto',
          backgroundColor: 'white',
        }}
      >
        <h1
          style={{
            justifyContent: 'flex-start',
            textAlign: 'start',
            marginLeft: '2rem',
            paddingTop: '20px',
            fontFamily: 'sans-serif',
            fontSize: '20px',
          }}
        >
          Pre-sale Incentive Structure
        </h1>
        {loading ? (
          <p>Loading...</p>
        ) : selectedIncentive.length === 0 ? (
          <p>No incentive structure available</p>
        ) : (
          <Paper
            sx={{ width: '95%', marginBottom: '1rem', marginLeft: '2rem' }}
          >
            <TableContainer>
              <div
                style={{ maxHeight: 'calc(80vh - 72px)', overflowY: 'scroll' }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          style={{
                            backgroundColor: '#1976D2',
                            color: 'white',
                            borderRadius: '2px',
                          }}
                          key={column.id}
                        >
                          {column.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedIncentive &&
                      selectedIncentive.flat().map((row, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor: '#EDF7FF',
                          }}
                        >
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              style={{
                                borderBottom: '5px solid white',
                              }}
                            >
                              {row[column.id] !== null &&
                              row[column.id] !== undefined
                                ? row[column.id]
                                : '-'}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Paper>
        )}
      </div>
    </Modal>
  );
}

export default LeaderboardIncentiveStructureTable;
