import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  Dialog,
  Box,
  Typography,
  DialogTitle,
  IconButton,
  Button,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfettiEffect from './users/ConfettingEffect';
import { base_url } from './Mode';
import { useAuthStateValue } from './auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'apis/axiosInstance';

const AcceptNotificationModel = ({
  openAcceptModel,
  setOpenAcceptModel,
  leadUserId,
}) => {
  const [currLeadData, setCurrLeadData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/api/users/${leadUserId}`)
      .then((res) => {
        setCurrLeadData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Box>
      <ConfettiEffect />
      <Dialog
        open={openAcceptModel}
        onClose={(e) => setOpenAcceptModel(false)}
      >
        <DialogTitle
          sx={{
            width: '500px',
          }}
        >
          <Typography
            variant='h5'
            fontWeight='500'
            textAlign='center'
            display='block'
            width='100%'
          >
            10 min connect lead details
          </Typography>
          <hr style={{ width: '80%', margin: 'auto', marginTop: '20px' }} />
          <DialogContent
            sx={{
              width: '460px',
              paddingLeft: '26px',
              paddingRight: '26px',
              flexGrow: 2,
              display: 'flex',
            }}
          >
            <Stack
              sx={{
                overflow: 'auto',
                width: '400px',
                margin: 'auto',
              }}
            >
              <Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant='h6'
                    fontSize='16px'
                    width='100px'
                  >
                    Name:
                  </Typography>
                  <Typography
                    variant='h6'
                    fontSize='16px'
                  >
                    {`${currLeadData.fname} ${currLeadData.lname}`}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant='h6'
                    fontSize='16px'
                    width='100px'
                  >
                    Lead ID:
                  </Typography>
                  <Typography
                    variant='h6'
                    fontSize='16px'
                  >
                    {currLeadData.id}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant='h6'
                    fontSize='16px'
                    width='100px'
                  >
                    Phone No:
                  </Typography>
                  <Typography
                    variant='h6'
                    fontSize='16px'
                  >
                    {currLeadData.mobile}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant='h6'
                    fontSize='16px'
                    width='100px'
                  >
                    Email:
                  </Typography>
                  <Typography
                    variant='h6'
                    fontSize='16px'
                  >
                    {currLeadData.email}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '20px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{ fontSize: '12px', width: '44%', height: '40px' }}
                    onClick={() => {
                      window.open(`/leads/${currLeadData.id}`, '_blank');
                    }}
                  >
                    View More
                  </Button>
                  <Button
                    variant='outlined'
                    color='inherit'
                    sx={{ fontSize: '12px', width: '44%', height: '40px' }}
                    onClick={(e) => setOpenAcceptModel(false)}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Stack>
          </DialogContent>
        </DialogTitle>
      </Dialog>
    </Box>
  );
};

export default AcceptNotificationModel;
