import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  Box,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { useState } from 'react';

function NotificationPopup({
  open,
  setOpen,
  popupNotifications,
  setNotifications,
  setPopupNotifications,
  fetchNotifications,
}) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleAcknowledgement = () => {
    const notificationIds = Object.keys(popupNotifications).map((key) => {
      const { id } = popupNotifications[key];
      return id;
    });

    const updateBody = {
      notification_ids: notificationIds,
      acknowledged: true,
    };

    const url = `/api/notifications/bulk-update`;
    axiosInstance
      .patch(url, updateBody)
      .then((res) => {
        const { acknowledged_at } = res.data;
        setPopupNotifications({});
        fetchNotifications();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage("Couldn't acknowledge the notification.");
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Dialog
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '700px',
            height: 'fit-content',
            borderRadius: '10px',
            p: 2,
          },
        }}
      >
        <DialogTitle>Notification</DialogTitle>
        <DialogContent m={2}>
          <Box component='ul'>
            {Object.keys(popupNotifications).map((key, index) => {
              const { notes } = popupNotifications[key];
              return (
                <Typography
                  key={index}
                  component='li'
                  sx={{
                    fontSize: '1.2rem',
                    color: 'black',
                    mb: 1,
                  }}
                >
                  {notes}
                </Typography>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleAcknowledgement}
          >
            Acknowledge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NotificationPopup;
