import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../components/Mode';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import constants from '../../../utils/constants';
import ProductivityInsightsSummaryTable from './ProductivityInsightSummaryTable';
import ProductivityInsightDetailedAnalysisTable from './ProductivityInsightDetailedAnalysisTable';
import { getCurrentAdminId, getCurrentAdminRole } from '../../../utils/common';
import { sortByFullName } from '../../../utils/common';
import CustomBackdrop from '../../CustomBackdrop';

const {
  EXPECTED_UNIQUE_LEADS,
  EXPECTED_CPE,
  EXPECTED_AAP,
  EXPECTED_OLS,
  EXPECTED_TAP,
  EXPECTED_CAP,
  MANAGER,
  SENIOR_MANAGER,
} = constants;

const ProductivityInsights = ({
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
}) => {
  const [insightData, setInsightData] = useState('');
  const [insightDateWiseData, setInsightDateWiseData] = useState('');
  const [currentCount, setCurrentCount] = useState('0');
  const [name, setName] = useState('');
  const [activeTab, setActiveTab] = useState('summary');
  const [reporteeID, setReporteeID] = useState(null);
  const [suggestedAction, setSuggestedAction] = useState('');
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const [managerId, setManagerId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); // Yesterday's date
    const startDate = new Date(yesterday);
    startDate.setDate(startDate.getDate() - 7);
    setStartDate(startDate.toISOString().split('T')[0]);
    setEndDate(yesterday.toISOString().split('T')[0]);
    if (getCurrentAdminRole().includes(MANAGER)) {
      setManagerId(getCurrentAdminId());
    }
    if (getCurrentAdminRole().includes(SENIOR_MANAGER)) {
      setManagerId(getCurrentAdminId());
    }
  }, []);

  useEffect(() => {
    getmanagerDropdown();
  }, [managerId]);

  const getmanagerDropdown = async () => {
    setIsLoading(true);
    const res = await axiosInstance.get(
      `/api/manager_actions/subordinate_managers`
    );
    setSubordinateAdmins(res.data);
    setIsLoading(false);
  };
  const managerDropdown = () => {
    return sortByFullName(subordinateAdmins, 'Full_Name').map(
      (manager, index) => {
        return (
          <MenuItem
            value={manager?.Admin_id}
          >{`${manager?.Full_Name}`}</MenuItem>
        );
      }
    );
  };

  let url = `/api/manager/productivity-insights?manager_id=${managerId}&startDate=${startDate}&endDate=${endDate}`;

  useEffect(() => {
    if (managerId) {
      setIsLoading(true);
      axiosInstance
        .get(url)
        .then((res) => {
          const { data } = res;
          setInsightData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(
            err.response?.data?.message ||
              'Could not fetch productivity insights.'
          );
          setIsLoading(false);
        });
    }
  }, [managerId, startDate, endDate]);

  useEffect(() => {
    if (activeTab === 'detailed_analysis' && reporteeID) {
      setIsLoading(true);
      axiosInstance
        .get(
          `/api/manager/productivity-insights/date-wise/${reporteeID}?startDate=${startDate}&endDate=${endDate}`
        )
        .then((res) => {
          const { data } = res;
          setInsightDateWiseData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorMessage(
            err.response?.data?.message || 'Could not fetch detailed analysis.'
          );
          setIsLoading(false);
        });
    }
  }, [activeTab, reporteeID, startDate, endDate]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Forming sentence from summary array for suggested actions
  const getSuggestedActions = (summary) => {
    let summarySentence = '';
    if (summary && Array.isArray(summary)) {
      if (summary && Array.isArray(summary) && summary.length === 1) {
        return (summarySentence = summary[0]);
      }

      summary.map((data, index) => {
        summarySentence +=
          index === summary.length - 1 ? `${data}` : `${data} & `;
      });
    }
    return summarySentence;
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (selectedDate <= yesterday) {
      setEndDate(e.target.value);
    } else {
      setHasError(true);
      setErrorMessage('Selected end date cannot be greater than yesterday');
    }
  };

  //Rendering table on basis of selected tab
  const renderTableForDeepDive = () => {
    if (insightData.length === 0) {
      return (
        <>
          <div
            style={{
              marginTop: '25px',
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '1rem',
              textAlign: 'center',
            }}
          >
            Oops! No Performance Data to show
          </div>
        </>
      );
    }
    if (activeTab === 'summary') {
      return (
        <>
          <div
            style={{
              marginTop: '25px',
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '1.5rem',
            }}
          >
            Suggested Action
          </div>
          <div
            style={{
              margin: '15px 0px 15px 0px',
              fontFamily: 'Arial',
              fontSize: '1rem',
              display: 'inline-block',
            }}
          >
            <span
              style={{
                backgroundColor: '#bbdefb',
                padding: '5px',
              }}
            >
              {getSuggestedActions(suggestedAction)}
            </span>
          </div>
          <ProductivityInsightsSummaryTable currentCount={currentCount} />
        </>
      );
    } else if (activeTab === 'detailed_analysis') {
      return (
        <>
          <div
            style={{
              marginTop: '25px',
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '1.5rem',
            }}
          >
            Suggested Action
          </div>
          <div
            style={{
              margin: '15px 0px 15px 0px',
              fontFamily: 'Arial',
              fontSize: '1rem',
              display: 'inline-block',
            }}
          >
            <span
              style={{
                backgroundColor: '#bbdefb',
                padding: '5px',
              }}
            >
              {getSuggestedActions(suggestedAction)}
            </span>
            <ProductivityInsightDetailedAnalysisTable
              data={insightDateWiseData}
            />
          </div>
        </>
      );
    }
    return null;
  };
  const tablecolumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }}>
          {`${params.row.fname || ''} ${params.row.lname || ''}`}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Suggested Actions',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return getSuggestedActions(params.row.summary);
      },
    },
    {
      field: 'unique_leads',
      headerName: (
        <Tooltip title='Unique Leads'>
          <span>U L</span>
        </Tooltip>
      ),
      width: 120,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.unique_leads,
      cellClassName: (params) => {
        return clsx('super-app', {
          negative: +params.row.unique_leads >= EXPECTED_UNIQUE_LEADS,
          positive: +params.row.unique_leads < EXPECTED_UNIQUE_LEADS,
        });
      },
    },
    {
      field: 'complete_program_explained',
      headerName: (
        <Tooltip title='Compelete Program Explained'>
          <span>C P E</span>
        </Tooltip>
      ),
      width: 120,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.complete_program_explained,
      cellClassName: (params) => {
        return clsx('super-app', {
          negative: +params.row.complete_program_explained >= EXPECTED_CPE,
          positive: +params.row.complete_program_explained < EXPECTED_CPE,
        });
      },
    },
    {
      field: 'eligibility_form_filled',
      headerName: (
        <Tooltip title='Eligibility Form Filled'>
          <span>E F F</span>
        </Tooltip>
      ),
      width: 120,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.eligibility_form_filled,
      cellClassName: (params) => {
        return clsx('super-app', {
          negative: +params.row.eligibility_form_filled >= EXPECTED_AAP,
          positive: +params.row.eligibility_form_filled < EXPECTED_AAP,
        });
      },
    },

    {
      field: 'offer_letter_sent',
      headerName: (
        <Tooltip title='Offer Letter Sent'>
          <span>O L S</span>
        </Tooltip>
      ),
      width: 120,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.offer_letter_sent,
    },

    {
      field: `token_amount_paid`,

      headerName: (
        <Tooltip title='Token Amount Paid'>
          <span>T A P</span>
        </Tooltip>
      ),
      width: 120,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.token_amount_paid,
    },
    {
      field: 'complete_amount_paid',

      headerName: (
        <Tooltip title='Complete Amount Paid'>
          <span>C A P</span>
        </Tooltip>
      ),
      width: 120,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row.complete_amount_paid,
    },
    {
      field: 'summary',
      headerName: 'Summary',
      width: 110,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              setCurrentCount(params.row);
              setName(params.row.fname);
              setReporteeID(params.row.id);
            }}
            aria-label='edit'
            sx={{ fontSize: '0.9rem', fontWeight: '600' }}
          >
            Dive Deep
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <CustomBackdrop open={{ open: isLoading }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: 160,
          margin: 2,
          gap: 2,
        }}
      >
        <TextField
          type='date'
          label='Start Date'
          size='small'
          InputLabelProps={{ shrink: true }}
          onChange={handleStartDateChange}
          value={startDate}
          style={{ marginRight: '16px' }}
        />
        <TextField
          type='date'
          label='End Date'
          size='small'
          InputLabelProps={{ shrink: true }}
          onChange={handleEndDateChange}
          value={endDate}
        />
        {subordinateAdmins && subordinateAdmins.length > 0 ? (
          <FormControl sx={{ minWidth: 180 }}>
            <InputLabel
              id='managers-label'
              sx={{
                marginBottom: 2,
                paddingRight: 1,
                marginTop: '-8px',
              }}
            >
              Managers
            </InputLabel>
            <Select
              labelId='managers-label'
              id='manager-select'
              value={managerId}
              label='Managers'
              size='small'
              onChange={(e) => {
                setManagerId(e.target.value);
              }}
            >
              {managerDropdown()}
            </Select>
          </FormControl>
        ) : (
          ''
        )}
      </Box>
      {managerId ? (
        <>
          <Box
            sx={{
              marginTop: '20px',
              minHeight: '200px',
              maxHeight: '500px',
              overflow: 'auto',
              '& .super-app.negative': {
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                color: '#1a3e72',
                fontWeight: '600',
                '&::before': {
                  content: '""',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '50px',
                  backgroundColor: '#dcedc8',
                  marginRight: '4px',
                  position: 'absolute',
                  zIndex: -1,
                },
              },
              '& .super-app.positive': {
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                color: '#1a3e72',
                fontWeight: '600',
                '&::before': {
                  content: '""',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '50px',
                  backgroundColor: '#ffcdd2',
                  marginRight: '4px',
                  position: 'absolute',
                  zIndex: -1,
                },
              },
            }}
          >
            <DataGrid
              rows={insightData}
              columns={tablecolumns}
              autoHeight
              onRowClick={(params, event) => {
                setCurrentCount(params.row);
                setName(params.row.fname);
                setReporteeID(params.row.id);
                setSuggestedAction(params.row.summary);
              }}
            />
          </Box>

          {name ? (
            <div>
              <div style={{ margin: '0 20px 20px 20px' }}>
                <div
                  style={{
                    marginTop: '10px',
                    padding: '8px',
                    fontWeight: 'bold',
                    fontFamily: 'Arial',
                    fontSize: '2rem',
                  }}
                >
                  Diving into {name}'s performance
                </div>
                <div
                  style={{
                    fontFamily: 'Arial',
                    fontSize: '1.1rem',
                    borderBottom: '1px solid black',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',

                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        padding: '8px',
                        marginRight: '20px',
                        borderBottom:
                          activeTab === 'summary'
                            ? '2px solid #1976D2'
                            : 'black',
                        color: activeTab === 'summary' ? '#1976D2' : 'black',
                      }}
                      onClick={() => handleTabClick('summary')}
                    >
                      Summary
                    </div>
                    <div
                      style={{
                        padding: '8px',
                        borderBottom:
                          activeTab === 'detailed_analysis'
                            ? '2px solid #1976D2'
                            : 'black',
                        color:
                          activeTab === 'detailed_analysis'
                            ? '#1976D2'
                            : 'black',
                      }}
                      onClick={() => handleTabClick('detailed_analysis')}
                    >
                      Detailed Analysis
                    </div>
                  </div>
                </div>
                {renderTableForDeepDive()}
              </div>
            </div>
          ) : (
            <div
              style={{
                marginTop: '100px',
                padding: '8px',
                fontFamily: 'Arial',
                fontSize: '1.5rem',
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              Click on the Deep Dive button above to view a detailed analysis
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            marginTop: '100px',
            padding: '8px',
            fontFamily: 'Arial',
            fontSize: '1.5rem',
            textAlign: 'center',
            fontWeight: 700,
          }}
        >
          Select manager to see the performance funnel
        </div>
      )}
    </>
  );
};

export default ProductivityInsights;
