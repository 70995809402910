import React from 'react';
import LeadSearchBar from './LeadSearchBar';
import { currentAdminIsTraineeOrIC } from '../../../utils/common';

function QuickSearch() {
  const getLeadDetails = (id) => {
    const currentUrl = `/leads/${id}?quick-search`;
    if (currentAdminIsTraineeOrIC()) {
      window.location.href = currentUrl;
    } else {
      window.open(currentUrl, '_blank');
    }
  };

  return (
    <>
      <LeadSearchBar
        {...{
          getLeadDetails,
        }}
      />
    </>
  );
}

export default QuickSearch;
