import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AudioPlayer from '../../AudioPlayer';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../../../Mode';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import ErrorNotifier from '../../../../ToastNotifications/ErrorNotifier';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const MockcallSubmission = ({
  isUploadModalOpen,
  setIsUploadModalOpen,
  submissionId,
  setIsSuccess,
  setSuccessMessage,
  setUploads,
}) => {
  const [uploadedCall, setUploadedCall] = useState([]);
  const [base64String, setBase64String] = useState('');
  const [audioDuration, setAudioDuration] = useState();
  const [selectedFileName, setSelectedFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedCall(file);
    if (!file) {
      return;
    }

    const audio = new Audio();
    audio.src = URL.createObjectURL(file);

    audio.addEventListener('loadedmetadata', () => {
      const duration = audio.duration;
      setAudioDuration(duration);
      convertToBase64(file, (base64) => {
        setBase64String(base64);
        setSelectedFileName(file.name);
      });
    });
  };

  const convertToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
      callback(base64String);
    };
  };

  const handleRemoveClick = () => {
    setSelectedFileName('');
    setBase64String('');
  };
  const handleUploadMockcall = async () => {
    const formData = new FormData();
    formData.append('mp3File', uploadedCall);
    formData.append('duration', audioDuration);
    const url =
      base_url() +
      `/api/training-mockcalls/submissions/${submissionId}/upload-mockcalls?context=mockcalls`;
    setIsLoading(true);
    await axiosInstance
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setIsLoading(false);
        setIsUploadModalOpen(false);
        setIsSuccess(true);
        setSuccessMessage('Recording uploaded successfully.');
        setUploads(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage('An error occurred while uploading the mockcall!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Dialog
      open={isUploadModalOpen}
      maxWidth='md'
    >
      <Paper
        style={{
          borderRadius: '7px',
          height: '30vh',
          width: '30vw',
          textAlign: 'center',
        }}
      >
        {hasError && (
          <ErrorNotifier
            message={errorMessage}
            setHasError={setHasError}
          />
        )}
        {selectedFileName ? (
          <Box>
            <Box
              style={{ display: 'flex' }}
              justifyContent='center'
              mt={5}
            >
              <Box
                sx={{
                  border: '1px solid gray',
                  borderRadius: '8px',
                  width: '21vw',
                  display: 'flex',
                }}
              >
                <AudioPlayer
                  audioSrc={`data:audio/mp3;base64,${base64String}`}
                />
              </Box>
              <Box mt={3}>
                <IconButton
                  color='error'
                  aria-label='remove'
                  onClick={handleRemoveClick}
                  style={{
                    marginTop: '-25px',
                    marginLeft: '3px',
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
            <Box mt={3}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  style={{
                    backgroundColor: '#3994FF',
                    paddingLeft: '25px',
                    paddingRight: '25px',
                  }}
                  variant='contained'
                  onClick={handleUploadMockcall}
                >
                  Confirm Upload
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              flexDirection='column'
              justifyContent='center'
              mt={6}
            >
              <Typography variant='h6'>
                Upload your mockcall recording
              </Typography>
            </Box>
            <Box mt={2}>
              <Button
                component='label'
                variant='contained'
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type='file'
                  id='upload-file'
                  accept='.mp3'
                  onChange={handleFileChange}
                />
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Dialog>
  );
};

export default MockcallSubmission;
